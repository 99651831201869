export const GokwikIcon = () => {
    return (
        <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M12.8863 7.99683L5.13081 15.6872C4.71447 16.1 4.04155 16.1 3.62521 15.6872L0.312254 12.4021C-0.104085 11.9892 -0.104085 11.322 0.312254 10.9091L8.06777 3.21875L12.8863 7.99683Z'
                fill='url(#paint0_linear_7017_79152)'
            />
            <path
                d='M8.61657 6.9457L1.71148 13.7944L0.488281 12.5815L7.39337 5.73438L8.61657 6.9457Z'
                fill='url(#paint1_linear_7017_79152)'
            />
            <path
                d='M6.79939 11.5188L0.312254 5.08611C-0.104085 4.67327 -0.104085 4.006 0.312254 3.59316L3.62521 0.309631C4.04155 -0.10321 4.71447 -0.10321 5.13081 0.309631L11.6179 6.74228L6.79939 11.5188Z'
                fill='url(#paint2_linear_7017_79152)'
            />
            <path
                d='M13.9919 11.1687L9.43159 15.6908C9.01525 16.1036 8.34233 16.1036 7.92599 15.6908L4.61304 12.4056C4.1967 11.9928 4.1967 11.3255 4.61304 10.9127L9.17339 6.39062L13.9919 11.1687Z'
                fill='url(#paint3_linear_7017_79152)'
            />
            <path
                d='M12.9173 6.9457L6.01226 13.7928L4.78906 12.5815L11.6942 5.73438L12.9173 6.9457Z'
                fill='url(#paint4_linear_7017_79152)'
            />
            <path
                d='M15.1569 5.99081L9.43792 0.319844C9.01835 -0.0961979 8.33736 -0.0961979 7.91618 0.319844L4.61936 3.58897C4.1998 4.00501 4.1998 4.68028 4.61936 5.09792L9.86232 10.2968C11.2453 11.6682 11.2453 13.8924 9.86232 15.2637L12.3668 12.7803L15.1569 10.0136C16.2768 8.9031 16.2768 7.10292 15.1569 5.99081Z'
                fill='url(#paint5_linear_7017_79152)'
            />
            <defs>
                <linearGradient
                    id='paint0_linear_7017_79152'
                    x1='0.19221'
                    y1='15.8071'
                    x2='4.89253'
                    y2='11.067'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stop-color='#EDDE5D' />
                    <stop offset='1' stop-color='#F09819' />
                </linearGradient>
                <linearGradient
                    id='paint1_linear_7017_79152'
                    x1='-1.01665'
                    y1='15.2865'
                    x2='2.79663'
                    y2='11.4409'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stop-color='#EDDE5D' />
                    <stop offset='1' stop-color='#F09819' />
                </linearGradient>
                <linearGradient
                    id='paint2_linear_7017_79152'
                    x1='-0.750394'
                    y1='-0.744091'
                    x2='9.84135'
                    y2='9.93737'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stop-color='#EDCE4D' />
                    <stop offset='1' stop-color='#F09819' />
                </linearGradient>
                <linearGradient
                    id='paint3_linear_7017_79152'
                    x1='18.3499'
                    y1='2.07046'
                    x2='6.73808'
                    y2='13.7806'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop offset='0.1617' stop-color='#000428' />
                    <stop offset='1' stop-color='#004B8D' />
                </linearGradient>
                <linearGradient
                    id='paint4_linear_7017_79152'
                    x1='11.7783'
                    y1='6.86358'
                    x2='5.35463'
                    y2='13.3417'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop offset='0.1617' stop-color='#000428' />
                    <stop offset='1' stop-color='#004B8D' />
                </linearGradient>
                <linearGradient
                    id='paint5_linear_7017_79152'
                    x1='-12.1191'
                    y1='-13.7946'
                    x2='11.4313'
                    y2='9.95539'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop offset='0.1617' stop-color='#000428' />
                    <stop offset='1' stop-color='#004B8D' />
                </linearGradient>
            </defs>
        </svg>
    )
}
