import React from 'react'

interface CartIconProps {
    active: boolean
    className?: string
}

const CartIcon: React.FC<CartIconProps> = ({ active, className = '' }) => {
    return (
        <svg
            width='19'
            height='20'
            viewBox='0 0 19 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            className={className}
        >
            <path
                id='Vector'
                fill-rule='evenodd'
                clip-rule='evenodd'
                d='M0.375 1C0.375 0.448 0.825 0 1.379 0H2.884C3.715 0 4.389 0.672 4.389 1.5V2.06L16.963 2.968C17.84 3.023 18.483 3.811 18.36 4.678L17.494 10.712C17.4421 11.0698 17.263 11.3969 16.9895 11.6335C16.7161 11.87 16.3666 12.0001 16.005 12H4.389V14H14.432C14.8267 13.9992 15.2177 14.0762 15.5827 14.2265C15.9476 14.3769 16.2794 14.5977 16.559 14.8763C16.8386 15.1548 17.0606 15.4858 17.2122 15.8502C17.3639 16.2146 17.4423 16.6053 17.443 17C17.443 18.657 16.095 20 14.433 20C13.9525 20.0007 13.4788 19.8862 13.0517 19.666C12.6245 19.4459 12.2564 19.1265 11.9783 18.7347C11.7001 18.3428 11.5199 17.89 11.4529 17.4142C11.3858 16.9384 11.4339 16.4534 11.593 16H6.225C6.38411 16.4534 6.43216 16.9384 6.36513 17.4142C6.29809 17.89 6.11792 18.3428 5.83974 18.7347C5.56155 19.1265 5.19346 19.4459 4.76634 19.666C4.33922 19.8862 3.86553 20.0007 3.385 20C2.99038 20.0007 2.59949 19.9236 2.23466 19.7732C1.86982 19.6228 1.53818 19.402 1.25868 19.1234C0.979174 18.8448 0.757276 18.5139 0.605654 18.1496C0.454033 17.7853 0.375656 17.3946 0.375 17C0.375 15.694 1.213 14.582 2.382 14.17V3.01V2H1.379C1.24742 2.00026 1.11707 1.97461 0.995399 1.92449C0.87373 1.87438 0.763123 1.80079 0.669893 1.70794C0.576663 1.61508 0.502635 1.50476 0.452038 1.3833C0.40144 1.26183 0.375262 1.13158 0.375 1ZM13.429 17C13.429 16.448 13.878 16 14.432 16C14.986 16 15.436 16.448 15.436 17C15.436 17.552 14.986 18 14.432 18C14.1664 18.0003 13.9116 17.8951 13.7235 17.7076C13.5355 17.5201 13.4295 17.2656 13.429 17ZM2.382 17C2.382 16.448 2.832 16 3.386 16C3.94 16 4.389 16.448 4.389 17C4.389 17.552 3.94 18 3.386 18C3.25442 18.0003 3.12407 17.9746 3.0024 17.9245C2.88073 17.8744 2.77012 17.8008 2.67689 17.7079C2.58366 17.6151 2.50964 17.5048 2.45904 17.3833C2.40844 17.2618 2.38226 17.1316 2.382 17Z'
                fill={active ? 'white' : '#5C5F62'}
            />
        </svg>
    )
}

export default CartIcon
