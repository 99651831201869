export const UpiIcon = () => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='14' height='18' fill='none'>
            <path
                fill-rule='evenodd'
                d='M13.166 8.46L9.751 1.571a1.21 1.21 0 0 0-2.244.218l-.227.83-.518-1.046a1.21 1.21 0 0 0-2.244.217L.755 15.569c-.323 1.181 1.116 2.036 1.999 1.188l.944-.907c-.049 1.029 1.232 1.689 2.044.909l7.179-6.893c.38-.365.479-.934.245-1.406zM7.683 3.433l2.488 5.028c.233.472.134 1.04-.245 1.405l-5.423 5.21-.179.654c-.161.59.558 1.018.999.594l7.178-6.893c.19-.182.24-.467.123-.703L9.211 1.838c-.246-.497-.976-.426-1.122.109l-.406 1.485z'
                fill='#00192f'
            />
        </svg>
    )
}
