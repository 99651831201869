import { tieringFaqLink } from '@library/utilities/constants/constants'
import { logEvent } from '@library/utilities/userLogEvents/userLogEvents'
import { setShowKwikFlowTierDrawer } from '@store/actions'
import { getShowKwikFlowTierDrawer } from '@store/actions/selectors'
import { getMerchantDetails, getUserDetails } from '@store/user/selectors'
import { Button, Drawer, FileTextOutlined } from 'gokwik-ui-kit'
import { useDispatch, useSelector } from 'react-redux'

function KwikFlowTierDrawer() {
    const showKwikFlowTierDrawer = useSelector(getShowKwikFlowTierDrawer)
    const merchant = useSelector(getMerchantDetails)
    const user_details = useSelector(getUserDetails)
    const dispatch = useDispatch()

    const features = [
        {
            id: 1,
            title: 'Unlock Unlimited A/B Testing',
            content:
                'With unlimited A/B tests, You can experiment across the funnel – from the moment a customer lands on the payment page till delivery – to improve prepaid share, reduce RTO, and optimize discount burn.',
        },
        {
            id: 2,
            title: 'Run Multiple A/B Tests in Parallel',
            content:
                'Conduct multiple A/B tests simultaneously across different workflows, campaigns, or customer segments to accelerate learning and optimization.',
        },
        {
            id: 3,
            title: 'Full-Funnel Visibility',
            content:
                'Data-driven decisions lead to a stronger bottom line. Track how your tests influence conversion rates, prepaid share, RTO reduction, and cancellations to optimize your results.',
        },
    ]

    const steps = [
        {
            id: 1,
            content: (
                <>
                    Contact us at{' '}
                    <a
                        href={`mailto:${process.env.KWIKFLOW_TIERING_UPGRADE_EMAIL}`}
                        className='text-[#004B8D] underline'
                    >
                        {process.env.KWIKFLOW_TIERING_UPGRADE_EMAIL}
                    </a>{' '}
                    to request an upgrade to KwikFlows Pro.
                </>
            ),
        },
        {
            id: 2,
            content: 'Enjoy Your Kwikflow Pro: Unlock unlimited A/B testing',
        },
    ]

    const handleButtonClick = async () => {
        logEvent(
            'kwikflows_tier_activate_clicked',
            'click',
            'Kwik Flows',
            user_details?.email,
            merchant?.m_id,
            merchant?.short_name,
            user_details?.name,
        )
        dispatch(setShowKwikFlowTierDrawer(false))
    }

    const RenderFooter = () => {
        return (
            <div className='bg-gray-50 shadow-md p-4 flex justify-end gap-2 pr-24'>
                <Button type='primary' className='flex items-center gap-2' onClick={handleButtonClick}>
                    Got it
                </Button>
            </div>
        )
    }

    return (
        <Drawer
            onClose={() => dispatch(setShowKwikFlowTierDrawer(false))}
            open={showKwikFlowTierDrawer}
            placement='right'
            closable={false}
            width={750}
            footer={<RenderFooter />}
            styles={{
                footer: {
                    padding: 0,
                },
            }}
            zIndex={2147483001}
        >
            <div className='gap-4 flex flex-col'>
                <div className='flex items-center gap-2'>
                    <span className='font-semibold text-xl'>You're currently using KwikFlow's free plan.</span>
                </div>
                <span className='font-normal text-sm'>
                    Your free plan offers powerful workflows to engage customers. Ready to level up? Unlock A/B testing,
                    optimize every step of the customer journey, and drive real results. Upgrade to Pro today!
                </span>

                <span className='font-semibold text-sm'>Upgrade to KwikFlow Pro and Supercharge Your Business</span>

                <div className='flex justify-between gap-4'>
                    {features.map(({ id, title, content }) => (
                        <div
                            key={id}
                            className='flex flex-col flex-1 rounded-md gap-2 p-4 my-2 shadow-lg shadow-blue-200/20 bg-gradient-to-br from-opacity-5 to-opacity-10 from-blue-100/20 to-blue-200/40'
                        >
                            <p className='font-semibold text-base'>{title}</p>
                            <span className='text-sm font-normal'>{content}</span>
                        </div>
                    ))}
                </div>

                <div className='bg-gray-50 p-4 rounded-md'>
                    <p className='font-semibold text-base flex items-center gap-2'>
                        How to Upgrade to KwikFlow Pro:{' '}
                        <span className='flex items-center gap-1 text-sm'>
                            <FileTextOutlined className='text-[#004B8D]' />
                            <a href={tieringFaqLink} target='_blank' className='text-[#004B8D]'>
                                Read FAQs
                            </a>
                        </span>
                    </p>
                    <ul className='font-medium text-sm list-none p-0'>
                        {steps.map(({ id, content }) => (
                            <li key={id} className='kwikChatDrawerList relative'>
                                <span className='absolute w-2 h-2 rounded-full bg-black top-2 left-2' />
                                <p className='text-gray-500 m-0 flex items-center gap-1'>Step {id}</p>
                                <p>{content}</p>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </Drawer>
    )
}

export default KwikFlowTierDrawer
