import React, { useCallback, useEffect, useRef, useState } from 'react'
import ReactFlow, {
    addEdge,
    useNodesState,
    useEdgesState,
    ConnectionLineType,
    Handle,
    Position,
    FitViewOptions,
} from 'reactflow'
import 'reactflow/dist/style.css'
import { RcaCards, RcaChildrenCards, RcaLeafCards } from '.'
import { FilterType, rcaCardsData, sortedData } from '@library/utilities/constants/constants'
import {
    getRangeForLabel,
    updateDecileRcaCardsData,
    updatePincodesRcaCardsData,
} from '@library/utilities/helpers/rca_view_data_filtering'
import { getStateCityPincode } from '@store/rca-view/api'
import { message, Tooltip } from 'gokwik-ui-kit'
import { useSelector } from 'react-redux'
import { getRcaViewData } from '@store/rca-view/selector'
import { keyMap } from '@pages/analytics/rca-view'

const CustomCardNode = ({ data }) => {
    const getNodeWidth = (label: string) => (label === '2' ? '192.8' : '99.5')

    const handleSelection = (data) => {
        if (rcaCardsData['filter_type'] === FilterType.PINCODE && data.level === '3') {
            data.selected = false
        }
        if (['2', '9', '15'].includes(data.label)) {
            data.selected = true
        }
        return data.selected ? 'rca-blue' : 'rca-gray'
    }

    const getCountAndLabelText = (data, clickedNode) => {
        let count = rcaCardsData['16']?.count + rcaCardsData['17']?.count + rcaCardsData['18']?.count
        let labelText = rcaCardsData[rcaCardsData['selected_city']?.label]?.filter_data

        if (data?.label === '15' && rcaCardsData['filter_type'] === FilterType.PINCODE) {
            const range = getRangeForLabel(clickedNode?.label)?.range
            count = rcaCardsData[clickedNode?.label]?.count
            labelText = `${range[0]}% - ${range[1]}% RTO Rate`
        }
        return { count, labelText }
    }

    const nodewidth = getNodeWidth(data.label)
    const handlecolor = handleSelection(data)
    const { count, labelText } = getCountAndLabelText(data, rcaCardsData['curr_clicked_node'])

    const renderHandle = (position: Position) => (
        <Handle
            type={position === Position.Top ? 'target' : 'source'}
            position={position}
            isConnectable={false}
            className={`z-[1] !border-none !w-[1px] !h-[1px] ${
                handlecolor === 'rca-blue' ? '!bg-rca-blue' : '!bg-rca-gray'
            }`}
        />
    )

    const renderCardContent = () => {
        switch (data.label) {
            case '1':
                return <RcaCards onEnable={() => data?.defaultFunction(data)} />
            case '2':
            case '9':
                return (
                    <div className={`label-nodes ${data.label === '2' ? 'w-[192.8px]' : 'w-[100px]'}`}>
                        {data.label === '2' ? (
                            <Tooltip title={`RTO Orders: ${rcaCardsData['1']?.total_rto_orders}`}>
                                <span>100% RTO Orders</span>
                            </Tooltip>
                        ) : (
                            <Tooltip title={`RTO Orders: ${rcaCardsData[data.label]?.total_rto_orders}`}>
                                <span>100% RTO Orders of {rcaCardsData[data.label]?.filter_data}</span>
                            </Tooltip>
                        )}
                    </div>
                )
            case '15':
                return (
                    <div className={`label-nodes ${data.label === '2' ? 'w-[192.8px]' : 'w-[99.5px]'}`}>
                        <Tooltip title={`RTO Orders: ${rcaCardsData[data.label]?.total_rto_orders}`}>
                            <span>
                                {count} Pincodes of {labelText}
                            </span>
                        </Tooltip>
                    </div>
                )
            case '16':
            case '17':
            case '18':
                return <RcaLeafCards data={data} onEnable={() => data?.defaultFunction(data)} />
            default:
                return <RcaChildrenCards data={data} onEnable={() => data?.defaultFunction(data)} />
        }
    }

    return (
        <div className='card-block'>
            {data?.label !== '1' &&
                (['2', '9', '15', '16', '17', '18'].includes(data.label) ||
                    !isNaN(rcaCardsData[data?.label]?.rto_share)) &&
                renderHandle(Position.Top)}

            {renderCardContent()}

            {data?.selected && renderHandle(Position.Bottom)}
        </div>
    )
}

const defaultNodeProperties = {
    type: 'customCard',
    position: { x: 0, y: 0 },
    data: {
        defaultFunction: () => {},
        selected: false,
    },
}

const createNode = (id, label, level) => ({
    id,
    ...defaultNodeProperties,
    data: { ...defaultNodeProperties.data, label, level },
})

export const initialNodes = [createNode('1', '1', '1')]

const level2Nodes = [
    createNode('2', '2', '2'),
    createNode('4', '4', '2'),
    createNode('5', '5', '2'),
    createNode('6', '6', '2'),
    createNode('7', '7', '2'),
    createNode('8', '8', '2'),
]

const level3Nodes = [
    createNode('9', '9', '3'),
    createNode('10', '10', '3'),
    createNode('11', '11', '3'),
    createNode('12', '12', '3'),
    createNode('13', '13', '3'),
    createNode('14', '14', '3'),
]

const level4Nodes = [
    createNode('15', '15', '4'),
    createNode('16', '16', '4'),
    createNode('17', '17', '4'),
    createNode('18', '18', '4'),
]

const defaultEdgeProperties = {
    type: 'smoothstep',
    pathOptions: { borderRadius: 50 },
    animated: false,
    style: { strokeWidth: 1 },
}

const createEdge = (id, source, target, level, strokeColor, zIndex = 20) => ({
    id,
    source,
    target,
    data: { level },
    style: { ...defaultEdgeProperties.style, stroke: strokeColor },
    zIndex,
    ...defaultEdgeProperties,
})

const levelColors = {
    1: 'rgb(var(--rca-blue))',
    2: 'rgb(var(--rca-gray))',
}

export const initialEdges = [
    createEdge('1', '1', '2', '1', levelColors[1], 21),
    createEdge('3', '2', '4', '2', levelColors[2], 21),
    createEdge('4', '2', '5', '2', levelColors[2]),
    createEdge('5', '2', '6', '2', levelColors[2]),
    createEdge('6', '2', '7', '2', levelColors[2]),
    createEdge('7', '2', '8', '2', levelColors[2]),
    createEdge('8', '4', '9', '3', levelColors[1], 21),
    createEdge('9', '9', '10', '3', levelColors[2]),
    createEdge('10', '9', '11', '3', levelColors[2]),
    createEdge('11', '9', '12', '3', levelColors[2]),
    createEdge('12', '9', '13', '3', levelColors[2], 21),
    createEdge('13', '9', '14', '3', levelColors[2]),
    createEdge('14', '13', '15', '4', levelColors[1], 21),
    createEdge('15', '15', '16', '4', levelColors[2]),
    createEdge('16', '15', '17', '4', levelColors[2], 21),
    createEdge('17', '15', '18', '4', levelColors[2]),
]

const calculateNodePositions = (nodes, clickedNode) => {
    const nodeSpacingY = 250
    const node1Y = 0
    const viewportWidth = window.innerWidth

    const getVerticalPos = () => {
        const verticalPos = {
            '1': node1Y,
            '2': 160,
            '4': nodeSpacingY,
            '5': nodeSpacingY,
            '6': nodeSpacingY,
            '7': nodeSpacingY,
            '8': nodeSpacingY,
            '9': nodeSpacingY + 130,
            '10': nodeSpacingY + (rcaCardsData['filter_type'] === FilterType.PINCODE ? 240 : 230),
            '11': nodeSpacingY + (rcaCardsData['filter_type'] === FilterType.PINCODE ? 240 : 230),
            '12': nodeSpacingY + (rcaCardsData['filter_type'] === FilterType.PINCODE ? 240 : 230),
            '13': nodeSpacingY + (rcaCardsData['filter_type'] === FilterType.PINCODE ? 240 : 230),
            '14': nodeSpacingY + (rcaCardsData['filter_type'] === FilterType.PINCODE ? 240 : 230),
            '15':
                nodeSpacingY +
                (rcaCardsData['filter_type'] === FilterType.PINCODE
                    ? 140
                    : rcaCardsData['filter_type'] === FilterType.CITY
                    ? 130
                    : 360),
            '16':
                nodeSpacingY +
                (rcaCardsData['filter_type'] === FilterType.PINCODE
                    ? 0
                    : rcaCardsData['filter_type'] === FilterType.CITY
                    ? 230
                    : 460),
            '17':
                nodeSpacingY +
                (rcaCardsData['filter_type'] === FilterType.PINCODE
                    ? 0
                    : rcaCardsData['filter_type'] === FilterType.CITY
                    ? 230
                    : 460),
            '18':
                nodeSpacingY +
                (rcaCardsData['filter_type'] === FilterType.PINCODE
                    ? 0
                    : rcaCardsData['filter_type'] === FilterType.CITY
                    ? 230
                    : 460),
        }
        return verticalPos
    }

    const getHorizontalPos = () => {
        const horizontalPos = { '1': 0 }

        let leftNode, rightNode, midNode

        if (rcaCardsData['filter_type'] === FilterType.STATE || rcaCardsData['filter_type'] === FilterType.DECILE) {
            horizontalPos['6'] = horizontalPos['1'] + 46.5
            horizontalPos['5'] = horizontalPos['6'] - 120
            horizontalPos['4'] = horizontalPos['5'] - 120
            horizontalPos['7'] = horizontalPos['6'] + 120
            horizontalPos['8'] = horizontalPos['7'] + 120

            horizontalPos['10'] = horizontalPos['4']
            horizontalPos['11'] = horizontalPos['5']
            horizontalPos['12'] = horizontalPos['6']
            horizontalPos['13'] = horizontalPos['7']
            horizontalPos['14'] = horizontalPos['8']

            leftNode =
                clickedNode?.label === '10'
                    ? '10'
                    : clickedNode?.label === '14'
                    ? '12'
                    : parseInt(clickedNode?.label) - 1
            rightNode =
                clickedNode?.label === '10'
                    ? '12'
                    : clickedNode?.label === '14'
                    ? '14'
                    : parseInt(clickedNode?.label) + 1
            midNode = clickedNode?.label === '10' ? '11' : clickedNode?.label === '14' ? '13' : clickedNode?.label

            horizontalPos['16'] = clickedNode?.level === '3' ? horizontalPos[leftNode.toString()] : horizontalPos['12']
            horizontalPos['17'] = clickedNode?.level === '3' ? horizontalPos[midNode] : horizontalPos['13']
            horizontalPos['18'] = clickedNode?.level === '3' ? horizontalPos[rightNode.toString()] : horizontalPos['14']

            horizontalPos['15'] = clickedNode?.level == '3' ? horizontalPos[clickedNode.label] : horizontalPos['13']
        } else if (rcaCardsData['filter_type'] === FilterType.CITY) {
            horizontalPos['6'] = horizontalPos['1'] + 46.5
            horizontalPos['5'] = horizontalPos['6'] - 120
            horizontalPos['4'] = horizontalPos['5'] - 120
            horizontalPos['7'] = horizontalPos['6'] + 120
            horizontalPos['8'] = horizontalPos['7'] + 120

            leftNode =
                clickedNode?.label == '4' ? '4' : clickedNode?.label == '7' ? '6' : parseInt(clickedNode?.label) - 1
            rightNode =
                clickedNode?.label == '4' ? '6' : clickedNode?.label == '7' ? '8' : parseInt(clickedNode?.label) + 1
            midNode = clickedNode?.label == '4' ? '5' : clickedNode?.label == '7' ? '7' : clickedNode?.label

            horizontalPos['16'] = clickedNode?.level == '2' ? horizontalPos[leftNode.toString()] : horizontalPos['4']
            horizontalPos['17'] = clickedNode?.level == '2' ? horizontalPos[midNode] : horizontalPos['5']
            horizontalPos['18'] = clickedNode?.level == '2' ? horizontalPos[rightNode.toString()] : horizontalPos['6']

            horizontalPos['15'] = clickedNode?.level == '2' ? horizontalPos[clickedNode.label] : horizontalPos['4']
        } else if (rcaCardsData['filter_type'] === FilterType.PINCODE) {
            horizontalPos['15'] = horizontalPos['1'] + 46.5
            horizontalPos['17'] = horizontalPos['1'] + 46.5
            horizontalPos['16'] = horizontalPos['17'] - 120
            horizontalPos['18'] = horizontalPos['17'] + 120

            horizontalPos['12'] = horizontalPos['1'] + 46.5
            horizontalPos['11'] = horizontalPos['12'] - 120
            horizontalPos['13'] = horizontalPos['12'] + 120
            horizontalPos['10'] = horizontalPos['11'] - 120
            horizontalPos['14'] = horizontalPos['13'] + 120
        }

        horizontalPos['9'] = clickedNode?.level === '2' ? horizontalPos[clickedNode.label] : horizontalPos['4']
        horizontalPos['2'] = horizontalPos['1']
        // horizontalPos['15'] = clickedNode?.level === '3' ? horizontalPos[clickedNode.label] : horizontalPos['13'];

        return horizontalPos
    }

    const verticalPos = getVerticalPos()
    const horizontalPos = getHorizontalPos()

    return nodes.map((node) => ({
        ...node,
        position: {
            x: viewportWidth / 2 + horizontalPos[node.id],
            y: verticalPos[node.id],
        },
    }))
}

const calculateMaxTreeHeight = (nodes) => {
    const screenWidth = window.innerWidth

    const levels = new Set(nodes.map((node) => node?.data?.level))

    let paddingMap = {
        large: { 1: 370, 2: 578, 3: 823, 4: 1035 },
        medium: { 1: 270, 2: 380, 3: 525, 4: 655 },
        small: { 1: 170, 2: 145, 3: 150, 4: 130 },
        extraSmall: { 1: 100, 2: -22.5, 3: -120, 4: -228 },
    }

    if (rcaCardsData['filter_type'] === FilterType.STATE)
        paddingMap = {
            large: { 1: 370, 2: 664, 3: 823, 4: 1040 },
            medium: { 1: 270, 2: 450, 3: 525.5, 4: 650 },
            small: { 1: 170, 2: 189, 3: 152, 4: 143 },
            extraSmall: { 1: 100, 2: 2, 3: -120, 4: -225 },
        }
    else if (rcaCardsData['filter_type'] === FilterType.CITY)
        paddingMap = {
            large: { 1: 370, 2: 665, 3: 793, 4: 1035 },
            medium: { 1: 270, 2: 450, 3: 505, 4: 655 },
            small: { 1: 170, 2: 189, 3: 136, 4: 139 },
            extraSmall: { 1: 100, 2: 2, 3: -129, 4: -228 },
        }
    else if (rcaCardsData['filter_type'] === FilterType.PINCODE)
        paddingMap = {
            large: { 1: 370, 2: 548, 3: 832, 4: 1035 },
            medium: { 1: 270, 2: 357, 3: 531, 4: 655 },
            small: { 1: 170, 2: 131, 3: 150, 4: 130 },
            extraSmall: { 1: 100, 2: -30, 3: -125, 4: -228 },
        }

    let sizeCategory
    if (screenWidth > 1540) sizeCategory = 'large'
    else if (screenWidth > 1280) sizeCategory = 'medium'
    else if (screenWidth > 780) sizeCategory = 'small'
    else sizeCategory = 'extraSmall'

    let levelCount = levels.size

    if (rcaCardsData['filter_type'] === FilterType.PINCODE) {
        levelCount = levelCount > 1 ? levelCount - 1 : levelCount
    }

    let padding = paddingMap[sizeCategory][levelCount] ?? paddingMap[sizeCategory].default ?? 0

    const maxHeight = Math.max(...nodes.map((node) => node.position.y))

    return maxHeight + padding
}

const calculateInitialStateHeight = () => {
    const screenWidth = window.innerWidth

    let paddingMap = {
        large: 40.3,
        medium: 32.4,
        small: 25.3,
        extraSmall: 14.9,
    }

    let sizeCategory
    if (screenWidth > 1540) sizeCategory = 'large'
    else if (screenWidth > 1280) sizeCategory = 'medium'
    else if (screenWidth > 780) sizeCategory = 'small'
    else sizeCategory = 'extraSmall'

    let padding = paddingMap[sizeCategory]

    return padding
}

const RcaViewLayout: React.FC<{
    switchToMoreView: () => void
    filterType: string
    selectedItem: string
    merchantId: number
    fromDate: Date
    toDate: Date
    savedStateRef: React.MutableRefObject<{ nodes; edges; clickedNodeData; selectedNodeIds }>
}> = ({ switchToMoreView, filterType, selectedItem, merchantId, fromDate, toDate, savedStateRef }) => {
    const [clickedNodeData, setClickedNodeData] = useState(savedStateRef.current.clickedNodeData)
    const [currClickedNodeData, setCurrClickedNodeData] = useState(clickedNodeData)
    const [selectedNodeIds, setSelectedNodeIds] = useState(new Set(savedStateRef.current.selectedNodeIds))
    const [filtertype, setFiltertype] = useState(filterType)
    const [selectedItems, setSelectedItem] = useState(selectedItem)

    const reactFlowWrapper = useRef(null)
    const reactFlowInstance = useRef(null)
    const [containerWidth, setContainerWidth] = useState<number | null>(null)
    const [containerHeight, setContainerHeight] = useState<number>(calculateInitialStateHeight())

    const rtoDetailsData = useSelector(getRcaViewData)

    let clickedNode = clickedNodeData
    if (clickedNodeData?.level == '4') {
        savedStateRef.current.nodes.forEach((node) => {
            if (node?.data?.level == '3' && selectedNodeIds.has(node.id)) clickedNode = node.data
        })
    }

    const updatedNodesPositions = calculateNodePositions(savedStateRef.current.nodes, clickedNode)
    const [nodes, setNodes] = useNodesState(updatedNodesPositions)
    const [edges, setEdges, onEdgesChange] = useEdgesState(savedStateRef.current.edges)

    useEffect(() => {
        setFiltertype(filterType)
    }, [filterType])

    useEffect(() => {
        setSelectedItem(selectedItem)
    }, [selectedItem])

    useEffect(() => {
        setNodes(calculateNodePositions(updatedNodesPositions, currClickedNodeData))
        setEdges(savedStateRef.current.edges)
    }, [updatedNodesPositions?.length, filtertype, selectedItems])

    const fitViewOptions: FitViewOptions = {
        padding: 0,
    }

    const getUpdatedNodes = (clickedNode, additionalNodes = []) =>
        calculateNodePositions([...initialNodes, ...additionalNodes], clickedNode)

    const handleFilterTypePincode = (clickedNode) => {
        const isCountAvailable = rcaCardsData[clickedNode.label]?.count > 0
        const filteredLevel4Nodes = level4Nodes.filter((node) => node?.data?.label !== '15')
        return isCountAvailable
            ? calculateNodePositions([...initialNodes, ...level3Nodes, ...level4Nodes, level2Nodes[0]], clickedNode)
            : calculateNodePositions([...initialNodes, ...filteredLevel4Nodes, level2Nodes[0]], clickedNode)
    }

    const handleNodeLevel1 = (clickedNode) => {
        const isPincodeFilter = rcaCardsData['filter_type'] === FilterType.PINCODE
        const additionalNodes = isPincodeFilter
            ? [...level4Nodes.filter((node) => node?.data?.label !== '15'), level2Nodes[0]]
            : level2Nodes
        return getUpdatedNodes(clickedNode, additionalNodes)
    }

    const handleNodeLevel2 = (clickedNode) => {
        const isStateOrDecileFilter =
            rcaCardsData['filter_type'] === FilterType.STATE || rcaCardsData['filter_type'] === FilterType.DECILE
        const additionalNodes = isStateOrDecileFilter
            ? [...level2Nodes, ...level3Nodes]
            : [...level2Nodes, ...level4Nodes]
        return getUpdatedNodes(clickedNode, additionalNodes)
    }

    const handleNodeLevel3 = (clickedNode) => {
        return getUpdatedNodes(clickedNode, [...level2Nodes, ...level3Nodes, ...level4Nodes])
    }

    const useHandleNodeClick = useCallback(
        async (clickedNode) => {
            let updatedNodes = nodes
            let updatedEdges = edges
            setCurrClickedNodeData(clickedNode)
            rcaCardsData['curr_clicked_node'] = clickedNode

            switch (clickedNode.level) {
                case '1':
                    const { keyName, comparedKeyName } = keyMap[rcaCardsData['filter_type']] || {}
                    if (rtoDetailsData?.[keyName].status == 'failed') {
                        message.warning({
                            content: `We're having trouble loading this right now. Please try again later.`,
                        })
                    } else if (
                        rcaCardsData['1']?.rto_rate == 0 ||
                        (rtoDetailsData?.[keyName].status == 'idle' && !rtoDetailsData?.[keyName]?.data?.data)
                    ) {
                        message.warning(
                            `No data available for the selected filters. Please adjust your search and try again.`,
                        )
                    } else if (
                        rcaCardsData['1']?.rto_rate != 0 &&
                        rcaCardsData['8']?.rto_share != 100 &&
                        rcaCardsData['filter_type'] !== FilterType.PINCODE
                    ) {
                        updatedNodes = handleNodeLevel1(clickedNode)
                        updatedEdges = edges.map((edge) => {
                            if (edge.id == '1') {
                                return {
                                    ...edge,
                                    source: clickedNode.label,
                                    style: { stroke: 'rgb(var(--rca-blue))' },
                                    zIndex: 50,
                                }
                            }
                            return { ...edge, style: { stroke: 'rgb(var(--rca-gray))' }, zIndex: 20 }
                        })
                    } else if (rcaCardsData['1']?.rto_rate != 0 && rcaCardsData['filter_type'] == FilterType.PINCODE) {
                        updatedNodes = handleNodeLevel1(clickedNode)
                        updatedEdges = edges.map((edge) => {
                            if (edge.id == '1') {
                                return {
                                    ...edge,
                                    source: clickedNode.label,
                                    style: { stroke: 'rgb(var(--rca-blue))', strokeWidth: 1 },
                                    zIndex: 50,
                                }
                            } else if (['16', '17', '18'].includes(edge.target))
                                return {
                                    ...edge,
                                    source: '2',
                                    style: { stroke: 'rgb(var(--rca-gray))', strokeWidth: 1 },
                                    zIndex: 20,
                                }
                            return { ...edge, style: { stroke: 'rgb(var(--rca-gray))', strokeWidth: 1 }, zIndex: 20 }
                        })
                    }
                    break
                case '2':
                    updatedNodes = handleNodeLevel2(clickedNode)
                    if (
                        rcaCardsData['filter_type'] == FilterType.STATE ||
                        rcaCardsData['filter_type'] === FilterType.DECILE
                    ) {
                        updatedEdges = edges.map((edge) => {
                            if (edge.id == '8') {
                                return {
                                    ...edge,
                                    source: clickedNode.label,
                                    style: { stroke: 'rgb(var(--rca-blue))', strokeWidth: 1 },
                                    zIndex: 50,
                                }
                            }

                            if (
                                edge.target == clickedNode.label ||
                                (edge.source == '2' && edge.target == clickedNode.label) ||
                                edge.id == '1' ||
                                edge.id == '2'
                            )
                                return {
                                    ...edge,
                                    style: { stroke: 'rgb(var(--rca-blue))', strokeWidth: 1 },
                                    zIndex: 50,
                                }
                            else if (edge.data.level == '2' || edge.data.level == '3' || edge.data.level == '4')
                                return {
                                    ...edge,
                                    style: { stroke: 'rgb(var(--rca-gray))', strokeWidth: 1 },
                                    zIndex: 20,
                                }

                            return { ...edge, zIndex: 20 }
                        })
                    } else if (rcaCardsData['filter_type'] == FilterType.CITY) {
                        rcaCardsData['selected_city'] = clickedNode
                        updatedEdges = edges.map((edge) => {
                            if (edge.id === '14') {
                                return {
                                    ...edge,
                                    source: clickedNode.label,
                                    style: { stroke: 'rgb(var(--rca-blue))', strokeWidth: 1 },
                                    zIndex: 50,
                                }
                            }
                            if (edge.id == '8') {
                                return {
                                    ...edge,
                                    source: clickedNode.label,
                                    style: { stroke: 'rgb(var(--rca-blue))', strokeWidth: 1 },
                                    zIndex: 50,
                                }
                            }
                            if (edge.target == clickedNode.label)
                                return {
                                    ...edge,
                                    style: { stroke: 'rgb(var(--rca-blue))', strokeWidth: 1 },
                                    zIndex: 50,
                                }
                            else if (edge.data.level == '2' || edge.data.level == '3' || edge.data.level == '4')
                                return {
                                    ...edge,
                                    style: { stroke: 'rgb(var(--rca-gray))', strokeWidth: 1 },
                                    zIndex: 20,
                                }

                            return { ...edge, zIndex: 20 }
                        })
                    }
                    break
                case '3':
                    updatedNodes = handleNodeLevel3(clickedNode)
                    updatedEdges = edges.map((edge) => {
                        if (edge.id === '14') {
                            return {
                                ...edge,
                                source: clickedNode.label,
                                style: { stroke: 'rgb(var(--rca-blue))', strokeWidth: 1 },
                                zIndex: 50,
                            }
                        }
                        if (edge.id == '8') {
                            return { ...edge, style: { stroke: 'rgb(var(--rca-blue))', strokeWidth: 1 }, zIndex: 50 }
                        }
                        if (edge.target == clickedNode.label)
                            return { ...edge, style: { stroke: 'rgb(var(--rca-blue))', strokeWidth: 1 }, zIndex: 50 }
                        else if (edge.data.level == '3' || edge.data.level == '4')
                            return { ...edge, style: { stroke: 'rgb(var(--rca-gray))', strokeWidth: 1 }, zIndex: 20 }

                        return edge
                    })
                    rcaCardsData['selected_city'] = clickedNode
                    break
                default:
                    if (rcaCardsData['filter_type'] == FilterType.PINCODE) {
                        updatePincodesRcaCardsData(
                            rcaCardsData['filter_type'],
                            sortedData.pincodesData,
                            sortedData.comparedPincodesData,
                            null,
                            clickedNode.label,
                        )
                        updatedNodes = handleFilterTypePincode(clickedNode)
                        updatedEdges = edges.map((edge) => {
                            if (edge.id === '14') {
                                return {
                                    ...edge,
                                    source: clickedNode.label,
                                    style: { stroke: 'rgb(var(--rca-blue))', strokeWidth: 1 },
                                    zIndex: 50,
                                }
                            }
                            if (edge.target == clickedNode.label)
                                return {
                                    ...edge,
                                    style: { stroke: 'rgb(var(--rca-blue))', strokeWidth: 1 },
                                    zIndex: 50,
                                }
                            else if (edge.data.level == '3' && edge.target != '9')
                                return {
                                    ...edge,
                                    source: '15',
                                    style: { stroke: 'rgb(var(--rca-gray))', strokeWidth: 1 },
                                    zIndex: 20,
                                }
                            else if (edge.data.level == '4')
                                return {
                                    ...edge,
                                    style: { stroke: 'rgb(var(--rca-gray))', strokeWidth: 1 },
                                    zIndex: 20,
                                }

                            return edge
                        })
                    } else {
                        updatedEdges = edges.map((edge) => {
                            if (edge.id === '14') {
                                return {
                                    ...edge,
                                    style: { stroke: 'rgb(var(--rca-blue))', strokeWidth: 1 },
                                    zIndex: 50,
                                }
                            }
                            if (edge.target == clickedNode.label)
                                return {
                                    ...edge,
                                    style: { stroke: 'rgb(var(--rca-blue))', strokeWidth: 1 },
                                    zIndex: 50,
                                }
                            else if (edge.data.level == '4')
                                return {
                                    ...edge,
                                    style: { stroke: 'rgb(var(--rca-gray))', strokeWidth: 1 },
                                    zIndex: 20,
                                }

                            return edge
                        })
                    }
                    break
            }

            if (clickedNode.level == '2' && rcaCardsData['filter_type'] == FilterType.STATE) {
                if (rtoDetailsData.citiesData.status == 'loading' || rtoDetailsData.citiesData.status == 'failed') {
                    message.warning({
                        content: `We're having trouble loading this right now. Please try again later.`,
                    })
                } else if (rtoDetailsData.citiesData.status == 'idle' && !rtoDetailsData.citiesData?.data?.data) {
                    message.warning({
                        content: `No data available for the selected filters. Please adjust your search and try again.`,
                    })
                } else {
                    await getStateCityPincode({
                        merchantId,
                        fromDate,
                        toDate,
                        filterType: rcaCardsData['filter_type'],
                        filterValue: rcaCardsData[clickedNode?.label]?.state,
                        clickedNode: clickedNode?.label,
                    })
                }
            } else if (clickedNode.level == '2' && rcaCardsData['filter_type'] == FilterType.DECILE)
                updateDecileRcaCardsData(
                    filterType,
                    sortedData.decileData,
                    sortedData.comparedDecileData,
                    clickedNode.label,
                )
            else if (clickedNode.level == '2' || clickedNode.level == '3') {
                if (rtoDetailsData.pincodesData.status == 'loading' || rtoDetailsData.pincodesData.status == 'failed') {
                    message.warning({
                        content: `We're having trouble loading this right now. Please try again later.`,
                    })
                } else if (rtoDetailsData.pincodesData.status == 'idle' && !rtoDetailsData.pincodesData?.data?.data) {
                    message.warning({
                        content: `No data available for the selected filters. Please adjust your search and try again.`,
                    })
                }
                await getStateCityPincode({
                    merchantId,
                    fromDate,
                    toDate,
                    filterType: FilterType.CITY,
                    filterValue: rcaCardsData[clickedNode?.label]?.city,
                    clickedNode: currClickedNodeData?.label,
                })
            }

            setNodes(updatedNodes)
            setEdges(updatedEdges)
            setClickedNodeData(clickedNode)
        },
        [nodes.length, edges, setNodes, setEdges],
    )

    useEffect(() => {
        if (clickedNodeData) {
            setSelectedNodeIds((prevSelectedNodeIds) => {
                const newSelectedNodeIds = new Set(prevSelectedNodeIds)

                nodes
                    .filter((node) => parseInt(node.data?.level) >= parseInt(clickedNodeData.level))
                    .forEach((node) => newSelectedNodeIds.delete(node.id))

                newSelectedNodeIds.add(clickedNodeData.label)

                return newSelectedNodeIds
            })
        }
        setEdges(edges)
    }, [clickedNodeData, nodes.length])

    useEffect(() => {
        setNodes((prevNodes) => {
            const level2clickednode = Array.from(selectedNodeIds).find((nodelabel) =>
                ['4', '5', '6', '7', '8'].includes(nodelabel as string),
            )

            return prevNodes.map((node) => {
                const targetNode = prevNodes.find((nodef) => nodef.data.label === level2clickednode)

                const updatedNode = {
                    ...node,
                    position: {
                        ...node.position,
                        x: targetNode && node.data.label == '9' ? targetNode.position.x : node.position.x,
                    },
                    data: {
                        ...node.data,
                        defaultFunction:
                            node.data.label == '8' && rcaCardsData['filter_type'] !== FilterType.DECILE
                                ? switchToMoreView
                                : useHandleNodeClick,
                        selected: selectedNodeIds.has(node.id),
                    },
                }

                if ((targetNode && node.data.label == '9') || (currClickedNodeData && node.data.label == '15')) {
                    rcaCardsData[node.data.label] =
                        targetNode && node.data.label == '9'
                            ? rcaCardsData[targetNode?.data?.label]
                            : rcaCardsData[currClickedNodeData?.label]
                }
                return updatedNode
            })
        })
    }, [useHandleNodeClick, selectedNodeIds, nodes.length, filtertype, selectedItems])

    useEffect(() => {
        savedStateRef.current = { nodes, edges, clickedNodeData, selectedNodeIds: Array.from(selectedNodeIds) } // Save state on updates
    }, [nodes, edges, clickedNodeData, selectedNodeIds])

    useEffect(() => {
        const updateContainerSize = () => {
            if (reactFlowWrapper.current) {
                const { width } = reactFlowWrapper.current.getBoundingClientRect()
                setContainerWidth(width)
                const height = calculateMaxTreeHeight(nodes)
                setContainerHeight((height / window.innerHeight) * 100)
            }
        }
        updateContainerSize()

        if (reactFlowWrapper.current) {
            const resizeObserver = new ResizeObserver(() => {
                updateContainerSize()
            })
            resizeObserver.observe(reactFlowWrapper.current)
            return () => resizeObserver.disconnect()
        }
    }, [nodes])

    useEffect(() => {
        if (containerWidth && reactFlowInstance.current) {
            reactFlowInstance.current.fitView(fitViewOptions)
        }
    }, [containerWidth, fitViewOptions])

    const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), [setEdges])

    const onInit = (instance) => {
        reactFlowInstance.current = instance
        instance.fitView(fitViewOptions)
    }

    return (
        <div
            className='reactflow-wrapper'
            ref={reactFlowWrapper}
            style={{
                height: `${containerHeight}%`,
            }}
        >
            <ReactFlow
                className='reactflow-graph'
                nodes={nodes}
                edges={edges}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                fitView={true}
                fitViewOptions={fitViewOptions}
                nodeTypes={{ customCard: CustomCardNode }}
                connectionLineType={ConnectionLineType.SmoothStep}
                zoomOnScroll={false}
                panOnScroll={false}
                zoomOnDoubleClick={false}
                panOnDrag={false}
                preventScrolling={false}
                onInit={onInit}
                zoomOnPinch={false}
            ></ReactFlow>
        </div>
    )
}

export default RcaViewLayout
