import { RightOutlined } from 'gokwik-ui-kit'
import { getTemplateIcon, showPremiumIconOnTemplates } from './helpers'
import { PremiumTemplateIcon } from '@library/images/templates/premiumTemplateIcon'
import { Workflow } from '@library/utilities/interface'

interface TemplateCardProps {
    templateData: Workflow
    showTiering: boolean
    setSelectedTemplate: (template: Workflow) => void
    setShowPreview: (show: boolean) => void
}

const TemplateCard = ({ templateData, showTiering, setSelectedTemplate, setShowPreview }: TemplateCardProps) => {
    const { rule_name, rules, noOfMerchantsUsing, ab_test_toggle } = templateData

    const onTemplateClick = () => {
        setShowPreview(true)
        setSelectedTemplate(templateData)
    }

    return (
        <>
            <div className='w-[280px] h-[224px] flex flex-col border border-solid border-gray-200 rounded-md relative flex-shrink-0 shadow-md'>
                <span className='absolute top-6 left-5'>
                    {showPremiumIconOnTemplates(ab_test_toggle, showTiering) && <PremiumTemplateIcon />}
                </span>
                <span className='w-full flex items-center justify-center bg-custom-gradient'>
                    {getTemplateIcon(rules)}
                </span>
                <div className='flex flex-col justify-around flex-1 px-4'>
                    <p>{rule_name}</p>
                    <div className='group flex items-center justify-between cursor-pointer' onClick={onTemplateClick}>
                        <div className='relative overflow-hidden'>
                            <div className='transition-all duration-300 transform group-hover:translate-x-full'>
                                <p>{noOfMerchantsUsing} Merchants Using</p>
                            </div>
                            <div className='absolute left-0 top-0 transition-all duration-300 transform -translate-x-full group-hover:translate-x-0'>
                                <p className='text-blue-500'>About this template</p>
                            </div>
                        </div>
                        <div className='relative overflow-hidden'>
                            <div className='transition-all duration-300 transform group-hover:translate-x-full'>
                                <RightOutlined />
                            </div>
                            <div className='absolute left-0 top-0 transition-all duration-300 transform -translate-x-full group-hover:translate-x-0 text-blue-500'>
                                <RightOutlined />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TemplateCard
