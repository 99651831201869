import React from 'react'

interface SkuIconProps {
    active: boolean
    className?: string
}

const SkuIcon: React.FC<SkuIconProps> = ({ active, className = '' }) => {
    return (
        <svg
            width='21'
            height='19'
            viewBox='0 0 21 19'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            className={className}
        >
            <path
                d='M20.713 4.45522C20.6077 4.29304 20.4576 4.16487 20.281 4.08622L11.281 0.0862212C11.1532 0.0293742 11.0149 0 10.875 0C10.7351 0 10.5968 0.0293742 10.469 0.0862212L1.469 4.08622C1.29218 4.16478 1.14195 4.29294 1.03651 4.45517C0.931081 4.6174 0.874975 4.80674 0.875 5.00022V19.0002H2.875V5.65022L10.875 2.09422L18.875 5.65022V19.0002H20.875V5.00022C20.8749 4.80671 20.8186 4.61739 20.713 4.45522ZM12.875 7.00022H16.875V11.0002H12.875V7.00022ZM10.875 9.00022H4.875V19.0002H10.875V9.00022ZM16.875 13.0002H12.875V19.0002H16.875V13.0002Z'
                fill={active ? 'white' : '#5C5F62'}
            />
        </svg>
    )
}

export default SkuIcon
