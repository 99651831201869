import { Col, Row, Tabs } from 'gokwik-ui-kit'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Routes, useLocation } from 'react-router-dom'
import { updateBreadcrumbs } from '@gokwik/utilities'
import RTOKlothoConfig from './klotho-config'
import RTOModelConfig from './model-config-control-segment'
import RTOModelRiskThreshold from './model-risk-threshold'
import { getRTOMerchantConfig } from '@store/rto-configs/api'
import { getRTOMerchantConfig as getRTOMerchantConfigSelector } from '@store/rto-configs/selectors'
import { setRtoMerchantConfig } from '@store/rto-configs/index'
import { RtoMerchantConfig } from '../interface'
import { getMerchantDetails } from '@store/user/selectors'

const RTOMerchantConfig = () => {
    const rtoMerchantConfig = useSelector(getRTOMerchantConfigSelector) as RtoMerchantConfig
    const dispatch = useDispatch()
    const location = useLocation()
    const [activeTab, setActiveTab] = useState('model-risk-threshold')
    const merchantDetails = useSelector(getMerchantDetails)

    const fetchRtoConfigData = async () => {
        try {
            const response = await getRTOMerchantConfig()
            if (response?.data?.status_code === 200 && response?.data?.data) dispatch(setRtoMerchantConfig(response.data.data))
            else    dispatch(setRtoMerchantConfig(null))
        } catch (error) {
            console.error('Error fetching RTO Merchant Config:', error)
        }
    }

    const tabs = useMemo(
        () => [
            {
                key: 'model-risk-threshold',
                label: 'Model Risk Threshold Configuration',
                children: <RTOModelRiskThreshold fetchRtoConfigData={fetchRtoConfigData}/>,
            },
            {
                key: 'population-intervention',
                label: 'Population Intervention Configuration',
                children: <RTOModelConfig fetchRtoConfigData={fetchRtoConfigData}/>,
            },
            {
                key: 'klotho-config',
                label: 'Klotho Configurations',
                children: <RTOKlothoConfig fetchRtoConfigData={fetchRtoConfigData}/>,
            },
        ],
        [],
    )
    useEffect(() => {
        if (location.state?.activeTab) {
            setActiveTab(location.state.activeTab)
        }
    }, [location.state])

    useEffect(() => {
        updateBreadcrumbs((prev) => [
            prev[0],
            {
                key: 'settings',
                href: '/rto/settings',
                text: 'RTO Settings',
            },
            {
                key: 'rto-configs',
                href: '/rto/settings/rto-configs',
                text: 'RTO Intelligence',
            },
        ])
    }, [])

    useEffect(() => {
        fetchRtoConfigData()
    }, [])

    return (
        <>
            {merchantDetails?.rto_go_live_date === null || rtoMerchantConfig === null ? (
                <div className="flex justify-center items-center h-screen">
                    <h2 className='text-xl font-bold mb-4'>Please enable RTO API From Admin Dashboard</h2>
                </div>
            ) : (
                <Row className='p-2 rounded bg-neutrals-15' align='middle' justify='space-between'>
                    <Col sm={24}>
                        <Tabs
                            className='inter'
                            items={tabs}
                            activeKey={activeTab}
                            onChange={(key) => {
                                setActiveTab(key);
                            }}
                        />
                    </Col>
                </Row>
            )}
        </>
    )
}

export default RTOMerchantConfig