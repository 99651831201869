import { Col, Input, Modal, Row, Spin, message } from 'gokwik-ui-kit'
import { useCallback, useEffect, useState } from 'react'
import { debounce } from 'lodash'
import { makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { useSelector } from 'react-redux'
import { getMerchantDetails } from '@store/user/selectors'
import ProductList from './product-list'
import { Product, Variant } from '@library/utilities/interface'
import { getSearchProducts } from '@store/actions/api'

interface ProductSearchModalProps {
    showProductListModal: boolean
    setShowProductListModal: (show: boolean) => void
    selectedProducts: Product[]
    setSelectedProducts: (products: Product[]) => void
    selectedProductVariants: Variant[]
    setSelectedProductVariants: (variants: Variant[]) => void
    title: JSX.Element
    isVariant: boolean
}

const ProductSearchModal = ({
    showProductListModal,
    setShowProductListModal,
    selectedProducts,
    setSelectedProducts,
    selectedProductVariants,
    setSelectedProductVariants,
    title,
    isVariant,
}: ProductSearchModalProps) => {
    const merchant_details = useSelector(getMerchantDetails)
    const [list, setList] = useState([])
    const [checkedList, setCheckedList] = useState([...selectedProducts])
    const [checkedVariantsList, setCheckedVariantsList] = useState([...selectedProductVariants])
    const [isLoading, setIsLoading] = useState(false)

    const [searchValue, setSearchValue] = useState('')

    useEffect(() => {
        if (searchValue) {
            debouncedFetchProductsList(searchValue)
        }
    }, [searchValue])

    useEffect(() => {
        const mapProductsWithVariants = (productItem) => {
            const checkedVariantsForProduct = checkedVariantsList.filter(
                (variant) => variant.product_id === productItem.product_id,
            )
            const isAllVariantsSelected =
                checkedVariantsForProduct.length === (productItem.variants?.length || productItem.variantsLength)
            const selectedVariants = checkedVariantsForProduct.map((variantItem) => ({
                variant_name: variantItem.name || variantItem?.variant_name,
                variant_id: variantItem.variant_id,
            }))

            if (isAllVariantsSelected && checkedVariantsForProduct.length > 0) {
                return {
                    product_name: productItem.product_name,
                    product_id: productItem.product_id,
                    variant_ids: selectedVariants?.length > 0 ? selectedVariants : productItem?.variant_ids,
                    variantsLength: productItem.variants?.length || productItem.variantsLength,
                }
            }

            return {
                product_name: productItem.product_name,
                product_id: productItem.product_id,
                variant_ids: selectedVariants?.length > 0 ? selectedVariants : productItem?.variant_ids,
                variantsLength: productItem.variants?.length || productItem.variantsLength,
            }
        }

        const selectedProducts = checkedList.map(mapProductsWithVariants)
        setSelectedProducts(selectedProducts)
    }, [checkedList, checkedVariantsList])

    const debouncedFetchProductsList = useCallback(
        debounce((searchText: string) => {
            setIsLoading(true)
            fetchProductsList(searchText)
        }, 800), // 800 ms delay
        [],
    )

    const onProductCheckChange = (e, product) => {
        const isProductSelected = checkedList?.some((item) => item?.product_id === product?.product_id)
        if (e?.target?.checked && !isProductSelected) {
            setCheckedList((prev) => [...prev, product])
            setCheckedVariantsList((prev) => [...prev, ...product?.variants])
        } else {
            setCheckedList(checkedList?.filter((item) => item?.product_id !== product?.product_id))
            setCheckedVariantsList(
                checkedVariantsList?.filter((variant) => variant?.product_id !== product?.product_id),
            )
        }
    }

    const onProductVariantsCheckChange = (e, product, variant) => {
        const isVariantSelected = checkedVariantsList?.some((item) => item?.variant_id === variant?.variant_id)
        if (e?.target?.checked && !isVariantSelected) {
            if (!checkedList?.some((item) => item?.product_id === variant?.product_id)) {
                setCheckedList((prev) => [...prev, product])
            }
            setCheckedVariantsList((prev) => [...prev, variant])
        } else {
            if (checkedVariantsList?.filter((item) => item?.product_id === product?.product_id)?.length === 1) {
                setCheckedList(checkedList?.filter((item) => item?.product_id !== product?.product_id))
            }
            setCheckedVariantsList(checkedVariantsList?.filter((item) => item?.variant_id !== variant?.variant_id))
        }
    }

    const handleSearchChange = (e) => {
        const value = e.target.value
        setSearchValue(value)
    }

    const fetchProductsList = async (search_text: string) => {
        try {
            let response = await getSearchProducts(search_text, merchant_details?.m_id)
            if (response?.data?.status_code === 200) {
                setList(response?.data?.data)
            } else {

                message.error(`Error fetching products list`)
                setList([])
            }
        } catch (error) {
            console.error('Error fetching products:', error)
            setList([])
        } finally {
            setIsLoading(false)
        }
    }

    const saveDetails = () => {
        setSearchValue('')
        setShowProductListModal(false)
        setSelectedProductVariants(checkedVariantsList)
    }

    return (
        <Modal
            title={title}
            width={555}
            cancelText={'Cancel'}
            okText={'Add'}
            centered
            open={showProductListModal}
            onOk={() => saveDetails()}
            onCancel={() => {
                setSearchValue('')
                setShowProductListModal(false)
                setCheckedList([])
                setCheckedVariantsList([])
            }}
        >
            <div>
                <Row>
                    {' '}
                    <Col span={24}>
                        <Input
                            value={searchValue}
                            onChange={handleSearchChange}
                            placeholder={'Search Products...'}
                            allowClear
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        {isLoading ? (
                            <div className='flex items-center justify-center mt-5'>
                                <Spin />
                            </div>
                        ) : (
                            <ProductList
                                list={list}
                                onProductCheckChange={onProductCheckChange}
                                onProductVariantsCheckChange={onProductVariantsCheckChange}
                                checkedList={checkedList}
                                checkedVariantsList={checkedVariantsList}
                                isVariant={isVariant}
                            />
                        )}
                    </Col>
                </Row>
            </div>
        </Modal>
    )
}

export { ProductSearchModal }
