import {
    awbAwaitedText,
    defaultImpactData,
    impactViewMapping,
    variantAName,
} from '@library/utilities/constants/constants'
import { getAbTestControlActionName, getActionNameByWorkflowResponse } from '@library/utilities/helpers/helper'
import { Workflow } from '@library/utilities/interface'
import { getABTestMetrics } from '@store/actions/api'
import { getMerchantDetails } from '@store/user/selectors'
import { ArrowDownOutlined, ArrowUpOutlined, Card, InfoCircleOutlined, message, Tooltip } from 'gokwik-ui-kit'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

interface AbImpactViewProps {
    workflow: Workflow
}

interface ImpactData {
    ab_test_flag_id: string
    merchant_id: number
    total_carts: number
    total_orders: number
    total_cod_orders: number
    missing_awb_count: number
    total_rto_orders: number
    total_delivered_orders: number
    total_cancelled_orders: number
    cod_rto_orders: number
    prepaid_rto_orders: number
    total_prepaid_orders: number
    cod_share: number
    conversion_rate: number
    rto_rate: number
    cod_rto_rate: number
}

function AbImpactView({ workflow }: AbImpactViewProps) {
    const merchantDetails = useSelector(getMerchantDetails)
    const merchant_id = merchantDetails.id
    const [treatMentData, setTreatmentData] = useState(defaultImpactData)
    const [controlData, setControlData] = useState(defaultImpactData)
    const [loading, setLoading] = useState(false)
    const [showAwaitedTooltip, setShowAwaitedTooltip] = useState(false)

    const fetchABTestMetrics = async (abTestFlagId: string | null, merchantId: number) => {
        try {
            setLoading(true)
            const response = await getABTestMetrics(abTestFlagId, merchantId)
            if (response?.data?.data?.length) {
                const { data } = response.data
                const treatMentData =
                    data.find((item: ImpactData) => item.ab_test_flag_id?.toLowerCase().includes('treatment')) ??
                    defaultImpactData
                const controlData =
                    data.find((item: ImpactData) => item.ab_test_flag_id?.toLowerCase().includes('control')) ??
                    defaultImpactData
                setTreatmentData(treatMentData)
                setControlData(controlData)
            }
        } catch (err) {
            message.error('Unable to find A/B test Impact')
            console.error(err)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        const abTestFlagId = workflow?.ab_test_flag?.flag_id ?? null
        if (abTestFlagId && merchant_id) {
            fetchABTestMetrics(abTestFlagId, merchant_id)
        }
        const abTestEnabledOn = workflow?.ab_test_enabled_on ?? new Date()
        const showAwbMetricsAfterDays = workflow?.show_awb_metrics_after_days ?? 0
        const resultDate = new Date(abTestEnabledOn)
        resultDate.setDate(resultDate.getDate() + showAwbMetricsAfterDays)

        const currentDate = new Date()
        const showAwaitedTooltip = resultDate > currentDate
        setShowAwaitedTooltip(showAwaitedTooltip)
    }, [workflow])

    const renderArrow = (datakey: number = 0, compareDataKey: number = 0, key: string) => {
        let compareDataConverted = compareDataKey
        let dataConverted = datakey

        if (impactViewMapping[key]?.type !== 'number') {
            compareDataConverted = roundOffPercentageValue(compareDataConverted)
            dataConverted = roundOffPercentageValue(dataConverted)
        }
        if (dataConverted === compareDataConverted) return null
        
        const arrowType = impactViewMapping[key]?.arrowType

        const isPositive = arrowType === 'positive'
        const isNegative = arrowType === 'negative'

        if (isPositive) {
            return dataConverted > compareDataConverted ? (
                <ArrowUpOutlined className='text-[#19970E] flex items-center justify-center' />
            ) : (
                <ArrowDownOutlined className='text-[#FF4D4F] flex items-center justify-center' />
            )
        }

        if (isNegative) {
            return dataConverted > compareDataConverted ? (
                <ArrowUpOutlined className='text-[#FF4D4F] flex items-center justify-center' />
            ) : (
                <ArrowDownOutlined className='text-[#19970E] flex items-center justify-center' />
            )
        }

        return null
    }

    const getImpactTooltipText = (data) => {
        return `Delivery status only available for ${roundOffPercentageValue(
            data?.available_awb_order_perc,
        )}% of orders`
    }

    const roundOffPercentageValue = (value: number) => {
        const roundedValue = Math.round(value * 10) / 10 // Round to one decimal place
        return roundedValue % 1 === 0 ? Math.round(roundedValue) : +roundedValue.toFixed(1)
    }

    const renderCardContent = (data: ImpactData, compareData: ImpactData, variantType: string) =>
        data && Object.keys(data).map(
            (key) =>
                impactViewMapping[key] && (
                    <p className='flex items-center justify-between leading-6' key={key}>
                        <span>{impactViewMapping[key]?.label}</span>
                        <span className='flex items-center'>
                            {impactViewMapping[key]?.showTooltip && showAwaitedTooltip ? (
                                <span className='text-[#000000E0] italic'>Awaited</span>
                            ) : (
                                <span className='text-[#000000E0] font-semibold'>
                                    {impactViewMapping[key]?.type === 'number'
                                        ? data[key]
                                        : `${roundOffPercentageValue(data[key])}%`}
                                </span>
                            )}
                            {variantType === variantAName && (
                                <>
                                    <span className='min-w-6 min-h-4 flex items-center justify-center'>
                                        {!(impactViewMapping[key]?.showTooltip && showAwaitedTooltip) && (
                                            <span className='ml-1'>
                                                {renderArrow(Number(data?.[key]), Number(compareData?.[key]), key)}
                                            </span>
                                        )}
                                        {impactViewMapping[key]?.showTooltip && (
                                            <Tooltip
                                                placement='bottom'
                                                title={showAwaitedTooltip ? awbAwaitedText : getImpactTooltipText(data)}
                                            >
                                                <InfoCircleOutlined className='flex items-center justify-center ml-1' />
                                            </Tooltip>
                                        )}
                                    </span>
                                </>
                            )}
                        </span>
                    </p>
                ),
        )

    return (
        <div className='flex items-start gap-4 mt-4  border-t-2 border-gray-500 fade-in-delayed'>
            <div>
                <p className='mb-2 text-[#000000E0] font-medium'>
                    Variant A Impact {`(${getActionNameByWorkflowResponse(workflow)})`}
                </p>
                <Card size='small' className='text-[#00000080] font-medium' loading={loading} style={{ minWidth: 420 }}>
                    {treatMentData && renderCardContent(treatMentData, controlData, 'variantA')}
                </Card>
            </div>

            <div>
                <p className='mb-2 text-[#000000E0] font-medium'>
                    Variant B Impact {`(${getAbTestControlActionName(workflow)})`}
                </p>
                <Card size='small' className='text-[#00000080] font-medium' loading={loading} style={{ minWidth: 420 }}>
                    {controlData && renderCardContent(controlData, treatMentData, 'variantB')}
                </Card>
            </div>
        </div>
    )
}

export default AbImpactView
