import React, { useState, ReactNode, useMemo, useRef, useEffect } from 'react'
import { Layout, NavigationMenu, QuestionOutlined, Select, SolutionOutlined, TagOutlined, WalletOutlined, WarningFilled } from 'gokwik-ui-kit'
import { useNavigate, Routes, Route, useLocation } from 'react-router-dom'
import RTOAnalytics from './overview'
import RtoDetails from '../rto_details'
import { useSelector } from 'react-redux'
import { getUserConfig, getMerchantDetails, getUserDetails } from '@store/user/selectors'
import EmptyPageData from '@library/components/empty-page-data'
import { logEvent } from '@library/utilities/userLogEvents/userLogEvents'
import RTOSummary from '../rto_analytics_summary'

const { Sider } = Layout

interface MenuItem {
    key: string
    label: ReactNode
    icon?: ReactNode
    children?: MenuItem[]
    type?: 'group'
}

function createMenuItem(
    label: ReactNode,
    key: string,
    icon?: ReactNode,
    children?: MenuItem[],
    type?: 'group',
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
        type,
    }
}
export default function () {
    const route = useLocation()
    const navigate = useNavigate()
    const previousOpenedItems = useRef({
        openedKey: '',
        selectedKey: '',
    })
    const merchantConfig = useSelector(getUserConfig)
    const merchantData = useSelector(getMerchantDetails)
    const user_details = useSelector(getUserDetails)
    const { selected_products, show_rto_analytics } = merchantConfig;
    const showRTOAnalytics = selected_products?.rto && show_rto_analytics;

    if(merchantConfig && !showRTOAnalytics){
        return (
            <EmptyPageData
                title="You currently don't have access to this page!"
                icon={<WarningFilled className='text-[#ffc107] text-7xl' />}
            />
        )
    }

    const settingsList = [
        {
            title: 'SummaryView',
            path: '/',
            component: RTOSummary,
        },
        {
            title: 'Overview',
            path: '/overview',
            component: RTOAnalytics,
        },
        {
            title: 'Overview',
            path: '/overview/:filter_type/:filter_value/:fromDate/:toDate',
            component: RTOAnalytics,
        },
        {
            title: 'Deep-Dive',
            path: '/rto-tables/',
            component: RtoDetails,
        },
        {
            title: 'Deep-Dive',
            path: '/rto-tables/:filter_type',
            component: RtoDetails,
        },
        {
            title: 'Summary View',
            path: '/summary',
            component: RTOSummary,
        } 
    ]

    const menuItems = () => {
        let items = [
            // createMenuItem(
            //     'Overview',
            //     '/rto/analytics/overview',
            //     <WalletOutlined />,
            // ),
            createMenuItem(
                'Summary View',
                '/rto/analytics/summary',
                <SolutionOutlined />,
            ),
            // createMenuItem(
            //     'Deep-Dive',
            //     '/rto/analytics/rto-tables/',
            //     <TagOutlined />,
            // ),
        ];    
        return items;
    }

    const pathMap = {
        '/rto/analytics/overview': 'overview',
        '/rto/analytics/rto-tables/': 'deep_dive',
        '/rto/analytics/summary': 'summary',
    }

    const openItems = useMemo(() => {
        const menu = menuItems()

        const openedKey = menu.find((item) => {
            if (route.pathname.includes(item.key)) {
                return true;
            }
            return false
        })

        if (openedKey) {
            previousOpenedItems.current = {
                openedKey: openedKey.key,
                selectedKey: openedKey.key,
            }
            return {
                openedKey: openedKey.key,
                selectedKey: openedKey.key,
            }
        } 
        else {

            previousOpenedItems.current = {
                openedKey: '/rto/analytics/summary',
                selectedKey: '/rto/analytics/summary',
            }
            return {
                openedKey: '/rto/analytics/summary',
                selectedKey: '/rto/analytics/summary',
            }
        }
    }, [route.pathname])

    useEffect(() => {
        logEvent('kwik_rto_rto_analytics_clicked', 'click', 'Kwik RTO Analytics', user_details?.email, merchantData?.m_id, merchantData?.short_name, user_details?.name);
    }, [merchantData,user_details])

    const handleMenuSelection = ({ key }: any) => {
        logEvent('kwik_rto_rto_analytics_' + pathMap[key] + '_clicked', 'click', 'Kwik RTO Analytics', user_details?.email, merchantData?.m_id, merchantData?.short_name, user_details?.name);
        navigate(key)
    }

    return (
        <div className='w-full bg-white'>
            <Layout className='bg-white h-full '>
                <Sider
                    className='bg-white h-full border-0 border-gray-100 border-r-2 fixed overflow-hidden rto-sider'
                >
                    <div>
                        <NavigationMenu
                            onClick={handleMenuSelection}
                            defaultOpenKeys={[openItems.openedKey]}
                            defaultSelectedKeys={[openItems.selectedKey]}
                            key={route.pathname}
                            className=''
                            items={menuItems()}
                            inlineCollapsed={true}
                            mode='inline'
                        />
                    </div>
                </Sider>
                <Layout className='px-0 overflow-auto'>
                    <Routes>
                        {settingsList.map((item, index) => (
                            <Route key={index} path={item.path} element={<item.component />} />
                        ))}
                    </Routes>
                </Layout>
            </Layout>
        </div>
    )
}
