import { Button, Form, Select, Tooltip, InfoCircleOutlined, PlusCircleOutlined } from 'gokwik-ui-kit'
import RenderActionInput from './actionInputs'
import { useDispatch, useSelector } from 'react-redux'
import { setModalStates } from '@store/actions'
import { getSecondaryActionInputs, getSelectValue } from '@library/utilities/helpers/helper'
import { getAbTestSettings, getModalStates } from '@store/actions/selectors'
import {
    actionTypes,
    secondaryActionText,
} from '@library/utilities/constants/constants'
import React from 'react'
import { ActionType } from '@library/utilities/interface'

interface ActionFormItemsProps {
    actionType: ActionType
    secondaryActionType: ActionType
    showSecondaryAction: boolean
    setShowSecondaryAction: (value: boolean) => void
    setSecondaryAction: (value: ActionType) => void
    setPrimaryAction: (value: ActionType) => void
    setActionState: (
        value: Partial<{ primary: ActionType; secondary: ActionType; showSecondaryAction: boolean }>,
    ) => void
    actionInputOptions: any
    merchantDiscountConfig: any
}

const ActionFormItems: React.FC<ActionFormItemsProps> = ({
    actionType,
    secondaryActionType,
    showSecondaryAction,
    setShowSecondaryAction,
    setSecondaryAction,
    setPrimaryAction,
    setActionState,
    actionInputOptions,
    merchantDiscountConfig = [],
}) => {
    const dispatch = useDispatch()
    const abTestSettings = useSelector(getAbTestSettings)
    const modalStates = useSelector(getModalStates)
    const abTestEnabledOn = abTestSettings?.abTestEnabledOn
    const isActionDisabled = !!abTestEnabledOn
    const isPreviewVisible = modalStates.isPreviewVisible
    const isSecondaryPreviewVisible = modalStates.isSecondaryPreviewVisible

    return (
        <>
            <Form.Item
                name='select-action'
                rules={[{ required: true, message: '' }]}
                initialValue={getSelectValue(actionType.action)}
                className={`bg-gray-50 p-3 !mb-0 border-solid border-gray-200 shadow-sm ${
                    showSecondaryAction ? 'rounded-t-md border-t border-b-0' : 'rounded-md border'
                }`}
            >
                {showSecondaryAction ? (
                    <p className='flex items-center justify-between mb-3 font-semibold'>Primary Action</p>
                ) : null}

                <div className='flex items-center justify-between'>
                    <Select
                        className='!w-80'
                        placeholder='Select Action'
                        onChange={(value, label) => {
                            setActionState({ primary: { action: value }, secondary: { action: '' } })
                        }}
                        value={actionType.action || 'Select Option'}
                        options={[
                            ...actionInputOptions,
                            ...(merchantDiscountConfig.length != 0
                                ? [{ value: 'apply_freebie', label: 'Apply Freebie' }]
                                : []),
                        ]}
                        disabled={isActionDisabled}
                    />
                    <Button
                        type='link'
                        onClick={() => dispatch(setModalStates({ ...modalStates, isPreviewVisible: true }))}
                    >
                        {isPreviewVisible ? 'Close Preview' : 'Preview'}
                    </Button>
                </div>

                <RenderActionInput
                    actionType={actionType}
                    setActionType={(value: ActionType) => setPrimaryAction(value)}
                    primary={true}
                    merchantDiscountConfig={merchantDiscountConfig}
                />
                {!showSecondaryAction && actionType.action !== actionTypes.allowCod && !isActionDisabled ? (
                    <div className='rounded-b-md p-2 flex flex-col gap-2 bg-[#E6F4FF] mt-5 shadow-sm'>
                        <p>You’re allowed to add two actions on same work flow</p>
                        <div className='text-[#1677FF] cursor-pointer' onClick={() => setShowSecondaryAction(true)}>
                            <PlusCircleOutlined />
                            <span className='ml-1'>Add Action</span>
                        </div>
                    </div>
                ) : null}
            </Form.Item>

            {showSecondaryAction ? (
                <Form.Item
                    name='select-action-secondary'
                    initialValue={getSelectValue(secondaryActionType.action)}
                    className='bg-gray-50 p-3 !mb-0 rounded-b-md border-b border-solid border-gray-200 shadow-sm'
                >
                    <p className='flex items-center justify-between mb-3'>
                        <span className='font-semibold'>
                            Secondary Action
                            <Tooltip placement='bottom' title={secondaryActionText}>
                                <InfoCircleOutlined className='ml-2' />
                            </Tooltip>
                        </span>
                        <span
                            className='uppercase text-[#FF4D4F] cursor-pointer'
                            onClick={() => {
                                setActionState({ secondary: { action: '' }, showSecondaryAction: false })
                            }}
                        >
                            Remove
                        </span>
                    </p>
                    <div className='flex items-center justify-between'>
                        <Select
                            className='!w-80'
                            placeholder='Select Action'
                            onChange={(value, label) => {
                                setSecondaryAction({ action: value })
                            }}
                            value={secondaryActionType.action || 'Select Option'}
                            options={getSecondaryActionInputs(actionType.action)}
                            disabled={isActionDisabled}
                        />
                        <Button
                            type='link'
                            onClick={() =>
                                dispatch(setModalStates({ ...modalStates, isSecondaryPreviewVisible: true }))
                            }
                        >
                            {isSecondaryPreviewVisible ? 'Close Preview' : 'Preview'}
                        </Button>
                    </div>
                    <RenderActionInput
                        actionType={secondaryActionType}
                        setActionType={setSecondaryAction}
                        primary={false}
                        merchantDiscountConfig={merchantDiscountConfig}
                    />
                </Form.Item>
            ) : null}
        </>
    )
}

export default ActionFormItems
