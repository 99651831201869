import { CaretDownFilled, CaretUpFilled, Flex, Tooltip } from 'gokwik-ui-kit';
const heroValueFormatter = {
    percentage: (val: number) => `${val}%`,
    number: (val: number) =>
        Intl.NumberFormat('en-US', {
            //@ts-ignore
            notation: 'compact',
        })
            .format(val)
            .replace('T', 'K'),
    amount: (val: number) =>
        Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR',
            //@ts-ignore
            notation: 'compact',
        })
            .format(val)
            .replace('T', 'K'),
}
interface HeroValueProps {
    data: number | null
    deltaValue: number | null
    heroValueTransform?: 'percentage' | 'number' | 'amount'
    heading?: string
    calledBy?: string
}

const CustomHeroValue: React.FC<HeroValueProps> = ({ data, deltaValue, heroValueTransform, heading, calledBy }) => {
    const isDecreaseGreen = [
        'total rto orders',
        'cod rto orders',
        'overall rto rate',
        'cod rto rate',
        'overall rto orders',
        'cod share',
        'cod orders',
    ].includes(heading.toLowerCase().trim())

    return (
        <Flex align='center' gap='4px'>
            {calledBy === 'summaryBlock' ? (
                <Tooltip title={data || data === 0 ? Intl.NumberFormat('en-IN').format(data) : ''}>
                    <span className='summary-value'>
                        {data || data === 0 ? heroValueFormatter[heroValueTransform || 'number'](data) : ''}
                    </span>
                </Tooltip>
            ) : (
                <span className='summary-value inter font-semibold text-2xl'>
                    {data || data === 0 ? Intl.NumberFormat('en-IN').format(data) : ''}
                </span>
            )}

            {(deltaValue || deltaValue === 0) && (
                <div className='flex items-center gap-x-1'>
                    {+deltaValue >= 0 ? (
                        <CaretUpFilled
                            className={isDecreaseGreen ? 'text-error-700' : 'text-success-600'}
                        />
                    ) : (
                        <CaretDownFilled
                            className={isDecreaseGreen ? 'text-success-600' : 'text-error-700'}
                        />
                    )}
                    <span
                        className={`${
                            +deltaValue >= 0
                                ? isDecreaseGreen
                                    ? 'text-error-700'
                                    : 'text-success-600'
                                : isDecreaseGreen
                                ? 'text-success-600'
                                : 'text-error-700'
                        } font-semibold text-sm`}
                    >
                        {Math.abs(deltaValue)}%
                    </span>
                </div>
            )}
        </Flex>
    )
}

export default CustomHeroValue
