import { CartTemplateIcon } from '@library/images/templates/cartTemplateIcon'
import { CustomerTemplateIcon } from '@library/images/templates/customerTemplateIcon'
import { RTOTemplateIcon } from '@library/images/templates/rtoTemplateIcon'
import { SKUTemplateIcon } from '@library/images/templates/skuTemplateIcon'
import { UTMTemplateIcon } from '@library/images/templates/utmTemplateIcon'
import { AddressTemplateIcon } from '@library/images/templates/addressTemplateIcon'

const categoryMap = {
    rto_reduction: 'RTO Reduction',
    fraud_control: 'Fraud Control',
    improve_prepaid: 'Increase Prepaid Share',
    most_used_in_gokwik: 'Most Used in Gokwik',
    cod_blocking: 'COD Blocking',
    cod_prompt: 'COD Prompt',
    partial_cod: 'Partial COD',
    cod_fees: 'COD Fees',
    popular_ab_test: 'Popular A/B Tests',
}

const conditionCategoryMapper = {
    customer_type: 'CUSTOMER',
    phone_number: 'CUSTOMER',
    billing_name: 'CUSTOMER',
    billing_email: 'CUSTOMER',
    customer_since_dt: 'CUSTOMER',
    address_length: 'ADDRESS',
    cod_rate: 'CUSTOMER',
    duplicate_order: 'CUSTOMER',
    past_delivery: 'CUSTOMER',
    past_shipped: 'CUSTOMER',
    shipping_state: 'ADDRESS',
    shipping_city: 'ADDRESS',
    pincode: 'ADDRESS',
    cart_value: 'CART',
    total_line_items: 'CART',
    is_gst_cart: 'CART',
    utm_source: 'UTM',
    utm_medium: 'UTM',
    utm_campaign: 'UTM',
    sku_ids: 'SKU',
    product_id: 'SKU',
    risk_flag: 'RTO_RISK',
    risk_reason: 'RTO_RISK',
    product_title: 'SKU',
    variant_ids: 'SKU',
    rto_score: 'RTO_RISK',
}

const categoryToIconMap = {
    CUSTOMER: CustomerTemplateIcon,
    ADDRESS: AddressTemplateIcon,
    CART: CartTemplateIcon,
    UTM: UTMTemplateIcon,
    SKU: SKUTemplateIcon,
    RTO_RISK: RTOTemplateIcon,
}

const actionNames = {
    upi: 'UPI Discount',
    codFees: 'COD Fees',
    ppcod: 'Partial COD',
    codBlock: 'COD Blocking',
    codPrompt: 'COD Prompt',
    codCaptcha: 'COD Captcha',
}

const hoveredCODActions = [actionNames.codFees, actionNames.ppcod]

const opacityActions = [actionNames.codFees, actionNames.ppcod, actionNames.upi, actionNames.codPrompt]

const sections = [
    {
        title: 'BY THEME',
        items: [
            { label: 'Most Used in Gokwik', id: 'most_used_in_gokwik' },
            { label: 'Most Used in Fashion Category', id: 'most_used_in_fashion_category' },
            { label: 'Festive Special', id: 'festive_special' },
            { label: 'Popular A/B Tests', id: 'popular_ab_test', showProTag: true },
        ],
    },
    {
        title: 'BY GOAL',
        items: [
            { label: 'Increase Prepaid Share', id: 'improve_prepaid' },
            { label: 'RTO Reduction', id: 'rto_reduction' },
            { label: 'Increase Prepaid Share', id: 'increase_prepaid_share' },
            { label: 'Discount Burn Optimization', id: 'discount_burn_optimization' },
        ],
        tagContent: 'Curated For You',
    },
    {
        title: 'BY ACTION',
        items: [
            { label: 'Allow COD', id: 'allow_cod' },
            { label: 'COD Blocking', id: 'cod_blocking' },
            { label: 'COD Captcha', id: 'cod_captcha' },
            { label: 'COD Fees', id: 'cod_fees' },
            { label: 'COD Prompt', id: 'cod_prompt' },
            { label: 'Partial COD', id: 'partial_cod' },
            { label: 'UPI discount', id: 'upi_discount' },
        ],
    },
]
export {
    categoryMap,
    conditionCategoryMapper,
    categoryToIconMap,
    hoveredCODActions,
    opacityActions,
    actionNames,
    sections,
}
