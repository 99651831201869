import { updateBreadcrumbs } from '@gokwik/utilities'
import { Button, message, Modal, PlusOutlined } from 'gokwik-ui-kit'
import { useCallback, useEffect, useState } from 'react'
import UploadScreen from './upload-screen'
import UploadSuccess from './upload-success'
import { uploadType } from '../constants'
import UploadDragger from './upload-dragger'
import AWBIngestionTable from './awb-ingestion-table'
import { getUploadUrl, uploadCsvToS3 } from '@store/awb-flows/api'
import { createUploadHeaders, getFileType, notifyAndPollUpload, parseFileContents } from '../helpers'
import { fetchAwbFillRate } from '@store/awb-flows'
import { getMerchantDetails, getUserDetails } from '@store/user/selectors'
import { useDispatch, useSelector } from 'react-redux'
import { getAwbFillRate } from '@store/awb-flows/selectors'
import { logEvent } from '@library/utilities/userLogEvents/userLogEvents'

const XLSX = typeof window !== 'undefined' ? require('xlsx') : null

interface S3BucketUrl {
    url: string
    upload_id: string
}

function AWBFlows() {
    const [loading, setLoading] = useState(false)
    const [showUploadModal, setShowUploadModal] = useState(false)
    const [fileUploadStatus, setFileUploadStatus] = useState(true)
    const [uploadSuccess, setUploadSuccess] = useState(false)
    const [s3BucketUrl, setS3BucketUrl] = useState<S3BucketUrl>({
        url: '',
        upload_id: '',
    })
    const [uploading, setUploading] = useState(false)
    const [fileData, setFileData] = useState<File | null>(null)
    const merchant = useSelector(getMerchantDetails)
    const user_details = useSelector(getUserDetails)
    const { data: awbFillRate, status } = useSelector(getAwbFillRate)
    const dispatch = useDispatch()

    useEffect(() => {
        updateBreadcrumbs((prev) => [
            prev[0],
            {
                key: 'settings',
                href: '/rto/settings',
                text: 'RTO Settings',
            },
            {
                key: 'awb-flows',
                href: '/rto/settings/awb-flows',
                text: 'AWBFlows',
            },
        ])
    }, [])

    useEffect(() => {
        if (merchant.id) {
            getAwbFillRateAsync()
        }
    }, [merchant.id])

    const getAwbFillRateAsync = () => {
        //@ts-ignore
        dispatch(fetchAwbFillRate({ params: { merchant_id: merchant.id } }))
    }

    useEffect(() => {
        if (s3BucketUrl?.url) {
            uploadCsv()
        }
    }, [s3BucketUrl])

    const uploadCsv = async () => {
        try {
            setUploading(true)
            const headers = createUploadHeaders()
            var requestOptions = {
                method: 'PUT',
                headers: headers,
                body: fileData,
                redirect: 'follow',
            }
            const response = await uploadCsvToS3(s3BucketUrl.url, requestOptions)
            if (response.ok) {
                await notifyAndPollUpload(s3BucketUrl.upload_id, merchant.id)
            } else {
                throw new Error('Failed to upload file')
            }
            setUploading(false)
            setUploadSuccess(true)
            logEvent(
                'kwik_rto_awb_flows_ingestion_uploaded',
                'click',
                'Kwik RTO AWB Flows',
                user_details?.email,
                merchant?.m_id,
                merchant?.short_name,
                user_details?.name,
            )
        } catch (err) {
            message.error(err.message)
            console.log('Error in uploadCsv', err)
            resetStates()
        }
    }

    const handleUpload = async () => {
        try {
            const response = await getUploadUrl(uploadType, fileData?.name, merchant.m_id)
            if (response.status === 200 && response.data && response.data.url) {
                setS3BucketUrl(response.data)
            } else {
                throw new Error(response?.response?.data?.message)
            }
        } catch (err) {
            message.error(err.message)
            console.warn(err)
            resetStates()
        }
    }

    const validateFile = useCallback((file: File) => {
        const MAX_FILE_SIZE = 20 * 1024 * 1024 // 20 MB
        if (file.size > MAX_FILE_SIZE) {
            message.error('File size exceeds the 20 MB limit.')
            return false
        }
        return true
    }, [])

    const handleFileChange = async (file: File) => {
        try {
            setFileData(file)
            setLoading(true)
            setFileUploadStatus(true)

            if (!validateFile(file)) {
                setLoading(false)
                return false
            }

            const fileType = getFileType(file.name)

            const validationResult = await parseFileContents(file, fileType, XLSX)

            if (!validationResult.isValid) {
                message.error(validationResult.error || 'File validation failed')
                setLoading(false)
                return false
            }

            setFileUploadStatus(false)
            setLoading(false)

            return true
        } catch (error) {
            message.error(error.message || 'An error occurred while processing the file')
            setLoading(false)
            setFileUploadStatus(false)
            return false
        }
    }
    const handleUploadClick = () => {
        setShowUploadModal(true)
    }

    const handleBack = () => {
        setFileUploadStatus(true)
    }

    const RenderFooter = () => {
        return uploadSuccess ? null : fileUploadStatus ? (
            <div className='flex items-center justify-between gap-2 mt-6'>
                <p>Supported Formats: CSV, XLS</p>
                <p className='text-[#00000073]'>Maximum file size: 20 MB</p>
            </div>
        ) : (
            <div className='flex items-center justify-end gap-2 mt-6'>
                <Button key='back' onClick={handleBack}>
                    No, Upload Later
                </Button>
                <Button key='submit' type='primary' onClick={handleUpload} loading={uploading}>
                    Yes, Upload Now
                </Button>
            </div>
        )
    }

    const resetStates = () => {
        setShowUploadModal(false)
        setUploadSuccess(false)
        setFileUploadStatus(true)
        setUploading(false)
        setFileData(null)
        setS3BucketUrl({
            url: '',
            upload_id: '',
        })
    }

    return (
        <>
            <div className='overflow-scroll p-4 bg-white h-full'>
                <div className='flex justify-end recommended-segmented'>
                    <button
                        type='button'
                        className='bg-[#004b8d] hover:bg-[#186199] text-white font-medium rounded-lg border-none px-4 py-2'
                        onClick={handleUploadClick}
                    >
                        {' '}
                        <PlusOutlined style={{ marginRight: '8px' }} />
                        <span>Upload AWB Data</span>
                    </button>
                </div>
                <AWBIngestionTable
                    awbFillRate={{ merchant_id: merchant.id, platform: merchant.platform, awbFillRate }}
                    loading={status === 'loading'}
                />
            </div>

            <Modal
                title={uploadSuccess ? null : fileUploadStatus ? 'Upload Files' : 'Upload AWB Data'}
                open={showUploadModal}
                onCancel={resetStates}
                footer={<RenderFooter />}
                width={uploadSuccess ? 350 : 650}
                centered
                closeIcon={uploadSuccess ? false : true}
            >
                {uploadSuccess ? (
                    <UploadSuccess resetStates={resetStates} />
                ) : fileUploadStatus ? (
                    <UploadDragger handleFileChange={handleFileChange} />
                ) : (
                    <UploadScreen fileName={fileData?.name} />
                )}
            </Modal>
        </>
    )
}

export default AWBFlows
