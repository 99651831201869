import {
    ExclamationCircleFilled,
    InfoCircleFilled,
    Modal,
    Alert,
    InfoCircleOutlined,
    Button,
    Tooltip,
} from 'gokwik-ui-kit'
import { ActionType } from '@library/utilities/interface'
import { getPreviewModalTitle, getPreviewModalAlert, imageMapping } from '@library/utilities/helpers/helper'
import {
    abHelpsTexts,
    howABTestingWorksText,
    howABWorksModalTooltipText,
    whyABImportantText,
} from '@library/utilities/constants/constants'
import { resetAbActionValues, setModalStates } from '@store/actions'
import { useDispatch, useSelector } from 'react-redux'
import { getModalStates } from '@store/actions/selectors'

interface ModalComponentsProps {
    onBack: () => void
    submitWorkflow: () => void
    updateTourConfig: () => void
    actionType: ActionType
    secondaryActionType: ActionType
}

const ModalComponents: React.FC<ModalComponentsProps> = ({
    onBack,
    submitWorkflow,
    updateTourConfig,
    actionType,
    secondaryActionType,
}) => {
    const dispatch = useDispatch()
    const modalStates = useSelector(getModalStates)
    const { isPreviewVisible, backModalVisible, submitModalVisible, tourModalVisible, isSecondaryPreviewVisible, showABTestModal } = modalStates

    const onChangeModalStates = (key, value: boolean) => {
        dispatch(setModalStates({ ...modalStates, [key]: value }))
    }

    return (
        <>
            <Modal
                title={
                    <span>
                        <ExclamationCircleFilled className='text-[#ffc107] mr-2' />
                        Unsaved Workflow
                    </span>
                }
                open={backModalVisible}
                okText='Yes'
                cancelText='No, I Want to Continue'
                onOk={() => {
                    onChangeModalStates('backModalVisible', false)
                    onBack()
                    dispatch(resetAbActionValues())
                }}
                onCancel={() => onChangeModalStates('backModalVisible', false)}
                centered
            >
                <p className='ml-6'>Your workflow isn’t save right now, are you sure you want to continue?</p>
            </Modal>
            <Modal
                title={
                    <span>
                        <InfoCircleFilled className='mr-2 text-[#004b8d]' />
                        Are you Sure
                    </span>
                }
                open={submitModalVisible}
                okText='Yes'
                cancelText='No, I Want to Continue'
                onOk={() => submitWorkflow()}
                onCancel={() => onChangeModalStates('submitModalVisible', false)}
                centered
            >
                <p className='ml-6'>Your Workflow will be saved.</p>
            </Modal>
            <Modal
                title={
                    <span>
                        <ExclamationCircleFilled className='text-[#ffc107] mr-2' />
                        Would you like to view the RTO Actions Tour in future?
                        <br />
                        <br />
                    </span>
                }
                open={tourModalVisible}
                okText='Don’t show this tour again'
                cancelText='Yes'
                onOk={() => updateTourConfig()}
                onCancel={() => onChangeModalStates('tourModalVisible', false)}
                centered
            ></Modal>
            <Modal
                title={<div className='text-center'>{getPreviewModalTitle(actionType.action)}</div>}
                open={isPreviewVisible}
                onOk={() => onChangeModalStates('isPreviewVisible', false)}
                onCancel={() => onChangeModalStates('isPreviewVisible', false)}
                footer={null}
                mask={false}
                className='mr-12 mt-24 flex !max-w-[320px]'
            >
                <Alert message={getPreviewModalAlert(actionType.action)} type='info' />
                <br />
                {actionType.action && <p>This is mobile preview of intervention</p>}
                <img src={imageMapping[actionType.action]} alt={actionType.action} className='block mx-auto' />
            </Modal>
            <Modal
                title={<div className='text-center'>{getPreviewModalTitle(secondaryActionType?.action)}</div>}
                open={isSecondaryPreviewVisible}
                onOk={() => onChangeModalStates('isSecondaryPreviewVisible', false)}
                onCancel={() => onChangeModalStates('isSecondaryPreviewVisible', false)}
                footer={null}
                mask={false}
                className='mr-12 mt-24 flex !max-w-[320px]'
            >
                <Alert message={getPreviewModalAlert(secondaryActionType?.action)} type='info' />
                <br />
                {secondaryActionType?.action && <p>This is mobile preview of intervention</p>}
                <img
                    src={imageMapping[secondaryActionType?.action]}
                    alt={secondaryActionType?.action}
                    className='block mx-auto'
                />
            </Modal>

            {/* A/B Test Modal */}
            <Modal
                title={
                    <div className='flex items-center gap-2'>
                        <span className='text-[#00325E] font-semibold'>A/B Testing</span>
                        <Tooltip placement='bottom' title={howABWorksModalTooltipText}>
                            <InfoCircleOutlined className='text-gray-400' />
                        </Tooltip>
                    </div>
                }
                open={showABTestModal}
                onOk={() => onChangeModalStates('showABTestModal', false)}
                onCancel={() => onChangeModalStates('showABTestModal', false)}
                footer={[
                    <Button key='back' onClick={() => onChangeModalStates('showABTestModal', false)}>
                        Dismiss
                    </Button>,
                    <Button key='submit' type='primary' onClick={() => onChangeModalStates('showABTestModal', false)}>
                        Got it!
                    </Button>,
                ]}
                width={700}
                centered
            >
                <div className='text-sm flex flex-col gap-4'>
                    <div>
                        <p className='font-semibold mb-2'>How A/B testing works?</p>
                        <p>{howABTestingWorksText}</p>
                    </div>
                    <div>
                        <p className='font-semibold mb-2'>Why is it important?</p>
                        <p>{whyABImportantText}</p>
                        <ul className='list-decimal !pl-4'>
                            {abHelpsTexts?.map((text, index) => (
                                <li key={index}>{text}</li>
                            ))}
                        </ul>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default ModalComponents
