import { defaultTieringConfig } from '@library/utilities/constants/constants'
import { logEvent } from '@library/utilities/userLogEvents/userLogEvents'
import { setShowKwikFlowTierDrawer } from '@store/actions'
import { getShowKwikFlowTierDrawer, getWorkflowData } from '@store/actions/selectors'
import { getMerchantDetails, getUserDetails } from '@store/user/selectors'
import { Button, Drawer } from 'gokwik-ui-kit'
import { useDispatch, useSelector } from 'react-redux'

function KwikFlowTierDrawer() {
    const showKwikFlowTierDrawer = useSelector(getShowKwikFlowTierDrawer)
    const merchant = useSelector(getMerchantDetails)
    const user_details = useSelector(getUserDetails)
    const dispatch = useDispatch()
    const workflowDetails = useSelector(getWorkflowData)
    const kwikFlowTierConfigs = workflowDetails?.kwikflows_tier_config ?? defaultTieringConfig;
    const {
        max_ab_workflows: maxAbWorkflows,
        max_active_workflows: maxActiveWorkflows,
    } = kwikFlowTierConfigs

    const features = [
        {
            id: 1,
            title: 'Unlimited Workflows',
            content: 'Build a Robust Process for Expanding COD to 20K+ Pincodes.',
        },
        {
            id: 2,
            title: 'Unlimited A/B Tests',
            content: 'Optimize your prepaid share and maximize delivered orders, reduce RTOs, cancellations.',
        },
        {
            id: 3,
            title: 'Unlock New Segments',
            content: 'Tailor COD rules based on customer behavior, cart items, and product specifics.',
        },
    ]

    const steps = [
        {
            id: 1,
            content: "Contact Your CSM: Let your Customer Success Manager know you're ready to upgrade.",
        },
        {
            id: 2,
            content:
                "Enjoy Your Upgrade: Your account will be upgraded soon. You'll then have access to unlimited workflows, A/B testing, and advanced segmentation features",
        },
    ]

    const handleButtonClick = async () => {
        logEvent(
            'kwikflows_tier_activate_clicked',
            'click',
            'Kwik Flows',
            user_details?.email,
            merchant?.m_id,
            merchant?.short_name,
            user_details?.name,
        )
        dispatch(setShowKwikFlowTierDrawer(false))
    }

    const RenderFooter = () => {
        return (
            <div className='bg-gray-50 shadow-md p-4 flex justify-end gap-2 pr-24'>
                <button
                    onClick={() => dispatch(setShowKwikFlowTierDrawer(false))}
                    className='text-sm text-gray-500 bg-white outline-none rounded-md border border-solid border-gray-200 shadow-sm py-1 px-2'
                >
                    Discard
                </button>
                <Button type='primary' className='flex items-center gap-2' onClick={handleButtonClick}>
                    Got it
                </Button>
            </div>
        )
    }

    return (
        <Drawer
            onClose={() => dispatch(setShowKwikFlowTierDrawer(false))}
            open={showKwikFlowTierDrawer}
            placement='right'
            closable={false}
            width={750}
            footer={<RenderFooter />}
            styles={{
                footer: {
                    padding: 0,
                },
            }}
            zIndex={2147483001}
        >
            <div className='gap-4 flex flex-col'>
                <div className='flex items-center gap-2'>
                    <span className='font-semibold text-xl'>You're currently using KwikFlow's free plan.</span>
                </div>
                <span className='font-normal text-sm'>
                    Your free plan offers {maxActiveWorkflows} active workflows with {maxAbWorkflows} A/B test, and limited segmentation options (RTO
                    Risk, Address, Customer)
                </span>

                <span className='font-semibold text-sm'>Upgrade to KwikFlow Pro and Supercharge Your Business</span>

                <div className='flex justify-between gap-4'>
                    {features.map(({ id, title, content }) => (
                        <div
                            key={id}
                            className='flex flex-col flex-1 rounded-md gap-2 p-4 my-2 shadow-lg shadow-blue-200/20 bg-gradient-to-br from-opacity-5 to-opacity-10 from-blue-100/20 to-blue-200/40'
                        >
                            <p className='font-semibold text-base'>{title}</p>
                            <span className='text-sm font-normal'>{content}</span>
                        </div>
                    ))}
                </div>

                <div className='bg-gray-50 p-4 rounded-md'>
                    <p className='font-semibold text-base'>How to Upgrade to KwikFlow Pro:</p>
                    <ul className='font-medium text-sm list-none p-0'>
                        {steps.map(({ id, content }) => (
                            <li key={id} className='kwikChatDrawerList relative'>
                                <span className='absolute w-2 h-2 rounded-full bg-black top-2 left-2' />
                                <p className='text-gray-500 m-0 flex items-center gap-1'>Step {id}</p>
                                <p>{content}</p>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </Drawer>
    )
}

export default KwikFlowTierDrawer
