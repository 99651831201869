import { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import {
    Alert,
    Button,
    InfoCircleFilled,
    InfoCircleOutlined,
    Layout,
    Modal,
    Progress,
    Segmented,
    Switch,
    Tabs,
    message,
} from 'gokwik-ui-kit'

import { getMerchantDetails } from '@store/user/selectors'
import { getWorkflowData } from '@store/actions/selectors'
import { fetchAllWorkflows, setWorkflows } from '@store/actions'
import { Workflow } from '@library/utilities/interface'
import { capitalize } from '@library/utilities/helpers/helper'
import {
    createWorkflowAsync,
    getEidaReccomendationStatus,
    getRecommendations,
    updateWorkflowAsync,
} from '@store/actions/api'

import { ruleTemplates } from '@library/utilities/constants/constants'
import { rtoActionsOptionsMenu, rtoActionsOptionsMenuWithAB } from '../all-workflow/constants'
import EmptyState from '../all-workflow/empty-state'
import BoostAWB from '@library/components/settings/awb-flows/boost-awb'
import { useAwbFillRate } from '@library/components/settings/awb-flows/useAwbFillRate'
import EditWorkflow from '../edit-workflow'
var relativeTime = require('dayjs/plugin/relativeTime')
const { Content } = Layout
dayjs.extend(relativeTime)
dayjs.extend(utc)

interface EditWorkflowType {
    item: Workflow
    index: number
}

const ReccomendedWorkflow = ({ onClick, abControlEnable }) => {
    const merchantDetails = useSelector(getMerchantDetails)
    const workflowDetails = useSelector(getWorkflowData)
    const [draggedListItem, setDraggedListItem] = useState(null)
    const [allWorkflow, setAllWorkflow] = useState<Workflow[]>([])
    const [deleteModal, setDeleteModal] = useState<EditWorkflowType>(null)
    const [prevDraggedIndex, setPrevDraggedIndex] = useState(null)
    const [current, setCurrentFilter] = useState('Recommended(Beta)')
    const [showModal, setShowModal] = useState(false)
    const [editWorkflowData, setEditWorkflowData] = useState<EditWorkflowType>(null)
    const [isProgressModalVisible, setIsProgressModalVisible] = useState(false)
    const [progress, setProgress] = useState(0)
    const dispatch = useDispatch()
    const [editFlow, setCreateFlow] = useState(false)
    const [isEditing, setIsEditing] = useState(null)
    const [currentindex, setCurrentIndex] = useState(0)
    const [apiWorkflow, setApiWorkflow] = useState<Workflow[]>([])
    const workflows = workflowDetails?.data ?? []
    const eidaReccomendations = workflowDetails?.eida_reccomendations?.raw_data ?? []
    const [opacity, setOpacity] = useState('opacity-0')
    const [eidaStatus, setEidaStatus] = useState('unavailable')
    const isEligibe = eidaReccomendations?.length > 0 ? dayjs.utc().diff(dayjs(workflowDetails?.eida_reccomendations?.last_run), 'hour') >= 4 && eidaStatus !== 'pending' : true
    const { showBoostAWB } = useAwbFillRate(merchantDetails?.id)
    const [showEditConfirmation, setShowEditConfirmation] = useState(false)
    const [potentialEditItem, setPotentialEditItem] = useState(null)
    const [potentialEditIndex, setPotentialEditIndex] = useState(null)
    const [showEditWorkflow, setShowEditWorkflow] = useState(false)

    useEffect(() => {
        const fetchEidaStatus = async () => {
            const eidaStatus = await getEidaReccomendationStatus(merchantDetails?.m_id)
            if (eidaStatus?.data?.data === 'pending') {
                setEidaStatus('pending')
                setIsProgressModalVisible(true)
            }
            else if (eidaStatus?.data?.data === 'failed') {
                setEidaStatus('failed')
                 setIsProgressModalVisible(false)
            }
        }

        fetchEidaStatus()
    }, [merchantDetails?.m_id, showModal])

    const handleEditWorkflowClick = (item, index) => {
        if (item.is_ab_test_enabled) {
            setCreateFlow(true)
            setIsEditing(item)
            setCurrentIndex(index)
        } else {
            setPotentialEditItem(item)
            setPotentialEditIndex(index)
            setShowEditConfirmation(true)
            setShowEditWorkflow(true)
        }
    }

    const handleEditBack = () => {
        setShowEditWorkflow(false)
        setIsEditing(null)
    }

    const fetchWorkflowAsync = () => {
        dispatch(
            //@ts-ignore
            fetchAllWorkflows({
                params: {
                    merchant_id: merchantDetails.id,
                },
            }),
        )
    }

    useEffect(() => {
        setApiWorkflow(workflows)
        let templates = []
        if (workflows && workflows?.length > 0) {
            templates = eidaReccomendations.map((itm) => {
                let isEqual = workflows?.filter((item: Workflow) => itm?.rule_name === item.rule_name)
                return isEqual[0] ? { ...isEqual[0], impact: itm.impact } : itm
            })
        }
        else {
            templates = eidaReccomendations
        }
        setAllWorkflow(templates)
    }, [workflows])
    const isWorkflowPresent = (workflow: Workflow) => {
        let isEqual = workflows?.filter((item: Workflow) => workflow?.rule_name === item.rule_name)
        return isEqual[0]
    }
    const createWorkflow = async (item: Workflow) => {
        let payload = {
            merchant_id: merchantDetails?.id,
            name: item.rule_name,
            ...item,
            created_at: new Date().toISOString(),
        }

        try {
            let response = await createWorkflowAsync(payload)
            if (response.success && response.data) {
                message.success('Workflow Enabled Successfully')
                fetchWorkflowAsync()
            }
        } catch (err) {
            message.error('Workflow Creation Failed')
            console.log(err)
        }
    }

    const handleClick = (e) => {
        console.log(e)
        setCurrentFilter(e)
        onClick(e)
    }

    const enableWorkflow = async (idx: number, isEnabled: boolean) => {
        let updatedWorkflows = [...allWorkflow]
        updatedWorkflows[idx] = {
            ...updatedWorkflows[idx],
            updated_at: new Date().toISOString(),
            is_enabled: !isEnabled,
        }

        const nonTemplates = updatedWorkflows?.filter((itm) => !itm?.template)
        const work = workflows.map((itm: Workflow) => {
            let isEqual = nonTemplates?.filter((workflow: Workflow) => workflow.rule_name == itm.rule_name)
            return isEqual[0] ? isEqual[0] : { ...itm, updated_at: new Date().toISOString() }
        })
        try {
            let response = await updateWorkflowAsync(work, merchantDetails?.id)
            if (response.success && response.data) {
                message.success(`Workflow ${!isEnabled ? 'Enabled' : 'Disabled'} Successfully`)
                dispatch(setWorkflows(work))
            }
        } catch (err) {
            message.error('Updation Failed')
            console.log(err)
        }
    }

    useEffect(() => {
        let intervalId
        if (isProgressModalVisible) {
            intervalId = setInterval(async () => {
                try {
                    const response = await checkProgressAPI()
                    if (response.success) {
                        if (response?.data?.data === 'completed') {
                            setProgress(100)
                            clearInterval(intervalId)
                            setEidaStatus('completed')
                        } else if (response?.data?.data === 'failed') {
                            setProgress(100)
                            clearInterval(intervalId)
                            setEidaStatus('failed')
                            setIsProgressModalVisible(false)
                        }
                        else {
                            setProgress((prev) => Math.min(prev + 10, 99))
                        }
                    }
                } catch (error) {
                    console.error('Error checking progress:', error)
                }
            }, 30000) // 30 seconds
            setProgress((prev) => Math.min(prev + 1, 99))
        }

        return () => clearInterval(intervalId)
    }, [isProgressModalVisible])

    const checkProgressAPI = async () => {
        return await getEidaReccomendationStatus(merchantDetails?.m_id)
    }

    const generateWorkflows = () => {
        setShowModal(true)
        handleGenerateOk()
    }

    const convertToTargetWorkflow = (sourceWorkflow) => {
        return {
            rule_name: sourceWorkflow.rule_name,
            rules: sourceWorkflow.rules.map(rule => ({
                raw_data: {
                    actions: rule.actions,
                    conditions: rule.conditions.map(condition => {
                        if (condition.values) {
                            try {
                                const valuesArray = JSON.parse(condition.values.replace(/'/g, '"'));
                                const numericValue = Number(valuesArray[0]);
                                return {
                                    ...condition,
                                    value: isNaN(numericValue) ? valuesArray[0] : numericValue,
                                    values: undefined
                                };
                            } catch (e) {
                                console.error('Error parsing values:', e);
                                return condition;
                            }
                        }
                        return condition;
                    }),
                    priority: rule.priority
                }
            })),
            type: sourceWorkflow.type
        };
    };
   

    const handleGenerateOk = async () => {
        setIsProgressModalVisible(true)
        setShowModal(true)
        setProgress(0)
        await getRecommendations(merchantDetails?.id, merchantDetails?.m_id)
        const response = await checkProgressAPI()
        if (response?.data?.data === 'pending') {
            setEidaStatus('pending')
        } else {
            setEidaStatus(response?.data?.data)
            if (response?.data?.data === 'completed') {
                setProgress(100)
            }
        }
    }
    return (
        <div>
            {/* @ts-ignore */}
            {showEditWorkflow ? (
                <EditWorkflow
                    onBack={handleEditBack}
                    editData={convertToTargetWorkflow(eidaReccomendations[potentialEditIndex])}
                    allWorkflow={allWorkflow}
                    currentFilteredIndex={potentialEditIndex}
                    currentWorkflow={workflows}
                />
            ) : (
            <Layout hasSider>
                <Content>
                    {showBoostAWB && (
                        <div className='p-4 bg-white'>
                            <BoostAWB
                                heading='AWB Data Not in Sync'
                                subHeading='Improve your AWB Fill Rate to uncover valuable RTO insights, optimize delivery performance, and proactively address potential issues.'
                            />
                        </div>
                    )}
                    <div className='overflow-scroll p-4 bg-white h-full'>
                        <div className='flex justify-between recommended-segmented' style={{ paddingBottom: '10px' }}>
                            <Segmented
                                options={abControlEnable ? rtoActionsOptionsMenuWithAB : rtoActionsOptionsMenu}
                                defaultValue={current}
                                onChange={onClick}
                            ></Segmented>
                            <Button onClick={generateWorkflows} disabled={!isEligibe} type='primary'>
                                Generate Workflows
                            </Button>
                        </div>
                        {(() => {
                            switch (eidaStatus) {
                                case 'pending':
                                    return <Alert
                                        className='my-2.5'
                                        message='Workflow Generation is in progress. It will take 5-10 minutes'
                                        type='info'
                                        showIcon
                                        closable={false}
                                    />
                                case 'completed':
                                    return <Alert
                                        className='my-2.5'
                                        message='Worflows generated successfully. Please refresh the page.'
                                        type='info'
                                        showIcon
                                        closable={false}
                                        action={<Button onClick={() => {
                                            setShowModal(false)
                                            window.location.reload()
                                        }}>Reload</Button>}
                                    />
                                case 'failed':
                                    return <Alert
                                        className='my-2.5'
                                        message='Workflow Generation failed. Please try again.'
                                        type='error'
                                        showIcon
                                        closable={false}
                                    />
                                default:
                                    return null;
                            }
                        })()}
                        <div>
                            {eidaReccomendations.length === 0 ? (
                                <EmptyState
                                    title='Recommended workflows not found'
                                    icon={<InfoCircleFilled className='text-7xl text-[#004b8d]' />}
                                />
                            ) : (
                                allWorkflow?.map((item, index) => (
                                    <Fragment key={index}>
                                        <div
                                            key={index}
                                            className={`flex p-4 items-center gap-4 flex-1 rounded-lg border-solid border border-gray-300 mb-1.5 ${draggedListItem === index && opacity
                                                }`}
                                            draggable
                                        >
                                            <div className='flex py-3 pr-4 pl-0 flex-col justify-center align-start gap-3 self-stretch border-solid border-0 border-r border-r-gray-200 items-center cursor-pointer'>
                                                <p>{index + 1}</p>
                                            </div>
                                            <div className='flex flex-col items-start gap-2 flex-1'>
                                                <div className='flex items-center gap-2 self-stretch'>
                                                    <Switch
                                                        checked={item.is_enabled}
                                                        onChange={() => {
                                                            !isWorkflowPresent(item)
                                                                ? createWorkflow(item)
                                                                : enableWorkflow(index, item.is_enabled)
                                                        }}
                                                    />
                                                    <p className='flex-1 text-stone-900 text-base font-semibold leading-6'>
                                                        {item.rule_name}
                                                    </p>
                                                    {/* <DeleteFilled
                                        onClick={() => {
                                            setDeleteModal({ item, index })
                                        }}
                                        className='text-xl text-gray-500'
                                    /> */}
                                                </div>
                                                <p className='self-stretch color-[##00000073] text-xs leading-5'>
                                                    Type: {capitalize(item.type.toLowerCase())} | Last changes on:{' '}
                                                    {/* @ts-ignore */}
                                                    {dayjs(item.updated_at).fromNow()} |  <span className='text-[#004b8d]'>Impact : {''}{item?.impact || 'RTO reduction : 5%'}</span>
                                                </p>
                                                <div className='flex items-start gap-4 self-stretch justify-between'>
                                                    <div>
                                                        <Button onClick={() => { handleEditWorkflowClick(item, index) }} type='link' block>Edit Workflow</Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Fragment>
                                ))
                            )}
                        </div>
                    </div>
                </Content>
            </Layout> )}
        </div>
    )
}

export default ReccomendedWorkflow