import { DownOutlined } from 'gokwik-ui-kit'

function CodPromptPreview() {
    return (
        <div className='absolute bottom-0 z-50 w-[calc(100%+2rem)] h-64 bg-white' style={{ margin: '-1rem' }}>
            <div className='absolute inset-0 top-[-20rem] flex items-center justify-center p-2'>
                <DownOutlined className='px-2 py-1 text-white bg-black rounded-3xl' />
            </div>

            <div>
                <p className='p-4 font-bold text-[#002647] bg-[#FCFCFD] shadow-sm'>Confirm cash on delivery</p>

                <div className='flex flex-col items-center justify-between gap-4 p-5'>
                    <p className='text-[#00192F] text-center'>
                        Are you sure you want to proceed with Cash on Delivery for your order?
                    </p>

                    <div className='flex flex-col items-center justify-center w-full gap-2'>
                        <button className='w-full px-4 py-2 text-center font-medium text-white bg-[#004B8D] border border-[#004B8D] rounded-2xl'>
                            Confirm Now
                        </button>
                        <button className='w-full px-4 py-2 text-center font-medium text-[#004B8D] bg-white border border-[#004B8D] rounded-2xl'>
                            Pay Online Now
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CodPromptPreview
