import { message } from 'gokwik-ui-kit';

export const  handleError = (error) => {
    console.warn(error);

    if (error.response) {
        if (error.response.status === 504) {
            message.error('Gateway Timeout Error');
        } else if (error.response.data?.message) {
            message.error(error.response.data.message);
        } else {
            message.error('An error occurred while processing your request.');
        }
    } else if (error.request) {
        message.error('No response received from server. Please try again later.');
    } else {
        message.error('An unexpected error occurred. Please try again later.');
    }
}


