const TemplateIcon = () => (
    <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clip-path='url(#clip0_2525_2214)'>
            <path
                d='M4.10401 7.69689L11.242 4.58789C11.3598 4.5374 11.4865 4.51101 11.6146 4.51027C11.7428 4.50953 11.8698 4.53445 11.9881 4.58356C12.1065 4.63268 12.2138 4.705 12.3038 4.79625C12.3938 4.8875 12.4646 4.99584 12.512 5.11489L17.436 17.0169C17.5384 17.2582 17.5418 17.5301 17.4457 17.774C17.3496 18.0179 17.1615 18.2143 16.922 18.3209L9.78501 21.4299C9.66717 21.4805 9.54038 21.507 9.41212 21.5079C9.28387 21.5087 9.15675 21.4838 9.03827 21.4347C8.91979 21.3856 8.81235 21.3132 8.72231 21.2219C8.63227 21.1305 8.56144 21.0221 8.51401 20.9029L3.59001 8.99989C3.48766 8.75854 3.48418 8.48665 3.58032 8.24277C3.67645 7.99888 3.86451 7.80349 4.10401 7.69689Z'
                stroke='#101828'
                stroke-width='1.6'
                stroke-linecap='round'
                stroke-linejoin='round'
            />
            <path
                d='M15.5 4.5H16.5C16.7652 4.5 17.0196 4.60536 17.2071 4.79289C17.3946 4.98043 17.5 5.23478 17.5 5.5V9'
                stroke='#101828'
                stroke-width='1.6'
                stroke-linecap='round'
                stroke-linejoin='round'
            />
            <path
                d='M20.5 6.5C20.764 6.612 21.02 6.717 21.268 6.815C21.5121 6.91859 21.7051 7.1149 21.8045 7.36075C21.9039 7.6066 21.9015 7.88187 21.798 8.126L19.5 13.5'
                stroke='black'
                stroke-width='1.6'
                stroke-linecap='round'
                stroke-linejoin='round'
            />
        </g>
        <defs>
            <clipPath id='clip0_2525_2214'>
                <rect width='23' height='24' fill='white' transform='translate(0.5 0.5)' />
            </clipPath>
        </defs>
    </svg>
)
export default TemplateIcon
