import React, { useEffect } from 'react'
import { Select, Form } from 'gokwik-ui-kit'
import { ActionType } from '@library/utilities/interface'

interface ApplyFreebieInputProps {
    actionType: ActionType
    setActionType: React.Dispatch<React.SetStateAction<ActionType>>
    merchantDiscountConfig: any
    isActionDisabled: boolean
    formText: string
    type: string
}

const setApplyFreebieInput = (
    actionType: ActionType,
    setActionType: React.Dispatch<React.SetStateAction<ActionType>>,
    value: string[],
) => {
    let newFreebieValueClone: ActionType = JSON.parse(JSON.stringify(actionType))

    newFreebieValueClone.apply_freebie_config = []

    value.forEach((val: string) => {
        const [discountCode, methodType] = val.split(' | ')
        newFreebieValueClone.apply_freebie_config.push({
            discount_code: discountCode,
            method_type: methodType,
        })
    })

    setActionType(newFreebieValueClone)
}

const ApplyFreebieInput: React.FC<ApplyFreebieInputProps> = ({
    actionType,
    setActionType,
    merchantDiscountConfig,
    isActionDisabled,
    formText,
    type,
}) => {
    const form = Form.useFormInstance()
    const getFormName = (type: string) => `${formText}-${type}-apply-freebie`
    const formName = getFormName(type)
    const formNameRemove = getFormName(type === 'primary' ? 'secondary' : 'primary')

    const applyFreebieConfigsOriginal = actionType?.apply_freebie_config?.map((item) => ({
        value: `${item?.discount_code} | ${item?.method_type}`,
        label: `${item?.discount_code} | ${item?.method_type}`,
    }))

    useEffect(() => {
        form.setFieldsValue({ [formName]: applyFreebieConfigsOriginal, [formNameRemove]: undefined })
    }, [formName, formNameRemove, actionType?.cod_fees, form])

    const handleFreebieChange = (value: string[], option: any) => {
        setApplyFreebieInput(actionType, setActionType, value)
    }

    return (
        <Form.Item
            name={formName}
            className='!mb-2'
            rules={[{ required: true, message: 'Selecting atleast one dicount coupon is required' }]}
            initialValue={applyFreebieConfigsOriginal}
        >
            <Select
                className='w-80 mt-5'
                placeholder='Select Freebie Discount'
                mode='multiple'
                onChange={handleFreebieChange}
                value={applyFreebieConfigsOriginal || []}
                options={merchantDiscountConfig}
                disabled={isActionDisabled}
            />
        </Form.Item>
    )
}

export default ApplyFreebieInput
