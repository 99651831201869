import { makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'

export const getRTOMerchantConfig = async () => {
    const response = await makeAPICall({
        method: 'get',
        url: process.env.DASHBOARD_BACKEND_URL + APIEndPoints.getRtoConfigData,
        headers: {
            Authorization: process.env.RTO_DASHBOARD_SERVICE_AUTHTOKEN,
        },
        skipLoader: true,
    })
    return response
}

export const updateRTOModelConfig = async ({
    risk_flag_configs,
}: {
    risk_flag_configs: any;
}) => {
    const response = await makeAPICall({
        method: 'post',
        url: process.env.DASHBOARD_BACKEND_URL + APIEndPoints.updateRtoModelConfig,
        payload: {
            risk_flag_configs,
        },
        headers: {
            Authorization: process.env.RTO_DASHBOARD_SERVICE_AUTHTOKEN,
        },
        skipLoader: true,
    })
    return response.data
}

export const updateControlSegmentConfig = async ({
    control_segment_configs,
}: {
    control_segment_configs: any;
}) => {
    const response = await makeAPICall({
        method: 'post',
        url: process.env.DASHBOARD_BACKEND_URL + APIEndPoints.updateControlSegmentConfig,
        payload: {
            control_segment_configs,
        },
        headers: {
            Authorization: process.env.RTO_DASHBOARD_SERVICE_AUTHTOKEN,
        },
        skipLoader: true,
    })
    return response.data
}

export const updateKlothoConfig = async ({
    rto_klotho_config,
    klotho_control_threshold,
    klotho_mode,
}: {
    rto_klotho_config?: any;
    klotho_control_threshold?: any;
    klotho_mode?: any;
}) => {
    const payload: any = {};

    if (klotho_mode === 'Live') {
        payload.klotho_config = rto_klotho_config;
        payload.klotho_control_threshold = klotho_control_threshold;
    } else if (klotho_mode === 'Shadow') {
        payload.shadow_klotho_config = rto_klotho_config;
    }

    const response = await makeAPICall({
        method: 'post',
        url: process.env.DASHBOARD_BACKEND_URL + APIEndPoints.updateKlothoConfig,
        payload,
        headers: {
            Authorization: process.env.RTO_DASHBOARD_SERVICE_AUTHTOKEN,
        },
        skipLoader: true,
    })
    return response.data
}