import { Tour } from 'gokwik-ui-kit'

function RenderTour({ refs, tourVisible, setTourVisible, kcConfigs, updateKCConfig }) {
    const RenderTitle = ({ heading = 'Introducing', subHeading }) => {
        return (
            <div>
                <p className='text-base font-normal'>{heading}</p>
                <p className='text-xl font-semibold'>{subHeading}</p>
            </div>
        )
    }

    const RenderListDesc = () => {
        return (
            <>
                <ul className='list-disc'>
                    <li>
                        This tab will showcase all the orders placed where the RTO Risk high either for customer or
                        address or both.
                    </li>
                    <li>You can tap on verify button against the order to send a WhatsApp message to customer.</li>
                </ul>
                <p>To maximize delivered orders and reduce RTO ensure that you verify all risky orders DAILY.</p>
            </>
        )
    }

    const steps = [
        {
            title: <RenderTitle subHeading={'Funnel-based tabs'} />,
            description:
                "You can now access RTO Related Information and take actions with just one click. Results appear instantly on the same page, without any hassle. Here, you can easily track the progress of the actions you're planning to take.",
            target: () => refs.current[0].current!,
        },
        {
            title: <RenderTitle subHeading={'Funnel-based tabs'} />,
            description: <RenderListDesc />,
            target: () => refs.current[1].current!,
        },
        {
            title: <RenderTitle subHeading={'Funnel-based tabs'} />,
            description: 'This tab will showcase all the orders that are awaiting confirmation from the customer.',
            target: () => refs.current[2].current!,
        },
        {
            title: <RenderTitle subHeading={'Funnel-based tabs'} />,
            description: 'This tab will showcase all the orders which are verified by the customer on WhatsApp.',
            target: () => refs.current[3].current!,
        },
        {
            title: <RenderTitle subHeading={'Funnel-based tabs'} />,
            description:
                'This tab will showcase all the orders which are unverified. Gokwik recommends to call the customer once before you decide to ship these orders.',
            target: () => refs.current[4].current!,
        },
        {
            title: <RenderTitle subHeading={'Take Action'} />,
            description:
                'You now have the ability to take action on risky orders. Our templates will be tailored according to the level of risk associated with each order. All you need to decide is whether to take action or not.',
            target: () => refs.current[5].current!,
        },
        {
            title: <RenderTitle subHeading={'Preview Icon'} />,
            description:
                'You now have access to preview the WhatsApp template that users will receive upon action. Simply click the eye icon to view.',
            target: () => refs.current[6].current!,
        },
    ]

    const handleCloseClick = async () => {
        setTourVisible(false)
        updateKCConfig({
            kc_config: { ...kcConfigs, rto_tour_shown: true },
        })
    }

    return tourVisible ? (
        <Tour
            open={tourVisible}
            onClose={handleCloseClick}
            steps={steps}
            indicatorsRender={(current, total) => (
                <span>
                    {current + 1} / {total}
                </span>
            )}
        />
    ) : null
}

export default RenderTour
