import { addBreadcrumb, convertIsoToLocaleString } from '@gokwik/utilities'
import dayjs from 'dayjs'
import { ColumnProps, Tag, Tooltip, InfoCircleFilled, InfoCircleOutlined } from 'gokwik-ui-kit'
import { Link } from 'react-router-dom'
import High from '@library/images/high.svg'
import Mid from '@library/images/medium.svg'
import Low from '@library/images/low.svg'
import Controlled from '@library/images/controlled.svg'
import { formatAmount } from '@library/utilities/helpers/format_amount'
const paymentModes = {
    All: 'All',
    UPIIntent: 'UPI',
    COD: 'COD',
    cod: 'cod',
    cc: 'Credit Card',
    dc: 'Debit Card',
    wallet: 'Wallet',
    gift_card: 'Gift Card',
    bnpl: 'BNPL',
    emi: 'EMI',
    'emi-cc': 'EMI(Credit Card)',
    'emi-dc': 'EMI(Debit Card)',
    credpay: 'Credpay',
    netbanking: 'Netbanking',
}
export const internalUserColumns: ColumnProps<any>[] = [
    {
        title: <span>Order #</span>,
        dataIndex: 'order_number',
        width: 180,
        ellipsis: true,
        render: (text: string) => (
            <Link
                to={`/checkout/orders/${text}`}
                onClick={() =>
                    addBreadcrumb({
                        key: 'order-details',
                        href: `/checkout/orders/${text}`,
                        text,
                    })
                }
            >
                <a style={{ width: 'min-content' }}>
                    <Tooltip placement='topLeft' title={text}>
                        {text?.toUpperCase()}
                    </Tooltip>
                </a>
            </Link>
        ),
        fixed: 'left',
    },
    {
        title: <span>Order Status</span>,
        dataIndex: 'order_status',
        width: 150,
        render: (text: string) => (
            <div>
                <>
                    {' '}
                    {text === 'confirmed' && (
                        <Tag
                            variant={'success'}
                            className={`!px-3 !py-1 rounded-2xl inter font-medium inline-block capitalize text-xs`}
                        >
                            Confirmed
                        </Tag>
                    )}
                </>
                <>
                    {text === 'pending' && (
                        <Tag
                            variant={'warning'}
                            className={`!px-3 !py-1 rounded-2xl inter font-medium inline-block capitalize text-xs`}
                        >
                            Pending
                        </Tag>
                    )}
                </>
                <>
                    {text === 'cancelled' && (
                        <Tag
                            variant={'error'}
                            className={`!px-3 !py-1 rounded-2xl inter font-medium inline-block capitalize text-xs`}
                        >
                            Cancelled
                        </Tag>
                    )}
                </>

                <>
                    {text === 'verified' && (
                        <Tag
                            variant={'default'}
                            className={`!px-3 !py-1 rounded-2xl inter font-medium inline-block capitalize text-xs`}
                        >
                            Verified
                        </Tag>
                    )}
                </>
            </div>
        ),
        align: 'center',
    },
    {
        title: <span>Merchant Order #</span>,
        dataIndex: 'moid',
        width: 150,
        ellipsis: true,
        render: (text: string) => (
            <Tooltip placement='topLeft' title={text}>
                {text}
            </Tooltip>
        ),
    },

    {
        title: <span>Merchant Order Status</span>,
        dataIndex: 'm_order_status',
        width: 180,
        ellipsis: true,
        render: (text: string) => (
            <Tooltip placement='topLeft' title={text}>
                <span className='capitalize'>{text}</span>
            </Tooltip>
        ),
        align: 'center',
    },
    {
        title: <span>Platform Order #</span>,
        dataIndex: 'order_name',
        width: 150,
        ellipsis: true,
        render: (text: string) => (
            <Tooltip placement='topLeft' title={text}>
                <span className='capitalize'>{text}</span>
            </Tooltip>
        ),
    },
    {
        title: <span>Created At</span>,
        dataIndex: 'created_at',
        width: 200,
        ellipsis: true,
        render: (text: string) => <span>{convertIsoToLocaleString(text)}</span>,
        sorter: (a, b) => (dayjs(a.created_at).isBefore(dayjs(b.created_at)) ? -1 : 1),
        sortDirections: ['ascend', 'descend'],
    },
    {
        title: <span>Updated At</span>,
        dataIndex: 'updated_at',
        render: (text: string) => <span>{convertIsoToLocaleString(text)}</span>,
        sorter: (a, b) => (dayjs(a.updated_at).isBefore(dayjs(b.updated_at)) ? -1 : 1),
        sortDirections: ['ascend', 'descend'],
        width: 150,
    },
    {
        title: <span>RTO Risk Flag</span>,
        dataIndex: 'rto_risk_flag',
        width: 100,
        ellipsis: true,
        render: (text: string) => (
            <div>
                <>
                    {text?.toLowerCase()?.includes('high') && (
                        <span>
                            <span>
                                <img src={High} alt='high' /> &nbsp; High
                            </span>
                        </span>
                    )}
                </>
                <>
                    {text?.toLowerCase()?.includes('low') && (
                        <span>
                            <span>
                                <img src={Low} alt='low' /> &nbsp; Low
                            </span>
                        </span>
                    )}
                </>
                <>
                    {text?.toLowerCase()?.includes('medium') && (
                        <span>
                            <span>
                                <img src={Mid} alt='mid' /> &nbsp; Mid
                            </span>
                        </span>
                    )}
                </>
                <>
                    {text?.toLowerCase()?.includes('controlled') && (
                        <span>
                            <img src={Controlled} alt='controlled' /> &nbsp; Controlled
                        </span>
                    )}
                </>
            </div>
        ),
    },
    {
        title: <span>RTO Remark</span>,
        dataIndex: 'rto_reason',
        width: 200,
        ellipsis: true,
        render: (text: string) => (
            <Tooltip placement='topLeft' title={text}>
                {text}
            </Tooltip>
        ),
        align: 'center',
    },

    {
        title: <span>RTO Score</span>,
        dataIndex: 'rto_score',
        align: 'right',
        width: 120,
    },
    {
        title: <span>Customer Phone #</span>,
        dataIndex: 'billing_phone',
        width: 150,
    },
    {
        title: <span>Payment Mode</span>,
        dataIndex: 'payment_method',
        width: 150,
        render: (text: string) => <span>{`${paymentModes[text] || text}`}</span>,
    },
    {
        title: <span>Payment Status</span>,
        dataIndex: 'payment_status',
        render: (text: string) => <span>{`${text ? 'Paid' : 'Pending'}`}</span>,
        width: 150,
        align: 'center',
    },
    {
        title: <span>Payment At</span>,
        dataIndex: 'payment_at',
        render: (text: string) => <span>{convertIsoToLocaleString(text)}</span>,
        width: 150,
    },
    {
        title: <span>Grand Total</span>,
        dataIndex: 'grand_total',
        align: 'right',
        width: 100,
        render: (t, record) => <p>{formatAmount(t, 2)}</p>,
    },

    {
        title: <span>Is ABC Recovered</span>,
        dataIndex: 'is_abc',
        width: 150,
        ellipsis: true,
        render: (text: string) => (
            <Tooltip placement='topLeft' title={text}>
                <span>{`${text ? 'Yes' : 'No'}`}</span>
            </Tooltip>
        ),
    },

    {
        title: <span>C2P Done</span>,
        dataIndex: 'c2p_converted',
        width: 150,
        ellipsis: true,
        render: (text: string) => (
            <Tooltip placement='topLeft' title={text}>
                <span>{`${text ? 'Yes' : 'No'}`}</span>
            </Tooltip>
        ),
    },

    {
        title: <span>UTM Source</span>,
        dataIndex: 'mkt_source',
        width: 100,
        ellipsis: true,
        render: (text: string) => (
            <Tooltip placement='topLeft' title={text}>
                <span className='capitalize'>{text}</span>
            </Tooltip>
        ),
    },
    {
        title: <span>UTM Medium</span>,
        dataIndex: 'mkt_medium',
        width: 100,
        ellipsis: true,
        render: (text: string) => (
            <Tooltip placement='topLeft' title={text}>
                {text}
            </Tooltip>
        ),
    },
    {
        title: <span>UTM Campaign</span>,
        dataIndex: 'mkt_campaign',
        width: 120,
        ellipsis: true,
        render: (text: string) => (
            <Tooltip placement='topLeft' title={text}>
                {text}
            </Tooltip>
        ),
    },
]

export const merchantUserColumns: ColumnProps<any>[] = [
    {
        title: <span>Order #</span>,
        dataIndex: 'order_number',
        width: 180,
        ellipsis: true,
        render: (text: string) => (
            <Link
                to={`/checkout/orders/${text}`}
                onClick={() =>
                    addBreadcrumb({
                        key: 'order-details',
                        href: `/checkout/orders/${text}`,
                        text,
                    })
                }
            >
                <a style={{ width: 'min-content' }}>
                    <Tooltip placement='topLeft' title={text}>
                        {text?.toUpperCase()}
                    </Tooltip>
                </a>
            </Link>
        ),
        fixed: 'left',
    },
    {
        title: <span>Order Status</span>,
        dataIndex: 'order_status',
        width: 150,
        render: (text: string) => (
            <Tag
                variant={
                    text === 'confirmed'
                        ? 'success'
                        : text === 'pending'
                        ? 'warning'
                        : text === 'cancelled'
                        ? 'error'
                        : 'default'
                }
                className={`!px-3 !py-1 rounded-2xl inter font-medium inline-block capitalize text-xs`}
            >
                {text}
            </Tag>
        ),
        align: 'center',
    },
    {
        title: <span>Created At</span>,
        dataIndex: 'created_at',
        width: 200,
        ellipsis: true,
        render: (text: string) => <span>{convertIsoToLocaleString(text)}</span>,
        sorter: (a, b) => (dayjs(a.created_at).isBefore(dayjs(b.created_at)) ? -1 : 1),
        sortDirections: ['ascend', 'descend'],
    },

    {
        title: <span>Customer Phone #</span>,
        dataIndex: 'billing_phone',
        width: 150,
    },

    // {
    //     title: <span>RTO Score</span>,
    //     dataIndex: 'rto_score',
    //     align: 'right',
    //     width: 120,
    // },
    {
        title: <span>RTO Risk</span>,
        dataIndex: 'rto_risk_flag',
        width: 100,
        ellipsis: true,
        render: (text: string) => (
            <div>
                <>
                    {text?.toLowerCase()?.includes('high') && (
                        <span>
                            <span>
                                <img src={High} alt='high' /> &nbsp; High
                            </span>
                        </span>
                    )}
                </>
                <>
                    {text?.toLowerCase()?.includes('low') && (
                        <span>
                            <span>
                                <img src={Low} alt='low' /> &nbsp; Low
                            </span>
                        </span>
                    )}
                </>
                <>
                    {text?.toLowerCase()?.includes('medium') && (
                        <span>
                            <span>
                                <img src={Mid} alt='mid' /> &nbsp; Mid
                            </span>
                        </span>
                    )}
                </>
                <>
                    {text?.toLowerCase()?.includes('controlled') && (
                        <span>
                            <img src={Controlled} alt='controlled' /> &nbsp; Controlled
                        </span>
                    )}
                </>
            </div>
        ),
    },
    {
        title: <span>RTO Remark</span>,
        dataIndex: 'rto_reason',
        width: 200,
        ellipsis: true,
        render: (text: string) => (
            <Tooltip placement='topLeft' title={text}>
                {text}
            </Tooltip>
        ),
        align: 'center',
    },
    {
        title: <span>Payment Mode</span>,
        dataIndex: 'payment_method',
        width: 150,
        render: (text: string) => <span>{`${paymentModes[text] || text}`}</span>,
    },
    {
        title: <span>Payment Status</span>,
        dataIndex: 'payment_status',
        render: (text: string) => <span>{`${text ? 'Paid' : 'Pending'}`}</span>,
        width: 150,
        align: 'center',
    },
    {
        title: <span>Grand Total</span>,
        dataIndex: 'grand_total',
        align: 'right',
        width: 100,
        render: (t, record) => <p>{formatAmount(t, 2)}</p>,
    },
    {
        title: <span>Updated At</span>,
        dataIndex: 'updated_at',
        render: (text: string) => <span>{convertIsoToLocaleString(text)}</span>,
        sorter: (a, b) => (dayjs(a.updated_at).isBefore(dayjs(b.updated_at)) ? -1 : 1),
        sortDirections: ['ascend', 'descend'],
        width: 150,
    },
    {
        title: <span>Is ABC Recovered</span>,
        dataIndex: 'is_abc',
        width: 150,
        ellipsis: true,
        render: (text: string) => (
            <Tooltip placement='topLeft' title={text}>
                <span>{`${text ? 'Yes' : 'No'}`}</span>
            </Tooltip>
        ),
    },

    {
        title: <span>C2P Done</span>,
        dataIndex: 'c2p_converted',
        width: 150,
        ellipsis: true,
        render: (text: string) => (
            <Tooltip placement='topLeft' title={text}>
                <span>{`${text ? 'Yes' : 'No'}`}</span>
            </Tooltip>
        ),
    },

    {
        title: <span>UTM Source</span>,
        dataIndex: 'mkt_source',
        width: 100,
        ellipsis: true,
        render: (text: string) => (
            <Tooltip placement='topLeft' title={text}>
                <span className='capitalize'>{text}</span>
            </Tooltip>
        ),
    },
    {
        title: <span>UTM Medium</span>,
        dataIndex: 'mkt_medium',
        width: 100,
        ellipsis: true,
        render: (text: string) => (
            <Tooltip placement='topLeft' title={text}>
                {text}
            </Tooltip>
        ),
    },
    {
        title: <span>UTM Campaign</span>,
        dataIndex: 'mkt_campaign',
        width: 120,
        ellipsis: true,
        render: (text: string) => (
            <Tooltip placement='topLeft' title={text}>
                {text}
            </Tooltip>
        ),
    },
]