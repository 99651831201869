import { RtoDetailsData } from '../interface'
import { categorizePincodes, FilterType, rcaCardsData, sortedData } from '../constants/constants'
import { capitalize } from './helper'

const parseData = (value: any): number => parseInt(value) || 0

export const parseDecimalData = (value) => {
    if (value % 1 === 0) {
        value = parseInt(value)
    }
    return value
}

export const calculatePercentage = (numerator: number, denominator: number): string =>
    denominator == 0 ? '0' : ((numerator / denominator) * 100).toFixed(1)

const calculateDifferencePercentage = (current: number, compared: number): string =>
    compared === 0 ? '100' : (((current - compared) / compared) * 100).toFixed(1)

const sortByKey = (data, selectedItem) => {
    if (!data || data?.length <= 0) return []

    let key = selectedItem == 'RTO Share' ? 'total_rto_orders' : 'total_orders'

    return [...data].sort((a, b) => {
        const valA = isNaN(a[key]) ? a[key] : parseFloat(a[key])
        const valB = isNaN(b[key]) ? b[key] : parseFloat(b[key])
        return valA < valB ? 1 : valA > valB ? -1 : 0
    })
}

export const calculateRTOMetrics = (processedData: any): any => {
    const data = processedData?.data

    let rtoInsights = {
        total_hits: 0,
        total_orders: 0,
        cod_orders: 0,
        total_rto_orders: 0,
        total_delivered_orders: 0,
        total_cancelled_orders: 0,
        total_pending_orders: 0,
        total_null_status_orders: 0,
        total_cod_rto_orders: 0,
        total_cod_delivered_orders: 0,
        total_cod_pending_orders: 0,
    }

    data?.forEach((rtoData) => {
        rtoInsights.total_hits += parseData(rtoData.total_hits)
        rtoInsights.total_orders += parseData(rtoData.total_orders)
        rtoInsights.cod_orders += parseData(rtoData.cod_orders)
        rtoInsights.total_rto_orders += parseData(rtoData.total_rto_orders)
        rtoInsights.total_delivered_orders += parseData(rtoData.total_delivered_orders)
        rtoInsights.total_cancelled_orders += parseData(rtoData.total_cancelled_orders)
        rtoInsights.total_pending_orders += parseData(rtoData.total_pending_orders)
        rtoInsights.total_null_status_orders += parseData(rtoData.total_null_status_orders)
        rtoInsights.total_cod_rto_orders += parseData(rtoData.total_cod_rto_orders)
        rtoInsights.total_cod_delivered_orders += parseData(rtoData.total_cod_delivered_orders)
        rtoInsights.total_cod_pending_orders += parseData(rtoData.total_cod_pending_orders)
    })

    return rtoInsights
}

export const updateRcaCardsData = (rtoDetailsData: RtoDetailsData, filterType: string, selectedItem: string): void => {
    const sortData = (key: keyof RtoDetailsData) => {
        const excludedKeys = ['rtoInsights', 'rtoInsightsPrevComparison']
        const unsortedKeys = ['decileData', 'comparedDecileData']

        if (!excludedKeys.includes(key)) {
            const data = rtoDetailsData?.[key]?.data?.data
            return unsortedKeys.includes(key) ? data : sortByKey(data, selectedItem)
        }
    }

    sortedData.statesData = sortData('statesData')
    sortedData.citiesData = sortData('citiesData')
    sortedData.pincodesData = sortData('pincodesData')
    sortedData.decileData = sortData('decileData')
    sortedData.comparedStatesData = sortData('comparedStatesData')
    sortedData.comparedCitiesData = sortData('comparedCitiesData')
    sortedData.comparedPincodesData = sortData('comparedPincodesData')
    sortedData.comparedDecileData = sortData('comparedDecileData')
    //to be added later
    // sortedData.productsData = sortData('productsData')
    // sortedData.comparedProductsData = sortData('comparedProductsData')

    const rtoInsights = rtoDetailsData?.['rtoInsights']?.data
    const rtoInsightsPrevComparison = rtoDetailsData?.['rtoInsightsPrevComparison']?.data

    const currentRtoRate = calculatePercentage(
            parseInt(rtoInsights?.total_rto_orders),
            parseInt(rtoInsights?.total_rto_orders) +
                parseInt(rtoInsights?.total_pending_orders) +
                parseInt(rtoInsights?.total_delivered_orders),
        ),
        comparedRtoRate = calculatePercentage(
            parseInt(rtoInsightsPrevComparison?.total_rto_orders),
            parseInt(rtoInsightsPrevComparison?.total_rto_orders) +
                parseInt(rtoInsightsPrevComparison?.total_pending_orders) +
                parseInt(rtoInsightsPrevComparison?.total_delivered_orders),
        )
    rcaCardsData['1'] = {
        total_rto_orders: rtoInsights?.total_rto_orders,
        rto_rate: currentRtoRate,
        compared_rto_rate: comparedRtoRate,
        rto_orders_diff: (parseFloat(currentRtoRate) - parseFloat(comparedRtoRate)).toFixed(1),
    }

    switch (filterType) {
        case FilterType.DECILE:
            updateDecileRcaCardsData(filterType, sortedData.decileData, sortedData.comparedDecileData)
            break
        case FilterType.STATE:
            updateStatesRcaCardsData(filterType, sortedData.statesData, sortedData.comparedStatesData)
            break
        case FilterType.CITY:
            updateCitiesRcaCardsData(filterType, sortedData.citiesData, sortedData.comparedCitiesData)
            break
        case FilterType.PINCODE:
            updatePincodesRcaCardsData(filterType, sortedData.pincodesData, sortedData.comparedPincodesData)
            break
        //to be added later
        // case FilterType.PRODUCT:
        //     updateProductsRcaCardsData(filterType, sortedData.productsData, sortedData.comparedProductsData)
        //     break
    }
}

export const updateStatesRcaCardsData = (filterType: string, statesData: any[], comparedStatesData: any[]): void => {
    let totalOrders = 0,
        totalRtoOrders = 0,
        totalCodOrders = 0,
        rtoOrdersCount = 0,
        ordersCount = 0,
        codOrdersCount = 0,
        rtoRateOrdersCount = 0,
        totalRtoRateOrders = 0

    let comparedTotalRtoOrders = 0,
        comparedRtoOrdersCount = 0,
        comparedRtoRateOrdersCount = 0,
        comparedTotalRtoRateOrders = 0

    let rtoPerc = 0

    statesData?.forEach((node) => {
        totalRtoOrders += parseData(node?.total_rto_orders)
        totalOrders += parseData(node?.total_orders)
        totalCodOrders += parseData(node?.cod_orders)
        totalRtoRateOrders +=
            parseInt(node?.total_rto_orders) +
            parseInt(node?.total_pending_orders) +
            parseInt(node?.total_delivered_orders)
    })

    const comparedStatesDataMap = comparedStatesData.reduce((map, node) => {
        comparedTotalRtoOrders += parseData(node?.total_rto_orders)
        comparedTotalRtoRateOrders +=
            parseInt(node?.total_rto_orders) +
            parseInt(node?.total_pending_orders) +
            parseInt(node?.total_delivered_orders)

        map[node.state] = node
        return map
    }, {})

    statesData?.forEach((node, idx) => {
        const index = idx < 4 ? idx + 4 : idx + 15
        const comparedState = comparedStatesDataMap[node.state]
        const rtoRateOrders =
            parseInt(node?.total_rto_orders) +
            parseInt(node?.total_pending_orders) +
            parseInt(node?.total_delivered_orders)
        const comparedRtoRateOrders = comparedState
            ? parseInt(comparedState?.total_rto_orders) +
              parseInt(comparedState?.total_pending_orders) +
              parseInt(comparedState?.total_delivered_orders)
            : 0

        rcaCardsData[`${index}`] = {
            rto_share: calculatePercentage(node?.total_rto_orders, totalRtoOrders),
            order_share: calculatePercentage(node?.total_orders, totalOrders),
            cod_share: calculatePercentage(node?.cod_orders, node?.total_orders),
            rto_rate: calculatePercentage(node?.total_rto_orders, rtoRateOrders),
            filter_data: node?.state ? capitalize(node?.state) : 'Unclassified',
            rto_rate_diff: !comparedState
                ? 100
                : (
                      parseFloat(calculatePercentage(node?.total_rto_orders, rtoRateOrders)) -
                      parseFloat(calculatePercentage(comparedState?.total_rto_orders, comparedRtoRateOrders))
                  ).toFixed(1),
            ...node,
        }

        if (idx < 4) {
            rtoPerc += parseFloat(rcaCardsData[`${index}`]?.rto_share)
            rtoOrdersCount += parseData(node?.total_rto_orders)
            ordersCount += parseData(node?.total_orders)
            codOrdersCount += parseData(node?.cod_orders)
            comparedRtoOrdersCount += parseData(comparedState?.total_rto_orders ?? 0)
            rtoRateOrdersCount +=
                parseInt(node?.total_rto_orders) +
                parseInt(node?.total_pending_orders) +
                parseInt(node?.total_delivered_orders)
            comparedRtoRateOrdersCount += comparedState
                ? parseInt(comparedState?.total_rto_orders) +
                  parseInt(comparedState?.total_pending_orders) +
                  parseInt(comparedState?.total_delivered_orders)
                : 0
        }
    })

    const remainingRtoOrders = totalRtoOrders - rtoOrdersCount
    const remainingOrders = totalOrders - ordersCount
    const remainingCodOrders = totalCodOrders - codOrdersCount
    const comparedRemainingRtoOrders = comparedTotalRtoOrders - comparedRtoOrdersCount
    const remainingRtoRateOrders = totalRtoRateOrders - rtoRateOrdersCount
    const remainingComparedRtoRateOrders = comparedTotalRtoRateOrders - comparedRtoRateOrdersCount

    rcaCardsData['8'] = {
        filter_data: filterType,
        rto_share: (100 - rtoPerc).toFixed(1),
        order_share: calculatePercentage(remainingOrders, totalOrders),
        rto_rate: calculatePercentage(remainingRtoOrders, remainingRtoRateOrders),
        cod_share: calculatePercentage(remainingCodOrders, remainingOrders),
        rto_rate_diff: (
            parseFloat(calculatePercentage(remainingRtoOrders, remainingRtoRateOrders)) -
            parseFloat(calculatePercentage(comparedRemainingRtoOrders, remainingComparedRtoRateOrders))
        ).toFixed(1),
    }
}

export const updateCitiesRcaCardsData = (
    filterType: string,
    citiesData: any[],
    comparedCitiesData: any[],
    filteredCities?: string[],
    clickedNode?: string,
) => {
    let totalOrders = 0,
        totalRtoOrders = 0,
        totalCodOrders = 0,
        rtoOrdersCount = 0,
        ordersCount = 0,
        codOrdersCount = 0,
        rtoRateOrdersCount = 0,
        totalRtoRateOrders = 0,
        rtoPerc = 0

    let comparedTotalRtoOrders = 0,
        comparedRtoOrdersCount = 0,
        comparedRtoRateOrdersCount = 0,
        comparedTotalRtoRateOrders = 0

    const comparedCitiesDataMap = comparedCitiesData.reduce((map, node) => {
        comparedTotalRtoOrders += parseData(node?.total_rto_orders)
        comparedTotalRtoRateOrders +=
            parseInt(node?.total_rto_orders) +
            parseInt(node?.total_pending_orders) +
            parseInt(node?.total_delivered_orders)

        map[node.city] = node
        return map
    }, {})

    if (filterType === FilterType.CITY) {
        citiesData?.forEach((node) => {
            totalRtoOrders += parseData(node?.total_rto_orders)
            totalOrders += parseData(node?.total_orders)
            totalCodOrders += parseData(node?.cod_orders)
            totalRtoRateOrders +=
                parseInt(node?.total_rto_orders) +
                parseInt(node?.total_pending_orders) +
                parseInt(node?.total_delivered_orders)
        })
    } else {
        totalRtoOrders += parseData(rcaCardsData[clickedNode]?.total_rto_orders)
        totalOrders += parseData(rcaCardsData[clickedNode]?.total_orders)
    }

    const resetRcaCardsCitiesData = () => {
        let index = 10
        Object.keys(rcaCardsData).forEach((key) => {
            if (parseInt(key) === index) {
                rcaCardsData[key] = {}
                index++
            }
            if (index >= 15) return
        })
    }

    if (filterType !== FilterType.CITY) {
        resetRcaCardsCitiesData()
        let index = 10
        citiesData?.forEach((node) => {
            if (!filteredCities?.length || !filteredCities.includes(node?.city?.toLowerCase()) || index >= 15) return
            const comparedCity = comparedCitiesDataMap[node.city]
            const rtoRateOrders =
                parseInt(node?.total_rto_orders) +
                parseInt(node?.total_pending_orders) +
                parseInt(node?.total_delivered_orders)
            const comparedRtoRateOrders = comparedCity
                ? parseInt(comparedCity?.total_rto_orders) +
                  parseInt(comparedCity?.total_pending_orders) +
                  parseInt(comparedCity?.total_delivered_orders)
                : 0

            rcaCardsData[`${index}`] = {
                rto_share: calculatePercentage(node?.total_rto_orders, totalRtoOrders),
                order_share: calculatePercentage(node?.total_orders, totalOrders),
                cod_share: calculatePercentage(node?.cod_orders, node?.total_orders),
                rto_rate: calculatePercentage(node?.total_rto_orders, rtoRateOrders),
                filter_data: node?.city ? capitalize(node?.city) : 'Unclassified',
                rto_rate_diff: !comparedCity
                    ? 100
                    : (
                          parseFloat(calculatePercentage(node?.total_rto_orders, rtoRateOrders)) -
                          parseFloat(calculatePercentage(comparedCity?.total_rto_orders, comparedRtoRateOrders))
                      ).toFixed(1),
                ...node,
            }
            index++
        })
    } else {
        citiesData?.forEach((node, idx) => {
            const index = idx < 4 ? idx + 4 : idx + 15

            const comparedCity = comparedCitiesDataMap[node.city]
            const rtoRateOrders =
                parseInt(node?.total_rto_orders) +
                parseInt(node?.total_pending_orders) +
                parseInt(node?.total_delivered_orders)
            const comparedRtoRateOrders = comparedCity
                ? parseInt(comparedCity?.total_rto_orders) +
                  parseInt(comparedCity?.total_pending_orders) +
                  parseInt(comparedCity?.total_delivered_orders)
                : 0

            rcaCardsData[`${index}`] = {
                rto_share: calculatePercentage(node?.total_rto_orders, totalRtoOrders),
                order_share: calculatePercentage(node?.total_orders, totalOrders),
                cod_share: calculatePercentage(node?.cod_orders, node?.total_orders),
                rto_rate: calculatePercentage(node?.total_rto_orders, rtoRateOrders),
                filter_data: node?.city ? capitalize(node?.city) : 'Unclassified',
                rto_rate_diff: !comparedCity
                    ? 100
                    : (
                          parseFloat(calculatePercentage(node?.total_rto_orders, rtoRateOrders)) -
                          parseFloat(calculatePercentage(comparedCity?.total_rto_orders, comparedRtoRateOrders))
                      ).toFixed(1),
                ...node,
            }

            if (idx < 4) {
                rtoPerc += parseFloat(rcaCardsData[`${index}`]?.rto_share)
                rtoOrdersCount += parseData(node?.total_rto_orders)
                ordersCount += parseData(node?.total_orders)
                codOrdersCount += parseData(node?.cod_orders)
                comparedRtoOrdersCount += parseData(comparedCity?.total_rto_orders ?? 0)
                rtoRateOrdersCount +=
                    parseInt(node?.total_rto_orders) +
                    parseInt(node?.total_pending_orders) +
                    parseInt(node?.total_delivered_orders)
                comparedRtoRateOrdersCount += comparedCity
                    ? parseInt(comparedCity?.total_rto_orders) +
                      parseInt(comparedCity?.total_pending_orders) +
                      parseInt(comparedCity?.total_delivered_orders)
                    : 0
            }
        })

        const remainingRtoOrders = totalRtoOrders - rtoOrdersCount
        const remainingOrders = totalOrders - ordersCount
        const remainingCodOrders = totalCodOrders - codOrdersCount
        const comparedRemainingRtoOrders = comparedTotalRtoOrders - comparedRtoOrdersCount
        const remainingRtoRateOrders = totalRtoRateOrders - rtoRateOrdersCount
        const remainingComparedRtoRateOrders = comparedTotalRtoRateOrders - comparedRtoRateOrdersCount

        rcaCardsData['8'] = {
            rto_share: (100 - rtoPerc).toFixed(1),
            order_share: calculatePercentage(remainingOrders, totalOrders),
            rto_rate: calculatePercentage(remainingRtoOrders, remainingRtoRateOrders),
            cod_share: calculatePercentage(remainingCodOrders, remainingOrders),
            rto_rate_diff: (
                parseFloat(calculatePercentage(remainingRtoOrders, remainingRtoRateOrders)) -
                parseFloat(calculatePercentage(comparedRemainingRtoOrders, remainingComparedRtoRateOrders))
            ).toFixed(1),
        }
    }
}

export const updatePincodesRcaCardsData = (
    filterType: string,
    pincodesData: any[],
    comparedPincodesData: any[],
    filteredPincodes?: string[],
    selectedPincodeRange?: string,
): void => {
    categorizePincodes.above40Percent = []
    categorizePincodes.between20And40Percent = []
    categorizePincodes.below20Percent = []

    const classifyPincode = (node: any): void => {
        const rto_rate = parseFloat(calculatePercentage(node?.total_rto_orders, node?.total_orders))
        if (rto_rate < 20) categorizePincodes.below20Percent.push(node)
        else if (rto_rate >= 20 && rto_rate <= 40) categorizePincodes.between20And40Percent.push(node)
        else categorizePincodes.above40Percent.push(node)
    }

    if (filterType == FilterType.PINCODE) {
        let totalOrders = 0,
            totalRtoOrders = 0

        const comparedPincodesDataMap = comparedPincodesData.reduce((map, node) => {
            map[node.pincode] = node
            return map
        }, {})

        pincodesData?.forEach((node) => {
            classifyPincode(node)
        })

        const selectedRange = getRangeForLabel(selectedPincodeRange)?.range_data

        selectedRange?.forEach((node) => {
            totalRtoOrders += parseData(node?.total_rto_orders)
            totalOrders += parseData(node?.total_orders)
        })

        selectedRange.forEach((node, idx) => {
            const index = idx + 10
            const comparedPincode = comparedPincodesDataMap[node.pincode]
            const rtoRateOrders =
                parseInt(node?.total_rto_orders) +
                parseInt(node?.total_pending_orders) +
                parseInt(node?.total_delivered_orders)
            const comparedRtoRateOrders = comparedPincode
                ? parseInt(comparedPincode?.total_rto_orders) +
                  parseInt(comparedPincode?.total_pending_orders) +
                  parseInt(comparedPincode?.total_delivered_orders)
                : 0

            rcaCardsData[`${index}`] = {
                rto_share: calculatePercentage(node?.total_rto_orders, totalRtoOrders),
                order_share: calculatePercentage(node?.total_orders, totalOrders),
                cod_share: calculatePercentage(node?.cod_orders, node?.total_orders),
                rto_rate: calculatePercentage(node?.total_rto_orders, rtoRateOrders),
                filter_data: node?.pincode,
                rto_rate_diff: !comparedPincode
                    ? 100
                    : (
                          parseFloat(calculatePercentage(node?.total_rto_orders, rtoRateOrders)) -
                          parseFloat(calculatePercentage(comparedPincode?.total_rto_orders, comparedRtoRateOrders))
                      ).toFixed(1),
                ...node,
            }
        })
    } else {
        pincodesData?.forEach((node) => {
            if (filteredPincodes.length > 0 && filteredPincodes.includes(node?.pincode)) {
                classifyPincode(node)
            }
        })
    }

    rcaCardsData['16'] = { ...rcaCardsData, count: categorizePincodes.below20Percent.length }
    rcaCardsData['17'] = { ...rcaCardsData, count: categorizePincodes.between20And40Percent.length }
    rcaCardsData['18'] = { ...rcaCardsData, count: categorizePincodes.above40Percent.length }
}

const aggregateData = (data) => {
    const result = []

    // Helper to parse decile level range into numeric values
    const parseRange = (range: string): [number, number] => {
        const [start, end] = range.split('-').map(Number)
        return [start, end]
    }

    // Initialize ranges for aggregation
    const ranges: { start: number; end: number }[] = []
    for (let i = 0; i < 1; i += 0.2) {
        ranges.push({ start: i, end: i + 0.2 })
    }

    // Aggregate data by range
    ranges.forEach(({ start, end }) => {
        const rangeLabel = `${start.toFixed(1)}-${end.toFixed(1)}`
        const aggregated = {
            decile_level: rangeLabel,
            total_hits: 0,
            total_orders: 0,
            cod_orders: 0,
            total_rto_orders: 0,
            total_delivered_orders: 0,
            total_pending_orders: 0,
            total_null_status_orders: 0,
            total_cancelled_orders: 0,
            total_cod_rto_orders: 0,
            total_cod_pending_orders: 0,
            total_cod_delivered_orders: 0,
        }

        data?.forEach((item) => {
            const [itemStart, itemEnd] = parseRange(item.decile_level)

            // Check if the current item falls within the current range
            if (itemStart >= Number(start.toFixed(2)) && itemEnd <= Number(end.toFixed(2))) {
                aggregated.total_hits += Number(item.total_hits)
                aggregated.total_orders += Number(item.total_orders)
                aggregated.cod_orders += Number(item.cod_orders)
                aggregated.total_rto_orders += Number(item.total_rto_orders)
                aggregated.total_delivered_orders += Number(item.total_delivered_orders)
                aggregated.total_pending_orders += Number(item.total_pending_orders)
                aggregated.total_null_status_orders += Number(item.total_null_status_orders)
                aggregated.total_cancelled_orders += Number(item.total_cancelled_orders)
                aggregated.total_cod_rto_orders += Number(item.total_cod_rto_orders)
                aggregated.total_cod_pending_orders += Number(item.total_cod_pending_orders)
                aggregated.total_cod_delivered_orders += Number(item.total_cod_delivered_orders)
            }
        })

        result.push(aggregated)
    })

    return result
}

export const updateDecileRcaCardsData = (
    filterType: string,
    decileData: any[],
    comparedDecileData: any[],
    clickedNode?: string,
) => {
    let totalOrders = 0,
        totalRtoOrders = 0

    const resetRcaCardsDecileData = () => {
        for (let i = 10; i < 15; i++) {
            rcaCardsData[`${i}`] = {}
        }
    }

    if (clickedNode) {
        resetRcaCardsDecileData()
        totalRtoOrders += parseData(rcaCardsData[clickedNode]?.total_rto_orders)
        totalOrders += parseData(rcaCardsData[clickedNode]?.total_orders)
    } else {
        decileData?.forEach((node) => {
            totalRtoOrders += parseData(node?.total_rto_orders)
            totalOrders += parseData(node?.total_orders)
        })
    }

    const { startRange, endRange } = getRangeForDecile(clickedNode)

    const defaultDecileNode = (start: number, end: number) => ({
        decile_level: `${start.toFixed(2)}-${end.toFixed(2)}`,
        total_hits: 0,
        total_orders: 0,
        cod_orders: 0,
        total_rto_orders: 0,
        total_delivered_orders: 0,
        total_pending_orders: 0,
        total_null_status_orders: 0,
        total_cancelled_orders: 0,
        total_cod_rto_orders: 0,
        total_cod_pending_orders: 0,
        total_cod_delivered_orders: 0,
    })

    const processDecileData = (rangeData) => {
        let start = Number(startRange.toFixed(2))
        const processedData = []
        let dataIndex = 0

        for (let i = 0; i < 4; i++) {
            const end = Number(start.toFixed(2)) + 0.05
            const [nodeStart, nodeEnd] = rangeData[dataIndex]?.decile_level?.split('-').map(Number) || []

            if (nodeStart === Number(start.toFixed(2)) && nodeEnd === Number(end.toFixed(2))) {
                processedData.push(rangeData[dataIndex])
                dataIndex++
            } else {
                processedData.push(defaultDecileNode(start, end))
            }

            start = end // Increment to the next range
        }

        return processedData
    }

    const filterDecileDataByRange = (data, startRange, endRange) => {
        return data?.filter((node) => {
            const [nodeStart, nodeEnd] = node.decile_level.split('-').map(Number)
            return nodeStart >= startRange && nodeEnd <= endRange
        })
    }

    const rangeData = filterDecileDataByRange(decileData, startRange, endRange)
    const comparedRangeData = filterDecileDataByRange(comparedDecileData, startRange, endRange)

    const aggregatedDecileData = clickedNode ? processDecileData(rangeData) : aggregateData(decileData)

    const aggregatedComparedDecileData = clickedNode
        ? processDecileData(comparedRangeData)
        : aggregateData(comparedDecileData)

    const comparedDecileDataMap = aggregatedComparedDecileData?.reduce((map, node) => {
        map[node?.decile_level] = node
        return map
    }, {})

    aggregatedDecileData?.forEach((node, idx) => {
        const index = parseInt(clickedNode) < 7 ? idx + 10 : parseInt(clickedNode) > 6 ? idx + 11 : idx + 4
        const comparedDecile = comparedDecileDataMap?.[node?.decile_level]
        const rtoRateOrders =
            parseInt(node?.total_rto_orders) +
            parseInt(node?.total_pending_orders) +
            parseInt(node?.total_delivered_orders)
        const comparedRtoRateOrders = comparedDecile
            ? parseInt(comparedDecile?.total_rto_orders) +
              parseInt(comparedDecile?.total_pending_orders) +
              parseInt(comparedDecile?.total_delivered_orders)
            : 0

        rcaCardsData[`${index}`] = {
            rto_share: calculatePercentage(node?.total_rto_orders, totalRtoOrders),
            order_share: calculatePercentage(node?.total_orders, totalOrders),
            cod_share: calculatePercentage(node?.cod_orders, node?.total_orders),
            rto_rate: calculatePercentage(node?.total_rto_orders, rtoRateOrders),
            filter_data: node?.decile_level,
            rto_rate_diff: !comparedDecile
                ? 100
                : (
                      parseFloat(calculatePercentage(node?.total_rto_orders, rtoRateOrders)) -
                      parseFloat(calculatePercentage(comparedDecile?.total_rto_orders, comparedRtoRateOrders))
                  ).toFixed(1),
            ...node,
        }
    })
}

export const updateProductsRcaCardsData = (filterType: string, productsData: any[], comparedProductsData: any[]) => {
    let totalOrders = 0,
        totalRtoOrders = 0,
        totalCodOrders = 0,
        comparedTotalOrders = 0,
        comparedTotalRtoOrders = 0,
        avgRtoRate = 0,
        avgRtoRateCount = 0

    productsData?.forEach((node) => {
        totalRtoOrders += parseData(node?.total_rto_orders)
        totalOrders += parseData(node?.total_orders)
        totalCodOrders += parseData(node?.total_cod_orders)
    })

    const comparedProductsDataMap = comparedProductsData.reduce((map, node) => {
        comparedTotalRtoOrders += parseData(node?.total_rto_orders)
        comparedTotalOrders += parseData(node?.total_orders)

        map[node.p_name] = node
        return map
    }, {})

    let rtoPerc = 0
    let rtoOrdersCount = 0
    let ordersCount = 0
    let codOrdersCount = 0
    let comparedRtoOrdersCount = 0

    productsData?.forEach((node, idx) => {
        const index = idx < 4 ? idx + 4 : idx + 15
        const comparedProduct = comparedProductsDataMap[node?.p_name]

        rcaCardsData[`${index}`] = {
            rto_share: calculatePercentage(node?.total_rto_orders, totalRtoOrders),
            cod_share: calculatePercentage(node?.total_cod_orders, node?.total_orders),
            rto_rate: Math.round(node?.avg_rto_rate * 100),
            filter_data: capitalize(node?.p_name),
            rto_rate_diff: !comparedProduct
                ? 100
                : calculateDifferencePercentage(
                      parseData(node?.total_rto_orders),
                      parseData(comparedProduct?.total_rto_orders),
                  ),
            ...node,
        }
        if (idx < 4) {
            rtoPerc += parseData(rcaCardsData[`${index}`]?.rto_share)
            rtoOrdersCount += parseData(node?.total_rto_orders)
            ordersCount += parseData(node?.total_orders)
            codOrdersCount += parseData(node?.total_cod_orders)
            comparedRtoOrdersCount += parseData(comparedProductsDataMap[node?.p_name]?.total_rto_orders ?? 0)
        } else {
            avgRtoRate += parseFloat(node?.avg_rto_rate ?? 0)
            avgRtoRateCount++
        }
    })

    const remainingRtoOrders = totalRtoOrders - rtoOrdersCount
    const remainingOrders = totalOrders - ordersCount
    const remainingCodOrders = totalCodOrders - codOrdersCount
    const comparedRemainingRtoOrders = comparedTotalRtoOrders - comparedRtoOrdersCount
    avgRtoRate = avgRtoRateCount > 0 ? avgRtoRate / avgRtoRateCount : 0

    rcaCardsData['8'] = {
        filter_data: filterType,
        rto_share: 100 - rtoPerc,
        rto_rate: parseData(avgRtoRate * 100),
        cod_share: calculatePercentage(remainingCodOrders, remainingOrders),
        rto_rate_diff: calculateDifferencePercentage(remainingRtoOrders, comparedRemainingRtoOrders),
    }
}

export const getRangeForLabel = (label) => {
    switch (label) {
        case '16':
            return { range: [0, 20], range_data: categorizePincodes.below20Percent }
        case '17':
            return { range: [20, 40], range_data: categorizePincodes.between20And40Percent }
        case '18':
            return { range: [40, 100], range_data: categorizePincodes.above40Percent }
        default:
            return { range: [0, 0], range_data: [] }
    }
}

export const getRangeForDecile = (label) => {
    const ranges = {
        '4': { startRange: 0.0, endRange: 0.2 },
        '5': { startRange: 0.2, endRange: 0.4 },
        '6': { startRange: 0.4, endRange: 0.6 },
        '7': { startRange: 0.6, endRange: 0.8 },
        '8': { startRange: 0.8, endRange: 1.0 },
    }
    return ranges[label] || { startRange: 0.0, endRange: 0.2 }
}
