import { SearchFilters, Workflow } from '../interface'
import React from 'react'
import { WalletOutlined, TagOutlined } from 'gokwik-ui-kit'

const searchFilters: SearchFilters[] = [
    {
        id: 1,
        label: 'Payment Mode',
        input_details: {
            input_type: 'select',
            mode: 'multi-select',
            options: [
                { id: 0, text: 'All' },
                { id: 1, text: 'UPI' },
                { id: 2, text: 'COD' },
                { id: 3, text: 'Credit Card' },
                { id: 4, text: 'Debit Card' },
                { id: 5, text: 'Wallet' },
                { id: 6, text: 'Gift Card' },
                { id: 7, text: 'BNPL' },
                { id: 8, text: 'EMI' },
                { id: 9, text: 'EMI(Credit Card)' },
                { id: 10, text: 'EMI(Debit Card)' },
                { id: 11, text: 'Credpay' },
                { id: 12, text: 'Netbanking' },
            ],
        },
        key: 'key-that-goes-in-the-query-params-of-api-to-get-data',
        is_dynamic: true,
    },
    {
        id: 2,
        label: 'Order Status',
        input_details: {
            input_type: 'select',
            mode: 'multi-select',
            options: [
                { id: 0, text: 'Confirmed' },
                { id: 1, text: 'Pending' },
                { id: 2, text: 'Verified' },
                { id: 3, text: 'Cancelled' },
            ],
        },
        key: 'key-that-goes-in-the-query-params-of-api-to-get-data',
        is_dynamic: false,
    },
    {
        id: 3,
        label: 'Shopify Order Number',
        input_details: {
            input_type: 'text',
        },
        key: 'key-that-goes-in-the-query-params-of-api-to-get-data',
        is_dynamic: true,
    },
    {
        id: 4,
        label: 'Payment ID',
        input_details: {
            input_type: 'text',
        },
        key: 'key-that-goes-in-the-query-params-of-api-to-get-data',
        is_dynamic: true,
    },
    {
        id: 5,
        label: 'Payment Status',
        input_details: {
            input_type: 'select',
            mode: 'multi-select',
            options: [
                { id: 0, text: 'Paid' },
                { id: 1, text: 'Pending' },
            ],
        },
        key: 'key-that-goes-in-the-query-params-of-api-to-get-data',
        is_dynamic: true,
    },
    {
        id: 6,
        label: 'UTM Source',
        input_details: {
            input_type: 'text',
        },
        key: 'key-that-goes-in-the-query-params-of-api-to-get-data',
        is_dynamic: true,
    },
    {
        id: 7,
        label: 'UTM Medium',
        input_details: {
            input_type: 'text',
        },
        key: 'key-that-goes-in-the-query-params-of-api-to-get-data',
        is_dynamic: true,
    },
    {
        id: 8,
        label: 'UTM Campaign',
        input_details: {
            input_type: 'select',
            mode: 'multi-select',
            options: [
                { id: 0, text: 'Campaign_1' },
                { id: 1, text: 'Campaign_2' },
            ],
        },
        key: 'key-that-goes-in-the-query-params-of-api-to-get-data',
        is_dynamic: true,
    },

    {
        id: 9,
        label: 'Phone Number',
        input_details: {
            input_type: 'number',
        },
        key: 'key-that-goes-in-the-query-params-of-api-to-get-data',
        is_dynamic: false,
    },
    {
        id: 10,
        label: 'Email',
        input_details: {
            input_type: 'text',
        },
        key: 'key-that-goes-in-the-query-params-of-api-to-get-data',
        is_dynamic: false,
    },
    {
        id: 11,
        label: 'Merchant Name',
        input_details: {
            input_type: 'select',
            mode: 'single-select',
            options: [
                { id: 0, text: 'Boat' },
                { id: 1, text: 'MamaEarth' },
                { id: 2, text: 'YBL' },
                { id: 3, text: 'BSC' },
                { id: 4, text: 'GoKwik' },
                { id: 5, text: 'GKTest' },
            ],
        },
        key: 'key-to-search-api-get-data',
        is_dynamic: false,
    },
]

const rtoDetailsTableMenu = [
    {
        label: 'All',
        key: 'all',
    },
    {
        label: 'RTO < 20%',
        key: 'rto_lt_20',
    },
    {
        label: 'RTO Between 20-40%',
        key: 'rto_bt_20_40',
    },
    {
        label: 'RTO > 40%',
        key: 'rto_gt_40',
    },
]

const rtoDetailsFilterMenu: any[] = [
    {
        label: 'States',
        key: 'state',
        icon: React.createElement(WalletOutlined),
    },
    {
        label: 'Cities',
        key: 'city',
        icon: React.createElement(WalletOutlined),
    },
    {
        label: 'Pincodes',
        key: 'pincode',
        icon: React.createElement(WalletOutlined),
    },
    {
        label: 'Products',
        key: 'product',
        icon: React.createElement(WalletOutlined),
    },
]

const ruleTemplates: Workflow[] = [
    {
        template: true,
        rule_name: 'Rs 10 COD fee for all new users',
        rules: [
            {
                actions: [
                    {
                        action: 'cod_fees',
                        cod_fees: 10,
                    },
                ],
                conditions: [
                    {
                        workflow_type: 'CUSTOMER',
                        operator: '==',
                        key: 'customer_type',
                        value: 'new',
                    },
                ],
                priority: 1,
            },
        ],
        type: 'CUSTOM',
    },
    {
        template: true,
        rule_name: 'Apply COD captcha for cart value above Rs 1600',
        rules: [
            {
                actions: [
                    {
                        action: 'cod_captcha',
                    },
                ],
                conditions: [
                    {
                        workflow_type: 'CART',
                        operator: '>',
                        key: 'cart_value',
                        value: 1600,
                    },
                ],
                priority: 1,
            },
        ],
        type: 'CUSTOM',
    },
    {
        template: true,
        rule_name: 'Apply COD Fee for customers coming from facebook',
        rules: [
            {
                actions: [
                    {
                        action: 'cod_fees',
                        cod_fees: 30,
                    },
                ],
                conditions: [
                    {
                        workflow_type: 'UTM',
                        operator: '==',
                        key: 'utm_source',
                        value: 'Facebook',
                    },
                ],
                priority: 1,
            },
        ],
        type: 'CUSTOM',
    },
    {
        template: true,
        rule_name: 'Allow COD for customers ordering from Iphone',
        rules: [
            {
                actions: [
                    {
                        action: 'allow_cod',
                    },
                ],
                conditions: [
                    {
                        workflow_type: 'UTM',
                        operator: '==',
                        key: 'utm_medium',
                        value: 'iphone 14 pro',
                    },
                ],
                priority: 1,
            },
        ],
        type: 'CUSTOM',
    },
    {
        template: true,

        rule_name: 'Apply COD Blocking for cart value above Rs 2500',
        rules: [
            {
                actions: [
                    {
                        action: 'cod_blocking',
                    },
                ],
                conditions: [
                    {
                        workflow_type: 'CART',
                        operator: '>',
                        key: 'cart_value',
                        value: 2500,
                    },
                ],
                priority: 1,
            },
        ],
        type: 'CUSTOM',
    },
]

const conditionCategories = [
    {
        id: 1,
        label: 'Customer',
        type: 'CUSTOMER',
        tiered: false,
    },
    {
        id: 2,
        label: 'Address',
        type: 'ADDRESS',
        tiered: false,
    },
    {
        id: 4,
        label: 'RTO Risk',
        type: 'RTO_RISK',
        tiered: false,
    },
    {
        id: 3,
        label: 'Cart',
        type: 'CART',
        tiered: true,
    },
    {
        id: 5,
        label: 'UTMs',
        type: 'UTM',
        tiered: true,
    },
    {
        id: 6,
        label: 'SKUs',
        type: 'SKU',
        tiered: true,
    },
]

const excludedFields = new Set([
    'updated_at',
    'created_at',
    'show_awb_metrics_after_days',
    'show_ab_metrics_after_days',
    'ab_control_actions',
    'ab_test_flag.flag_id',
    'workflow_id',
])

const fullUppercaseWords = ['cod']

const rtoRiskWorkflow = 'RTO_RISK'

const rtoScore = 'rto_score'

const initialRiskOptions = {
    risk_flag: [
        { value: 'Low Risk', label: 'Low Risk' },
        { value: 'Medium Risk', label: 'Medium Risk' },
        { value: 'High Risk', label: 'High Risk' },
    ],
    risk_reason: [],
}

const workflowDefaultValue = {
    customer_type: {
        operator: '==',
        value: 'new',
    },
    customer_past_rto: {
        operator: '>=',
        value: 1,
    },
    phone_number: {
        operator: 'contains',
    },
    billing_name: {
        operator: 'contains',
    },
    billing_email: {
        operator: 'contains',
    },
    customer_since_dt: {
        operator: '>=',
    },
    address_length: {
        operator: '==',
    },
    cod_rate: {
        operator: 'contains',
    },
    duplicate_order: {
        operator: 'contains',
    },
    past_delivery: {
        operator: '==',
    },
    past_shipped: {
        operator: '==',
    },
    shipping_state: {
        operator: 'contains',
    },
    shipping_city: {
        operator: 'contains',
    },
    pincode: {
        operator: 'contains',
    },
    pincode_tier: {
        operator: 'contains',
    },
    os: {
        operator: 'contains',
    },
    browser: {
        operator: 'contains',
    },
    cart_value: {
        operator: '==',
    },
    total_line_items: {
        operator: '==',
    },
    is_gst_cart: {
        operator: '==',
    },
    utm_source: {
        operator: 'contains',
    },
    utm_medium: {
        operator: 'contains',
    },
    utm_campaign: {
        operator: 'contains',
    },
    sku_ids: {
        operator: 'contains',
    },
    product_id: {
        operator: 'contains',
    },
    risk_flag: {
        operator: 'contains',
        values: ['High Risk'],
    },
    risk_reason: {
        operator: 'contains',
        values: ['High RTO Intent'],
    },
    product_title: {
        operator: 'contains',
    },
    variant_ids: {
        operator: 'contains',
    },
}

const operators = {
    equals: '==',
    contains: 'contains',
    notContains: 'not_contains',
}

const rtoRiskKeys = ['rto_score', 'risk_flag', 'risk_reason']

const ppcodActionType = {
    percentage: 'percentage',
    fixed: 'fixed',
}

const actionInputOptions = [
    { value: 'cod_blocking', label: 'COD Blocking', isNumeric: false },
    { value: 'cod_fees', label: 'COD Fees', isNumeric: true },
    { value: 'cod_prompt', label: 'COD Prompt', isNumeric: false },
    { value: 'cod_captcha', label: 'COD Captcha', isNumeric: false },
    { value: 'allow_cod', label: 'Allow COD', isNumeric: false },
    { value: 'ppcod_upi', label: 'Partial COD', isNumeric: true },
    { value: 'upi_discount', label: 'UPI Discount', isNumeric: true },
]

const actionInputOptionsWithFreebies = [
    { value: 'cod_blocking', label: 'COD Blocking' },
    { value: 'cod_fees', label: 'COD Fees' },
    { value: 'cod_prompt', label: 'COD Prompt' },
    { value: 'cod_captcha', label: 'COD Captcha' },
    { value: 'allow_cod', label: 'Allow COD' },
    { value: 'ppcod_upi', label: 'Partial COD' },
    { value: 'upi_discount', label: 'UPI Discount' },
    { value: 'apply_freebie', label: 'Apply Freebie' },
]

const bulkEntryData = [
    'phone_number',
    'billing_name',
    'billing_email',
    'shipping_state',
    'shipping_city',
    'pincode',
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'sku_ids',
    'product_id',
    'product_title',
    'variant_ids',
    'risk_flag',
    'risk_reason',
    'pincode_tier',
    'os',
    'browser',
]

const bulkUploadCheck = [
    'phone_number',
    'billing_name',
    'billing_email',
    'pincode',
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'sku_ids',
    'product_id',
]

const actionNames = {
    allowCod: 'allow_cod',
    codBlocking: 'cod_blocking',
    codFees: 'cod_fees',
    codPrompt: 'cod_prompt',
    ppcodUpi: 'ppcod_upi',
    upiDiscount: 'upi_discount',
    applyFreebie: 'apply_freebie',
}

const secondaryActionText = 'Shows all potential actions that can be combined with your primary choice.'

const tertiaryActionText = 'Shows the potential actions that can be combined with your primary & secondary choice.'

const inputFieldData = [
    {
        name: 'customer_type',
        type: 'text',
        label: 'Customer Type',
        operator: [
            { value: '==', label: 'Equal' },
            { value: '!=', label: 'Not Equal' },
        ],
    },

    {
        name: 'phone_number',
        type: 'number',
        list: true,
        label: 'Phone no.',
        operator: [
            { value: 'contains', label: 'Contains' },
            { value: 'not_contains', label: 'Does not contains' },
        ],
    },
    {
        name: 'billing_name',
        type: 'text',
        list: true,
        label: 'Name',
        operator: [
            { value: 'contains', label: 'Contains' },
            { value: 'not_contains', label: 'Does not contains' },
        ],
    },
    {
        name: 'billing_email',
        type: 'text',
        list: true,
        label: 'Email',
        operator: [
            { value: 'contains', label: 'Contains' },
            { value: 'not_contains', label: 'Does not contains' },
        ],
    },
    {
        name: 'customer_since_dt',
        type: 'date',
        label: 'Customer Since',
        operator: [
            { value: '>=', label: 'Greater than or Equal to' },
            { value: '<=', label: 'Less than or Equal to' },
        ],
    },
    {
        name: 'customer_past_rto',
        type: 'text',
        label: 'Number of RTO By Customer',
        operator: [{ value: '>=', label: 'Greater than or Equal to' }],
    },
    {
        name: 'address_length',
        type: 'number',
        label: 'Address Length',
        operator: [
            { value: '>=', label: 'Greater than or Equal to' },
            { value: '<=', label: 'Less than or Equal to' },
            { value: '==', label: 'Equal to' },
        ],
    },
    {
        name: 'cod_rate',
        type: 'number',
        list: true,
        label: 'COD Rate',
        operator: [
            { value: 'contains', label: 'Contains' },
            { value: 'not_contains', label: 'Does not contains' },
        ],
    },
    {
        name: 'duplicate_order',
        type: 'number',
        list: true,
        label: 'Duplicate Order',
        operator: [
            { value: 'contains', label: 'Contains' },
            { value: 'not_contains', label: 'Does not contains' },
        ],
    },
    {
        name: 'past_delivery',
        type: 'number',
        label: 'Past Delivery',
        operator: [
            { value: '>=', label: 'Greater than or Equal to' },
            { value: '<=', label: 'Less than or Equal to' },
            { value: '==', label: 'Equal to' },
        ],
    },
    {
        name: 'past_shipped',
        type: 'number',
        label: 'Past Shipped',
        operator: [
            { value: '>=', label: 'Greater than or Equal to' },
            { value: '<=', label: 'Less than or Equal to' },
            { value: '==', label: 'Equal to' },
        ],
    },
    {
        name: 'shipping_state',
        type: 'text',
        label: 'Shipping State',
        operator: [
            { value: '==', label: 'Equal to' },
            { value: 'contains', label: 'Contains' },
            { value: 'not_contains', label: 'Does not contains' },
        ],
    },
    {
        name: 'shipping_city',
        type: 'text',
        label: 'Shipping City',
        operator: [
            { value: '==', label: 'Equal to' },
            { value: 'contains', label: 'Contains' },
            { value: 'not_contains', label: 'Does not contains' },
        ],
    },
    {
        name: 'pincode_tier',
        type: 'text',
        label: 'Pincode Tier',
        operator: [
            { value: '==', label: 'Equal to' },
            { value: 'contains', label: 'Contains' },
            { value: 'not_contains', label: 'Does not contains' },
        ],
    },
    {
        name: 'os',
        type: 'text',
        label: 'Operating System',
        operator: [
            { value: '==', label: 'Equal to' },
            { value: 'contains', label: 'Contains' },
            { value: 'not_contains', label: 'Does not contains' },
        ],
    },
    {
        name: 'browser',
        type: 'text',
        label: 'Browser',
        operator: [
            { value: '==', label: 'Equal to' },
            { value: 'contains', label: 'Contains' },
            { value: 'not_contains', label: 'Does not contains' },
        ],
    },
    {
        name: 'pincode',
        type: 'number',
        list: true,
        label: 'Pincode',
        operator: [
            { value: 'contains', label: 'Contains' },
            { value: 'not_contains', label: 'Does not contains' },
        ],
    },
    {
        name: 'cart_value',
        type: 'number',
        label: 'Cart Value',
        operator: [
            { value: '>=', label: 'Greater than or Equal to' },
            { value: '<=', label: 'Less than or Equal to' },
            { value: '==', label: 'Equal to' },
        ],
    },
    {
        name: 'total_line_items',
        type: 'number',
        label: 'Line Items',
        operator: [
            { value: '>=', label: 'Greater than or Equal to' },
            { value: '<=', label: 'Less than or Equal to' },
            { value: '==', label: 'Equal to' },
        ],
    },
    {
        name: 'is_gst_cart',
        type: 'text',
        label: 'GST Tag',
        operator: [{ value: '==', label: 'Equal to' }],
    },
    {
        name: 'utm_source',
        type: 'text',
        list: true,
        label: 'Source',
        operator: [
            { value: 'contains', label: 'Contains' },
            { value: '==', label: 'Equal to' },
        ],
    },
    {
        name: 'utm_medium',
        type: 'text',
        list: true,
        label: 'Medium',
        operator: [
            { value: 'contains', label: 'Contains' },
            { value: '==', label: 'Equal to' },
        ],
    },
    {
        name: 'utm_campaign',
        type: 'text',
        list: true,
        label: 'Campaign',
        operator: [
            { value: 'contains', label: 'Contains' },
            { value: '==', label: 'Equal to' },
        ],
    },
    {
        name: 'sku_ids',
        type: 'text',
        list: true,
        label: 'SKU ID',
        operator: [
            { value: 'contains', label: 'Contains' },
            { value: '==', label: 'Equal to' },
        ],
    },
    {
        name: 'product_id',
        type: 'text',
        list: true,
        label: 'Product ID',
        operator: [
            { value: 'contains', label: 'Contains' },
            { value: '==', label: 'Equal to' },
        ],
    },
    {
        name: 'product_title',
        type: 'text',
        label: 'Product',
        operator: [
            { value: 'contains', label: 'Contains' },
            { value: 'not_contains', label: 'Does not contains' },
        ],
    },
    {
        name: 'variant_ids',
        type: 'text',
        label: 'Product Variant',
        operator: [
            { value: 'contains', label: 'Contains' },
            { value: 'not_contains', label: 'Does not contains' },
        ],
    },
]

const abCreationMessage =
    'Once a test is created, it cannot be edited. You can activate or deactivate a test. If you want to configure A/B values, you can create a new workflow.'

const howABTestingWorksText =
    'A/B testing is like a scientific experiment for your online store. By creating two variants on a single segment, you can test which action performs better. This helps you optimize key metrics like RTO Rate, Conversion Rate and Prepaid Share. For e.g, You could test whether Partial COD of Rs 50 is effective to reduce RTO for high RTO risk segment. A/B Testing provides you a real time view in the workflow section to improve your overall delivered orders and boost sales. You can also perform impact analysis through the shopify flags across each variant.'

const whyABImportantText =
    'A/B testing is crucial because it provides a data-driven approach to optimizing your e-commerce operations. By understanding how different COD interventions on checkout impact metrics like RTO, CR, and prepaid share, You can make informed decisions to improve customer satisfaction, increase conversions, and ultimately drive more delivered orders. A/B testing helps you:'

const abHelpsTexts = [
    'Data-driven optimization: Identify the most effective strategies to increase delivered orders and reduce RTO.',
    'Cost reduction: Optimize marketing and operational expenses by targeting high-risk regions.',
    'Competitive advantage: Stay ahead of the curve by continuously improving the customer experience.',
]

const howABWorksModalTooltipText =
    'AB Testing is a method of comparing two segments to determine if the COD intervention performs better based on RTO, CR and Prepaid Share.'

const actionTypes = {
    codFee: 'cod_fees',
    codBlocking: 'cod_blocking',
    codPrompt: 'cod_prompt',
    codCaptcha: 'cod_captcha',
    allowCod: 'allow_cod',
    ppcodUpi: 'ppcod_upi',
    upiDiscount: 'upi_discount',
    applyFreebie: 'apply_freebie',
}

const secondaryActionTypeVar = 'secondaryAction'

const tertiaryActionTypeVar = 'tertiaryAction'

const hideAbEditWorkflowTooltip =
    'Workflows with active A/B tests are locked for editing. To make changes, please disable the A/B test and create a new workflow'

const workflowLockedInfo = 'Workflow is locked for changes during A/B testing'

const loadingWorkflowStatus = 'loading'

const workflowShouldEnabled = 'Workflow should be enabled for Applying, Removing A/B test.'

const impactViewMapping = {
    total_carts: {
        label: 'Landed on Payment Page',
        type: 'number',
        arrowType: 'no_arrow',
        showTooltip: false,
        getValue: (value) => value,
    },
    total_cod_orders: {
        label: 'Orders Placed (COD)',
        type: 'number',
        arrowType: 'positive',
        showTooltip: false,
        getValue: (value) => value,
    },

    total_rto_orders: {
        label: 'RTO Orders (Overall)',
        type: 'number',
        arrowType: 'negative',
        showTooltip: true,
        getValue: (value) => value,
    },

    total_delivered_orders: {
        label: 'Delivered Orders (Overall)',
        type: 'number',
        arrowType: 'positive',
        showTooltip: true,
        getValue: (value) => value,
    },

    total_cancelled_orders: {
        label: 'Cancelled Orders (Overall)',
        type: 'number',
        arrowType: 'negative',
        showTooltip: false,
        getValue: (value) => value,
    },

    cod_rto_orders: {
        label: 'RTO Orders (COD)',
        type: 'number',
        arrowType: 'negative',
        showTooltip: true,
        getValue: (value) => value,
    },
    total_orders: {
        label: 'Orders Placed (Overall)',
        type: 'number',
        arrowType: 'positive',
        showTooltip: false,
        getValue: (value) => value,
    },

    prepaid_share: {
        label: 'Prepaid Share',
        type: 'percentage',
        arrowType: 'positive',
        showTooltip: false,
        getValue: (value) => value,
    },

    conversion_rate: {
        label: 'Payment Page CR',
        type: 'percentage',
        arrowType: 'positive',
        showTooltip: false,
        getValue: (value) => value,
    },

    rto_rate: {
        label: 'RTO Rate (Overall)',
        type: 'percentage',
        arrowType: 'negative',
        showTooltip: true,
        getValue: (value) => value,
    },

    cod_rto_rate: {
        label: 'RTO Rate (COD)',
        type: 'percentage',
        arrowType: 'negative',
        showTooltip: true,
        getValue: (value) => value,
    },
}

const impactViewLockedText =
    'Your AB test is underway. While you wait for final RTO rate results (15 days recommended), stay informed with real-time data on conversion rates, cancellations, and Prepaid Share.'

const defaultImpactData = {
    total_carts: 0,
    total_orders: 0,
    total_cod_orders: 0,
    conversion_rate: 0,
    cod_share: 0,
    prepaid_share:0,
    total_delivered_orders: 0,
    total_cancelled_orders: 0,
    total_rto_orders: 0,
    prepaid_rto_orders: 0,
    rto_rate: 0,
    cod_rto_rate: 0,
    missing_awb_count: 0,
    cod_rto_orders: 0,
    total_prepaid_orders: 0,
    ab_test_flag_id: 'string',
    merchant_id: 123,
    created_at: null,
}

const phoneRegEx = /^\d{10}$/

const numRegEx = /^\d+$/

const validations = {
    phone_number: {
        validate: (value) => phoneRegEx.test(String(value)),
        failed: 'invalid phone number(s) found.',
        successful: 'phone number(s) processed successfully.',
    },
    pincode: {
        validate: (value) => {
            const stringValue = String(value)
            return stringValue.length === 6 && numRegEx.test(stringValue)
        },
        failed: 'invalid pincode(s) found.',
        successful: 'pincode(s) processed successfully.',
    },
}

const variantAName = 'variantA'

const awbAwaitedText = 'It usually takes around 15 days to get the delivery status of the orders placed.'

const abTestShopifyFlagTooltip =
    'Track orders in your AB test with these Shopify tags. Use them to distinguish between control and treatment groups.'

const WorkflowFlagTooltip = 'Track orders for this workflow with this Shopify tag.'

const workflowKeys = {
    customerType: 'customer_type',
    phoneNumber: 'phone_number',
    customerSince: 'customer_since_dt',
    addressLength: 'address_length',
    pastDelivery: 'past_delivery',
    pastShipped: 'past_shipped',
    pincode: 'pincode',
    cartValue: 'cart_value',
    totalLineItems: 'total_line_items',
    isGstCart: 'is_gst_cart',
    utmSource: 'utm_source',
    utmMedium: 'utm_medium',
    utmCampaign: 'utm_campaign',
    skuIds: 'sku_ids',
    productId: 'product_id',
    riskFlag: 'risk_flag',
    riskReason: 'risk_reason',
    productTitle: 'product_title',
    variantIds: 'variant_ids',
}

const specialNamesForBlankOrNull = [
    'phone_number',
    'billing_name',
    'billing_email',
    'pincode',
    'utm',
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'sku_ids',
    'product_id',
    'rto_score',
    'product_title',
    'variant_ids',
    'risk_reason',
    'risk_flag',
    'shipping_state',
    'shipping_city',
    'pincode_tier',
    'os',
    'browser',
]

const productVariantInfo = 'Search the desired product variant. You can select multiple at once.'

const abFlagConditionToolTip =
    'Use up to 30 characters, including only letters, numbers, and underscores. Special characters are not allowed.'

const workflowFlagConditionToolTip =
    'Enter a unique workflow flag: use up to 40 characters, including only letters, numbers, and underscores. Special characters are not allowed.'

const excludedPatterns = /rto_rule_type|priority|rule_id/

const duplicateOrderMessage =
    'Set a limit on Duplicate orders time frame by customer. Choose the time frame in hours. Customers wouldn’t be able to place COD orders with same products within this time frame.'

const codRateMessage =
    'Set a limit on COD orders per customer. Choose the time frame in hours. Only 1 COD orders will be allowed per customer within this.'

const COD_RATE_OPTIONS = [
    { label: 'Select Days', value: '' },
    { value: 24, label: '1 day' },
    { value: 48, label: '2 days' },
    { value: 72, label: '3 days' },
    { value: 'custom', label: 'Custom' },
]

const RTO_ACTIONS_API_RULES = ['cod_rate', 'duplicate_order']

const abTestImpactInfoTooltip = 'Last updated yesterday at 10:55 AM. Data refreshes daily before 11 AM.'

const impactInfoAfter90Days =
    'Last updated yesterday at 10:57 AM, Data refreshes daily before 11 AM. Impact Metrics available only for last 90 days'

const summaryViewMetrics = {
    hits: { label: 'RTO API HITS', type: 'number' },
    total_orders: { label: 'ORDERS', type: 'number' },
    cod_orders: { label: 'COD ORDERS', type: 'number' },
    cod_rto_perc: { label: 'RTO COD %', type: 'percentage' },
    rto_overall_perc: { label: 'RTO % (OVERALL)', type: 'percentage' },
    prepaid_share: { label: 'PREPAID SHARE %', type: 'percentage' },
    awb_fill_rate_perc: { label: 'AWB FILL RATE %', type: 'percentage' },
    delivered_orders_perc: { label: 'DELIVERED ORDERS %', type: 'percentage' },
    cancelled_orders_perc: { label: 'CANCELLED ORDERS %', type: 'percentage' },
}

const deepDiveViewMetrics = {
    total_orders: { label: 'ORDERS', type: 'number' },
    order_share: { label: 'ORDER SHARE%', type: 'percentage' },
    prepaid_share: { label: 'PREPAID SHARE %', type: 'percentage' },
    rto_overall_perc: { label: 'RTO % (OVERALL)', type: 'percentage' },
    rto_share_overall: { label: 'RTO SHARE %', type: 'percentage' },
    delivered_share: { label: 'DELIVERED SHARE %', type: 'percentage' },
}

const mappingsDeepDiveView = {
    state: 'stateLevelMetrics',
    tier: 'tierLevelMetrics',
    os: 'osLevelMetrics',
    browser: 'browserLevelMetrics',
    address_length_bucket: 'addressLengthLevelMetrics',
    quantity: 'qtyOrderedLevelMetrics',
}

const titlesDeepDiveView = {
    state: 'STATE',
    tier: 'TIER',
    os: 'OS',
    browser: 'BROWSER',
    address_length_bucket: 'ADDRESS LENGTH',
    quantity: 'QUANTITY ORDERED',
}
const rtoApiHitsIconMsg =
    'The RTO API is triggered when a user proceeds from the Address Page to the Payment Page. This metric indicates the number of users who have reached the payment stage.'
const awbFillRateIconMsg =
    'The AWB Fill Rate measures the proportion of orders for which Gokwik has received delivery status updates. This information is crucial for maintaining a strong RTO Model and assisting you in RTO reduction Action Plan.'

const workflowImpactViewText =
    'Your workflow metrics are underway. While you wait for final RTO rate results (15 days recommended), stay informed with real-time data on conversion rates, cancellations, and Prepaid Share.'

const defaultWorkflowImpactData = {
    total_carts: 0,
    total_orders: 0,
    total_cod_orders: 0,
    conversion_rate: 0,
    cod_share: 0,
    prepaid_share: 0,
    total_delivered_orders: 0,
    total_cancelled_orders: 0,
    total_missing_orders: 0,
    awb_fill_rate: 0,
    awb_missing_perc: 0,
    total_rto_orders: 0,
    rto_rate: 0,
    cod_rto_rate: 0,
    cod_rto_orders: 0,
    total_prepaid_orders: 0,
    rule_ids: [],
    merchant_id: 123,
    created_at: '',
}

const tieringFreeConditions = [
    'phone_number',
    'shipping_state',
    'shipping_city',
    'pincode',
    'pincode_tier',
    'risk_flag',
    'rto_score',
    'risk_reason',
    'is_repeat_customer',
    'billing_name',
    'billing_email',
    'customer_since_dt',
    'past_delivery',
    'past_shipped',
    'cod_rate',
    'duplicate_order',
    'address_length',
    'customer_type',
    'customer_past_rto',
]

const DEFAULT_BULK_WORKFLOW = ['phone_number', 'pincode']

const defaultTieringConfig = {
    enable_tiering: false,
    start_tier_flow: false,
}

const optionsDict = {
    bulk_extra: 'Bulk (Upto 40K)',
    bulk: 'Bulk (Upto 1K)',
    individual: 'Individual',
}

enum FilterType {
    STATE = 'state',
    CITY = 'city',
    PINCODE = 'pincode',
    DECILE = 'decile',
    // PRODUCT = 'product',   //to be added later
}

const rcaViewDropdownItems = [
    {
        label: 'RTO Share',
        key: '1',
    },
    {
        label: 'Order Share',
        key: '2',
    },
]

let rcaCardsData = {}

const categorizePincodes = {
    above40Percent: [],
    between20And40Percent: [],
    below20Percent: [],
}

const sortedData = {
    statesData: [],
    citiesData: [],
    pincodesData: [],
    decileData: [],
    comparedStatesData: [],
    comparedCitiesData: [],
    comparedPincodesData: [],
    comparedDecileData: [],
    // productsData: [],
    // comparedProductsData: [],    //to be added later
}

const resetRcaCardsData = () => {
    rcaCardsData = {}
}

const radioToolTipCondition = ['cod_rate', 'duplicate_order', 'customer_type', 'customer_since_dt', 'customer_past_rto']

const isRepeatCustomerMessage =
    'Create workflows for new (never purchased in gokwik network) and repeat (previously purchased in gokwik network) customers.'

const customerSinceMessage = "Date of the customer's first order with Gokwik"

const customerPastRTO =
    'Build workflows for customers who have done RTO orders on your D2C website within the past year. Please ensure all delivery statuses are updated in Gokwik.'

const CONDITION_TOOLTIP_MESSAGES = {
    cod_rate: codRateMessage,
    duplicate_order: duplicateOrderMessage,
    customer_type: isRepeatCustomerMessage,
    customer_since_dt: customerSinceMessage,
    customer_past_rto: customerPastRTO,
}

const OS_OPTIONS = [
    { value: 'Android', label: 'Android' },
    { value: 'BlackBerry OS', label: 'BlackBerry OS' },
    { value: 'Chrome OS', label: 'Chrome OS' },
    { value: 'Chromecast', label: 'Chromecast' },
    { value: 'Fedora', label: 'Fedora' },
    { value: 'Linux', label: 'Linux' },
    { value: 'Mac OS', label: 'Mac OS' },
    { value: 'Other', label: 'Other' },
    { value: 'Symbian^3 Anna', label: 'Symbian^3 Anna' },
    { value: 'Tizen', label: 'Tizen' },
    { value: 'Ubuntu', label: 'Ubuntu' },
    { value: 'Windows', label: 'Windows' },
    { value: 'iOS', label: 'iOS' },
]

const BROWSER_OPTIONS = [
    { value: 'Amazon Silk', label: 'Amazon Silk' },
    { value: 'Android', label: 'Android' },
    { value: 'Apple Mail', label: 'Apple Mail' },
    { value: 'BlackBerry WebKit', label: 'BlackBerry WebKit' },
    { value: 'Chrome', label: 'Chrome' },
    { value: 'Chrome Mobile', label: 'Chrome Mobile' },
    { value: 'Chrome Mobile WebView', label: 'Chrome Mobile WebView' },
    { value: 'Chrome Mobile iOS', label: 'Chrome Mobile iOS' },
    { value: 'Crosswalk', label: 'Crosswalk' },
    { value: 'DuckDuckGo Mobile', label: 'DuckDuckGo Mobile' },
    { value: 'Edge', label: 'Edge' },
    { value: 'Edge Mobile', label: 'Edge Mobile' },
    { value: 'Electron', label: 'Electron' },
    { value: 'Facebook', label: 'Facebook' },
    { value: 'Firefox', label: 'Firefox' },
    { value: 'Firefox Mobile', label: 'Firefox Mobile' },
    { value: 'Firefox iOS', label: 'Firefox iOS' },
    { value: 'Google', label: 'Google' },
    { value: 'HeadlessChrome', label: 'Headless Chrome' },
    { value: 'Instagram', label: 'Instagram' },
    { value: 'Iron', label: 'Iron' },
    { value: 'LINE', label: 'LINE' },
    { value: 'Limeroad', label: 'Limeroad' },
    { value: 'Maxthon', label: 'Maxthon' },
    { value: 'Mint Browser', label: 'Mint Browser' },
    { value: 'Mirraw', label: 'Mirraw' },
    { value: 'MiuiBrowser', label: 'Miui Browser' },
    { value: 'Mobile Safari', label: 'Mobile Safari' },
    { value: 'Mobile Safari UI/WKWebView', label: 'Mobile Safari UI/WKWebView' },
    { value: 'Nokia Browser', label: 'Nokia Browser' },
    { value: 'Opera', label: 'Opera' },
    { value: 'Opera Mobile', label: 'Opera Mobile' },
    { value: 'Other', label: 'Other' },
    { value: 'Pale Moon', label: 'Pale Moon' },
    { value: 'Pinterest', label: 'Pinterest' },
    { value: 'Puffin', label: 'Puffin' },
    { value: 'Python Requests', label: 'Python Requests' },
    { value: 'QQ Browser Mobile', label: 'QQ Browser Mobile' },
    { value: 'Safari', label: 'Safari' },
    { value: 'Samsung Internet', label: 'Samsung Internet' },
    { value: 'Snapchat', label: 'Snapchat' },
    { value: 'Storebot', label: 'Storebot' },
    { value: 'Twitter', label: 'Twitter' },
    { value: 'UC Browser', label: 'UC Browser' },
    { value: 'Whale', label: 'Whale' },
    { value: 'Yandex Browser', label: 'Yandex Browser' },
    { value: 'axios', label: 'Axios' },
    { value: 'beyoungapp', label: 'Beyoung App' },
    { value: 'iOSConsumerApp', label: 'iOS Consumer App' },
    { value: 'okhttp', label: 'Okhttp' },
    { value: 'organicHarvest', label: 'Organic Harvest' },
    { value: 'sirona', label: 'Sirona' },
    { value: 'snappy', label: 'Snappy' },
    { value: 'stBotanicaiOSConsumerApp', label: 'St Botanica iOS Consumer App' },
    { value: 'theMomsCo', label: 'The Moms Co' },
]

const TIER_OPTIONS = [
    { value: 'TIER 1', label: 'TIER 1' },
    { value: 'TIER 2', label: 'TIER 2' },
    { value: 'TIER 3', label: 'TIER 3' },
]

const CODBlockingFlagTooltip = 'If you want this reason to be displayed at checkout in addition to being sent as a Shopify tag, please contact CSM.'

const tieringFaqLink = 'https://scribehow.com/page/KwikFlows_Pro__Frequently_Asked_Questions__ESBvs7m-Q86USy6fb7yFog'

export {
    searchFilters,
    rtoDetailsTableMenu,
    rtoDetailsFilterMenu,
    ruleTemplates,
    conditionCategories,
    fullUppercaseWords,
    rtoRiskWorkflow,
    rtoScore,
    initialRiskOptions,
    workflowDefaultValue,
    operators,
    rtoRiskKeys,
    ppcodActionType,
    actionInputOptions,
    actionInputOptionsWithFreebies,
    bulkEntryData,
    bulkUploadCheck,
    actionNames,
    secondaryActionText,
    tertiaryActionText,
    inputFieldData,
    abCreationMessage,
    howABTestingWorksText,
    whyABImportantText,
    abHelpsTexts,
    howABWorksModalTooltipText,
    actionTypes,
    workflowFlagConditionToolTip,
    secondaryActionTypeVar,
    tertiaryActionTypeVar,
    hideAbEditWorkflowTooltip,
    workflowLockedInfo,
    loadingWorkflowStatus,
    workflowShouldEnabled,
    impactViewMapping,
    impactViewLockedText,
    defaultImpactData,
    variantAName,
    awbAwaitedText,
    abTestShopifyFlagTooltip,
    WorkflowFlagTooltip,
    phoneRegEx,
    numRegEx,
    validations,
    workflowKeys,
    specialNamesForBlankOrNull,
    productVariantInfo,
    excludedFields,
    excludedPatterns,
    abFlagConditionToolTip,
    duplicateOrderMessage,
    codRateMessage,
    COD_RATE_OPTIONS,
    RTO_ACTIONS_API_RULES,
    abTestImpactInfoTooltip,
    impactInfoAfter90Days,
    summaryViewMetrics,
    rtoApiHitsIconMsg,
    awbFillRateIconMsg,
    workflowImpactViewText,
    defaultWorkflowImpactData,
    tieringFreeConditions,
    defaultTieringConfig,
    optionsDict,
    FilterType,
    rcaViewDropdownItems,
    rcaCardsData,
    categorizePincodes,
    sortedData,
    resetRcaCardsData,
    radioToolTipCondition,
    CONDITION_TOOLTIP_MESSAGES,
    deepDiveViewMetrics,
    mappingsDeepDiveView,
    titlesDeepDiveView,
    DEFAULT_BULK_WORKFLOW,
    OS_OPTIONS,
    BROWSER_OPTIONS,
    TIER_OPTIONS,
    CODBlockingFlagTooltip,
    tieringFaqLink,
}
