import React, { useEffect } from 'react'
import { Form, InputNumber, Select } from 'gokwik-ui-kit'
import { ppcodActionType } from '@library/utilities/constants/constants'
import { capitalize, inputNumberFormatter, inputNumberParser, isValidNumber } from '@library/utilities/helpers/helper'
import { ActionType } from '@library/utilities/interface'

interface PpCodUpiInputProps {
    actionType: ActionType
    setActionType: React.Dispatch<React.SetStateAction<ActionType>>
    formText?: string
    type?: string
    isActionDisabled: boolean
}

const PpCodUpiInput: React.FC<PpCodUpiInputProps> = ({
    actionType,
    setActionType,
    formText,
    type,
    isActionDisabled,
}) => {
    const ppcodDeductionType: string = actionType?.ppcod_config?.[0]?.deductionType ?? 'fixed'
    const form = Form.useFormInstance()

    const setPPCODActionType = (value: string) => {
        setActionType({
            ...actionType,
            ppcod_config: [
                {
                    ...actionType.ppcod_config?.[0],
                    deductionType: value,
                    fixedDeduction: undefined,
                    percentageDeduction: undefined,
                    maximumDeduction: undefined,
                    minimumDeduction: undefined,
                },
            ],
        })
    }

    const handleSelectChange = (value: string) => {
        setPPCODActionType(value)
    }

    const getPPCodInputValue = () => {
        if (ppcodDeductionType === ppcodActionType.fixed) {
            return actionType?.ppcod_config?.[0]?.fixedDeduction ?? ''
        }
        const percentageDeduction = actionType?.ppcod_config?.[0]?.percentageDeduction
        return percentageDeduction ? percentageDeduction * 100 : ''
    }

    const setPPCodInputValue = (value: number) => {
        let ppcodTypeClone: ActionType = JSON.parse(JSON.stringify(actionType)) // creating deep copy otherwise it would affect original object.
        if (ppcodDeductionType === ppcodActionType.fixed) {
            ppcodTypeClone.ppcod_config[0].fixedDeduction = Math.round(value * 100) / 100
        } else if (ppcodDeductionType === ppcodActionType.percentage) {
            ppcodTypeClone.ppcod_config[0].percentageDeduction = value / 100
        }

        setActionType(ppcodTypeClone)
    }

    const getLimitValue = (type: string) => {
        const limitVal =
            type === 'lower'
                ? actionType?.ppcod_config?.[0]?.minimumDeduction
                : actionType?.ppcod_config?.[0]?.maximumDeduction
        return limitVal ?? ''
    }

    const handleKeyDown = (e) => {
        const { key, target } = e

        if (
            (key === '.' && ppcodDeductionType === ppcodActionType.percentage) ||
            key === 'e' ||
            (key === '0' && (target as HTMLInputElement).value === '')
        ) {
            e.preventDefault()
        }
    }

    const generateFormName = (suffix: string, type: string) => `${formText}-${type}-${suffix}-${ppcodDeductionType}`

    const formName = generateFormName('ppcod-fee', type)
    const formNameLower = generateFormName('ppcod-lower', type)
    const formNameUpper = generateFormName('ppcod-upper', type)
    const formNameRemove = generateFormName('ppcod-fee', type === 'primary' ? 'secondary' : 'primary')
    const formNameLowerRemove = generateFormName('ppcod-lower', type === 'primary' ? 'secondary' : 'primary')
    const formNameUpperRemove = generateFormName('ppcod-upper', type === 'primary' ? 'secondary' : 'primary')
    useEffect(() => {
        const values = {
            [formName]: getPPCodInputValue(),
            [formNameLower]: getLimitValue('lower'),
            [formNameUpper]: getLimitValue('upper'),
            [formNameRemove]: undefined,
            [formNameLowerRemove]: undefined,
            [formNameUpperRemove]: undefined,
        }

        form.setFieldsValue(values)
    }, [formName, formNameLower, formNameUpper, form, ppcodDeductionType, actionType?.ppcod_config])

    return (
        <div className='flex flex-col mt-5'>
            <p>Fee Type</p>
            <div className='mt-2.5 flex items-start justify-between gap-3'>
                <Select
                    className='!w-52'
                    placeholder='Select Fee Type'
                    onChange={handleSelectChange}
                    value={ppcodDeductionType || 'Select Option'}
                    options={[
                        { value: 'fixed', label: 'Fixed' },
                        { value: 'percentage', label: 'Percentage' },
                    ]}
                    disabled={isActionDisabled}
                />
                <Form.Item
                    className='mb-0'
                    name={formName}
                    initialValue={getPPCodInputValue()}
                    rules={[
                        {
                            validator: (_, value) => {
                                const isPercentage = ppcodDeductionType.toLowerCase() === ppcodActionType.percentage
                                if (!isValidNumber(value)) {
                                    return Promise.reject(new Error(''))
                                }
                                if (isPercentage && (value < 0 || value > 100)) {
                                    return Promise.reject(new Error('Please enter values between 1 to 100'))
                                }
                                if (value <= 0) {
                                    return Promise.reject(new Error('Please enter positive values!'))
                                }
                                return Promise.resolve()
                            },
                        },
                    ]}
                >
                    <InputNumber
                        value={getPPCodInputValue()}
                        placeholder={`${capitalize(ppcodDeductionType)} value`}
                        onChange={(val) => setPPCodInputValue(val && Number(val))}
                        addonAfter={`${ppcodDeductionType.toLowerCase() === ppcodActionType.percentage ? '%' : ''}`}
                        addonBefore={`${ppcodDeductionType.toLowerCase() === ppcodActionType.fixed ? '₹' : ''}`}
                        onKeyDown={handleKeyDown}
                        formatter={inputNumberFormatter}
                        parser={inputNumberParser}
                        disabled={isActionDisabled}
                    />
                </Form.Item>
            </div>
            {ppcodDeductionType === ppcodActionType.percentage ? (
                <div className='flex flex-col gap-4 mt-2.5'>
                    <div>
                        <p className='mt-2.5'>Lower Limit</p>
                        <Form.Item
                            className='mb-0 mt-2.5'
                            name={formNameLower}
                            rules={[
                                {
                                    validator: (_, value) => {
                                        const upperLimit = getLimitValue('upper')
                                        if (!isValidNumber(value)) {
                                            return Promise.reject(new Error(''))
                                        }
                                        if (value <= 0) {
                                            return Promise.reject(new Error('Please enter positive values!'))
                                        }
                                        if (upperLimit && value >= upperLimit) {
                                            return Promise.reject(
                                                new Error('Please enter values less than upper limit!'),
                                            )
                                        }
                                        return Promise.resolve()
                                    },
                                },
                            ]}
                            initialValue={getLimitValue('lower')}
                            dependencies={[formNameUpper]}
                        >
                            <InputNumber
                                className='!w-full'
                                value={getLimitValue('lower')}
                                placeholder='Enter Lower Limit'
                                onChange={(val) =>
                                    setActionType({
                                        ...actionType,
                                        ppcod_config: [
                                            {
                                                ...actionType?.ppcod_config[0],
                                                minimumDeduction: val && Number(val),
                                            },
                                        ],
                                    })
                                }
                                addonBefore='₹'
                                formatter={inputNumberFormatter}
                                parser={inputNumberParser}
                                disabled={isActionDisabled}
                            />
                        </Form.Item>
                    </div>
                    <div>
                        <p>Upper Limit</p>
                        <Form.Item
                            className='mb-0 mt-2.5'
                            name={formNameUpper}
                            rules={[
                                {
                                    validator: (_, value) => {
                                        if (!isValidNumber(value)) {
                                            return Promise.reject(new Error(''))
                                        }
                                        if (value <= 0) {
                                            return Promise.reject(new Error('Please enter positive values!'))
                                        }
                                        return Promise.resolve()
                                    },
                                },
                            ]}
                            initialValue={getLimitValue('upper')}
                        >
                            <InputNumber
                                className='mt-2.5 !w-full'
                                placeholder='Enter Upper Limit'
                                onChange={(val) =>
                                    setActionType({
                                        ...actionType,
                                        ppcod_config: [
                                            {
                                                ...actionType?.ppcod_config[0],
                                                maximumDeduction: val && Number(val),
                                            },
                                        ],
                                    })
                                }
                                addonBefore='₹'
                                value={getLimitValue('upper')}
                                formatter={inputNumberFormatter}
                                parser={inputNumberParser}
                                disabled={isActionDisabled}
                            />
                        </Form.Item>
                    </div>
                </div>
            ) : null}
            <p className='mt-5'>of Total Order Value</p>
        </div>
    )
}

export default PpCodUpiInput
