import EmptyState from '@library/components/actions/all-workflow/empty-state'
import { Layout } from 'gokwik-ui-kit'

interface EmptyPageDataProps {
    title: string;
    icon: React.ReactNode;
}

const { Content } = Layout
function EmptyPageData({ title, icon }: EmptyPageDataProps) {
    return (
        <Content>
            <div className='overflow-scroll p-4 bg-white h-full'>
                <EmptyState icon={icon} title={title} />
            </div>
        </Content>
    )
}

export default EmptyPageData
