import { useCallback, useEffect, useMemo, useState } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAllAnalyticsData } from '@store/analytics'
import { getAnalyticsData } from '@store/analytics/selectors'
import { updateBreadcrumbs } from '@gokwik/utilities'
import RtoCards from '@library/components/analytics/rto-rate-cards'
import Chart from './chart'
import { ChartRows, LowerSummaryBlocksData, UpperSummaryBlocksData } from './chartData'
import { updateDataBasedOnFilter } from '@library/utilities/helpers/data_filtering'
import { getMerchantDetails, getUserDetails } from '@store/user/selectors'
import CustomHeroValue from '@library/components/analytics/tiles'
import { useNavigate } from 'react-router-dom'
import { useParams, useLocation } from 'react-router-dom' // Remove useNavigate as it's already imported
import { updateShopifyDashboardAnalytics } from '@store/analytics/api'
import {
    Col,
    Row,
    AnalyticsCard,
    AnalyticsDateRangePicker,
    SummaryBlocks,
    InfoCircleOutlined,
    message,
} from 'gokwik-ui-kit';
import { saveFiltersData } from '@store/filters'
import { getFilterDateOverviewPage } from '@store/filters/selectors'
import { calculateDelta, calculateOrderMetricsDelta } from '@library/utilities/helpers/calculate_metrics'
import { logEvent } from '@library/utilities/userLogEvents/userLogEvents'

const today = dayjs()
const t_15 = today.subtract(15, 'day')
const t_45 = today.subtract(45, 'day')
const t_75 = today.subtract(75, 'day')

const RTOAnalytics = () => {

    const merchantDetails = useSelector(getMerchantDetails)
    const user_details = useSelector(getUserDetails)
    const storedDates = useSelector(getFilterDateOverviewPage)
    const format = 'YYYY-MM-DD'
    const { filter_type = 'all', filter_value = '', fromDate = '', toDate = '' } = useParams()
    const merchant_id = merchantDetails.id
    const m_id = merchantDetails.m_id
    const isGranular = fromDate && toDate && filter_type && filter_value
    const from_date = storedDates.fromDate || t_45
    const to_date = storedDates.toDate || t_15
    const [dates, setDates] = useState<{ from: Dayjs; to: Dayjs; label?: string }>({
        from: from_date,
        to: to_date,
    })
    const diff = dates.to.diff(dates.from, 'day')
    const [prevComparisonDates, setPrevComparisonDates] = useState<{from: Dayjs; to: Dayjs}>({
        from: storedDates.comparisonFromDate || t_75,
        to: storedDates.comparisonToDate || t_45 
    })
    
    useEffect(() => {
        let breadcrumbs = [
            {
                key: 'rto-analytics',
                href: '/rto/analytics/overview',
                text: 'RTO Analytics Overview',
            },
        ]
        if (isGranular) {
            breadcrumbs.push({
                key: 'rto-analytics-granular',
                href: '/rto/analytics/overview',
                text: `${filter_value}`,
            })
        }
        updateBreadcrumbs((prev) => [prev[0],...breadcrumbs])
    }, [isGranular])
    const dispatch = useDispatch()
    const getFetch = async () => {
        if (isGranular) {
            await updateShopifyDashboardAnalytics(filter_type, filter_value)
        }
        dispatch(
            //@ts-ignore
            fetchAllAnalyticsData({
                params: {
                    from_date: dates.from.format(format),
                    to_date: dates.to.format(format),
                    prev_comparison_from_date: prevComparisonDates.from.format(format),
                    prev_comparison_to_date: prevComparisonDates.to.format(format),
                    filter_type: filter_type,
                    filter_value: filter_value,
                    merchant_id: merchant_id,
                    m_id: m_id
                },
                payload: {
                    from_date: dates.from.format(format),
                    to_date: dates.to.format(format),
                    merchant_id: merchant_id,
                },
            }),
        )
        dispatch<any>(saveFiltersData('analyticsOverview', {
            fromDate:dates.from,
            toDate:dates.to,
            comparisonFromDate: prevComparisonDates.from,
            comparisonToDate: prevComparisonDates.to
          }))
    }

    useEffect(() => {
        const updatedFromDate = dates.from.subtract(diff+1, 'day')
        setPrevComparisonDates({ from: updatedFromDate , to: dates.from.subtract(1, 'day')})
        getFetch()
    }, [dates,isGranular])

    const analyticsData = useSelector(getAnalyticsData)
    let comparisonRTOValues = null
    let comparisonOrderMetrics = null
    if(analyticsData.rtoInsights.status === 'idle'){
        comparisonRTOValues = calculateDelta(analyticsData?.rtoInsights?.data?.tileMetrics, analyticsData?.rtoInsightsPrevComparison?.data?.tileMetrics)
    }
    if(analyticsData.orderInsights.status === 'idle'){
        comparisonOrderMetrics = calculateOrderMetricsDelta(analyticsData?.orderInsights?.data?.tileMetrics, analyticsData?.orderInsightsPrevComparison?.data?.tileMetrics)
    }
   
    const upperBlocksData = useMemo(() => {
        return UpperSummaryBlocksData?.map((block) => {
            let data = null
            let deltaValue = null
            if (analyticsData?.rtoInsights?.status !== 'loading' || analyticsData?.orderInsights?.status !== 'loading') {
                if (block.heading === 'Total Orders' || block.heading === 'COD Orders') {
                    data = block.nestedKey
                        ? analyticsData?.orderInsights?.data?.[block.dataKey]?.[block.nestedKey]
                        : analyticsData?.orderInsights?.data?.[block.dataKey]
                    deltaValue =
                    comparisonOrderMetrics?.[block.nestedKey]
                } else {
                    data = block.nestedKey
                        ? analyticsData?.rtoInsights?.data?.[block.dataKey]?.[block.nestedKey]
                        : analyticsData?.rtoInsights?.data?.[block.dataKey]
                    deltaValue = comparisonRTOValues?.[block.nestedKey]
                }
            }
            const status = analyticsData.rtoInsights.status
            return {
                title: block.heading,
                tooltipText: block.tooltipText,
                heroValue: (
                    <CustomHeroValue
                        data={data}
                        deltaValue={deltaValue}
                        heroValueTransform={block.heroValueTransform}
                        heading={block?.heading || ''}
                        calledBy={'summaryBlock'}
                    />
                ),
                loading: status === 'loading',
            }
        })
    }, [analyticsData])

    const lowerBlocksData = useMemo(() => {
        return LowerSummaryBlocksData?.map((block) => {
            let data = null
            let deltaValue = null
            if (analyticsData?.rtoInsights?.status !== 'loading' && analyticsData?.rtoInsights?.status !== 'failed') {
                data = block.nestedKey
                    ? analyticsData?.rtoInsights?.data?.[block.dataKey]?.[block.nestedKey]
                    : analyticsData?.rtoInsights?.data?.[block.dataKey]
                deltaValue = comparisonRTOValues?.[block.nestedKey]
            }
            const status = analyticsData.rtoInsights.status || 'loading'

            return {
                title: block.heading,
                tooltipText: block.tooltipText,
                heroValue: (
                    <CustomHeroValue
                        data={data}
                        deltaValue={deltaValue}
                        heroValueTransform={block.heroValueTransform}
                        heading={block?.heading || ''}
                        calledBy={'summaryBlock'}
                    />
                ),
                loading: status === 'loading',
            }
        })
    }, [analyticsData])

    const handleDateRange = (values, label) => {
        const [fromDate, toDate] = values

        const isFromDateWithinLast15Days = fromDate.isAfter(dayjs().subtract(15, 'day'), 'day')

        const isToDateWithinLast15Days = toDate.isAfter(dayjs().subtract(15, 'day'), 'day')

        const isFromDateWithinLast105Days = fromDate.isAfter(dayjs().subtract(105, 'day'), 'day')

        const isToDateWithinLast105Days = toDate.isAfter(dayjs().subtract(105, 'day'), 'day')

        if (!isFromDateWithinLast105Days || !isToDateWithinLast105Days) {
            message.error({
                content: `Please don't select dates earlier than Last 90 days.`,
                duration: 2,
            })
            return
        }
        if (isFromDateWithinLast15Days && isToDateWithinLast15Days) {
            message.error({
                content: 'Please select dates earlier the last 15 days for updated data.',
                duration: 2,
            })
            return
        }

        const updatedFromDate = fromDate.subtract(diff+1, 'day')
        setPrevComparisonDates({ from: updatedFromDate , to: fromDate.subtract(1, 'day')})
        setDates({ from: fromDate, to: toDate, label })
        logEvent('kwik_rto_rto_analytics_date_range_apply_clicked', 'click', 'Kwik RTO Analytics', user_details?.email, merchantDetails?.m_id,merchantDetails?.short_name, user_details?.name)
    }

    const getDeltaValueKey = (key) => {
        if (key === 'total_orders_breakdown') return 'total_orders'
        else if (key === 'overall_rto_rate') return 'overall_rto_rate'
        else if (key === 'overall_rto_orders') return 'total_rto_orders'
    }
    return (
        <div className='main-div px-3'>
            <div className='tile-metrics'>
                <h1 className='ml-3 mb-0 inline-block'>
                    {filter_value ? filter_value.toUpperCase() : merchantDetails.business_name} METRICS AT A GLANCE
                </h1>
                <div className='flex align-middle mb-4'>
                    <p className='ml-3 mt-4 flex-1'>{analyticsData?.configData?.data?.tagline ? analyticsData?.configData?.data?.tagline : `Analyze, act, measure, share: Reduce RTO and optimize delivery performance.`}</p>
                    <div className='flex-1 text-right'>
                        <AnalyticsDateRangePicker values={[dates.from, dates.to]} onApply={handleDateRange} />
                    </div>
                </div>
                <div className='awb-info'>
                    <InfoCircleOutlined
                    />
                    <span className='ml-5'>
                        Only T-15 data is recommended to be viewed here since AWB takes 15 days to mature.
                    </span>
                </div>
                <div className='rto-summary-block mb-4'>
                    <SummaryBlocks data={upperBlocksData} />
                </div>
                <div className='rto-summary-block mb-4'>
                    <SummaryBlocks data={lowerBlocksData} />
                </div>
                <Row gutter={[16, 16]}>
                    {ChartRows.map((chart) => {
                        const [filterValues, setFilterValues] = useState(
                            chart.filters?.reduce((acc, filter) => ({ ...acc, [filter.key]: filter.value }), {}) || {},
                        )
                        const originalData =
                            chart.key === 'total_orders_breakdown'
                                ? analyticsData?.orderInsights?.[chart.dataKey]?.[chart.nestedKey]
                                : analyticsData?.rtoInsights?.[chart.dataKey]?.[chart.nestedKey]

                        const data = updateDataBasedOnFilter(originalData, filterValues['date_range_filter'])
                        const hasData = !!data
                        const deltaValueKey = getDeltaValueKey(chart.key)
                        return (
                            <Col key={chart.key} span={chart.span || 12} className="pr-2">
                                <AnalyticsCard
                                    title={chart.heading}
                                    titleTooltip={chart.tooltipText}
                                    loading={analyticsData.rtoInsights.status === 'loading'}
                                    noData={!hasData}
                                    {...(chart.chartProps && {
                                        heroValueTransform: 'number',
                                    })}
                                    filters={chart.filters}
                                    filterValues={filterValues}
                                    filterOnChange={(key, value) => {
                                        setFilterValues((prev) => ({ ...prev, [key]: value }))
                                    }}
                                >
                                    {hasData && (
                                        <>
                                            <CustomHeroValue
                                                data={
                                                    deltaValueKey === 'total_orders'
                                                        ? analyticsData?.orderInsights?.data?.['tileMetrics']?.[
                                                              deltaValueKey
                                                          ]
                                                        : analyticsData?.rtoInsights?.data?.['tileMetrics']?.[
                                                              deltaValueKey
                                                          ]
                                                }
                                                deltaValue={
                                                    deltaValueKey === 'total_orders'
                                                        ? comparisonOrderMetrics?.[deltaValueKey]
                                                        : comparisonRTOValues?.[deltaValueKey]
                                                }
                                                heroValueTransform={'number'}
                                                heading={chart?.heading || ''}
                                                calledBy={'charts'}
                                            />
                                            <div className="mr-1">
                                                <Chart
                                                    uniqueKey={chart.key}
                                                    wrapperClassName='min-h-[288px] h-full'
                                                    chartType={chart.chartProps.chartType as any}
                                                    seriesNames={chart.chartProps.seriesNames}
                                                    data={data}
                                                />
                                            </div>
                                        </>
                                    )}
                                </AnalyticsCard>
                            </Col>
                        )
                    })}
                </Row>
                {filter_type === 'all' && <Col span={24} className='mb-4'>
                    <h1>RTO Breakdown: States, Cities, Pincodes and Products</h1>
                    <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <RtoCards
                                    title='Top States'
                                    innerTitle='States'
                                    data={analyticsData?.topStatesByRtoRate?.data}
                                    filter_type='state'
                                    fromDate={dates.from.format(format)}
                                    toDate={dates.to.format(format)}
                                    isLoading={analyticsData.topStatesByRtoRate.status === 'loading'}
                                />
                            </Col>
                            <Col span={12}>
                                <RtoCards
                                    title='Top Cities'
                                    innerTitle='Cities'
                                    data={analyticsData?.topCitiesByRtoRate?.data}
                                    filter_type='city'
                                    fromDate={dates.from.format(format)}
                                    toDate={dates.to.format(format)}
                                    isLoading={analyticsData.topCitiesByRtoRate.status === 'loading'}
                                />
                            </Col>
                            <Col span={12}>
                                <RtoCards
                                    title='Top Pincodes'
                                    innerTitle='Pincodes'
                                    data={analyticsData?.topPincodesByRtoRate?.data}
                                    filter_type='pincode'
                                    fromDate={dates.from.format(format)}
                                    toDate={dates.to.format(format)}
                                    isLoading={analyticsData.topPincodesByRtoRate.status === 'loading'}
                                />
                            </Col>
                            <Col span={12}>
                                <RtoCards
                                    title='Top Products'
                                    innerTitle='Products'
                                    data={analyticsData?.topProductsByRtoRate?.data}
                                    filter_type='product'
                                    fromDate={dates.from.format(format)}
                                    toDate={dates.to.format(format)}
                                    isLoading={analyticsData.topProductsByRtoRate.status === 'loading'}
                                />
                            </Col>
                    </Row>
                </Col>}
            </div>
        </div>
    )
}

export default RTOAnalytics
