export const CardIcon = () => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='18' height='12' fill='none'>
            <path
                d='M4.9.214a4 4 0 0 0-4 4v3.571a4 4 0 0 0 4 4h8.2a4 4 0 0 0 4-4V4.214a4 4 0 0 0-4-4H4.9zm5.836 9.257C9.775 9.471 9 8.696 9 7.736S9.775 6 10.736 6s1.736.775 1.736 1.736-.775 1.736-1.736 1.736zm2.314 0c-.231 0-.451-.046-.654-.127a2.3 2.3 0 0 0 0-3.217c.202-.081.422-.127.654-.127.96 0 1.736.775 1.736 1.736s-.775 1.736-1.736 1.736z'
                fill='#00192f'
            />
        </svg>
    )
}
