import { useEffect, useState } from 'react'
import { Card, Alert, Button } from 'gokwik-ui-kit'
import ActionCards from './actionCards'
import ActionCardsListItem from './actionCardsListItem'
import PaymentPreview from './paymentPreview'
import { extractActionLabel, getUsedConditions } from './helpers'
import { actionInputOptionsWithFreebies } from '@library/utilities/constants/constants'
import { ActionType, RuleRawData, Workflow } from '@library/utilities/interface'

interface TemplateDetailsProps {
    selectedTemplate: {
        rule_name: string
        noOfMerchantsUsing: number
        rules: RuleRawData[]
        ab_test_toggle: boolean
        ab_control_actions: ActionType[]
        ab_control_perc: number
    }
    useTemplate: (template: Workflow) => void
}

const TemplateDetails: React.FC<TemplateDetailsProps> = ({ selectedTemplate, useTemplate }) => {
    const {
        rule_name,
        noOfMerchantsUsing,
        rules,
        ab_test_toggle: abTestToggle,
        ab_control_actions: abControlActions,
        ab_control_perc: abControlPercentage,
    } = selectedTemplate
    const [hoveredAction, setHoveredAction] = useState<string>('')
    const [actionList, setActionList] = useState<{ value: string; label: string }[]>([])
    const [controlActionList, setControlActionList] = useState<{ value: string; label: string }[]>([])
    const [variantHovered, setVariantHovered] = useState('')

    useEffect(() => {
        const actionTypes = rules[0]?.raw_data?.actions ?? []
        const action = actionTypes
            .map((action: ActionType) => extractActionLabel(action, actionInputOptionsWithFreebies))
            .filter(Boolean)

        setActionList(action)
    }, [rules])

    useEffect(() => {
        const action = abControlActions
            .map((action: ActionType) => extractActionLabel(action, actionInputOptionsWithFreebies))
            .filter(Boolean)
        setControlActionList(action)
    }, [abControlActions])

    const getActionOnHover = (actionName: string) => {
        setHoveredAction(actionName)
    }

    const getVariantOnHover = (variantType = '') => {
        setVariantHovered(variantType)
    }

    return (
        <div className='flex gap-4 h-full'>
            <div className='w-[65%] h-auto flex flex-col justify-between' style={{ borderRight: '1px solid #E5E7EB' }}>
                <Card
                    className=' bg-white !p-0 !m-3 !shadow-none'
                    styles={{
                        body: {
                            height: '100%',
                            padding: '8px',
                        },
                    }}
                    bordered={false}
                >
                    <div className=' h-full'>
                        <div className='flex justify-between items-start mb-6'>
                            <div>
                                <h2 className='text-lg font-semibold text-gray-800'>{rule_name}</h2>
                            </div>
                            <div className='flex items-center gap-2 bg-gray-50 px-4 py-2 rounded-lg'>
                                <span className='text-2xl font-semibold text-gray-700'>{noOfMerchantsUsing}</span>
                                <div className='text-xs'>
                                    <div className='font-medium'>Merchant</div>
                                    <div className='text-gray-500'>Using</div>
                                </div>
                            </div>
                        </div>

                        <div className='mb-6'>
                            <h3 className='text-sm font-medium text-gray-600 mb-3'>Used Condition</h3>
                            <div className='flex gap-2 flex-col'>
                                {getUsedConditions(rules).map(
                                    (item: { label: string; labels: string[] }, index: number) => (
                                        <div key={index} className='flex items-center'>
                                            <span className='font-semibold'>{item.label}:</span>
                                            <div className='flex gap-2 ml-2'>
                                                {item.labels.map((label, labelIndex) => (
                                                    <div
                                                        key={labelIndex}
                                                        className='px-2 py-1 bg-gray-100 rounded-md text-sm shadow-sm'
                                                    >
                                                        {label}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    ),
                                )}
                            </div>
                        </div>

                        <div>
                            <h3 className='text-sm font-medium text-gray-600 mb-3'>Used Intervention</h3>
                            <Alert
                                className='my-2.5'
                                message='To get experience hover to every section below to see preview.'
                                type='info'
                                showIcon
                                closable
                            />
                            {abTestToggle ? (
                                <div className='grid grid-cols-2 gap-4'>
                                    <ActionCards
                                        abPercentage={100 - abControlPercentage}
                                        actionList={controlActionList}
                                        getActionOnHover={getActionOnHover}
                                        treatMentVariant
                                        getVariantOnHover={getVariantOnHover}
                                    />
                                    <ActionCards
                                        actionList={actionList}
                                        abPercentage={abControlPercentage}
                                        getActionOnHover={getActionOnHover}
                                        getVariantOnHover={getVariantOnHover}
                                    />
                                </div>
                            ) : (
                                <div className='max-h-72 overflow-y-scroll'>
                                    {actionList?.map((action, index) => (
                                        <ActionCardsListItem action={action} key={index} onHover={getActionOnHover} />
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </Card>
                <footer className='flex items-center gap-2 font-semibold p-4 bg-[#F2F4F7] rounded-bl-lg justify-end'>
                    <Button
                        className='px-3 py-1 text-sm text-white'
                        variant='primary'
                        onClick={() => useTemplate(selectedTemplate)}
                    >
                        Use This Template
                    </Button>
                </footer>
            </div>
            <Card
                className='w-[35%] bg-white shadow-sm !m-3'
                styles={{
                    body: {
                        height: '100%',
                        padding: '8px',
                    },
                }}
            >
                <PaymentPreview hoveredAction={hoveredAction} variantHovered={variantHovered} />
            </Card>
        </div>
    )
}

export default TemplateDetails
