const sampleAWBData = [
    {
        'Merchant OrderID': '#WL375778',
        'AWB Number (Optional)': '',
        'Shipping Provider (Optional)': 'nimbus',
        'AWB Status': 'rto',
    },
    {
        'Merchant OrderID': 'FC102079833',
        'AWB Number (Optional)': '742073750573423',
        'Shipping Provider (Optional)': '',
        'AWB Status': 'shipped',
    },
    {
        'Merchant OrderID': '#11510790',
        'AWB Number (Optional)': '742073750573425',
        'Shipping Provider (Optional)': 'shipway',
        'AWB Status': 'order placed',
    },
]

const cellKeys = ['Merchant OrderID', 'AWB Number (Optional)', 'Shipping Provider (Optional)', 'AWB Status']

const cellHeadings = ['Merchant OrderID', 'AWB Number (Optional)', 'Shipping Provider (Optional)', 'AWB Status']

const requiredHeadings = ['Merchant OrderID', 'AWB Status']

const uploadType = 'AWB_INGESTION'

const awbFillRateSuccessfullIngestion =
    'Great news! Your AWB Ingestion is currently underway. This process can take up to 12-18 hours to complete. Please check back here tomorrow after 1 PM for the latest status update.'

const invalidUploadFile =
    "Please ensure your file's column names precisely match those in the provided sample file for successful data uploads."

const initialKlothoData = {
    rule_engine_enabled: null,
    a_overall_cut_off: null,
    a_rto_order_threshold: null,
    a_total_order_threshold: null,
    b_merchant_ratio: null,
    b_network_ratio: null,
    b_overall_cut_off: null,
    b_network_cod_order_threshold: null,
    b_merchant_cod_order_threshold: null,
    c_orders_cut_off: null,
    c_hours_cut_off: null,
    d_overall_cut_off: null,
    e_overall_cut_off: null,
    f_blocked_strings: null,
    g_address_length: null,
    is_score_calculate: null,
    high_risk_threshold: null,
    medium_risk_threshold: null,
    klotho_control_threshold: null,
    is_rule_n_enabled: null,
    is_rule_gn_enabled: null,
    is_rule_ge_enabled: null,
    rule_n_threshold: null,
    rule_gn_threshold: null,
    rule_ge_threshold: null,
    klotho_mode: null,
}

const KlothoInputsData = {
    head: [],
    a: [
        {
            key: 'a_overall_cut_off',
            name: 'a overall cut off',
            type: 'integer',
            max: 100,
        },
        {
            key: 'a_rto_order_threshold',
            name: 'a rto order threshold',
            type: 'integer',
            max: 100,
        },
        {
            key: 'a_total_order_threshold',
            name: 'a total order threshold',
            type: 'integer',
            max: 100,
        },
    ],
    b: [
        {
            key: 'b_merchant_ratio',
            name: 'b merchant ratio',
            type: 'number',
            max: 1,
        },
        {
            key: 'b_network_ratio',
            name: 'b network ratio',
            type: 'number',
            max: 1,
        },
        {
            key: 'b_overall_cut_off',
            name: 'b overall cut off',
            type: 'integer',
            max: 100,
        },
        {
            key: 'b_network_cod_order_threshold',
            name: 'b network cod order threshold',
            type: 'integer',
            max: 100,
        },
        {
            key: 'b_merchant_cod_order_threshold',
            name: 'b merchant cod order threshold',
            type: 'integer',
            max: 100,
        },
    ],
    c: [
        {
            key: 'c_orders_cut_off',
            name: 'c orders cut off',
            type: 'integer',
            max: 100,
        },
        {
            key: 'c_hours_cut_off',
            name: 'c hours cut off',
            type: 'integer',
            max: 100,
        },
    ],
    d: [
        {
            key: 'd_overall_cut_off',
            name: 'd overall cut off',
            type: 'number',
            max: 1,
        },
    ],
    e: [
        {
            key: 'e_overall_cut_off',
            name: 'e overall cut off',
            type: 'integer',
            max: 1000000,
        },
    ],
    f: [
        {
            key: 'f_blocked_strings',
            name: 'f blocked strings (comma separated)',
            type: 'text',
        },
    ],
    g: [
        {
            key: 'g_address_length',
            name: 'g address length',
            type: 'integer',
            max: 1000000000,
        },
    ],
    n: [
        {
            key: 'is_rule_n_enabled',
            name: 'gibberish address rule enabled',
            type: 'boolean',
        },
        {
            key: 'rule_n_threshold',
            name: 'rule n threshold',
            type: 'number',
            max: 20,
        },
    ],
    gn: [
        {
            key: 'is_rule_gn_enabled',
            name: 'gibberish name rule enabled',
            type: 'boolean',
        },
        {
            key: 'rule_gn_threshold',
            name: 'rule gn threshold',
            type: 'number',
            max: 20,
        },
    ],
    ge: [
        {
            key: 'is_rule_ge_enabled',
            name: 'gibberish email rule enabled',
            type: 'boolean',
        },
        {
            key: 'rule_ge_threshold',
            name: 'rule ge threshold',
            type: 'number',
            max: 20,
        },
    ],
}

const KlothoModeEnum = {
    Live: 'Live',
    Shadow: 'Shadow',
}
const minimumGibberishKlothoRulesThreshold = 6.8

const KlothoRulesDescription = {
    a: "Customer has at least X RTO orders out of their last 3Y COD orders, with a Z% cutoff threshold. Applied at customer level across the network.",
    b: "Pincode-specific restrictions applied at both merchant and network levels.",
    c: "Maximum of X COD orders allowed within Y hours. Applied at merchant level.",
    d: "Risk score of 0.X and above triggers restrictions.",
    e: "Restrictions based on Average Order Value thresholds.",
    f: "Detection of gibberish text in order details.",
    g: "Restrictions based on address character length limits."
};

export {
    sampleAWBData,
    cellKeys,
    cellHeadings,
    requiredHeadings,
    uploadType,
    awbFillRateSuccessfullIngestion,
    invalidUploadFile,
    initialKlothoData,
    KlothoInputsData,
    KlothoModeEnum,
    minimumGibberishKlothoRulesThreshold,
    KlothoRulesDescription,
}
