import ActionCardsListItem from './actionCardsListItem'

const ActionCards = ({ abPercentage, actionList = [], getActionOnHover, treatMentVariant = false, getVariantOnHover }) => {
    return (
        <div
            className='hover:border-gray-500 hover:shadow-lg  p-4 
        border border-solid border-gray-200 rounded-md relative flex-shrink-0 shadow-md bg-variant-gradient
        '
            onMouseEnter={() => getVariantOnHover(treatMentVariant ? 'A' : 'B')}
            onMouseLeave={() => getVariantOnHover('')}
        >
            <div className='text-sm font-medium text-gray-600 mb-2'>Variant {treatMentVariant ? 'A' : 'B'}</div>
            <div className='text-xl font-bold mb-4'>{abPercentage}%</div>
            <div className='max-h-52 overflow-y-scroll'>
                {actionList?.map((action, index) => (
                    <ActionCardsListItem action={action} key={index} onHover={getActionOnHover} />
                ))}
            </div>
        </div>
    )
}

export default ActionCards
