import { createAsyncThunk, createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, RootStoreType } from '../index';
import { analyticsAPIs, fetchAnalyticsData } from './api';

export interface AnalyticsSlice {
    [key: string]: {
        [key: string]: any;
        status?: 'idle' | 'loading' | 'failed';
    };
}

const assignToState = ({
    state,
    key,
    nestedKeys,
    payload,
    status,
}: {
    state: AnalyticsSlice;
    status: 'idle' | 'loading' | 'failed';
    key: string;
    nestedKeys: string[];
    payload: any;
}) => {
    if (nestedKeys && nestedKeys.length > 0) {
        nestedKeys.forEach((nestedKey) => {
            state[key][nestedKey].status = status;
            if (payload && payload.data) state[key][nestedKey].data = payload.data[nestedKey];
        });
    } else {
        state[key].status = status;
        state[key].data = payload.data;
    }
    return state;
};

const initialState: AnalyticsSlice = {
    rtoInsights: {
        data: [],
        status: 'idle',
    },
    rtoInsightsPrevComparison: {
        data: [],
        status: 'idle',
    },
    orderInsights: {
        data: [],
        status: 'idle',
    },
    orderInsightsPrevComparison: {
        data: [],
        status: 'idle',
    },
    configData: {
        data: [],
        status: 'idle',
    },
    topStatesByRtoRate: {
        data: [],
        status: 'idle',
    },
    topCitiesByRtoRate: {
        data: [],
        status: 'idle',
    },
    topPincodesByRtoRate: {
        data: [],
        status: 'idle',
    },
    topProductsByRtoRate: {
        data: [],
        status: 'idle',
    },
};

export const fetchAnalyticsDataAsync = createAsyncThunk(
    'analytics/fetchAnalytics',
    async (
        {
            key,
            params,
            payload,
        }: { key: string; nestedKey?: string; params?: { [key: string]: any }; payload?: { [key: string]: any } },
        { rejectWithValue },
    ) => {
        const response = await fetchAnalyticsData({ key, params, payload });
        if (response.success) return response;
        else return rejectWithValue(response);
    },
);

export const fetchAllAnalyticsData =
    ({ params, payload }) =>
    async (dispatch: any) => {
        Object.keys(analyticsAPIs).forEach((key) => {
            dispatch(fetchAnalyticsDataAsync({ key, params, payload }));
        });
    };

export const analyticsSlice = createSlice({
    name: 'analytics',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAnalyticsDataAsync.pending, (state, action) => {
                const nestedKeys = action.meta.arg.nestedKey
                    ? [action.meta.arg.nestedKey]
                    : analyticsAPIs[action.meta.arg.key].nestedKeys || [];
                assignToState({ state, key: action.meta.arg.key, nestedKeys, payload: {}, status: 'loading' });
            })
            .addCase(fetchAnalyticsDataAsync.fulfilled, (state, action) => {
                const nestedKeys = action.meta.arg.nestedKey
                    ? [action.meta.arg.nestedKey]
                    : analyticsAPIs[action.meta.arg.key].nestedKeys || [];
                assignToState({ state, key: action.meta.arg.key, nestedKeys, payload: action.payload, status: 'idle' });
            })
            .addCase(fetchAnalyticsDataAsync.rejected, (state, action) => {
                const nestedKeys = action.meta.arg.nestedKey
                    ? [action.meta.arg.nestedKey]
                    : analyticsAPIs[action.meta.arg.key].nestedKeys || [];
                assignToState({ state, key: action.meta.arg.key, nestedKeys, payload: {}, status: 'failed' });
            });
    },
});

export default analyticsSlice.reducer;
