import AddressIcon from '@library/images/addressIcon'
import BrainIcon from '@library/images/brainIcon'
import CartIcon from '@library/images/cartIcon'
import CustomerIcon from '@library/images/customerIcon'
import SkuIcon from '@library/images/skuIcon'
import UtmIcon from '@library/images/utmIcon'
import { Tag } from 'gokwik-ui-kit'
import React from 'react'

interface ConditionCategoryProps {
    type: string
    label: string
    active: boolean
    onClick: () => void
}

const getIconFromType = (type: string, active: boolean) => {
    switch (type) {
        case 'CUSTOMER':
            return <CustomerIcon className='w-6 h-6' active={active} />
        case 'ADDRESS':
            return <AddressIcon className='w-6 h-6' active={active} />
        case 'CART':
            return <CartIcon className='w-6 h-6' active={active} />
        case 'UTM':
            return <UtmIcon className='w-6 h-6' active={active} />
        case 'SKU':
            return <SkuIcon className='w-6 h-6' active={active} />
        case 'RTO_RISK':
            return <BrainIcon className='w-6 h-6' active={active} />
    }
}
const ConditionCategory: React.FC<ConditionCategoryProps> = ({ type, label, active, onClick }) => {
    return (
        <div
            onClick={onClick}
            className={`flex p-4 flex-col items-center justify-center gap-2 flex-1 rounded-lg border-solid border-2 border-[#0000000f] w-32 h-24 cursor-pointer relative ${
                active ? 'bg-[#004b8d] text-white' : 'bg-white text-black'
            }`}
        >
            {getIconFromType(type, active)}
            <p className={`${active ? 'text-white' : 'text-black'}`}>{label}</p>
        </div>
    )
}

export default ConditionCategory
