import { ActionType, RuleRawData } from '@library/utilities/interface'
import { actionNames, categoryToIconMap, conditionCategoryMapper, hoveredCODActions, sections } from './constants'
import React from 'react'
import { conditionCategories } from '@library/utilities/constants/constants'
import { getSubCategory } from '@library/utilities/helpers/helper'
import { CardIcon } from '@library/images/templates/cardIcon'
import { UpiIcon } from '@library/images/templates/upiIcon'
import { EmiIcon } from '@library/images/templates/emiIcon'
import { WalletIcon } from '@library/images/templates/walletIcon'
import { CODIcon } from '@library/images/templates/codIcon'

const getTemplateIcon = (rules) => {
    const firstCondition = rules[0]?.raw_data?.conditions?.[0]
    const conditionName = conditionCategoryMapper[firstCondition.key]
    return categoryToIconMap[conditionName] ? React.createElement(categoryToIconMap[conditionName]) : null
}

const showPremiumIconOnTemplates = (ab_test_toggle: boolean, showTiering: boolean): boolean => {
    return ab_test_toggle && showTiering
}

const getUsedConditions = (rules: RuleRawData[]) => {
    const uniqueKeys = new Set()
    const categorizedConditions = rules.reduce((acc, rule) => {
        const conditions = rule.raw_data.conditions
        conditions.forEach((condition) => {
            const category = conditionCategoryMapper[condition.key]
            const categoryLabel = conditionCategories.find(
                (conditionCategory) => conditionCategory.type === category,
            )?.label
            const subCategories = getSubCategory(category)
            const label = subCategories?.find((subCategory) => subCategory.value === condition.key)?.name ?? ''

            if (categoryLabel && label && !uniqueKeys.has(condition.key)) {
                uniqueKeys.add(condition.key)
                if (!acc[categoryLabel]) {
                    acc[categoryLabel] = []
                }
                acc[categoryLabel].push(label)
            }
        })
        return acc
    }, {})

    return Object.entries(categorizedConditions).map(([label, labels]) => ({ label, labels }))
}
const extractActionLabel = (action: ActionType, actionOptions: { value: string; label: string }[]) => {
    if (!action?.action) return null

    return actionOptions.find((option: { value: string; label: string }) => option.value === action.action) ?? null
}

const getCODTileData = (actionName: string) => {
    switch (actionName) {
        case actionNames.codFees:
            return {
                badge: {
                    text: 'Fee ₹50',
                    color: '#F04438',
                },
                label: 'COD',
            }
        case actionNames.ppcod:
            return {
                subtitle: 'Remaining ₹50 on delivery',
                label: 'Pay Now',
                amount: 50,
            }
        default:
            return {
                label: 'COD',
            }
    }
}

const getPaymentMethods = (actionName: string) => {
    return [
        {
            icon: UpiIcon,
            label: 'UPI',
            badge: { text: 'Extra ₹50 off', color: '#15B79E' },
            actionName: actionNames.upi,
            highlight: true,
        },
        { icon: CardIcon, label: 'Pay via Card' },
        { icon: EmiIcon, label: 'Cardless EMI', subtitle: 'Just Pay 1/3 Now + EMIs Later' },
        { icon: WalletIcon, label: 'Pay with Wallets' },

        ...(actionName !== actionNames.codBlock
            ? [
                  {
                      icon: CODIcon,
                      highlight: true,
                      actionName: hoveredCODActions.includes(actionName) ? actionName : '',
                      ...getCODTileData(actionName),
                  },
              ]
            : [
                  {
                      icon: CODIcon,
                      label: 'COD',
                      isFading: true,
                  },
              ]),
    ]
}

const getOrderedTemplates = (templates) => {
    const orderedIds = sections.flatMap((section) => section.items.map((item) => item.id))

    const orderedTemplates = orderedIds.reduce((acc, id) => {
        if (templates[id]) {
            acc[id] = templates[id]
        }
        return acc
    }, {})

    return orderedTemplates
}
export {
    getTemplateIcon,
    showPremiumIconOnTemplates,
    getUsedConditions,
    extractActionLabel,
    getPaymentMethods,
    getOrderedTemplates,
}
