export const BuildingStoreIcon = () => {
    return (
        <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g clip-path='url(#clip0_3198_79590)'>
                <path
                    d='M2.5 17.5H17.5'
                    stroke='#667085'
                    stroke-width='1.6'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                />
                <path
                    d='M2.5 5.83333V6.66667C2.5 7.32971 2.76339 7.96559 3.23223 8.43443C3.70107 8.90328 4.33696 9.16667 5 9.16667C5.66304 9.16667 6.29893 8.90328 6.76777 8.43443C7.23661 7.96559 7.5 7.32971 7.5 6.66667M2.5 5.83333H17.5M2.5 5.83333L4.16667 2.5H15.8333L17.5 5.83333M7.5 6.66667V5.83333M7.5 6.66667C7.5 7.32971 7.76339 7.96559 8.23223 8.43443C8.70107 8.90328 9.33696 9.16667 10 9.16667C10.663 9.16667 11.2989 8.90328 11.7678 8.43443C12.2366 7.96559 12.5 7.32971 12.5 6.66667M12.5 6.66667V5.83333M12.5 6.66667C12.5 7.32971 12.7634 7.96559 13.2322 8.43443C13.7011 8.90328 14.337 9.16667 15 9.16667C15.663 9.16667 16.2989 8.90328 16.7678 8.43443C17.2366 7.96559 17.5 7.32971 17.5 6.66667V5.83333'
                    stroke='#667085'
                    stroke-width='1.6'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                />
                <path
                    d='M4.1665 17.5V9.04169'
                    stroke='#667085'
                    stroke-width='1.6'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                />
                <path
                    d='M15.8335 17.5V9.04169'
                    stroke='#667085'
                    stroke-width='1.6'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                />
                <path
                    d='M7.5 17.5V14.1667C7.5 13.7246 7.6756 13.3007 7.98816 12.9882C8.30072 12.6756 8.72464 12.5 9.16667 12.5H10.8333C11.2754 12.5 11.6993 12.6756 12.0118 12.9882C12.3244 13.3007 12.5 13.7246 12.5 14.1667V17.5'
                    stroke='#667085'
                    stroke-width='1.6'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                />
            </g>
            <defs>
                <clipPath id='clip0_3198_79590'>
                    <rect width='20' height='20' fill='white' />
                </clipPath>
            </defs>
        </svg>
    )
}
