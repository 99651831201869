import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import filters from './filters'
import user from './user'
import analytics from './analytics'
import rto_detials from './rto_details'
import actions from './actions'
import rca_view from './rca-view'
import rto_summary from './rto_summary'
import awbFlows from './awb-flows'
import rto_deep_dive from './rto_deep_dive'
import rtoConfigs from './rto-configs'

const rootReducer = combineReducers({
    analytics,
    user,
    filters,
    rto_detials,
    actions,
    rto_summary,
    rca_view,
    awbFlows,
    rto_deep_dive,
    rtoConfigs,
})

const store = configureStore({
    reducer: rootReducer,
    devTools: true,
})

export default store
export type RootStoreType = ReturnType<typeof rootReducer>
export type AppDispatch = typeof store.dispatch
