import { useEffect, useRef, useState } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import { useDispatch, useSelector } from 'react-redux'
import { updateBreadcrumbs } from '@gokwik/utilities'
import { getMerchantDetails } from '@store/user/selectors'
import { AnalyticsDateRangePicker, message, Select } from 'gokwik-ui-kit'
import { saveFiltersData } from '@store/filters'
import { getFilterDateOverviewPage } from '@store/filters/selectors'
import DeepDiveTable from '@library/components/analytics/deep-dive-tables'
import { getAnalyticsDeepDiveData } from '@store/rto_deep_dive/selector'
import { fetchAllAnalyticsData } from '@store/rto_deep_dive'
import { useAwbFillRate } from '@library/components/settings/awb-flows/useAwbFillRate'
import BoostAWB from '@library/components/settings/awb-flows/boost-awb'

const today = dayjs()
const yesterday = today.subtract(1, 'day')

const RTOAnalyicsDeepDive = () => {
    const merchantDetails = useSelector(getMerchantDetails)
    const storedDates = useSelector(getFilterDateOverviewPage)
    const format = 'YYYY-MM-DD'
    const merchant_id = merchantDetails.id

    const from_date = storedDates.fromDate || dayjs().subtract(60, 'day').add(1, 'day').startOf('month')
    const to_date = storedDates.toDate || yesterday
    const [dates, setDates] = useState<{ from: Dayjs; to: Dayjs; label?: string }>({
        from: from_date,
        to: to_date,
    })
    const [timeframe, setTimeframe] = useState('monthly')
    const [rtoSegment, setRtoSegment] = useState('all')
    const { showBoostAWB } = useAwbFillRate(merchant_id)

    useEffect(() => {
        let breadcrumbs = [
            {
                key: 'rto-analytics',
                href: '/rto/analytics/deep-dive',
                text: 'RTO Analytics Deep Dive',
            },
        ]
        updateBreadcrumbs((prev) => [prev[0], ...breadcrumbs])
    }, [])

    const dispatch = useDispatch()

    const analyticsData = useSelector(getAnalyticsDeepDiveData)

    const getFetch = async () => {
        dispatch(
            //@ts-ignore
            fetchAllAnalyticsData({
                params: {
                    from_date: dates.from.format(format),
                    to_date: dates.to.format(format),
                    merchant_id: merchant_id,
                    rto_segment: rtoSegment,
                },
            }),
        )
        dispatch<any>(
            saveFiltersData('analyticsOverview', {
                fromDate: dates.from,
                toDate: dates.to,
            }),
        )
    }

    useEffect(() => {
        getFetch()
    }, [dates, rtoSegment])

    const handleDateRange = (values, label) => {
        const [fromDate, toDate] = values.map((date) => dayjs(date))

        const isFromDateTooEarly = fromDate.isBefore(dayjs().subtract(90, 'day').startOf('day'))
        const isToDateTodayOrLater = !toDate.isBefore(dayjs().startOf('day'))

        if (isFromDateTooEarly) {
            message.error({
                content: `The from date cannot be older than the last 90 days.`,
                duration: 2,
            })
            return
        }

        if (isToDateTodayOrLater) {
            message.error({
                content: `The to date cannot be today or in the future.`,
                duration: 2,
            })
            return
        }

        setDates({ from: fromDate, to: toDate, label })
    }

    return (
        <div className='main-div px-3'>
            <div className='tile-metrics'>
                <h1 className='ml-3 mb-3 inline-block'>
                    {merchantDetails.business_name.toUpperCase()} RTO DEEP DIVE VIEW
                </h1>
                <div className='flex justify-end mb-4'>
                    <div className='flex items-center mr-4'>
                        <span className='mr-2 font-medium' style={{ color: '#9ca3af' }}>
                            Timeframe:{' '}
                        </span>
                        <Select
                            placeholder='Timeframe'
                            defaultValue={timeframe}
                            options={[
                                // { value: 'weekly', label: 'Weekly' }, will be taken live later
                                { value: 'monthly', label: 'Monthly' },
                                { value: 'quarterly', label: 'Quarterly' },
                            ]}
                            onChange={(value) => setTimeframe(value)}
                        />
                    </div>
                    <div className='flex items-center mr-4'>
                        <span className='mr-2 font-medium' style={{ color: '#9ca3af' }}>
                            Date Range:{' '}
                        </span>
                        <AnalyticsDateRangePicker values={[dates.from, dates.to]} onApply={handleDateRange} />
                    </div>
                    <div className='flex items-center'>
                        <span className='mr-2 font-medium' style={{ color: '#9ca3af' }}>
                            RTO Segment:{' '}
                        </span>
                        <Select
                            placeholder='RTO Segment'
                            className='min-w-[110px]'
                            defaultValue={rtoSegment}
                            options={[
                                { value: 'treatment', label: 'Treatment' },
                                { value: 'control', label: 'Control' },
                                { value: 'all', label: 'All' }, //combined treatment and control
                            ]}
                            onChange={(value) => setRtoSegment(value)}
                        />
                    </div>
                </div>
                {showBoostAWB && (
                    <BoostAWB
                        heading='AWB Data Not in Sync'
                        subHeading='Improve your AWB Fill Rate to uncover valuable RTO insights, optimize delivery performance, and proactively address potential issues.'
                    />
                )}
                <div className='flex justify-center'>
                    <h1>STATE LEVEL {rtoSegment !== 'all' ? rtoSegment.toUpperCase() : ''}</h1>
                </div>

                <div className='rto-summary-block mb-24 max-h-[700px] overflow-y-auto'>
                    <DeepDiveTable analyticsData={analyticsData} timeframe={timeframe} filter={'state'} />
                </div>

                <div className='flex justify-center'>
                    <h1>ADDRESS LENGTH LEVEL {rtoSegment !== 'all' ? rtoSegment.toUpperCase() : ''}</h1>
                </div>

                <div className='rto-summary-block mb-24 max-h-[700px] overflow-y-auto'>
                    <DeepDiveTable
                        analyticsData={analyticsData}
                        timeframe={timeframe}
                        filter={'address_length_bucket'}
                    />
                </div>
                <div className='flex justify-center'>
                    <h1>PINCODE TIER LEVEL {rtoSegment !== 'all' ? rtoSegment.toUpperCase() : ''}</h1>
                </div>

                <div className='rto-summary-block mb-24'>
                    <DeepDiveTable analyticsData={analyticsData} timeframe={timeframe} filter={'tier'} />
                </div>
                <div className='flex justify-center'>
                    <h1>OS LEVEL {rtoSegment !== 'all' ? rtoSegment.toUpperCase() : ''}</h1>
                </div>

                <div className='rto-summary-block mb-24'>
                    <DeepDiveTable analyticsData={analyticsData} timeframe={timeframe} filter={'os'} />
                </div>
                <div className='flex justify-center'>
                    <h1>BROWSER LEVEL {rtoSegment !== 'all' ? rtoSegment.toUpperCase() : ''}</h1>
                </div>

                <div className='rto-summary-block mb-24'>
                    <DeepDiveTable analyticsData={analyticsData} timeframe={timeframe} filter={'browser'} />
                </div>
                <div className='flex justify-center'>
                    <h1>QUANTITY ORDERED LEVEL {rtoSegment !== 'all' ? rtoSegment.toUpperCase() : ''}</h1>
                </div>

                <div className='rto-summary-block mb-24'>
                    <DeepDiveTable analyticsData={analyticsData} timeframe={timeframe} filter={'quantity'} />
                </div>
            </div>
        </div>
    )
}

export default RTOAnalyicsDeepDive
