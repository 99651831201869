import React, { useEffect } from 'react';
import { Form, InputNumber } from 'gokwik-ui-kit';
import { ActionType } from '@library/utilities/interface';

interface CodFeesInputProps {
    actionType: ActionType;
    setActionType: React.Dispatch<React.SetStateAction<ActionType>>;
    formText?: string
    primary?: boolean
    isActionDisabled: boolean
}

const CodFeesInput: React.FC<CodFeesInputProps> = ({ actionType, setActionType, formText, primary, isActionDisabled }) => {
    const form = Form.useFormInstance();
    const getFormName = (isPrimary: boolean) => `${formText}-${isPrimary ? 'primary' : 'secondary'}-cod-fee`;
    const formName = getFormName(primary);
    const formNameRemove = getFormName(!primary);

    useEffect(() => {
        form.setFieldsValue({ [formName]: actionType?.cod_fees, [formNameRemove]: undefined });
    }, [formName, formNameRemove, actionType?.cod_fees, form]);

    return (
        <Form.Item key={formName} name={formName} className='!mb-0' rules={[{ required: true, message: 'COD fee is required' }]}>
            <InputNumber
                className='w-80 mt-5'
                value={actionType?.cod_fees || ''}
                placeholder='COD FEES'
                onChange={(val) => setActionType({ ...actionType, cod_fees: val && Number(val) })}
                addonBefore='₹'
                disabled={isActionDisabled}
            />
        </Form.Item>
    );
};

export default CodFeesInput;
