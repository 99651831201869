const StarIcon = ({ fillColor = '#4096FF'}) => (
    <svg xmlns='http://www.w3.org/2000/svg' width={20} height={20} fill='none'>
        <g clipPath='url(#a)'>
            <path
                fill={fillColor ?? '#4096FF'}
                stroke={fillColor ?? '#4096FF'}
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth={2}
                d='M13.333 15.003A1.666 1.666 0 0 1 15 16.669a1.666 1.666 0 0 1 1.667-1.666A1.666 1.666 0 0 1 15 13.336a1.666 1.666 0 0 1-1.667 1.667Zm0-10A1.666 1.666 0 0 1 15 6.669a1.667 1.667 0 0 1 1.667-1.666A1.666 1.666 0 0 1 15 3.336a1.667 1.667 0 0 1-1.667 1.667Zm-5.833 10a5 5 0 0 1 5-5 5 5 0 0 1-5-5 5 5 0 0 1-5 5 5 5 0 0 1 5 5Z'
            />
        </g>
        <defs>
            <clipPath id='a'>
                <path fill='#fff' d='M0 0h20v20H0z' />
            </clipPath>
        </defs>
    </svg>
)
export default StarIcon;
