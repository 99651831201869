import { WorkflowData } from '.'
import { RootStoreType } from '..'

export const getWorkflowData = (state: RootStoreType): WorkflowData => state.actions.workflowData

export const getStoreActionType = (state: RootStoreType) => state.actions.storeActionType

export const getAbTestCreateFlag = (state: RootStoreType) => state.actions.abTest.abTestCreateFlag

export const getAbTestToggle = (state: RootStoreType) => state.actions.abTest.abTestToggle

export const getAbTreatmentPercentage = (state: RootStoreType) => state.actions.abTest.abTreatmentPercentage

export const getAbTestShopifyFlag = (state: RootStoreType) => state.actions.abTest.abTestShopifyFlag

export const getAbTestSettings = (state: RootStoreType) => state.actions.abTest

export const getSelectedProducts = (state: RootStoreType) => state.actions.selectedProducts

export const getModalStates = (state: RootStoreType) => state.actions.modalStates

export const getWorkflowAction = (state: RootStoreType) => state.actions.workflowAction

export const getWorkflowAbTestTypeBAction = (state: RootStoreType) => state.actions.workflowAbTestTypeBAction
