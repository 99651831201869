import { PointerIcon } from '@library/images/pointer'
import { getPreviewModalAlert } from '@library/utilities/helpers/helper'
import { InfoCircleOutlined, Tooltip } from 'gokwik-ui-kit'

const ActionCardsListItem = ({ action = { value: 'cod_prompt', label: 'COD Prompt' }, onHover }) => {
    return (
        <div
            className='flex items-center justify-between border border-solid border-[#EAECF0] rounded-md p-2.5 cursor-pointer mb-2.5 hover:border-[#004b8d] hover:shadow-sm'
            onMouseEnter={() => onHover(action.label)}
            onMouseLeave={() => onHover('')}
        >
            <div>
                <span className='font-semibold mr-2'>{action.label}</span>
                <Tooltip title={getPreviewModalAlert(action.value)}>
                    <InfoCircleOutlined />
                </Tooltip>
            </div>
            <PointerIcon />
        </div>
    )
}

export default ActionCardsListItem
