import React from 'react'

type EmptyStateProps = {
    title: string
    icon: React.ReactNode
}

const EmptyState: React.FC<EmptyStateProps> = ({ title, icon }) => {
    return (
        <div className='flex items-center justify-center flex-col gap-2 h-5/6'>
            {icon}
            <p className='text-2xl'>{title}</p>
        </div>
    )
}

export default EmptyState
