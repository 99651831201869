import { Condition } from '@library/utilities/interface'
import {
    Alert,
    Button,
    ChipInput,
    DeleteFilled,
    DeleteOutlined,
    DownloadOutlined,
    FolderFilled,
    Form,
    InboxOutlined,
    Input,
    PaperClipOutlined,
    Select,
    Space,
    Spin,
    Upload,
    message,
} from 'gokwik-ui-kit'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { COD_RATE_OPTIONS, inputFieldData, operators, RTO_ACTIONS_API_RULES, rtoRiskWorkflow, validations } from '@library/utilities/constants/constants'
import RtoRiskCondition from '../rto-risk/rtoRiskCondition'
import { getSelectValue, isEqualString } from '@library/utilities/helpers/helper'
import { getUtmSuggestions, getAddressSuggestions } from '@store/actions/api'
import { useSelector } from 'react-redux'
import { getMerchantDetails } from '@store/user/selectors'
import { downloadToCSV } from '@gokwik/utilities'
import ProductTitleSearch from './product-title-search'

let XLSX
if (typeof window !== 'undefined') {
    XLSX = require('xlsx')
}

interface ConditionBoxProps {
    index: number
    deleteItem: (event: React.MouseEvent<HTMLElement>) => void
    workflowType: string
    workflowData: Condition
    onChangeValues: (value: any, field: string) => void
    defaultDataType: string
    conditionList: Condition[]
}

const ConditionBox: React.FC<ConditionBoxProps> = ({
    index,
    deleteItem,
    workflowType,
    workflowData,
    onChangeValues,
    defaultDataType,
    conditionList,
}) => {
    const [valuesColumn, setValuesColumn] = useState([])
    const [workflowDatavalues, setWorkflowDataValues] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [fileName, setFileName] = useState(null)
    const [isUtmWorkflow, setIsUtmWorkflow] = useState(false)
    const [shippingOptions, setShippingOptions] = useState([])
    const [allOptions, setAllOptions] = useState([])
    const [filteredOptions, setFilteredOptions] = useState([])
    const [showOptions, setShowOptions] = useState(false)
    const merchantDetails = useSelector(getMerchantDetails)
    const [isCodRateWorkflow, setIsCodRateWorkflow] = useState(false)
    const [isRtoRateCustom, setIsRtoRateCustom] = useState(false)
    const [isDuplicateWorkflow, setIsDuplicateWorkflow] = useState(false)
    const merchant_id = merchantDetails.id
    const [isShippingWorkflow, setIsShippingWorkflow] = useState(false)

    const { Dragger } = Upload

    useEffect(() => {
        if (defaultDataType && defaultDataType !== 'bulk') {
            if (workflowData?.file_name) {
                onChangeValues([], 'values')
                onChangeValues(undefined, 'file_name')
            }
            setValuesColumn([])
        }
    }, [defaultDataType])

    useEffect(() => {
        if (workflowData?.file_name && workflowData?.values?.length > 0) {
            onChangeValues('bulk', 'dataType')
            setFileName(workflowData?.file_name)
            setValuesColumn(workflowData?.values)
        }
    }, [workflowData?.file_name])

    useEffect(() => {
        const fetchInitialOptions = async () => {
            try {
                if (workflowData?.key?.includes('utm_')) {
                    setIsUtmWorkflow(true)
                    const type = workflowData?.key.replace(/^utm_/, '')
                    const response = await getUtmSuggestions(type, merchant_id)
                    if (response.status === 200 && response.data) {
                        const suggestions = response.data?.suggestions ?? []
                        setAllOptions(suggestions?.map((option) => ({ value: option, label: option })))
                    }
                }
                else {
                    setIsUtmWorkflow(false)
                }
                if (workflowData?.key === 'cod_rate') {
                    setIsCodRateWorkflow(true)
                    setIsDuplicateWorkflow(false)
                }
                if (workflowData?.key === 'duplicate_order') {
                    setIsDuplicateWorkflow(true)
                    setIsCodRateWorkflow(false)
                }
            } catch (error) {
                console.error('Error fetching suggestions:', error)
                setIsUtmWorkflow(false)
            }
        }

        fetchInitialOptions()
    }, [workflowData?.key])

    useEffect(() => {
        const fetchShippingOptions = async () => {
            try {
                if (workflowData?.key?.includes('shipping_')) {
                    setIsShippingWorkflow(true)
                    const type = workflowData?.key.replace(/^shipping_/, '')
                    const response = await getAddressSuggestions(type)
                    if (response.status === 200 && response.data) {
                        const suggestions = response.data?.suggestions ?? []
                        setShippingOptions(suggestions?.map((option) => ({ value: option, label: option })))
                    }
                }
                else {
                    setIsShippingWorkflow(false)
                }
            } catch (error) {
                console.error('Error fetching shipping suggestions:', error)
                setIsShippingWorkflow(false)
            }
        }

        fetchShippingOptions()
    }, [workflowData?.key])

    const onChangeRtoRate = (value: string, type: string) => {
        onChangeValues(value, type)
        setIsRtoRateCustom(value === 'custom')

    }
    const handleFileChange = async (file: File) => {
        setFileName(file?.name)
        setLoading(true)

        const fileType = file.name.slice(((file.name.lastIndexOf('.') - 1) >>> 0) + 2)
        const reader = new FileReader()

        reader.onload = (e) => {
            const data = e.target.result
            let workbook

            if (fileType === 'csv') {
                workbook = XLSX.read(data, { type: 'string' })
            } else {
                workbook = XLSX.read(data, { type: 'binary' })
            }

            // Get first worksheet
            const wsname = workbook.SheetNames[0]
            const ws = workbook.Sheets[wsname]

            // Convert to array of objects
            const parsedData = XLSX.utils.sheet_to_json(ws)

            // Extract column named "values"
            const extractedValues = parsedData.map((row) => row[Object.keys(parsedData[0])[0]])

            // Check if entries are more than 10

            if (extractedValues.length > 1000) {
                message.warning(`The "${Object.keys(parsedData[0])[0]}" column has more than 1000 entries. Please upload a valid file.`);
                setValuesColumn([]);
            } else {
                const validation = validations[workflowData?.key];

                if (validation) {
                    const invalidEntries = extractedValues.filter(value => !validation.validate(value));
                    if (invalidEntries.length > 0) {
                        message.warning(`${extractedValues.length - invalidEntries.length} ${validation.successful} ${invalidEntries.length} ${validation.failed}`);
                    }
                }
                setValuesColumn(extractedValues);
                setWorkflowDataValues(extractedValues);
                onChangeValues(extractedValues, 'values');
                onChangeValues(file?.name, 'file_name');
            }
            setLoading(false)
        }

        if (fileType === 'csv') {
            reader.readAsText(file)
        } else {
            reader.readAsBinaryString(file)
        }

        reader.onerror = () => {
            setLoading(false)
            message.error('An error occurred while reading the file.')
        }
        reader.readAsBinaryString(file)
        return false
    }

    const downloadUploadedFile = () => {
        // Check if valuesColumn has data
        if (!valuesColumn || valuesColumn.length === 0) {
            message.error('No data available for download.');
            return;
        }

        const filterData = valuesColumn?.map((item) => {
            return {
                value: item
            }
        })

        const downloadFileName = (fileName || 'download').replace(/\.csv$/, '');

        downloadToCSV(['value'], [workflowData?.key], filterData, downloadFileName, { addSerialNumber: false })
    };

    const addChip = (value: string) => {
        const chips = [...(workflowData?.values || [])]
        chips.push(value)
        let phoneRegEx = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/
        let numRegEx = /^\d+$/
        if (['phone_number'].includes(workflowData.key) && !phoneRegEx.test(value)) {
            message.warning('Please add valid phone no.')
            return
        } else if (workflowData.key === 'pincode' && (value.length != 6 || !numRegEx.test(value))) {
            message.warning('Please enter valid Pincode')
            return
        } else if (value.length === 0) {
            message.warning('Please enter valid data')
            return
        }
        setWorkflowDataValues(chips)
        onChangeValues(chips, 'values')
    }
    const removeChip = (value: number) => {
        const dataValues = workflowDatavalues.length > 0 ? workflowDatavalues : workflowData?.values || []
        const chips = [...dataValues]
        const index = chips.indexOf(value)
        if (index > -1) {
            chips.splice(index, 1)
        }
        setWorkflowDataValues(chips)
        onChangeValues(chips, 'values')
    }

    const getLableValue = (op: string) => {
        const operator = {
            '==': 'Equal to',
            '!=': 'Not Equal',
            contains: 'Contains',
            not_contains: 'Does not contains',
            '>=': 'Greater than or Equal to',
            '<=': 'Less than or Equal to',
            '>': 'Greater than',
            '<': 'Less than',
        }
        return operator[op]
    }
    const blockInvalidChar = (e) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()

    const downloadSampleFile = () => {
        const csvContent = 'value\n110018\n110025'

        // Create a Blob object with the CSV content and specify the type
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' })

        // Create a downloadable link element
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = 'sample.csv'

        // Simulate a click on the link to trigger download
        link.click()
    }

    const conditionName = useMemo(() => {
        const keyName = inputFieldData.find((item) => item.name === workflowData?.key)?.label
        if (keyName) {
            return `- Add ${keyName}`
        }
        return ''
    }, [workflowData?.key])

    if (workflowType === rtoRiskWorkflow) {
        return (
            <RtoRiskCondition
                index={index}
                deleteItem={deleteItem}
                workflowData={workflowData}
                onChangeValues={onChangeValues}
                conditionList={conditionList}
            />
        )
    }

    const multiSelectInputChange = (values: string[]) => {
        setWorkflowDataValues(values)
        onChangeValues(values, 'values')
    }

    const handleSearch = (inputValue: string) => {
        if (inputValue) {
            const filtered = allOptions.filter((option) =>
                option.label.toLowerCase().includes(inputValue.toLowerCase()),
            )
            setFilteredOptions(filtered.length > 0 ? filtered : [{ value: inputValue, label: inputValue }])
            setShowOptions(true)
        } else {
            setFilteredOptions([])
            setShowOptions(false)
        }
    }

    const setDefaultSuggestionList = () => {
        setShowOptions(false)
        setFilteredOptions([])
    }

    const renderConditionBox = (key, itm) => {
        switch (key) {
            case 'customer_type':
                return (
                    <Form.Item
                        className='w-36 mb-0'
                        name={`customer-type-${workflowData.value}-${index}`}
                        rules={[{ required: true, message: '' }]}
                        initialValue={getSelectValue(workflowData.value)}
                    >
                        <Select
                            placeholder='Select Customer'
                            onChange={(e) => {
                                onChangeValues(e, 'value')
                            }}
                            value={getSelectValue(workflowData.value)}
                            options={[
                                { value: 'new', label: 'New' },
                                { value: 'repeat', label: 'Repeat' },
                            ]}
                        />
                    </Form.Item>
                )
            case 'is_gst_cart':
                return (
                    <Form.Item
                        className='w-36 mb-0'
                        name={`input-value-${workflowData.key}-${index}`}
                        rules={[{ required: true, message: '' }]}
                        initialValue={workflowData.value || undefined}
                    >
                        <Select
                            placeholder='Yes/No'
                            onChange={(e) => {
                                onChangeValues(e, 'value')
                            }}
                            value={getSelectValue(workflowData.value)}
                            options={[
                                { value: 'true', label: 'Yes' },
                                { value: 'false', label: 'No' },
                            ]}
                        />
                    </Form.Item>
                )
            case 'product_title':
            case 'variant_ids':
                return (
                    <ProductTitleSearch
                        index={index}
                        onChangeValues={onChangeValues}
                        selectedProductsFromGet={workflowData?.selected_products}
                        workflowData={workflowData}
                    />
                )
            default:
                return (
                    <Form.Item
                        className='w-36 mb-0'
                        name={`input-value-${workflowData.key}-${index}`}
                        rules={[{ required: true, message: '' }]}
                        initialValue={String(workflowData?.value) || undefined}
                    >
                        {!(isUtmWorkflow || isShippingWorkflow) ? (
                            <Input
                                //@ts-ignore
                                type={itm.type}
                                value={String(workflowData?.value)}
                                placeholder='Type here...'
                                className='w-36'
                                onKeyDown={(e) => itm.type === 'number' && blockInvalidChar(e)}
                                onChange={(e) => {
                                    itm.type === 'number' && e.target.value.match(/[a-zA-Z]/g)
                                        ? null
                                        : onChangeValues(e, 'value')
                                }}
                            />
                        ) : isUtmWorkflow ? (
                            <Select
                                className='!max-w-[520px]'
                                showSearch
                                placeholder='Type here...'
                                optionFilterProp='label'
                                onChange={(value) => {
                                    onChangeValues(value, 'value')
                                }}
                                onSearch={handleSearch}
                                options={filteredOptions}
                                notFoundContent={null}
                                dropdownRender={(menu) => {
                                    return filteredOptions.length > 0 ? menu : null
                                }}
                                filterSort={false as any}
                            />
                        ) : (
                            <Select
                                className='w-60 !max-w-[520px]'
                                showSearch
                                options={shippingOptions}
                                placeholder='Type here...'
                                onChange={(value) => {
                                    onChangeValues(value, 'value')
                                }}
                            />
                        )}
                    </Form.Item>
                )
        }
    }

    return (
        <div className='flex w-full py-4 px-3 flex-col justify-center items-start gap-3 rounded-md border-solid border-[#004b8d] bg-white shadow-md'>
            <div className='flex w-full justify-between items-center'>
                <p className='text-gray-500 tex-sm leading-6'>
                    Condition {index + 1} {conditionName}
                </p>
                <DeleteFilled
                    className='text-neutral-400 text-lg'
                    onClick={(e) => {
                        deleteItem(e)
                        setValuesColumn([])
                    }}
                />
            </div>
            <Space wrap size={30} direction='horizontal' className='!items-start'>
                <Select
                    className='w-36'
                    value={workflowType?.charAt(0) + workflowType?.toLowerCase().slice(1)}
                    options={[{ value: workflowType, label: workflowType }]}
                />
                <Select
                    className='w-44'
                    value={workflowData.key}
                    options={[
                        {
                            value: workflowData.key,
                            label: inputFieldData.filter((itm) => itm.name === workflowData.key)[0]?.label || '',
                        },
                    ]}
                />
                {RTO_ACTIONS_API_RULES.includes(workflowData.key) ? null : (
                    <Form.Item
                        className='w-36 mb-0'
                        name={`select-operator-${workflowData.key}-${index}`}
                        rules={[{ required: true, message: '' }]}
                        initialValue={getLableValue(workflowData.operator)}
                    >
                        <Select
                            placeholder='Operator'
                            onChange={(e) => onChangeValues(e, 'operator')}
                            value={getLableValue(workflowData.operator)}
                            options={inputFieldData.filter((itm) => itm.name === workflowData.key)[0]?.operator}
                        />
                    </Form.Item>
                )}
                {inputFieldData
                    .filter((itm) => {
                        return itm.name === workflowData?.key
                    })
                    .map((itm, idx) => (
                        <Fragment key={idx}>
                            {itm.list && !(workflowData?.key === 'cod_rate' || workflowData?.key === 'duplicate_order') && (
                                <Select
                                    className='w-32'
                                    placeholder='Individual'
                                    value={workflowData?.dataType ?? 'Individual'}
                                    onChange={(e) => {
                                        onChangeValues(e, 'dataType')
                                        onChangeValues([], 'values')
                                    }}
                                    options={
                                        workflowData?.operator === operators.equals
                                            ? [{ value: 'individual', label: 'Individual' }]
                                            : [
                                                { value: 'bulk', label: 'Bulk' },
                                                { value: 'individual', label: 'Individual' },
                                            ]
                                    }
                                />
                            )}
                            {defaultDataType === 'bulk' ? (
                                loading ? (
                                    <Space>
                                        <Spin />
                                    </Space>
                                ) : error ? (
                                    <Alert
                                        message='Error'
                                        description='We have detected more than 1,000 entries. Please try again with only 1,000 entries.'
                                        type='error'
                                        showIcon
                                    />
                                ) : valuesColumn?.length !== 0 ? (
                                    <div className='flex items-center justify-between gap-4'>
                                        <div className='flex py-1.5 px-2 items-center justify-between gap-2 self-stretch rounded-lg bg-gray-100'>
                                            <div className='flex items-center'>
                                                <PaperClipOutlined />
                                                <p> {fileName} </p>
                                            </div>
                                            <DeleteOutlined
                                                onClick={() => {
                                                    setValuesColumn([])
                                                    setFileName(null)
                                                    onChangeValues([], 'values')
                                                }}
                                            />
                                        </div>
                                        <Button
                                            onClick={downloadUploadedFile}
                                            type='link'
                                            className='flex items-center'
                                        >
                                            {' '}
                                            <DownloadOutlined /> Download File
                                        </Button>
                                    </div>

                                ) : (
                                    <div>
                                        <div className='flex items-center justify-between p-1'>
                                            <p className='text-sm ml-2.5'>Upload CSV File</p>
                                            <Button
                                                onClick={downloadSampleFile}
                                                type='link'
                                                className='flex items-center'
                                            >
                                                {' '}
                                                <DownloadOutlined /> Sample File
                                            </Button>
                                        </div>
                                        <Form.Item
                                            name='dragger'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please upload',
                                                },
                                            ]}
                                        >
                                            <Dragger
                                                name='file'
                                                multiple={false}
                                                beforeUpload={handleFileChange}
                                                onChange={(info) => {
                                                    const { status } = info.file
                                                    if (status !== 'uploading') {
                                                        console.log(info.file, info.fileList)
                                                    }
                                                    if (status === 'done') {
                                                        message.success(`${info.file.name} file uploaded successfully.`)
                                                    } else if (status === 'error') {
                                                        message.error(`${info.file.name} file upload failed.`)
                                                    }
                                                }}
                                                onDrop={(e) => {
                                                    console.log('Dropped files', e.dataTransfer.files)
                                                }}
                                                showUploadList={false}
                                                accept='.xlsx,.xlsm,.xltm,.csv'
                                            >
                                                <p className='ant-upload-drag-icon'>
                                                    <InboxOutlined />
                                                </p>
                                                <p className='ant-upload-text'>
                                                    Click or drag file to this area to upload
                                                </p>
                                                <p className='ant-upload-hint'>
                                                    Support for a individual or bulk upload. Strictly prohibited from
                                                    uploading company data or other banned files.
                                                </p>
                                            </Dragger>
                                        </Form.Item>
                                    </div>
                                )
                            ) : [
                                'phone_number',
                                'shipping_state',
                                'shipping_city',
                                'pincode',
                                'utm_source',
                                'utm_medium',
                                'utm_campaign',
                                'sku_ids',
                                'product_id',
                                'cod_rate',
                                'duplicate_order',
                            ].includes(workflowData?.key) && !isEqualString(workflowData.operator, '==') ? (
                                <Form.Item
                                    name={`chip-input-${workflowData.key}-${index}`}
                                    className='mb-0 min-w-56'
                                    rules={[
                                        {
                                            validator: (_, value) => {
                                                return (workflowData?.values?.length > 0 || RTO_ACTIONS_API_RULES.includes(workflowData.key))
                                                    ? Promise.resolve()
                                                    : Promise.reject(new Error('Please Enter Values'))
                                            },
                                        },
                                    ]}
                                    initialValue={workflowData?.values}
                                >
                                    {isUtmWorkflow ? (
                                        <Select
                                            showSearch
                                            mode='tags'
                                            className='w-48 !max-w-[520px]'
                                            onChange={multiSelectInputChange}
                                            tokenSeparators={[',']}
                                            options={showOptions ? filteredOptions : []}
                                            placeholder='Press enter to insert values'
                                            onSearch={handleSearch}
                                            notFoundContent={null}
                                            filterOption={false}
                                            filterSort={false as any}
                                            onBlur={setDefaultSuggestionList}
                                            onFocus={setDefaultSuggestionList}
                                            dropdownRender={(menu) => {
                                                return filteredOptions.length > 0 ? menu : null
                                            }}
                                        />
                                    ) : isShippingWorkflow ? (
                                        <Select
                                            mode='multiple'
                                            className='w-48 !max-w-[520px]'
                                            onChange={multiSelectInputChange}
                                            options={shippingOptions}
                                            placeholder='Press enter to insert values'
                                        />
                                    ) : isCodRateWorkflow ? (
                                        <div className="flex gap-4">
                                            {isRtoRateCustom ? <Input
                                                type='number'
                                                value={String(workflowData?.value)}
                                                onChange={(e) => {
                                                    onChangeValues(e.target.value, 'value')
                                                }}
                                                placeholder='Enter decimals for minute values'
                                            /> : <Select
                                                className='w-36'
                                                placeholder='Select COD Rate'
                                                onChange={(e) => {
                                                    onChangeRtoRate(e, 'value')
                                                }}
                                                value={Number(workflowData?.value) || ''}
                                                options={COD_RATE_OPTIONS}
                                            />}
                                        </div>
                                    ) : isDuplicateWorkflow ? (
                                        <div style={{ display: 'flex', gap: '8px' }}>
                                            <Input
                                                type='number'
                                                value={String(workflowData?.total_hours)}
                                                onChange={(e) => {
                                                    const maxHours = 7 * 24;
                                                    if (Number(e.target.value) <= maxHours) {
                                                        onChangeValues(e.target.value, 'total_hours')
                                                    } else {
                                                        message.warning('Total hours cannot exceed 7 days.')
                                                    }
                                                }}
                                                placeholder='Total Hours'
                                            />
                                            <Input
                                                type='number'
                                                value={String(workflowData?.value)}
                                                onChange={(e) => {
                                                    onChangeValues(e.target.value, 'value')
                                                }}
                                                placeholder='Total Orders'
                                            />
                                        </div>
                                    ) : (
                                        <ChipInput
                                            chips={workflowData?.values || []}
                                            onAddChip={(value) => addChip(value)}
                                            onRemoveChip={(value) => removeChip(value)}
                                            placeholder='Press enter to insert values'
                                        />
                                    )}
                                </Form.Item>
                            ) : (
                                renderConditionBox(workflowData.key, itm)
                            )}
                        </Fragment>
                    ))}
            </Space>
        </div>
    )
}

export default ConditionBox
