import { Button, InfoCircleOutlined, PlusCircleOutlined, Tooltip } from 'gokwik-ui-kit'
import { useEffect, useRef } from 'react'
import AbTestView from './abTestView'
import {
    abCreationMessage,
    secondaryActionTypeVar,
    tertiaryActionTypeVar,
} from '@library/utilities/constants/constants'
import { useDispatch, useSelector } from 'react-redux'
import { setAbTestCreateFlag, setModalStates, setStoreActionType } from '@store/actions'
import {
    getAbTestCreateFlag,
    getAbTestSettings,
    getAbTestToggle,
    getModalStates,
    getStoreActionType,
    getWorkflowAbTestTypeBAction,
    getWorkflowAction,
} from '@store/actions/selectors'
import { shouldHideCreateButton } from '@library/utilities/helpers/helper'
import { ActionType, Workflow } from '@library/utilities/interface'
import { getUserConfig } from '@store/user/selectors'

interface ABTestProps {
    disabledButton: boolean
    allWorkflow: Workflow[]
    currentIndex: number
    onBack: (workflows?: Workflow[]) => void
}

function ABTest({ disabledButton, allWorkflow, currentIndex, onBack }: ABTestProps) {
    const abTestCreateFlag = useSelector(getAbTestCreateFlag)
    const storeActionType = useSelector(getStoreActionType)
    const abTestToggle = useSelector(getAbTestToggle)
    const merchantConfig = useSelector(getUserConfig)
    const bottomRef = useRef(null)
    const dispatch = useDispatch()
    const abTestSettings = useSelector(getAbTestSettings)
    const modalStates = useSelector(getModalStates)
    const workflowAction = useSelector(getWorkflowAction)
    const { secondary: secondaryActionType, primary: actionType, tertiary: tertiaryActionType } = workflowAction
    const workflowActionB = useSelector(getWorkflowAbTestTypeBAction)
    const { primary: actionTypeB } = workflowActionB

    const abTestEnabledOn = abTestSettings?.abTestEnabledOn
    const hideCreateBasedOnAction = shouldHideCreateButton(storeActionType, abTestEnabledOn, actionTypeB, abTestToggle)
    const isSecondaryActionPresent = storeActionType?.find(
        (actionType: ActionType) => actionType.action === secondaryActionType?.action,
    )
    const isTertiaryActionPresent = storeActionType?.find(
        (actionType: ActionType) => actionType.action === tertiaryActionType?.action,
    )
    const showAbTest = merchantConfig?.rto_actions_config?.show_ab_test || false

    useEffect(() => {
        abTestToggle && scrollToBottom()
    }, [abTestToggle])

    useEffect(() => {
        if (actionType.action) {
            if (isSecondaryActionPresent) {
                dispatch(setStoreActionType([actionType, secondaryActionType]))
            } else {
                dispatch(setStoreActionType([actionType]))
            }
        }
    }, [actionType])

    useEffect(() => {
        if (isSecondaryActionPresent) {
            dispatch(setStoreActionType([actionType, secondaryActionType]))
        } else if (secondaryActionType?.action) {
            dispatch(setStoreActionType({ ...secondaryActionType, type: secondaryActionTypeVar }))
        } else if (actionType?.action) {
            dispatch(setStoreActionType([actionType]))
        }
    }, [secondaryActionType])

    const handleCreateABClicked = () => {
        dispatch(setAbTestCreateFlag(true))
    }

    const scrollToBottom = () => {
        bottomRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
    if ((disabledButton && !abTestCreateFlag) || hideCreateBasedOnAction || !showAbTest) {
        return null
    }

    return (
        <>
            <div className='mt-8 mb-12'>
                {abTestCreateFlag ? (
                    <AbTestView allWorkflow={allWorkflow} currentIndex={currentIndex} onBack={onBack} />
                ) : (
                    <div className='fade-in-delayed'>
                        <div className='flex items-center gap-2 text-gray-400 text-sm'>
                            <p>Run A/B Testing</p>
                            <Tooltip placement='bottom' title={abCreationMessage}>
                                <InfoCircleOutlined />
                            </Tooltip>
                        </div>
                        <div className='rounded-md p-2 flex flex-col gap-2 bg-[#E6F4FF] shadow-sm mt-2.5'>
                            <div className='text-[#1677FF] cursor-pointer' onClick={handleCreateABClicked}>
                                <PlusCircleOutlined />
                                <span className='ml-1'>Create an A/B Test</span>
                            </div>
                            <p>
                                Confused?{' '}
                                <span
                                    className='underline cursor-pointer'
                                    onClick={() => dispatch(setModalStates({ ...modalStates, showABTestModal: true }))}
                                >
                                    See how it works
                                </span>
                            </p>
                        </div>
                    </div>
                )}
            </div>
            <div ref={bottomRef}>
                {abTestToggle && !abTestEnabledOn ? (
                    <Button
                        size={'large'}
                        type='primary'
                        htmlType='submit'
                        className={`w-full border border-solid !border-gray-300 mb-8`}
                    >
                        Apply Workflow with A/B Test
                    </Button>
                ) : null}
            </div>
        </>
    )
}

export default ABTest
