import { ReloadOutlined } from 'gokwik-ui-kit'

const CaptchaDisplay = () => {
    const digits = [
        { value: '3', classes: 'text-black rotate-[-10deg] top-[2px]' },
        { value: '5', classes: 'text-gray-500 rotate-[18deg] top-[10px]' },
        { value: '7', classes: 'text-black rotate-[-10deg] top-[2px]' },
        { value: '8', classes: 'text-gray-500 rotate-[18deg] top-[10px]' },
        { value: '5', classes: 'text-black rotate-[-10deg] top-[2px]' },
    ]

    return (
        <div className='flex items-center justify-center flex-[0.94] relative h-10 border border-solid border-[#000000E0] rounded-xl'>
            {digits.map((digit, index) => (
                <span
                    key={index}
                    className={`inline-block w-2.5 h-5 text-sm font-normal leading-5 mx-1 relative transform ${digit.classes} leading-[5px]`}
                >
                    {digit.value}
                </span>
            ))}
        </div>
    )
}

const CodCaptchaPreview = () => {
    return (
        <div className='max-w-md mx-auto p-4 space-y-4 min-h-[28rem] flex flex-col'>
            <div className='flex flex-col justify-center flex-grow'>
                <div className='relative'>
                    <div className='flex items-center justify-between'>
                        <CaptchaDisplay />
                        <div className='p-2 rounded-3xl border border-solid border-gray-200'>
                            <ReloadOutlined />
                        </div>
                    </div>
                </div>

                <div className='w-full px-4 py-2 flex items-center text-gray-500 border border-solid border-[#000000E0] rounded-xl mt-2'>
                    Enter the text in the image
                </div>
            </div>

            <button className='w-full px-4 py-2 text-center font-medium text-white bg-[#004B8D] border border-[#004B8D] rounded-2xl'>
                Submit
            </button>
        </div>
    )
}

export default CodCaptchaPreview
