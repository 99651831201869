const dateRangeFilter = {
    key: 'date_range_filter',
    value: 'weekly',
    options: [
        { label: 'Daily', value: 'daily' },
        { label: 'Weekly', value: 'weekly' },
        { label: 'Monthly', value: 'monthly' },
    ],
}

export const ChartRows = [
    {
        heading: 'Total Orders with Breakdown',
        chartProps: {
            chartType: 'bar',
            seriesNames: ['Total Orders', 'COD Orders', 'Prepaid Orders'],
        },
        tooltipText: 'Total Orders Placed',
        dataKey: 'data',
        nestedKey: 'graphData',
        heroValueTransform: 'number',
        key: 'total_orders_breakdown',
        filters: [dateRangeFilter],
        span: 24,
    },
    {
        heading: 'Overall RTO Rate',
        chartProps: {
            chartType: 'bar',
            seriesNames: ['Overall RTO Rate', 'COD RTO Rate', 'Prepaid RTO Rate'],
        },
        tooltipText: `Total RTO Orders/ (Total RTO Orders + Total Delivered Orders) * 100 . This doesn't include orders still in transit. It usually takes upto 15 days for AWB Data to be in sync.`,
        dataKey: 'data',
        nestedKey: 'graphData',
        heroValueTransform: 'number',
        key: 'overall_rto_rate',
        filters: [dateRangeFilter],
        span: 12,
    },
    {
        heading: 'RTO Risk Predicted by GoKwik for Total Orders',
        chartProps: {
            chartType: 'pie',
        },
        tooltipText:
            'GoKwik predicts RTO Risk for all orders placed and classifies them into High Risk, Low Risk and Medium Risk. A high risk order indicates the propensity of order being RTO is high.',
        dataKey: 'data',
        nestedKey: 'tileMetrics',
        heroValueTransform: 'percentage',
        key: 'risk_distribution',
        span: 12,
    },
    {
        heading: 'RTO Orders',
        chartProps: {
            chartType: 'bar',
            seriesNames: ['Total RTO Orders', 'COD RTO Orders', 'Prepaid RTO Orders'],
        },
        tooltipText: `Total RTO Orders. This doesn't include orders still in transit. It usually takes upto 15 days for AWB Data to be in sync.`,
        dataKey: 'data',
        nestedKey: 'graphData',
        heroValueTransform: 'number',
        key: 'overall_rto_orders',
        filters: [dateRangeFilter],
        span: 24,
    },
]
export const UpperSummaryBlocksData: {
    heading: string
    tooltipText: string
    dataKey: string
    nestedKey?: string
    heroValueTransform?: 'percentage' | 'number' | 'amount'
}[] = [
    {
        heading: 'Total Orders',
        tooltipText: 'Total Orders Placed',
        dataKey: 'tileMetrics',
        nestedKey: 'total_orders',
        heroValueTransform: 'number',
    },
    {
        heading: 'COD Orders',
        tooltipText: 'Total COD Orders Placed',
        dataKey: 'tileMetrics',
        nestedKey: 'cod_orders',
        heroValueTransform: 'number',
    },
    {
        heading: 'Total RTO Orders',
        tooltipText: `Total RTO Orders. This doesn't include orders still in transit. It usually takes upto 15 days for AWB Data to be in sync.`,
        dataKey: 'tileMetrics',
        nestedKey: 'total_rto_orders',
        heroValueTransform: 'number',
    },
    {
        heading: 'Overall RTO Rate',
        tooltipText: `Total RTO Orders/ (Total RTO Orders + Total Delivered Orders) * 100 . This doesn't include orders still in transit. It usually takes upto 15 days for AWB Data to be in sync.`,
        dataKey: 'tileMetrics',
        nestedKey: 'overall_rto_rate',
        heroValueTransform: 'percentage',
    },
]
export const LowerSummaryBlocksData: {
    heading: string
    tooltipText: string
    dataKey: string
    nestedKey?: string
    heroValueTransform?: 'percentage' | 'number' | 'amount'
}[] = [
    {
        heading: 'COD RTO Orders',
        tooltipText: `Total COD RTO Orders. This doesn't include orders still in transit. It usually takes upto 15 days for AWB Data to be in sync.`,
        dataKey: 'tileMetrics',
        nestedKey: 'cod_rto_orders',
        heroValueTransform: 'number',
    },
    {
        heading: 'COD RTO Rate',
        tooltipText: `COD RTO Orders * 100 / (Total COD RTO Orders + Total COD Orders Delivered). This doesn't include orders still in transit. It usually takes upto 15 days for AWB Data to be in sync.`,
        dataKey: 'tileMetrics',
        nestedKey: 'cod_rto_rate',
        heroValueTransform: 'percentage',
    },
    {
        heading: 'COD Share',
        tooltipText: 'Total COD Orders Placed/ Total Orders Placed * 100',
        dataKey: 'tileMetrics',
        nestedKey: 'cod_share',
        heroValueTransform: 'percentage',
    },
    {
        heading: 'RTO Predictions By GoKwik',
        tooltipText:
            'Gokwik RTO predicts RTO risk of an order at request level. This will include count of placed orders and abandoned checkouts as well. ',
        dataKey: 'tileMetrics',
        nestedKey: 'total_rto_hits',
        heroValueTransform: 'number',
    },
]
