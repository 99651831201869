import { Modal } from 'gokwik-ui-kit'
import TemplateDetails from './templateDetails'

const TemplatePreviewModal = ({ showPreview, setShowPreview, selectedTemplate, useTemplate }) => {
    return (
        <Modal
            open={showPreview}
            width={1000}
            centered
            footer={null}
            styles={{
                content: {
                    padding: 0,
                },
            }}
            onCancel={() => setShowPreview(false)}
        >
            <TemplateDetails selectedTemplate={selectedTemplate} useTemplate={useTemplate} />
        </Modal>
    )
}

export default TemplatePreviewModal
