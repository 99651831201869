import { makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { Workflow, RtoActionsConfig } from '@library/utilities/interface'

export const actionsApi: {
    [key: string]: {
        url: string
        errMsg: string
        nestedKeys?: string[]
        defaultParams?: { [key: string]: any }
        formatData?: (data: any) => any
    }
} = {
    workflowData: {
        url: APIEndPoints.getWorkFlow,
        errMsg: 'Error fetching workflows',
        defaultParams: {
            merchant_id: 8,
            mode: 'live',
        },
    },
}

export const fetchWorkflowsData = async ({
    key,
    params,
    nestedKey,
}: {
    key: string
    nestedKey?: string
    params?: { [key: string]: any }
}) => {
    const { url, nestedKeys, defaultParams, formatData } = actionsApi[key]
    const response = await makeAPICall({
        method: 'get',
        url: process.env.DASHBOARD_BACKEND_URL + url,
        params: {
            mode: 'live',
            merchant_id: params?.merchant_id,
        },
        skipLoader: true,
        skipMode: true,
        headers: {
            Authorization: localStorage.getItem('token'),
        },
    })
    if (response.success) {
        // correct it basd on api response
        let processedData = response.data?.data?.data.workflows ?? []

        if (formatData) {
            processedData = formatData(processedData)
        }
        return {
            success: true,
            data: processedData,
            ab_control_enable: response.data?.data?.data?.ab_control_enable,
            eida_reccomendations: response.data?.data?.data.eida_reccomendations,
            kwikflows_tier_config: response.data?.data?.data?.kwikflows_tier_config,
        }
    }
    return { error: true }
}

export const createWorkflowAsync = async (payload: Workflow) => {
    const response = await makeAPICall({
        method: 'post',
        url: process.env.DASHBOARD_BACKEND_URL + APIEndPoints.createWorkflow,
        params: {
            mode: 'live',
        },
        headers: {
            Authorization: localStorage.getItem('token'),
        },
        payload,
    })
    return response
}

export const updateWorkflowAsync = async (
    updatedWorkflows: Workflow[],
    merchantId: number,
    skipLoader: boolean = false,
) => {
    const response = await makeAPICall({
        method: 'put',
        url: process.env.DASHBOARD_BACKEND_URL + APIEndPoints.updateWorkflow,
        params: {
            mode: 'live',
        },
        headers: {
            Authorization: localStorage.getItem('token'),
        },
        payload: {
            merchant_id: merchantId,
            updateWorkflows: updatedWorkflows,
        },
        skipLoader,
    })
    return response
}

export const getTimelineData = async (merchantId: number) => {
    const response = await makeAPICall({
        url: process.env.AUDIT_SERVICE_URL+ '/filter/'+ merchantId ,
        method: 'post',
        payload: {
            page: 0,
            page_size: 1000,
            start_date: new Date(new Date().setDate(new Date().getDate() - 7)).setHours(0, 0, 0, 0),
            end_date: new Date().setHours(23, 59, 59, 999),
            modified_by: [],
            property_name: [],
        },
    })
    return response.data.data.audit_data
}

export const getUtmSuggestions = async (type, merchant_id) => {
    const response = await makeAPICall({
        method: 'get',
        url: process.env.RTO_DASHBOARD_SERVICE_URL + APIEndPoints.utmSuggestions,
        params: {
            type,
            merchant_id,
        },
        headers: {
            Authorization: process.env.RTO_DASHBOARD_SERVICE_AUTHTOKEN,
        },
        skipLoader: true,
    })
    return response
}

export const getMerchantDiscountConfig = async () => {
    const response = await makeAPICall({
        method: 'get',
        url: process.env.REACT_APP_BASE_URL + APIEndPoints.merchantConfigs,
        skipLoader: true,
    })
    return response?.data
}

export const callUpdateRtoActionsConfig = async (payload: RtoActionsConfig) => {
    let response
    try {
        response = await makeAPICall({
            method: 'post',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.merchantConfigs,
            payload,
            skipLoader: true,
        })
    } catch (error) {
        console.error('Error RTO Actions Updation', error)
    }
    return response
}

export const getABTestMetrics = async (abTestFlagId: string, merchantId: number) => {
    const response = await makeAPICall({
        method: 'get',
        url: process.env.RTO_DASHBOARD_SERVICE_URL + APIEndPoints.abTestMetrics,
        params: {
            ab_test_flag_id: abTestFlagId,
            merchant_id: merchantId,
        },
        headers: {
            Authorization: process.env.RTO_DASHBOARD_SERVICE_AUTHTOKEN,
        },
        skipLoader: true,
    })
    return response
}

export const getSearchProducts = async (searchText: string, mid: string) => {
    const response = await makeAPICall({
        method: 'get',
        url: process.env.DASHBOARD_BACKEND_URL + APIEndPoints.getProductsList,
        params: { name: searchText, merchant_mid: mid },
        skipLoader: true,
    })

    return response
}

export const getRiskReasons = async () => {
    const response = await makeAPICall({
        method: 'get',
        url: process.env.RTO_DASHBOARD_SERVICE_URL + APIEndPoints.getRiskReasons,
        headers: {
            Authorization: process.env.RTO_DASHBOARD_SERVICE_AUTHTOKEN,
        },
        skipLoader: true,
    })
    return response
}

export const getAddressSuggestions = async (type) => {
    const response = await makeAPICall({
        method: 'get',
        url: process.env.RTO_DASHBOARD_SERVICE_URL + APIEndPoints.addressSuggestions,
        params: {
            type,
        },
        headers: {
            Authorization: process.env.RTO_DASHBOARD_SERVICE_AUTHTOKEN,
        },
        skipLoader: true,
    })
    return response
}

export const getEidaReccomendationStatus = async (merchant_id) => {
    const response = await makeAPICall({
        method: 'get',
        url: process.env.RTO_DASHBOARD_SERVICE_URL + APIEndPoints.getEidaReccomendationStatus,
        params: {
            merchant_id,
        },
        headers: {
            Authorization: process.env.RTO_DASHBOARD_SERVICE_AUTHTOKEN,
        },
    })
    return response
}

export const getRecommendations = async (merchant_id, m_id) => {
    console.log(
        'getRecommendations',
        merchant_id,
        m_id,
        process.env.RTO_DASHBOARD_SERVICE_URL + APIEndPoints.getRecommendations,
    )
    const response = await makeAPICall({
        method: 'post',
        url: process.env.RTO_DASHBOARD_SERVICE_URL + APIEndPoints.getRecommendations,
        payload: {
            merchant_id: merchant_id,
            m_id: m_id,
        },
        headers: {
            Authorization: process.env.RTO_DASHBOARD_SERVICE_AUTHTOKEN,
        },
    })
    return response
}

export const getWorkflowImpactMetrics = async (ruleIds: string[], merchantId: number) => {
    const response = await makeAPICall({
        method: 'get',
        url: process.env.RTO_DASHBOARD_SERVICE_URL + APIEndPoints.workflowImpactMetrics,
        params: {
            rule_ids: ruleIds,
            merchant_id: merchantId,
        },
        headers: {
            Authorization: process.env.RTO_DASHBOARD_SERVICE_AUTHTOKEN,
        },
        skipLoader: true,
    })
    return response
}