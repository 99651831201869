import { useEffect, useState } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAllAnalyticsData } from '@store/rto_summary'
import { updateBreadcrumbs } from '@gokwik/utilities'
import { getMerchantDetails } from '@store/user/selectors'
import { useParams } from 'react-router-dom' 
import {
    AnalyticsDateRangePicker,
    message,
    Select,
    WarningFilled,
} from 'gokwik-ui-kit';
import { saveFiltersData } from '@store/filters'
import { getFilterDateOverviewPage } from '@store/filters/selectors'
import SummaryTable from './sumaryTable'
import { getAnalyticsSummaryData } from '@store/rto_summary/selector'
import RiskFlagSummaryTable from './riskFlagSummaryTable'
import EmptyPageData from '@library/components/empty-page-data'

const today = dayjs()
const yesterday = today.subtract(1, 'day')
const t_61 = today.subtract(61, 'day')

const RTOSummary = () => {
    const merchantDetails = useSelector(getMerchantDetails)
    const storedDates = useSelector(getFilterDateOverviewPage)
    const format = 'YYYY-MM-DD'
    const { filter_type = 'all', filter_value = '', fromDate = '', toDate = '' } = useParams()
    const merchant_id = merchantDetails.id
    const m_id = merchantDetails.m_id

    const from_date = storedDates.fromDate || t_61
    const to_date = storedDates.toDate || yesterday
    const [dates, setDates] = useState<{ from: Dayjs; to: Dayjs; label?: string }>({
        from: from_date,
        to: to_date,
    })
    const allowedMerchantIds = process.env.RTO_SUMMARY_MERCHANTS?.split(',') || [];
    const currentMerchantId = merchantDetails.m_id;
    const isRTOSummaryAllowed = allowedMerchantIds.includes(currentMerchantId);
    const [timeframe,setTimeframe] = useState('monthly')
    
    useEffect(() => {
        let breadcrumbs = [
            {
                key: 'rto-analytics',
                href: '/rto/analytics/summary',
                text: 'RTO Analytics Summary',
            },
        ]
        updateBreadcrumbs((prev) => [prev[0],...breadcrumbs])
    }, [])

    if(!isRTOSummaryAllowed){
        return (
            <EmptyPageData
                title="You currently don't have access to this page!"
                icon={<WarningFilled className='text-[#ffc107] text-7xl' />}
            />
        )
    }
    const dispatch = useDispatch()
    const getFetch = async () => {

        dispatch(
            //@ts-ignore
            fetchAllAnalyticsData({
                params: {
                    from_date: dates.from.format(format),
                    to_date: dates.to.format(format),
                    filter_type: filter_type,
                    filter_value: filter_value,
                    merchant_id: merchant_id,
                    m_id: m_id
                },
                payload: {
                    from_date: dates.from.format(format),
                    to_date: dates.to.format(format),
                    merchant_id: merchant_id,
                },
            }),
        )
        dispatch<any>(saveFiltersData('analyticsOverview', {
            fromDate:dates.from,
            toDate:dates.to,
          }))
    }

    useEffect(() => {
        getFetch()
    }, [dates])

    const analyticsData = useSelector(getAnalyticsSummaryData)

    const handleDateRange = (values, label) => {
        const [fromDate, toDate] = values;
    
        const isFromDateWithinLast90Days = fromDate.isAfter(dayjs().subtract(91, 'day'), 'day');
        const isToDateWithinLast90Days = toDate.isAfter(dayjs().subtract(91, 'day'), 'day');
    
        // Check if today is not included in the selected range
        const isFromDateBeforeToday = fromDate.isBefore(dayjs(), 'day');
        const isToDateBeforeToday = toDate.isBefore(dayjs(), 'day');
    
        if (!isFromDateWithinLast90Days || !isToDateWithinLast90Days) {
            message.error({
                content: `Please don't select dates earlier than the last 90 days.`,
                duration: 2,
            });
            return;
        }
    
        if (!isFromDateBeforeToday || !isToDateBeforeToday) {
            message.error({
                content: `Please ensure that the date range does not include today.`,
                duration: 2,
            });
            return;
        }
    
        setDates({ from: fromDate, to: toDate, label });
    };

    return (
        <div className='main-div px-3'>
            <div className='tile-metrics'>
                <h1 className='ml-3 mb-3 inline-block'>
                    {filter_value ? filter_value.toUpperCase() : merchantDetails.business_name.toUpperCase()} RTO SUMMARY VIEW
                </h1>
                <div className='flex justify-center mb-4'>
                    <div className='flex items-center mr-2'>
                    <span className='mr-2 font-medium' style={{ color: '#9ca3af' }}>Timeframe: </span>
                        <Select
                            placeholder='Timeframe'
                            defaultValue={timeframe}
                            options={[
                            // { value: 'weekly', label: 'Weekly' }, will be taken live later
                            { value: 'monthly', label: 'Monthly' },
                            { value: 'quarterly', label: 'Quarterly' },
                        ]}
                            onChange={(value) => setTimeframe(value)}
                        />
                    </div>
                    <div className='flex items-center'>
                    <span className='mr-2 font-medium' style={{ color: '#9ca3af' }}>Date Range: </span> 
                        <AnalyticsDateRangePicker values={[dates.from, dates.to]} onApply={handleDateRange} />
                    </div>
                </div>
                <div className='flex justify-center'>
                    <h1>
                        OVERALL SUMMARY
                    </h1>
                </div>
                
                <div className='rto-summary-block mb-4'>
                    <SummaryTable analyticsData={analyticsData} timeframe={timeframe}/>
                </div>

                <div className='flex justify-center'>
                    <h1>
                        RTO RISK FLAG SUMMARY
                    </h1>
                </div>
                
                <div className='rto-summary-block mb-24'>
                    <RiskFlagSummaryTable analyticsData={analyticsData} timeframe={timeframe}/>
                </div>
            </div>
        </div>
    )
}

export default RTOSummary
