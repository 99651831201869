import React, { memo, useRef } from 'react'
import styles from './index.module.css'
import { toast } from 'react-toastify'
import { Select, Input, DatePicker } from 'gokwik-ui-kit'
import { SearchFilters } from '../../utilities/interface'
import dayjs, { Dayjs } from 'dayjs'
import filters from '../../../store/filters'

const { Option } = Select

interface RenderFilterProps extends SearchFilters {
    removable?: boolean
    onRemove?: (key: string) => void
    value: string
    onChange: (data: { [key: string]: string }) => void
    inputKey: string
}

const RenderFilter = ({
    input_details,
    value,
    label,
    removable,
    onChange,
    onRemove,
    id,
    is_dynamic,
    inputKey,
}: RenderFilterProps) => {
    const inputRef = useRef(null)
    const handleInput = (newValue: any, key: any) => {
        if (key === 'searchPhone') {
            if (newValue.length <= 10) {
                newValue = newValue.toString()
            } else {
                toast.error('Phone number should be 10-digits', {
                    position: 'top-center',
                    autoClose: 1500,
                })
                return
            }
        }
        onChange({ [key]: newValue })
    }

    const getInputType = (label: any): any => {
        let type = 'text'
        if (label === 'Phone') {
            type = 'number'
        } else if (label === 'Email') {
            type = 'email'
        } else {
            type = 'text'
        }
        return type
    }

    const getValue = (key, option) => {
        return key === 'mid' ? option?.m_id : option?.id
    }

    return (
        <>
            {input_details?.input_type === 'date-range' ? (
                <DatePicker.RangePicker
                    className='w-full'
                    size='middle'
                    value={[dayjs(value?.split(',')[0]), dayjs(value?.split(',')[1])]}
                    onChange={([start, end, string]: any) => {
                        const key = inputKey.split('/')
                        onChange({
                            [key[0]]: dayjs(start).format('YYYY-MM-DD'),
                            [key[1]]: dayjs(end).format('YYYY-MM-DD'),
                        })
                    }}
                    format={'MMM DD, YY'}
                    allowClear={false}
                />
            ) : input_details?.input_type === 'text' || input_details?.input_type === 'number' ? (
                <Input
                    // ref={inputRef}
                    // onWheel={() => inputRef.current.blur()}
                    value={value}
                    type={getInputType(label)}
                    size='middle'
                    style={{ height: '32px' }}
                    className={'w-full'}
                    placeholder={label}
                    key={id}
                    maxLength={label === 'Phone' && 10}
                    allowClear
                    onChange={(e) => handleInput(e.target.value, inputKey)}
                    {...(is_dynamic && {
                        removable,
                        onRemove,
                    })}
                />
            ) : (
                <Select
                    value={value}
                    size='middle'
                    className={'w-full'}
                    placeholder={label}
                    showSearch={true}
                    optionFilterProp='children'
                    filterOption={(input, option) =>
                        option?.children?.toString()?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                        optionA?.children
                            ?.toString()
                            ?.toLowerCase()
                            ?.localeCompare(optionB?.children?.toString()?.toLowerCase())
                    }
                    allowClear
                    key={id}
                    mode={input_details?.mode === 'multi-select' ? 'multiple' : undefined}
                    onChange={(newValue: any) => {
                        handleInput(newValue, inputKey)
                    }}
                    {...(is_dynamic && {
                        removable,
                        onRemove,
                    })}
                >
                    {input_details?.options &&
                        input_details?.options?.length > 0 &&
                        input_details?.options?.map((option) => (
                            <Option
                                value={input_details?.value_key ? option[input_details.value_key] : option?.value}
                                key={option?.id}
                            >
                                {option?.text || option?.business_name || option?.short_name}
                            </Option>
                        ))}
                </Select>
            )}
        </>
    )
}

export default memo(RenderFilter)
