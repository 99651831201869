import { Button, Input, message, Select, Table, Switch, Alert, InputNumber } from 'gokwik-ui-kit'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getRTOMerchantConfig as getRTOMerchantConfigSelector } from '@store/rto-configs/selectors'
import { updateRTOModelConfig } from '@store/rto-configs/api'
import { RiskThresholdConfig, RtoMerchantConfig } from '../interface'
import { validateRTOModelConfig } from '../helpers'

const RTOModelRiskThreshold = ({ fetchRtoConfigData}) => {
    const data = useSelector(getRTOMerchantConfigSelector) as RtoMerchantConfig
    const [oldData, setOldData] = useState('')
    const [updatedRTOModelConfig, setUpdatedRTOModelConfig] = useState<RiskThresholdConfig>({} as RiskThresholdConfig)
    const [configData, setConfigData] = useState<RiskThresholdConfig>({} as RiskThresholdConfig)
    const [bucketTypeList, setBucketTypeList] = useState(['global'])
    const [saveDisabled, setSaveDisabled] = useState(true)
    const [rtoRiskThreshholdValues, setRTORiskThreshhold] = useState({})
    const [getUpdatedRiskThresholdValues, setUpdatedRiskThresholdValues] = useState(false)
    const [RTOModelData, setRTOModelData] = useState({
        rto_model_risk_flag: undefined,
        rto_model_risk_threshold: undefined,
        rto_model_risk_type: undefined,
    })

    useEffect(() => {
        setConfigData((prev) => ({
            ...prev,
            default_flag: 'Low Risk',
        }))
    }, [])

    useEffect(() => {
        if (data?.risk_flag_configs) {
            setOldData(
                JSON.parse(
                    JSON.stringify({
                        ...data?.risk_flag_configs,
                    }),
                ),
            )
            setConfigData(
                JSON.parse(
                    JSON.stringify({
                        ...data?.risk_flag_configs,
                    }),
                ),
            )
        }
    }, [data])

    useEffect(() => {
        Object.keys(updatedRTOModelConfig).length ? setSaveDisabled(false) : setSaveDisabled(true)
    }, [updatedRTOModelConfig])

    useEffect(() => {
        let options = new Set(['global'])
        if (!!data?.risk_flag_configs && Object.keys(data?.risk_flag_configs?.risk_flags).length != 0) {
            Object.keys(data?.risk_flag_configs?.risk_flags).forEach((flag) => {
                options.add(flag)
            })
        }
        if (
            data?.control_segment_configs?.segments != null &&
            Object.keys(data.control_segment_configs.segments).length != 0
        ) {
            Object.keys(data.control_segment_configs.segments).forEach((segment) => {
                options.add(segment)
            })
        }
        if (data?.blocked_buckets && data.blocked_buckets?.length != 0) {
            data.blocked_buckets.forEach((bucket) => {
                options.add(bucket)
            })
        }
        setBucketTypeList(Array.from(options))
    }, [data])

    const checkHighMediumThresholdsPresent = (risk_flags) => {
        let thresholdPresent = false
        Object.keys(risk_flags).forEach((bucket) => {
            const currentBucket = risk_flags[bucket]
            const areHighMediumThresholdsPresent = 'High Risk' in currentBucket && 'Medium Risk' in currentBucket
            if (!areHighMediumThresholdsPresent) {
                thresholdPresent = true
                return thresholdPresent
            }
        })
        return thresholdPresent
    }

    const deleteItem = (k, key) => {
        let tempData = configData?.risk_flags[k]
        tempData[key] = null
        delete tempData[key]

        if (Object.values(configData?.risk_flags[k]).length === 0) {
            delete configData?.risk_flags[k]
        }
        setUpdatedRTOModelConfig((configData) => ({
            ...configData,
            risk_flags: {
                ...configData?.risk_flags,
                [k]: {
                    ...tempData,
                },
            },
        }))
    }

    const handleAddNew = () => {
        let submit = validateRTOModelConfig(
            RTOModelData.rto_model_risk_type,
            RTOModelData.rto_model_risk_flag,
            RTOModelData.rto_model_risk_threshold,
        )

        let rtoRiskFlag = RTOModelData['rto_model_risk_flag']
        let rtoRiskThreshold = Number(RTOModelData['rto_model_risk_threshold'])
        let rtoRiskType = RTOModelData['rto_model_risk_type']

        if (submit.status) {
            let buckets = configData?.risk_flags || {}
            let rtoRiskFlags

            for (const b in buckets) {
                rtoRiskFlags = configData?.risk_flags[b] || {}
                for (const k in rtoRiskFlags) {
                    if (rtoRiskFlags[k] === rtoRiskThreshold && rtoRiskType === b) {
                        message.warning('Same thresholds are not allowed to enter for different risks.')
                        return
                    }
                }
            }

            buckets[rtoRiskType] = { ...buckets[rtoRiskType], [rtoRiskFlag]: rtoRiskThreshold }
            setUpdatedRTOModelConfig((prev) => ({
                ...(prev || {}),
                risk_flags: {
                    ...(prev?.risk_flags || {}),
                    [rtoRiskType]: { ...buckets[rtoRiskType] },
                },
                default_flag: configData?.default_flag || '',
            }))

            setConfigData({
                ...configData,
                risk_flags: {
                    ...(configData.risk_flags || {}),
                    [rtoRiskType]: { ...buckets[rtoRiskType] },
                },
                default_flag: configData?.default_flag || '',
            })

            setRTOModelData({
                rto_model_risk_flag: undefined,
                rto_model_risk_threshold: undefined,
                rto_model_risk_type: undefined,
            })
        } else {
            let err = submit.msg
            message.error(err)
        }
    }

    const configSubmit = async () => {
        if (configData?.risk_flags?.global != null) {
            if (Object.keys(configData?.risk_flags).length === 0) {
                setConfigData({} as RiskThresholdConfig)
            } else if (checkHighMediumThresholdsPresent(configData?.risk_flags)) {
                message.warning('Ensure High & Medium thresholds are present for each bucket.')
                return
            }
        }

        let tempData = JSON.parse(JSON.stringify({ ...configData }))

        try {
            const response = await updateRTOModelConfig({
                risk_flag_configs: tempData,
            })
            if (response.status_code === 200) {
                message.success('Config Updated Successfully')
                fetchRtoConfigData()
                setUpdatedRTOModelConfig({} as RiskThresholdConfig)
            }
        } catch (err) {
            message.error(err)
        }
    }

    const columns = [
        {
            title: 'S.No.',
            dataIndex: 'serial',
            key: 'serial',
        },
        {
            title: 'Bucket Type',
            dataIndex: 'bucketType',
            key: 'bucketType',
        },
        {
            title: 'Risk Flag',
            dataIndex: 'riskFlag',
            key: 'riskFlag',
        },
        {
            title: 'Threshold',
            dataIndex: 'threshold',
            key: 'threshold',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record: any) => (
                <Button type='link' style={{ textAlign: 'left', paddingLeft: 0 }} onClick={() => deleteItem(record.bucketType, record.riskFlag)}>
                    Delete
                </Button>
            ),
        },
    ]

    if (data == null) {
        return <p className='text-red-500 text-lg font-bold'>RTO Service Error</p>
    } else {
        return (
            <div className='p-4'>
                <h2 className='text-xl font-bold mb-4'>RTO Model Risk Threshold Configuration</h2>
                <div className='mb-4'>
                    <div className='flex mb-4'>
                        <Alert
                            className='self-stretch text-sm my-2.5'
                            message="Default Flag for this intervention is set to 'Low Risk'."
                            type='info'
                            showIcon
                            closable
                        />
                    </div>
                    <div className='flex space-x-4 mb-2'>
                        <div className='w-1/4'>
                            <label className='block text-sm font-medium mb-1'>Bucket Type</label>
                            <Select
                                className='w-full'
                                placeholder='Bucket Type'
                                value={RTOModelData.rto_model_risk_type}
                                onChange={(value) => setRTOModelData({ ...RTOModelData, rto_model_risk_type: value })}
                                options={bucketTypeList.map((bucket) => ({ label: bucket, value: bucket }))}
                            />
                        </div>
                        <div className='w-1/4'>
                            <label className='block text-sm font-medium mb-1'>Risk Flag</label>
                            <Select
                                className='w-full'
                                placeholder='Risk Flag'
                                value={RTOModelData.rto_model_risk_flag}
                                onChange={(value) => setRTOModelData({ ...RTOModelData, rto_model_risk_flag: value })}
                                options={[
                                    { label: 'Low Risk', value: 'Low Risk' },
                                    { label: 'Medium Risk', value: 'Medium Risk' },
                                    { label: 'High Risk', value: 'High Risk' },
                                ]}
                            />
                        </div>
                        <div className='w-1/4'>
                            <label className='block text-sm font-medium mb-1'>Threshold</label>
                            <Input
                                className='w-full'
                                placeholder='Threshold'
                                type='number'
                                min={0}
                                max={1}
                                step={0.05}
                                value={RTOModelData.rto_model_risk_threshold}
                                onChange={(e) =>
                                    setRTOModelData({ ...RTOModelData, rto_model_risk_threshold: e.target.value })
                                }
                            />
                        </div>
                        <div className='w-1/4 mt-4'>
                            <Button type='primary' onClick={handleAddNew}>
                                Add New
                            </Button>
                        </div>
                    </div>
                </div>

                <Table
                    dataSource={Object.keys(configData?.risk_flags || {}).flatMap((key,i) =>
                        Object.keys(configData?.risk_flags[key]).map((itm, index) => ({
                            serial: i,
                            bucketType: key,
                            riskFlag: itm,
                            threshold: parseFloat(Number(configData?.risk_flags[key][itm]).toFixed(4)),
                        })),
                    )}
                    columns={columns}
                    pagination={false}
                />

                <div className='flex justify-center mt-4'>
                    <Button type='primary' onClick={configSubmit} disabled={saveDisabled} style={{ width: '200px' }}>
                        Save
                    </Button>
                </div>
            </div>
        )
    }
}

export default RTOModelRiskThreshold
