import { ReactNode, useMemo, useRef, useEffect } from 'react'
import { Layout, NavigationMenu, DatabaseOutlined, SolutionOutlined } from 'gokwik-ui-kit'
import { useNavigate, Routes, Route, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getMerchantDetails, getUserDetails } from '@store/user/selectors'
import { logEvent } from '@library/utilities/userLogEvents/userLogEvents'
import AWBFlows from '@library/components/settings/awb-flows'
import RTOMerchantConfig from '@library/components/settings/rto-configs'
import { isMerchantSelector } from '@store/user/selectors'

const { Sider } = Layout

interface MenuItem {
    key: string
    label: ReactNode
    icon?: ReactNode
    children?: MenuItem[]
    type?: 'group'
}

function createMenuItem(
    label: ReactNode,
    key: string,
    icon?: ReactNode,
    children?: MenuItem[],
    type?: 'group',
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
        type,
    }
}
export default function () {
    const route = useLocation()
    const navigate = useNavigate()
    const previousOpenedItems = useRef({
        openedKey: '',
        selectedKey: '',
    })
    const merchantData = useSelector(getMerchantDetails)
    const user_details = useSelector(getUserDetails)
    const isMerchantUser = useSelector(isMerchantSelector)

    const settingsList = [
        {
            title: 'AWB-Flows',
            path: '/awb-flows',
            component: AWBFlows,
        },
        ...(!isMerchantUser ? [{
            title: 'RTO Configs',
            path: '/rto-configs',
            component: RTOMerchantConfig,
        }] : []),
    ]

    const menuItems = () => {
        let items = [
            createMenuItem(
                'AWB',
                '/rto/settings/awb-flows',
                <DatabaseOutlined />,
            ),
            ...(!isMerchantUser ? [
                createMenuItem('RTO Intelligence', '/rto/settings/rto-configs', <SolutionOutlined />),
            ] : []),
        ];
        return items;
    }

    const pathMap = {
        '/rto/settings/awb-flows': 'awb-flows',
        '/rto/settings/rto-configs': 'rto-configs',
    }

    const openItems = useMemo(() => {
        const menu = menuItems()

        const openedKey = menu.find((item) => {
            if (route.pathname.includes(item.key)) {
                return true;
            }
            return false
        })

        if (openedKey) {
            previousOpenedItems.current = {
                openedKey: openedKey.key,
                selectedKey: openedKey.key,
            }
            return {
                openedKey: openedKey.key,
                selectedKey: openedKey.key,
            }
        } 
        else {

            previousOpenedItems.current = {
                openedKey: '/rto/settings/awb-flows',
                selectedKey: '/rto/settings/awb-flows',
            }
            return {
                openedKey: '/rto/settings/awb-flows',
                selectedKey: '/rto/settings/awb-flows',
            }
        }
    }, [route.pathname])

    useEffect(() => {
        logEvent('kwik_rto_rto_settings_clicked', 'click', 'Kwik RTO Settings', user_details?.email, merchantData?.m_id, merchantData?.short_name, user_details?.name);
    }, [merchantData,user_details])

    const handleMenuSelection = ({ key }: any) => {
        logEvent('kwik_rto_rto_settings_' + pathMap[key] + '_clicked', 'click', 'Kwik RTO Settings', user_details?.email, merchantData?.m_id, merchantData?.short_name, user_details?.name);
        navigate(key)
    }


    useEffect(() => {
        if (route.pathname === '/rto/settings') navigate('/rto/settings' + settingsList[0].path)
    }, [route.pathname])

    return (
        <div className='w-full bg-white'>
            <Layout className='h-full '>
                <Sider
                    className='bg-white h-full mr-4 border-0 border-gray-100 border-r-2 fixed overflow-hidden'
                    style={{ background: 'white' }}
                >
                    <div className='setting-menu-custom-css'>
                        <NavigationMenu
                            onClick={handleMenuSelection}
                            defaultOpenKeys={[openItems.openedKey]}
                            defaultSelectedKeys={[openItems.selectedKey]}
                            key={route.pathname}
                            className=''
                            items={menuItems()}
                            inlineCollapsed={true}
                            mode='inline'
                        />
                    </div>
                </Sider>
                <Layout className='px-0 overflow-auto'>
                    <Routes>
                        {settingsList.map((item, index) => (
                            <Route key={index} path={item.path} element={<item.component />} />
                        ))}
                    </Routes>
                </Layout>
            </Layout>
        </div>
    )
}
