import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { ColumnProps, InfoCircleOutlined, Table, Tooltip } from 'gokwik-ui-kit'
import { formatDateTimeframe, numberFormatter } from '@library/utilities/helpers/helper'
import { updateDataBasedOnTimeframe } from '@library/utilities/helpers/data_filtering'

dayjs.extend(utc)

const metrics = {
    hits: { label: 'RTO API HITS', type: 'number' },
    total_orders: { label: 'ORDERS', type: 'number' },
    cod_orders: { label: 'COD ORDERS', type: 'number' },
    cod_rto_perc: { label: 'RTO COD %', type: 'percentage' },
    rto_overall_perc: { label: 'RTO % (OVERALL)', type: 'percentage' },
    prepaid_share: { label: 'PREPAID SHARE', type: 'percentage' },
    awb_fill_rate_perc: { label: 'AWB FILL RATE %', type: 'percentage' },
    delivered_orders_perc: { label: 'DELIVERED ORDERS %', type: 'percentage' },
    cancelled_orders_perc: { label: 'CANCELLED ORDERS %', type: 'percentage' },
}
const rtoApiHitsIconMsg =
    'The RTO API is triggered when a user proceeds from the Address Page to the Payment Page. This metric indicates the number of users who have reached the payment stage.'
const awbFillRateIconMsg =
    'The AWB Fill Rate measures the proportion of orders for which Gokwik has received delivery status updates. This information is crucial for maintaining a strong RTO Model and assisting you in RTO reduction Action Plan.'

const createDynamicColumns = (data, timeframe, status='loading') => {
    const baseColumns: ColumnProps<any>[] = [
        {
            title: 'Metric',
            dataIndex: 'metric_name',
            key: 'metric_name',
            render: (val) => {
                const metric = metrics[val]
                if (val === 'hits' || val === 'awb_fill_rate_perc') {
                    const tooltipMsg = val === 'hits' ? rtoApiHitsIconMsg : awbFillRateIconMsg
                    return (
                        <div>
                            <span className="font-bold">{metric.label}{' '}</span>
                            <Tooltip placement='right' title={tooltipMsg}>
                                <InfoCircleOutlined />
                            </Tooltip>
                        </div>
                    )
                }
                return <span className="font-bold">{metric.label}{' '}</span>
            },
            width: 150,
        },

        ...(data && data.length > 0 ? data.map((item) => ({
            title: formatDateTimeframe(item.date, timeframe),
            dataIndex: formatDateTimeframe(item.date, timeframe),
            key: formatDateTimeframe(item.date, timeframe),
            render: (val, record) => {
                if (metrics[record.metric_name].type === 'percentage' && isNaN(val)) {
                    return <Tooltip title="Awaited">Awaited</Tooltip>
                } else {
                    const formattedValue = numberFormatter(val, metrics[record.metric_name].type)
                    return <Tooltip title={`${val}`}>{formattedValue}</Tooltip>
                }
            },
            align: 'right',
            width: 120,
        })) : status === 'loading' ? [{
            title: 'Loading',
            dataIndex: 'loading',
            key: 'loading',
            render: () => 'Loading...',
            align: 'right',
            width: 200,
        }] : [{
            title: 'No Data Found',
            dataIndex: 'no_data',
            key: 'no_data',
            render: () => 'No Data Found',
            align: 'right',
            width: 150,
        }])
    ];

    return baseColumns;
}

const mapDataToRows = (data, timeframe) => {
    return Object.keys(metrics)?.map((metric_key) => {
        const row = { metric_name: metric_key }

        data?.forEach((item) => {
            const formattedDate = formatDateTimeframe(item.date, timeframe)
            row[formattedDate] = item[metric_key]
        })

        return row
    })
}

const SummaryTable = ({ analyticsData, timeframe = 'monthly' }) => {
    const originalData = analyticsData?.rtoInsights?.data?.originalData
    const status = analyticsData?.rtoInsights?.status;
    const timeframeData = updateDataBasedOnTimeframe(originalData, timeframe)
    const tableColumns = createDynamicColumns(timeframeData, timeframe, status)
    const tableData = mapDataToRows(timeframeData, timeframe)

    return <Table columns={tableColumns} dataSource={tableData} pagination={false} bordered={true} loading={status==='loading'}/>
}

export default SummaryTable;