import { actionInputOptions, actionNames } from '@library/utilities/constants/constants'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getWorkflowAction } from '@store/actions/selectors'
import { setWorkflowAction } from '@store/actions'
import { ActionType } from '@library/utilities/interface'
import ActionFormItems from './actionFormItems'
import { getMerchantDiscountConfig } from '@store/actions/api'
import { shouldShowTertiaryOption } from '@library/utilities/helpers/helper'

function TakeAction() {
    const dispatch = useDispatch()
    const [merchantDiscountConfig, setMerchantDiscountConfig] = useState([])

    const workflowAction = useSelector(getWorkflowAction)
    const {
        secondary: secondaryActionType,
        primary: actionType,
        showSecondaryAction,
        tertiary: tertiaryActionType,
        showTertiaryAction,
    } = workflowAction

    useEffect(() => {
        setActionState({
            showSecondaryAction: actionType.action === actionNames.allowCod ? false : showSecondaryAction,
            showTertiaryAction: shouldShowTertiaryOption(actionType, secondaryActionType) ? showTertiaryAction : false,
        })
    }, [actionType.action, secondaryActionType.action])

    useEffect(() => {
        const fetchInitialMerchantDiscount = async () => {
            try {
                const response = await getMerchantDiscountConfig()
                if (response.status_code === 200 && response.data) {
                    const merchant_discount_config = response.data?.tiered_discount_config ?? []
                    const freebies_merchant_discount_config = merchant_discount_config
                        .filter(
                            (item) =>
                                item.discount_type.toLowerCase() === 'freebie' && item.is_driven_by_rto_actions == true,
                        )
                        .map((item) => ({
                            value: `${item.discount_code} | ${item.method_type}`,
                            label: `${item.discount_code.toLowerCase()} | ${item.method_type.toLowerCase()}`,
                        }))
                    setMerchantDiscountConfig(freebies_merchant_discount_config)
                }
            } catch (error) {
                console.error('Error fetching merchant discount config:', error)
            }
        }
        fetchInitialMerchantDiscount()
    }, [])

    const setAction = useCallback(
        (
            key: 'primary' | 'secondary' | 'showSecondaryAction' | 'tertiary' | 'showTertiaryAction',
            value: ActionType | boolean,
        ) => {
            dispatch(setWorkflowAction({ ...workflowAction, [key]: value }))
        },
        [workflowAction],
    )

    const setSecondaryAction = (value: ActionType) => {
        setAction('secondary', value)
    }

    const setPrimaryAction = (value: ActionType) => {
        setAction('primary', value)
    }

    const setShowSecondaryAction = (value: boolean) => {
        setAction('showSecondaryAction', value)
    }

    const setTertiaryAction = (value: ActionType) => {
        setAction('tertiary', value)
    }

    const setShowTertiaryAction = (value: boolean) => {
        setAction('showTertiaryAction', value)
    }

    const setActionState = (value) => {
        dispatch(setWorkflowAction({ ...workflowAction, ...value }))
    }
    return (
        <div>
            <ActionFormItems
                actionType={actionType}
                secondaryActionType={secondaryActionType}
                showSecondaryAction={showSecondaryAction}
                tertiaryActionType={tertiaryActionType}
                showTertiaryAction={showTertiaryAction}
                setShowTertiaryAction={setShowTertiaryAction}
                setTertiaryAction={setTertiaryAction}
                setShowSecondaryAction={setShowSecondaryAction}
                setSecondaryAction={setSecondaryAction}
                setPrimaryAction={setPrimaryAction}
                setActionState={setActionState}
                actionInputOptions={actionInputOptions}
                merchantDiscountConfig={merchantDiscountConfig}
            />
        </div>
    )
}

export default React.memo(TakeAction)
