export const TickIcon = () => {
    return (
        <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g clip-path='url(#clip0_7017_79155)'>
                <path
                    d='M15.6667 8L14.04 6.14L14.2667 3.68L11.86 3.13333L10.6 1L8.33333 1.97333L6.06667 1L4.80667 3.12667L2.4 3.66667L2.62667 6.13333L1 8L2.62667 9.86L2.4 12.3267L4.80667 12.8733L6.06667 15L8.33333 14.02L10.6 14.9933L11.86 12.8667L14.2667 12.32L14.04 9.86L15.6667 8ZM7.06 11.1467L4.52667 8.60667L5.51333 7.62L7.06 9.17333L10.96 5.26L11.9467 6.24667L7.06 11.1467Z'
                    fill='#00E25C'
                />
            </g>
            <defs>
                <clipPath id='clip0_7017_79155'>
                    <rect width='16' height='16' fill='white' />
                </clipPath>
            </defs>
        </svg>
    )
}
