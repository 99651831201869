import { HTMLProps, useEffect, useState } from 'react'
import { AnalyticsDate } from '../../../library/utilities/interface'
import { apexChartConfig, ApexOptions, ReactApexChart } from 'gokwik-ui-kit'

interface ChartProps {
    uniqueKey?: string
    dates?: { start: AnalyticsDate; end: AnalyticsDate }
    data: any
    chartType: 'line' | 'bar' | 'pie'
    hideComparison?: boolean
    seriesNames?: string[]
    isAmount?: boolean
    wrapperClassName?: HTMLProps<HTMLElement>['className']
}

interface DataItem {
    date?: string;
    total_orders?: number;
    cod_orders?: number;
    prepaid_orders?: number;
    overall_rto_rate?: number;
    cod_rto_rate?: number;
    prepaid_rto_rate?: number;
    overall_rto_orders?: number;
    cod_rto_orders?: number;
    prepaid_rto_orders?: number;
}

const processBarChartData = (data, uniqueKey) => {
    const dates: string[] = []
    const primaryValues: number[] = []
    const secondaryValues: number[] = []
    const tertiaryValues: number[] = []
    if (uniqueKey === 'total_orders_breakdown') {
        data.forEach((item: DataItem) => {
            dates.push(item.date)
            primaryValues.push(item.total_orders || 0)
            secondaryValues.push(item.cod_orders || 0)
            tertiaryValues.push(item.prepaid_orders || 0)
        })
    }
    if (uniqueKey === 'overall_rto_rate') {
        data.forEach((item: DataItem) => {
            dates.push(item.date)
            primaryValues.push(item.overall_rto_rate || 0)
            secondaryValues.push(item.cod_rto_rate || 0)
            tertiaryValues.push(item.prepaid_rto_rate || 0)
        })
    }
    if (uniqueKey === 'overall_rto_orders') {
        data.forEach((item: DataItem) => {
            dates.push(item.date)
            primaryValues.push(item.overall_rto_orders || 0)
            secondaryValues.push(item.cod_rto_orders || 0)
            tertiaryValues.push(item.prepaid_rto_orders || 0)
        })
    }

    const configOptions = {
        plotOptions: {
            bar: {
                horizontal: false,
                borderRadius: 5,
                borderRadiusApplication: 'end',
            },
        },
    }

    return { dates, primaryValues, secondaryValues, tertiaryValues, configOptions }
}

const processPieChartData = (data, uniqueKey) => {
    const primaryValues: number[] = []
    if (uniqueKey === 'risk_distribution') {
        primaryValues.push(data.high_risk_orders || 0)
        primaryValues.push(data.medium_risk_orders || 0)
        primaryValues.push(data.low_risk_orders || 0)
    }

    const configOptions = {
        labels: ['High Risk', 'Medium Risk', 'Low Risk'],
    }
    return { primaryValues, configOptions }
}

const Chart = ({
    uniqueKey,
    dates,
    data,
    chartType,
    seriesNames,
    hideComparison,
    isAmount,
    wrapperClassName,
}: ChartProps) => {
    const [series, setSeries] = useState([])
    const [options, setOptions] = useState<ApexOptions>({})

    useEffect(() => {
        if (!data) return;
        const chartData =
            chartType === 'bar' ? processBarChartData(data, uniqueKey) : processPieChartData(data, uniqueKey)

        if (chartType === 'bar') {
            setSeries([
                {
                    name: seriesNames?.[0],
                    data: chartData['primaryValues'],
                },
                ...(chartData['secondaryValues']
                    ? [
                          {
                              name: seriesNames?.[1] || dates.start.label,
                              data: chartData['secondaryValues'],
                          },
                      ]
                    : []),
                ...(chartData['tertiaryValues']
                    ? [
                          {
                              name: seriesNames?.[2] || dates.start.label,
                              data: chartData['tertiaryValues'],
                          },
                      ]
                    : []),
            ])
        } else {
            setSeries(chartData['primaryValues'])
        }
        const customTooltip: ApexTooltip['custom'] = ({ series, seriesIndex, dataPointIndex, w }) => {
            const dateFormat = 'DD MMM YYYY'
            return `
            <div class='charts-tool-tip bg-white flex flex-col inter p-2'>
              <div class='flex flex-col items-center font-medium text-xs'>
                <div class='flex items-center w-full justify-between'>
                  <div class='flex items-center w-2/3'>
                  
                    <span class='ml-2 text-[10px] text-gray-500'>${'Date'}</span>
                  </div>
                  <span class='ml-2 text-[10px] text-gray-500'>
                            ${w.globals.labels[dataPointIndex]}
                  </span>
                </div>
                <div class='flex items-center w-full justify-between'>
                        <div class='flex items-center w-2/3'>
                          <div class='min-w-[5px] min-h-[5px] rounded-full' style='background-color:${
                              w.config.colors[seriesIndex]
                          };min-height:5px;min-width:5px'></div>
                          <span class='ml-2 text-[10px] w-2/3 text-right'>${w.globals.seriesNames[seriesIndex]}</span>
                        </div>
                        <span class='ml-2 text-[10px] w-2/3 text-right'>${series[seriesIndex][dataPointIndex]}</span>
                      </div>
              </div>
            </div>`
        }

        let newOptions: ApexOptions = {}
        if (chartType === 'bar') {
            newOptions = {
                ...apexChartConfig({
                    xLabelsPrimary: chartData['dates'],
                    datesDiff: 25,
                    isAmount: false,
                }),
                plotOptions: chartData['configOptions']['plotOptions'],
                colors: ['#FF891B', '#062752', '#AC8FFD'],
                tooltip: { custom: customTooltip, followCursor: true },
                stroke: {
                    colors: ['transparent'],
                    width: 2,
                },
            };
        } else if (chartType === 'pie') {
            newOptions = {
                chart: {
                    type: 'pie',
                },
                labels: ['High Risk', 'Medium Risk', 'Low Risk'],
                colors: ['#FF891B', '#AC8FFD', '#062752'],

                stroke: {
                    show: true,
                    width: 5,
                    colors: ['transparent'],
                },
                legend: {
                    position: 'right',
                    fontSize: '14px',
                },
            }
        }
        setOptions(newOptions)
    }, [dates, data])

    return (
        <div className={`${wrapperClassName} ${chartType === 'bar' ? 'bar-chart-rto' : 'pie-chart-rto'}`}>
            <ReactApexChart options={options} type={chartType} series={series} height={'350'} />
        </div>
    )
}
export default Chart
