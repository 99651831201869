import { Condition } from '@library/utilities/interface'
import { InputNumber, Slider } from 'gokwik-ui-kit'
import React, { useEffect, useState } from 'react'

interface RiskSliderProps {
    onChangeValues: (value: any, field: string) => void
    workflowData: Condition
}

const RiskSlider: React.FC<RiskSliderProps> = ({ onChangeValues, workflowData }) => {
    const [inputValue, setInputValue] = useState([0, 1])

    useEffect(() => {
        onChangeValues('==', 'operator')
    }, [])

    useEffect(() => {
        if (workflowData?.values && workflowData?.values?.length) {
            setInputValue(workflowData?.values?.map((item) => Math.round(Number(item) * 100)))
        } else {
            onChangeValues(inputValue, 'values')
        }
    }, [workflowData])

    const onChange = (value: number[]) => {
        if (isNaN(value[0] as number) || isNaN(value[1] as number)) {
            return
        }
        setInputValue(value as number[])
        onChangeValues(value.map(item => item / 100), 'values')
    }

    const onChangeInput = (value, idx: number) => {
        if (isNaN(value as number)) {
            return
        }
        let changedVal = [...inputValue]
        changedVal[idx] = value as number
        setInputValue(changedVal)
        onChangeValues(changedVal.map(item => item / 100), 'values')
    }
    return (
        <div className='w-full flex items-center gap-4'>
            <InputNumber
                value={inputValue[0]}
                min={0}
                max={100}
                formatter={(value) => `${value}%`}
                parser={(value) => value?.replace('%', '') as unknown as number}
                onChange={(val) => onChangeInput(val, 0)}
            />
            <Slider
                min={0}
                max={100}
                onChange={onChange}
                defaultValue={[0, 100]}
                value={inputValue}
                step={1}
                className='rto-risk-slider flex-1'
                marks={{
                    0: '0%',
                    100: '100%',
                }}
                range
            />

            <InputNumber
                value={inputValue[1]}
                min={0}
                max={100}
                formatter={(value) => `${value}%`}
                parser={(value) => value?.replace('%', '') as unknown as number}
                onChange={(val) => onChangeInput(val, 1)}
            />
        </div>
    )
}

export default React.memo(RiskSlider)
