import { makeAPICall } from '@gokwik/utilities'
import { aggregateMonthlyAWBData } from '@library/components/settings/helpers'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'

export const awbFlowsApi: {
    [key: string]: {
        url: string
        errMsg: string
        nestedKeys?: string[]
        defaultParams?: { [key: string]: any }
        formatData?: (data: any) => any
    }
} = {
    awbFillRate: {
        url: APIEndPoints.getRTOAnalyticsData,
        errMsg: 'Error fetching awb fill rate',
        defaultParams: {
            rto_segment: 'all',
            filter_type: 'risk_flag',
        },
    },
    getPendingMissingOrders: {
        url: APIEndPoints.getPendingMissingOrders,
        errMsg: 'Error fetching Pending/Missing Orders',
    },
}

export const getUploadUrl = async (uploadType: string, uploadName: string, merchantId: string) => {
    const response = await makeAPICall({
        method: 'get',
        url: process.env.RTO_DASHBOARD_SERVICE_URL + APIEndPoints.getUploadUrl,
        params: {
            upload_type: uploadType,
            upload_name: uploadName,
            merchant_id: merchantId,
        },
        headers: {
            Authorization: process.env.RTO_DASHBOARD_SERVICE_AUTHTOKEN,
        },
        skipLoader: true,
    })
    return response
}

export const uploadCsvToS3 = async (url: string, requestOptions: any) => {
    const response = await fetch(url, requestOptions)

    return response
}

export const notifyUpload = async (uploadId: string, merchantId: number) => {
    const response = await makeAPICall({
        method: 'post',
        url: process.env.RTO_DASHBOARD_SERVICE_URL + APIEndPoints.notifyUpload,
        payload: {
            upload_id: uploadId,
            merchant_int_id: merchantId,
        },
        headers: {
            Authorization: process.env.RTO_DASHBOARD_SERVICE_AUTHTOKEN,
        },
        skipLoader: true,
    })
    return response
}

export const getIngestionStatus = async (uploadId: string) => {
    const response = await makeAPICall({
        method: 'get',
        url: process.env.RTO_DASHBOARD_SERVICE_URL + APIEndPoints.getIngestionStatus,
        params: {
            upload_id: uploadId,
        },
        headers: {
            Authorization: process.env.RTO_DASHBOARD_SERVICE_AUTHTOKEN,
        },
        skipLoader: true,
    })
    return response
}

export const fetchAwbFillRateData = async ({
    key,
    params,
    nestedKey,
}: {
    key: string
    nestedKey?: string
    params?: { [key: string]: any }
}) => {
    const { url, defaultParams } = awbFlowsApi[key]
    const response = await makeAPICall({
        method: 'get',
        url: process.env.RTO_DASHBOARD_SERVICE_URL + url,
        headers: {
            Authorization: process.env.RTO_DASHBOARD_SERVICE_AUTHTOKEN,
        },
        params: {
            rto_segment: 'all',
            filter_type: 'risk_flag',
            from_date: new Date(new Date().setMonth(new Date().getMonth() - 2, 1)).toISOString().split('T')[0],
            to_date: new Date().toISOString().split('T')[0],
            merchant_id: params?.merchant_id,
        },
        skipLoader: true,
    })
    if (response.success) {
        return {
            success: true,
            data: aggregateMonthlyAWBData(response.data?.data),
        }
    }
    return { error: true }
}

export const downloadCsv = async (params) => {
    try {
        const { url } = awbFlowsApi['getPendingMissingOrders']
        const response = await makeAPICall({
            method: 'get',
            url: process.env.RTO_DASHBOARD_SERVICE_URL + url,
            params: {
                merchant_id: params.merchant_id,
                platform: params.platform,
                start_date: params.start_date,
                end_date: params.end_date,
            },
            headers: {
                Authorization: process.env.RTO_DASHBOARD_SERVICE_AUTHTOKEN,
            },
            skipLoader: true,
        })

        if (!response || !response?.data?.order_ids) {
            throw new Error('No data received')
        }

        return response?.data?.order_ids
    } catch (error) {
        console.error('Error downloading CSV:', error)
        return null
    }
}
