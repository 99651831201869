import { Layout, SummaryBlocks, Tooltip, QuestionCircleOutlined, Tabs, TagOutlined } from 'gokwik-ui-kit'
const { Sider } = Layout
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchAllRtoDetailsData } from '@store/rto_details'
import { getRtoDetailsData } from '@store/rto_details/selector'
import { getMerchantDetails, getUserDetails } from '@store/user/selectors'
import RtoDetailsTable from '@library/components/rto-details/rto-table'
import { rtoDetailsFilterMenu } from '@library/utilities/constants/constants'
import { getAnalyticsData } from '@store/analytics/selectors'
import { useLocation, useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import { updateShopifyDashboardAnalytics } from '@store/analytics/api'
import { logEvent } from '@library/utilities/userLogEvents/userLogEvents'

const today = dayjs()
const format = 'YYYY-MM-DD'
const t_15 = today.subtract(15, 'day').format(format)
const t_45 = today.subtract(45, 'day').format(format)

const RtoDetails = () => {
    const { filter_type = 'state' } = useParams()
    const location = useLocation()
    const filterType = location?.state?.filter_type || filter_type
    const [current, setCurrent] = useState(filterType)
    const [currentTitle, setCurrentTitle] = useState(filterType)
    const [currentFilter, setCurrentFilter] = useState(filterType)
    const [selectedFilter, setSelectedFilter] = useState(null)
    let merchantDetails = useSelector(getMerchantDetails)
    const user_details = useSelector(getUserDetails)
    const analyticsData = useSelector(getAnalyticsData)
    const { to_date = t_15, from_date = t_45 } = analyticsData?.rtoInsights?.data?.dateRange || {}
    const dispatch = useDispatch()
    const getFetch = async () => {
        const format = 'YYYY-MM-DD'
        dispatch(
            //@ts-ignore
            fetchAllRtoDetailsData({
                params: {
                    filter_type: currentFilter,
                    to_date: to_date,
                    from_date: from_date,
                    merchant_id: merchantDetails?.id,
                },
            }),
        )
    }
    useEffect(() => {
        getFetch()
    }, [current, merchantDetails])

    const rtoDetailsData = useSelector(getRtoDetailsData)

    const saveAnalytics = async (filterType, filterName) => {
        await updateShopifyDashboardAnalytics(filterType, filterName)
    }

    const getTitle = (filterType) => {
        const mapping = {
            state: 'states',
            city: 'cities',
            product: 'products',
            pincode: 'pincodes',
        }
        return mapping[filterType] || 'states'
    }
    const onClick = (e) => {
        setCurrent(e)
        setCurrentTitle(e)
        setCurrentFilter(e)
        logEvent('kwik_rto_rto_analytics_' + getTitle(e) + '_clicked', 'click', 'Kwik RTO Analytics', user_details?.email,merchantDetails?.m_id, merchantDetails?.short_name, user_details?.name)
    }
    const transformData = (rawData, filterType) => {
        if (rawData?.length) {
            let key
            switch (filterType) {
                case 'city':
                    key = 'billing_city'
                    break
                case 'state':
                    key = 'billing_state'
                    break
                case 'pincode':
                    key = 'billing_pincode'
                    break
                case 'product':
                    key = 'p_name'
                    break
                default:
                    throw new Error('Invalid filter type')
            }

            const transformedData = rawData
                .map((item, index) => {
                    const name = item[key]
                    return {
                        key: (index + 1).toString(),
                        [filterType]: name?.charAt(0).toUpperCase() + name?.slice(1).toLowerCase(),
                        total_orders: parseInt(item.total_orders),
                        cod_orders: parseInt(item.total_cod_orders),
                        rto_orders: parseInt(item.total_rto_orders),
                        rto_percentage: Math.round(parseFloat(item.avg_rto_rate) * 100),
                    }
                })
                .sort((a, b) => b.rto_percentage - a.rto_percentage)

            return transformedData
        }
        return []
    }
    const categorizeStates = (data) => {
        let totalCount = data?.length
        let above40Percent: string[] = []
        let between20And40Percent: string[] = []
        let below20Percent: string[] = []

        if (data?.length) {
            data.forEach((stateData) => {
                if (stateData.avg_rto_rate > 0.4) {
                    above40Percent.push(stateData.billing_state)
                } else if (stateData.avg_rto_rate >= 0.2 && stateData.avg_rto_rate <= 0.4) {
                    between20And40Percent.push(stateData.billing_state)
                } else {
                    below20Percent.push(stateData.billing_state)
                }
            })
        }
        let above40count = above40Percent?.length
        let between20and40count = between20And40Percent?.length
        let below20Count = below20Percent?.length

        return {
            totalCount,
            above40count,
            between20and40count,
            below20Count,
        }
    }
    const { totalCount, above40count, between20and40count, below20Count } = categorizeStates(
        rtoDetailsData.getTables?.data,
    )
    const formattedTableData = transformData(rtoDetailsData.getTables?.data, currentFilter)
    const status = rtoDetailsData.getTables.status
    const summaryTempData = useMemo(
        () => [
            {
                title: `All ${currentFilter.toLowerCase() === 'state' ? "states and UT's" : getTitle(currentFilter)}`,
                heroValue: <p style={{ fontSize: '40px', fontWeight: 'bold' }}>{totalCount ? totalCount : 0}</p>,
                heroTextColor: 'red',
                loading: status === 'loading',
            },
            {
                title: 'RTO > 40%',
                heroValue: (
                    <p style={{ color: 'red', fontSize: '40px', fontWeight: 'bold' }}>
                        {above40count ? above40count : 0}
                    </p>
                ),
                loading: status === 'loading',
            },
            {
                title: 'RTO Between 20-40%',
                heroValue: (
                    <p style={{ color: 'orange', fontSize: '40px', fontWeight: 'bold' }}>
                        {between20and40count ? between20and40count : 0}
                    </p>
                ),
                loading: status === 'loading',
            },
            {
                title: 'RTO < 20%',
                heroValue: (
                    <p style={{ color: 'green', fontSize: '40px', fontWeight: 'bold' }}>
                        {below20Count ? below20Count : 0}
                    </p>
                ),
                loading: status === 'loading',
            },
        ],
        [rtoDetailsData],
    )
    return (
        <div className='main-div'>
            <div className='tile-metrics'>
                <div style={{ marginBottom: '-20px', marginLeft: '7px' }}>
                    <Tabs onTabClick={onClick} defaultActiveKey={current} items={rtoDetailsFilterMenu} />
                </div>

                <div
                    className='card-text'
                    style={{
                        margin: '20px',
                    }}
                >
                    <p style={{ fontSize: '15px' }}>
                        {React.createElement(TagOutlined)} All {current.toLowerCase()} wise RTO performance{' '}
                        {
                            <Tooltip
                                title={`Performance of all the serviceable ${getTitle(
                                    currentTitle.toLowerCase(),
                                )} in terms of RTO`}
                            >
                                <QuestionCircleOutlined/>
                            </Tooltip>
                        }
                    </p>
                </div>
                <div className='rto-tables-text'>
                    <span>
                        At a glance information about all {getTitle(currentTitle.toLowerCase())} comes here in 2 lines
                    </span>
                </div>
                <div
                    style={{
                        width: '100%',
                        marginBottom: '15px',
                        padding: '0 7px 0 7px',
                    }}
                >
                    <SummaryBlocks data={summaryTempData} />
                </div>
                <div style={{ margin: '7px' }}>
                    {formattedTableData?.length ? (
                        <RtoDetailsTable
                            key={formattedTableData.length}
                            formattedTableData={formattedTableData}
                            filterType={currentFilter}
                            selectedFilter={selectedFilter}
                            setSelectedFilter={setSelectedFilter}
                        />
                    ) : (
                        <RtoDetailsTable
                            formattedTableData={[]}
                            filterType={currentFilter}
                            selectedFilter={selectedFilter}
                            setSelectedFilter={setSelectedFilter}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}

export default RtoDetails
