export const CouponTickIcon = () => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='none'>
            <path
                d='M9 0a2.61 2.61 0 0 0-2.61 2.61v.089l-.063-.063a2.61 2.61 0 1 0-3.691 3.691l.063.063H2.61a2.61 2.61 0 1 0 0 5.22h.089l-.063.063a2.61 2.61 0 0 0 3.691 3.691l.063-.063v.089a2.61 2.61 0 1 0 5.22 0v-.089l.063.063a2.61 2.61 0 0 0 3.691-3.691l-.063-.063h.089a2.61 2.61 0 1 0 0-5.22h-.089l.063-.063a2.61 2.61 0 0 0-3.691-3.691l-.063.063V2.61A2.61 2.61 0 0 0 9 0z'
                fill='#15b79e'
            />
            <g stroke='#fff' stroke-width='2' stroke-linecap='round'>
                <path d='M6.5 11.5l5-5m-.417 4.583l.417.417' />
                <path d='M6.5 6.5l.417.417' />
            </g>
        </svg>
    )
}
