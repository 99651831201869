export const formatAmount = (
    val: number,
    minFractionDigits: number = 0,
    maxFractionDigits: number = 2,
    notation: 'standard' | 'scientific' | 'engineering' | 'compact' = 'standard',
) =>
    val === undefined || typeof val === 'string' || val === null
        ? ''
        : Intl.NumberFormat('en-IN', {
              style: 'currency',
              currency: 'INR',
              notation,
              minimumFractionDigits: minFractionDigits,
              maximumFractionDigits: maxFractionDigits,
          })
              .format(val)
              .replace('T', 'K')