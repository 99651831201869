export const rtoActionsFilterMenu: any[] = [
    {
        label: 'All',
        key: 'all',

    },
    {
        label: 'Recommended',
        key: 'recommended',
    },
]

export const rtoActionsOptionsMenu = [
    'All',
    'Recommended(Beta)',
]