import { Product, Variant } from '@library/utilities/interface'
import { Checkbox, Divider, PictureOutlined } from 'gokwik-ui-kit'
import { FC } from 'react'

interface ProductListProps {
    list: Product[]
    checkedList: Product[]
    checkedVariantsList: Variant[]
    onProductCheckChange: any
    onProductVariantsCheckChange: any
    isVariant: boolean
}

const ProductList: FC<ProductListProps> = ({
    list,
    checkedList = [],
    checkedVariantsList,
    onProductCheckChange,
    onProductVariantsCheckChange,
    isVariant,
}) => {
    const checkIfProductIsChecked = (item: Product): boolean => {
        const isProductSelected = checkedList?.some((i) => i?.product_id === item?.product_id)
        const selectedVariantsCount = checkedVariantsList.filter(
            (variant) => variant?.product_id === item?.product_id,
        )?.length
        return isProductSelected || selectedVariantsCount === item.variants?.length
    }

    const inDeterminateChecked = (item: Product): boolean => {
        const selectedVariantsCount = checkedVariantsList?.filter(
            (variant) => variant?.product_id === item?.product_id,
        )?.length
        return selectedVariantsCount > 0 && selectedVariantsCount < item?.variants?.length
    }

    return (
        <>
            {list?.length === 0 && <div className='flex justify-center items-center p-5'>No Data</div>}

            <ul className='pl-0 list-none scroll-auto max-h-[calc(100vh-400px)] overflow-y-auto'>
                {list?.map((item, index) => (
                    <div key={item?.product_id}>
                        <li className='p-2'>
                            <Checkbox
                                indeterminate={inDeterminateChecked(item)}
                                checked={checkIfProductIsChecked(item)}
                                onChange={(e) => onProductCheckChange(e, item)}
                            >
                                <div className='flex items-center gap-2'>
                                    <div className=' ml-2'>
                                        {item?.variants?.length > 0 && (
                                            <>
                                                {item?.variants[0]?.thumbnail_url ? (
                                                    <div className=' rounded-md border border-solid border-[#E6E6E6]'>
                                                        <img
                                                            src={item?.variants[0]?.thumbnail_url}
                                                            alt='thumbnail'
                                                            width={60}
                                                            height={70}
                                                        />
                                                    </div>
                                                ) : (
                                                    <PictureOutlined className='text-[#004B8D] text-2xl' />
                                                )}
                                            </>
                                        )}
                                    </div>
                                    <span className='text-[#000000E0] text-sm font-normal'>{item?.product_name}</span>
                                </div>
                            </Checkbox>
                        </li>
                        {isVariant && (
                            <>
                                <Divider className='my-3' />
                                {item?.variants?.length > 0 &&
                                    !(item?.variants.length === 1 && item.variants[0]?.name === 'Default Title') && (
                                        <ul className='pl-0 list-none mb-3'>
                                            {item?.variants?.map((subItem, index) => (
                                                <div key={subItem?.variant_id} className='flex items-start py-2'>
                                                    <div className='rounded-md border border-solid border-[#E6E6E6] w-11 h-12'>
                                                        <img
                                                            src={subItem?.thumbnail_url}
                                                            alt={subItem?.name}
                                                            className='w-full h-full'
                                                        />
                                                    </div>
                                                    <div className='flex-1 ml-4'>
                                                        <li className='flex justify-between items-center'>
                                                            <div className='flex items-center gap-1'>
                                                                <Checkbox
                                                                    checked={
                                                                        !!checkedVariantsList?.some((i) => {
                                                                            return i?.variant_id === subItem?.variant_id
                                                                        })
                                                                    }
                                                                    onChange={(e) =>
                                                                        onProductVariantsCheckChange(e, item, subItem)
                                                                    }
                                                                >
                                                                    <span className='text-[#000000E0] text-sm font-semibold'>
                                                                        {subItem?.name}
                                                                    </span>
                                                                </Checkbox>
                                                            </div>
                                                            <div className='text-right'>
                                                                <span className='text-[#000000E0] text-sm font-semibold'>
                                                                    ₹{subItem?.price?.toFixed(2)}
                                                                </span>
                                                            </div>
                                                        </li>
                                                    </div>
                                                </div>
                                            ))}
                                        </ul>
                                    )}
                            </>
                        )}
                    </div>
                ))}
            </ul>
        </>
    )
}

export default ProductList
