import React, { useEffect, useRef, useState } from 'react'
import { Button, CloseOutlined, Divider, FileImageOutlined, FolderFilled, Form, InfoCircleOutlined, Tooltip } from 'gokwik-ui-kit'
import { ProductSearchModal } from './product-search-modal'
import { Condition, Product, Variant, Workflow } from '@library/utilities/interface'
import { workflowKeys, productVariantInfo } from '@library/utilities/constants/constants'

interface ProductTitleSearchProps {
    index: number
    onChangeValues: (values: any[] | boolean, valueType: string) => void
    selectedProductsFromGet: Product[]
    workflowData: Condition
}

function ProductTitleSearch({ index, onChangeValues, selectedProductsFromGet, workflowData }: ProductTitleSearchProps) {
    const isVariant = workflowData?.key === workflowKeys.variantIds
    const [showProductListModal, setShowProductListModal] = useState<boolean>(false)
    const [selectedProducts, setSelectedProducts] = useState<Product[]>(selectedProductsFromGet || [])
    const [selectedProductVariants, setSelectedProductVariants] = useState<Variant[]>([])

    useEffect(() => {
        setSelectedProducts(selectedProductsFromGet)
        setSelectedProductVariants(selectedProductsFromGet?.flatMap(selectedProduct => 
            selectedProduct?.variant_ids.map(variant => ({
                ...variant,
                product_id: selectedProduct.product_id
            }))
        ));
    }, [selectedProductsFromGet])

    const handleBrowseClick = (): void => {
        setShowProductListModal(true)
    }

    const removeProductFromList = (product_id: string): void => {
        setSelectedProducts((prevProducts) => prevProducts?.filter((product) => product?.product_id !== product_id))
        setSelectedProductVariants((prevVariants) =>
            prevVariants?.filter((variant) => variant?.product_id !== product_id),
        )
    }

    useEffect(() => {
        if (selectedProducts?.length) {
            let values: string[] = []
            if (isVariant) {
                selectedProducts.forEach((selectedProduct) => {
                    if (selectedProduct?.variant_ids?.length) {
                        selectedProduct.variant_ids.forEach((variant) => {
                            values.push(variant.variant_id)
                        })
                    }
                })
            } else {
                selectedProducts.forEach((selectedProduct) => {
                    values.push(selectedProduct.product_id)
                })
            }
            onChangeValues(!isVariant, 'is_not_variant')
            onChangeValues(values, 'values')
            onChangeValues(selectedProducts, 'selected_products')
        } else{
            onChangeValues([], 'values')
        }
    }, [selectedProducts])

    const renderModalTitle = (): JSX.Element => {
        return isVariant ? <p>Select Product Variant
                <Tooltip title={productVariantInfo}>
                    <InfoCircleOutlined className='ml-2' />
                </Tooltip>
        </p> : <p>Select Products</p>
    } 

    return (
        <>
            <div>
                <Form.Item
                    name='product_variant'
                    rules={[
                        {
                            required: true,
                            message: `Please Select ${isVariant ? 'Variant' : 'Product'}`,
                            validator: (_, value) =>
                                selectedProducts.length > 0
                                    ? Promise.resolve()
                                    : Promise.reject(new Error(`Please select at least one product ${isVariant && 'variant'}`)),
                        },
                    ]}
                >
                    <Button onClick={handleBrowseClick}>
                        <FolderFilled /> Browse
                    </Button>
                </Form.Item>
                <div className='flex gap-2 flex-wrap mt-2'>
                    {selectedProducts?.map((item, index) => (
                        <div className='border border-solid border-[#00000026] rounded-lg' key={index}>
                            <div className='flex justify-between gap-2 p-1 min-w-max items-center'>
                                <div className='flex gap-1 items-center truncate'>
                                    <span className='bg-[#627F99] rounded-md'>
                                        <FileImageOutlined className='text-sm text-white p-[6px]' />
                                    </span>
                                    <span className='text-sm font-semibold truncate'>{item?.product_name}</span>
                                </div>

                                <div
                                    className={'cursor-pointer'}
                                    onClick={() => removeProductFromList(item?.product_id)}
                                >
                                    <CloseOutlined className='text-[#00000073]' />
                                </div>
                            </div>

                            {isVariant && (
                                <div>
                                    {item?.variant_ids?.length > 0 ? (
                                        <ul className='list-none m-0 p-0'>
                                            {item.variant_ids.map((variant, vIndex) => (
                                                <li
                                                    key={vIndex}
                                                    className='py-1 text-sm text-gray-500 flex items-center ml-1'
                                                >
                                                    <span className='bg-gray-200 rounded-full px-2 py-1 text-xs mr-1'>
                                                        {vIndex + 1}
                                                    </span>
                                                    {variant?.variant_name}
                                                </li>
                                            ))}
                                        </ul>
                                    ) : (
                                        <p className='text-sm text-gray-500 mr-1 ml-1'>All variants selected</p>
                                    )}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
            {showProductListModal && (
                <ProductSearchModal
                    showProductListModal={showProductListModal}
                    setShowProductListModal={setShowProductListModal}
                    selectedProducts={selectedProducts}
                    selectedProductVariants={selectedProductVariants}
                    setSelectedProducts={setSelectedProducts}
                    setSelectedProductVariants={setSelectedProductVariants}
                    title={renderModalTitle()}
                    isVariant={isVariant}
                />
            )}
        </>
    )
}

export default ProductTitleSearch
