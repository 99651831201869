export const NotificationBar = () => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='320' height='24' fill='none'>
            <rect x='16' y='2' width='20' height='20' rx='10' fill='#eaecf0' />
            <rect x='50' width='184' height='24' rx='12' fill='#000' fill-opacity='.05' />
            <g fill='#d0d5dd'>
                <path d='M72.891 10.438h-.328V9.125A2.63 2.63 0 0 0 69.938 6.5a2.63 2.63 0 0 0-2.625 2.625v1.312h-.328c-.542 0-.984.441-.984.984v4.594c0 .543.442.984.984.984h5.906c.542 0 .984-.441.984-.984v-4.594c0-.543-.442-.984-.984-.984zm-4.703-1.312a1.75 1.75 0 1 1 3.5 0v1.312h-3.5V9.125z' />
                <rect x='85' y='8' width='135' height='8' rx='4' />
            </g>
            <g fill='#eaecf0'>
                <rect x='250' y='2' width='20' height='20' rx='10' />
                <rect x='284' y='2' width='20' height='20' rx='10' />
            </g>
        </svg>
    )
}
