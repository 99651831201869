import React, { useEffect } from 'react'
import { Form, InputNumber } from 'gokwik-ui-kit'
import { inputNumberFormatter, inputNumberParser, isValidNumber } from '@library/utilities/helpers/helper'
import { ActionType } from '@library/utilities/interface'
import { getAbTestSettings } from '@store/actions/selectors'
import { useSelector } from 'react-redux'

interface UpiDiscountInputProps {
    actionType: ActionType
    setActionType: React.Dispatch<React.SetStateAction<ActionType>>
    formText?: string
    primary?: boolean
    isActionDisabled: boolean
}

const UpiDiscountInput: React.FC<UpiDiscountInputProps> = ({ actionType, setActionType, formText, primary, isActionDisabled }) => {
    const form = Form.useFormInstance()
    const handleKeyDown = (e) => {
        const { key, target } = e
        if (key === '.' || key === 'e') {
            e.preventDefault()
            return
        }
        if (key === '0' && target.value === '') {
            e.preventDefault()
            return
        }
    }

    const handleChange = (val: number) => {
        let changedVal
        if (val) {
            changedVal = { ...actionType, discount_upto: val && Number(val) }
        } else {
            const { discount_upto, ...rest } = actionType
            changedVal = rest
        }
        setActionType(changedVal)
    }

    const generateFormName = (suffix: string) => `upi-discount-${formText}-${primary ? 'primary' : 'secondary'}-${suffix}`;

    const formName = generateFormName('');
    const formNameUpto = generateFormName('upto');

    useEffect(() => {
        const values = {
            [formName]: actionType?.upi_discount,
            [formNameUpto]: actionType?.discount_upto,
        };

        form.setFieldsValue(values);
    }, [formName, formNameUpto, form, actionType?.discount_upto, actionType?.upi_discount]);

    return (
        <>
            <Form.Item
                name={formName}
                initialValue={actionType?.upi_discount}
                rules={[
                    {
                        validator: (_, value) => {
                            if (!isValidNumber(value)) {
                                return Promise.reject(new Error(''))
                            }
                            if (value <= 0 || value > 100) {
                                return Promise.reject(new Error('Please enter values between 1 to 100'))
                            }
                            return Promise.resolve()
                        },
                    },
                ]}
            >
                <InputNumber
                    className='w-80 mt-5'
                    value={actionType?.upi_discount || ''}
                    placeholder='UPI Discount'
                    onChange={(val) => setActionType({ ...actionType, upi_discount: val && Number(val) })}
                    addonAfter='%'
                    step={1}
                    onKeyDown={handleKeyDown}
                    formatter={inputNumberFormatter}
                    parser={inputNumberParser}
                    disabled={isActionDisabled}
                />
            </Form.Item>

            <p className='mb-2.5'>Discount Upto</p>
            <Form.Item
                className='mb-0 mt-2.5'
                name={formNameUpto}
                rules={[
                    {
                        validator: (_, value) => {
                            if (value && value <= 0) {
                                return Promise.reject(new Error('Please enter positive values!'))
                            }
                            return Promise.resolve()
                        },
                    },
                ]}
                initialValue={actionType?.discount_upto}
            >
                <InputNumber
                    className='!w-full'
                    value={actionType?.discount_upto || ''}
                    placeholder='Discount Upto...'
                    onChange={handleChange}
                    step={1}
                    addonBefore='₹'
                    formatter={inputNumberFormatter}
                    parser={inputNumberParser}
                    disabled={isActionDisabled}
                />
            </Form.Item>
        </>
    )
}

export default UpiDiscountInput
