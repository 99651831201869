export const CODIcon = () => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='18' height='12' fill='none'>
            <path
                d='M17.1 4.214a4 4 0 0 0-4-4H4.9a4 4 0 0 0-4 4v3.571a4 4 0 0 0 4 4h8.2a4 4 0 0 0 4-4V4.214zm-5.427-.529h-1.516c.203.289.347.619.422.978h1.099v1.157h-1.099c-.197.891-.822 1.62-1.649 1.95l2.28 2.28H9.573L6.321 6.798h1.614c.654 0 1.209-.405 1.441-.978H6.564V4.664h2.812c-.231-.573-.787-.978-1.441-.978H6.564V2.529h5.115v1.157h-.006z'
                fill='#00192f'
            />
        </svg>
    )
}
