import React from 'react'

interface BrainIconProps {
    active: boolean
    className?: string
}

const BrainIcon: React.FC<BrainIconProps> = ({ active, className = '' }) => {
    return (
        <svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
            <g id='brain' clip-path='url(#clip0_320_10272)'>
                <path
                    id='Vector'
                    d='M15.8 13C14.8718 13 13.9816 13.3687 13.3252 14.0251C12.6688 14.6815 12.3 15.5717 12.3 16.5V17.5C12.3 18.4283 12.6688 19.3185 13.3252 19.9749C13.9816 20.6313 14.8718 21 15.8 21C16.7283 21 17.6185 20.6313 18.2749 19.9749C18.9313 19.3185 19.3 18.4283 19.3 17.5V15.7'
                    stroke={active ? 'white' : '#5C5F62'}
                    stroke-width='2'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                />
                <path
                    id='Vector_2'
                    d='M8.80005 13C9.72831 13 10.6185 13.3687 11.2749 14.0251C11.9313 14.6815 12.3 15.5717 12.3 16.5V17.5C12.3 18.4283 11.9313 19.3185 11.2749 19.9749C10.6185 20.6313 9.72831 21 8.80005 21C7.87179 21 6.98155 20.6313 6.32518 19.9749C5.6688 19.3185 5.30005 18.4283 5.30005 17.5V15.7'
                    stroke={active ? 'white' : '#5C5F62'}
                    stroke-width='2'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                />
                <path
                    id='Vector_3'
                    d='M17.8 16C18.7283 16 19.6185 15.6313 20.2749 14.9749C20.9313 14.3185 21.3 13.4283 21.3 12.5C21.3 11.5717 20.9313 10.6815 20.2749 10.0251C19.6185 9.36875 18.7283 9 17.8 9H17.3'
                    stroke={active ? 'white' : '#5C5F62'}
                    stroke-width='2'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                />
                <path
                    id='Vector_4'
                    d='M19.3 9.3V6.5C19.3 5.57174 18.9313 4.6815 18.2749 4.02513C17.6185 3.36875 16.7283 3 15.8 3C14.8718 3 13.9816 3.36875 13.3252 4.02513C12.6688 4.6815 12.3 5.57174 12.3 6.5'
                    stroke={active ? 'white' : '#5C5F62'}
                    stroke-width='2'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                />
                <path
                    id='Vector_5'
                    d='M6.80005 16C5.87179 16 4.98155 15.6313 4.32518 14.9749C3.6688 14.3185 3.30005 13.4283 3.30005 12.5C3.30005 11.5717 3.6688 10.6815 4.32518 10.0251C4.98155 9.36875 5.87179 9 6.80005 9H7.30005'
                    stroke={active ? 'white' : '#5C5F62'}
                    stroke-width='2'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                />
                <path
                    id='Vector_6'
                    d='M5.30005 9.3V6.5C5.30005 5.57174 5.6688 4.6815 6.32518 4.02513C6.98155 3.36875 7.87179 3 8.80005 3C9.72831 3 10.6185 3.36875 11.2749 4.02513C11.9313 4.6815 12.3 5.57174 12.3 6.5V16.5'
                    stroke={active ? 'white' : '#5C5F62'}
                    stroke-width='2'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                />
            </g>
            <defs>
                <clipPath id='clip0_320_10272'>
                    <rect width='24' height='24' fill='white' transform='translate(0.300049)' />
                </clipPath>
            </defs>
        </svg>
    )
}

export default BrainIcon
