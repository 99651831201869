import { setModalStates } from '@store/actions';
import { getModalStates } from '@store/actions/selectors';
import { Tour } from 'gokwik-ui-kit'
import { useDispatch, useSelector } from 'react-redux';

type PlacementType = 'top' | 'bottom' | 'left' | 'right';

function RenderTour({ tourRefs, tourVisible, setTourVisible }) {

    const modalStates = useSelector(getModalStates);
    const dispatch = useDispatch()
    const RenderTitle = ({ heading, subHeading }) => {
        return (
            <div>
                <p className='text-xl font-semibold'>{heading}</p>
                <br/>
                <p className='text-base font-normal'>{subHeading}</p>
            </div>
        )
    }

    const steps = [
        {
            title: <RenderTitle 
                    heading="Enter a Workflow Name" 
                    subHeading={'Give your Workflow a name'} />,
            description:
                "This will help you easily identify it later. You can rename the workflow anytime.",
            target: () => tourRefs.current[0].current!,
        },
        {
            title: <RenderTitle 
                    heading="Select a Workflow Category" 
                    subHeading={'Choose the general type of workflow'} />,
            description: 'This will help narrow down your options.',
            target: () => tourRefs.current[1].current!,
        },
        {
            title: <RenderTitle 
                    heading="Select a Workflow Sub Category" 
                    subHeading={'Pinpoint the specific nature of your workflow'} />,
            description: 'Get even more specific about which segment you want to target.',
            target: () => tourRefs.current[2].current!,
        },
        {
            title: <RenderTitle 
                    heading="Select up to 1 or more conditions" 
                    subHeading={'Define the criteria for your workflow to trigger'} />,
            description: 'Determine what needs to happen before your workflow starts.',
            target: () => tourRefs.current[3].current!,
        },
        {
            title: <RenderTitle 
                    heading="Determine one or two actions to perform" 
                    subHeading={'Decide what you want your workflow to do'} />,
            description: 'Select the COD intervention you want to apply on the segment selected.',
            target: () => tourRefs.current[4].current!,
            placement: "left" as PlacementType,
        },
    ]

    const handleFinishClick = async () => {
        setTourVisible(false)
    }

    const handleCloseClick = async () => {
        setTourVisible(false)
        dispatch(setModalStates({...modalStates, tourModalVisible: true}))
    }

    return tourVisible ? (
        <Tour
            open={tourVisible}
            disabledInteraction={true}
            onClose={handleCloseClick}
            steps={steps.map((step, index) => ({
                ...step,
                onFinish: index === steps.length - 1 ? handleFinishClick : undefined,
            }))}
            indicatorsRender={(current, total) => (
                <span>
                    {current + 1} / {total}
                </span>
            )}
        />
    ) : null
}

export default RenderTour
