export const CellularIcon = () => {
    return (
        <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g clip-path='url(#clip0_7017_79139)'>
                <path d='M0.875 12.5417H12.5417V0.875L0.875 12.5417Z' fill='white' />
            </g>
            <defs>
                <clipPath id='clip0_7017_79139'>
                    <rect width='14' height='14' fill='white' />
                </clipPath>
            </defs>
        </svg>
    )
}
