import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { convertUtcToIst } from './helper';

dayjs.extend(utc);
dayjs.extend(timezone);
interface OriginalDataItem {
    date: string;
    hour: string;
    risk_flag: string;
    payment_method: 'COD' | 'PREPAID';
    rto_orders: number;
    delivered_orders: number;
    cancelled_orders: number;
    pending_orders: number;
    null_status_orders: number;
    rid_hits: number;
    total_orders: number;
}

interface WeeklyMetrics {
    date: string;
    total_orders: number;
    hits: number;
    cod_orders: number;
    prepaid_orders: number;
    rto_cod_orders: number;
    pending_cod_orders: number;
    delivered_cod_orders: number;
    rto_overall_orders: number;
    awb_fill_rate: number;
    delivered_orders: number;
    cancelled_orders: number;
    pending_orders: number;
    cod_rto_perc?: string; // Optional to handle division by zero
    rto_overall_perc?: string;
    prepaid_share?: string;
    awb_fill_rate_perc?: string;
    delivered_orders_perc?: string;
    cancelled_orders_perc?: string;
}

interface QuarterlyMetrics {
    date: string; // Use the start date of the quarter
    total_orders: number;
    hits: number;
    cod_orders: number;
    prepaid_orders: number;
    rto_cod_orders: number;
    pending_cod_orders: number;
    delivered_cod_orders: number;
    rto_overall_orders: number;
    awb_fill_rate: number;
    delivered_orders: number;
    cancelled_orders: number;
    pending_orders: number;
    cod_rto_perc?: string; // Optional to handle division by zero
    rto_overall_perc?: string;
    prepaid_share?: string;
    awb_fill_rate_perc?: string;
    delivered_orders_perc?: string;
    cancelled_orders_perc?: string;
}

interface MonthlyMetrics {
    date: string;
    total_orders: number;
    hits: number;
    cod_orders: number;
    prepaid_orders: number;
    rto_cod_orders: number;
    pending_cod_orders: number;
    delivered_cod_orders: number;
    rto_overall_orders: number;
    awb_fill_rate: number;
    delivered_orders: number;
    cancelled_orders: number;
    pending_orders: number;
    cod_rto_perc?: string;
    rto_overall_perc?: string;
    prepaid_share?: string;
    awb_fill_rate_perc?: string;
    delivered_orders_perc?: string;
    cancelled_orders_perc?: string;
}


export const updateDataBasedOnFilter = (originalData, filterValue) => {
    if (filterValue === 'daily') {
        return originalData
    } else if (filterValue === 'weekly') {
        // If weekly filter is selected, combine data for every 7 days
        const updatedData = []
        for (let i = 0; i < originalData?.length; i += 7) {
            const chunk = originalData.slice(i, i + 7)
            const startDate = chunk[0].date
            const endDate = chunk[chunk.length - 1].date
            const combinedData = {
                date: startDate,
                // Aggregate values for the week
                total_orders: chunk.reduce((total, item) => total + item.total_orders, 0),
                cod_orders: chunk.reduce((total, item) => total + item.cod_orders, 0),
                prepaid_orders: chunk.reduce((total, item) => total + item.prepaid_orders, 0),
                overall_rto_orders: chunk.reduce((total, item) => total + item.overall_rto_orders, 0),
                cod_rto_orders: chunk.reduce((total, item) => total + item.cod_rto_orders, 0),
                prepaid_rto_orders: chunk.reduce((total, item) => total + item.prepaid_rto_orders, 0),
                overall_rto_rate: (
                    (chunk.reduce((total, item) => total + item.overall_rto_orders, 0) /
                        chunk.reduce((total, item) => total + item.total_orders, 0)) *
                    100
                ).toFixed(2),
                cod_rto_rate: (
                    (chunk.reduce((total, item) => total + item.cod_rto_orders, 0) /
                        chunk.reduce((total, item) => total + item.total_orders, 0)) *
                    100
                ).toFixed(2),
                prepaid_rto_rate: (
                    (chunk.reduce((total, item) => total + item.prepaid_rto_orders, 0) /
                        chunk.reduce((total, item) => total + item.total_orders, 0)) *
                    100
                ).toFixed(2),
            };
            updatedData.push(combinedData);
        }
        return updatedData
    } else if (filterValue === 'monthly') {
        // If monthly filter is selected, separate out data for specific months and combine it
        const updatedData = {}
        originalData?.forEach((item) => {
            const monthYear = item.date.substring(0, 7) // Extracting month and year
            if (!updatedData[monthYear]) {
                updatedData[monthYear] = { ...item } // Initialize with the first occurrence of the month
            } else {
                // Combine data for the same month
                updatedData[monthYear].total_orders += item.total_orders
                updatedData[monthYear].cod_orders += item.cod_orders
                updatedData[monthYear].prepaid_orders += item.prepaid_orders
                updatedData[monthYear].overall_rto_orders += item.overall_rto_orders
                updatedData[monthYear].cod_rto_orders += item.cod_rto_orders
                updatedData[monthYear].prepaid_rto_orders += item.prepaid_rto_orders
                // Calculate overall_rto_rate, cod_rto_rate, and prepaid_rto_rate
                updatedData[monthYear].overall_rto_rate = (
                    (updatedData[monthYear].overall_rto_orders / updatedData[monthYear].total_orders) *
                    100
                ).toFixed(2);
                updatedData[monthYear].cod_rto_rate = (
                    (updatedData[monthYear].cod_rto_orders / updatedData[monthYear].total_orders) *
                    100
                ).toFixed(2);
                updatedData[monthYear].prepaid_rto_rate = (
                    (updatedData[monthYear].prepaid_rto_orders / updatedData[monthYear].total_orders) *
                    100
                ).toFixed(2);
            }
        })
        return Object.values(updatedData)
    } else {
        return originalData // Handle other filter options as needed
    }
}

export const updateDataBasedOnTimeframe = (
    originalData: OriginalDataItem[],
    timeframe: string
): WeeklyMetrics[] => {
    if (timeframe === 'weekly') {
        const updatedData: { [key: string]: WeeklyMetrics } = {};
        
        originalData?.forEach(item => {
            const weekStart = dayjs(item.date).startOf('week').toISOString();

            if (!updatedData[weekStart]) {
                updatedData[weekStart] = {
                    date: weekStart,
                    total_orders: 0,
                    hits: 0,
                    cod_orders: 0,
                    prepaid_orders: 0,
                    rto_cod_orders: 0,
                    pending_cod_orders: 0,
                    delivered_cod_orders: 0,
                    rto_overall_orders: 0,
                    awb_fill_rate: 0,
                    delivered_orders: 0,
                    cancelled_orders: 0,
                    pending_orders: 0,
                };
            }

            updatedData[weekStart].total_orders += item.total_orders;
            updatedData[weekStart].hits += item.rid_hits;
            updatedData[weekStart].delivered_orders += item.delivered_orders;
            updatedData[weekStart].pending_orders += item.pending_orders;
            updatedData[weekStart].cancelled_orders += item.cancelled_orders;
            updatedData[weekStart].awb_fill_rate += item.pending_orders + item.null_status_orders;

            if (item.payment_method === 'COD') {
                updatedData[weekStart].cod_orders += item.total_orders;
                updatedData[weekStart].rto_cod_orders += item.rto_orders;
                updatedData[weekStart].pending_cod_orders += item.pending_orders;
                updatedData[weekStart].delivered_cod_orders += item.delivered_orders;
                
            } else if (item.payment_method === 'PREPAID') {
                updatedData[weekStart].prepaid_orders += item.total_orders;
            }

            updatedData[weekStart].rto_overall_orders += item.rto_orders;
        });

        return Object.values(updatedData)?.map(weekData => {
            const totalOrders = weekData.total_orders;
            const rtoRateOrders = weekData.pending_orders + weekData.delivered_orders + weekData.rto_overall_orders;
            const codRtoRateOrders = weekData.rto_cod_orders + weekData.delivered_cod_orders + weekData.pending_cod_orders;
            return {
                ...weekData,
                cod_rto_perc: totalOrders > 0 ? ((weekData.rto_cod_orders / codRtoRateOrders) * 100).toFixed(1) : 'NA',
                rto_overall_perc: totalOrders > 0 ? ((weekData.rto_overall_orders / rtoRateOrders) * 100).toFixed(1) : 'NA',
                prepaid_share: totalOrders > 0 ? ((weekData.prepaid_orders / totalOrders) * 100).toFixed(1) : 'NA',
                awb_fill_rate_perc: totalOrders > 0 ? (100 - ((weekData.awb_fill_rate / totalOrders) * 100)).toFixed(1) : 'NA',
                delivered_orders_perc: totalOrders > 0 ? ((weekData.delivered_orders / totalOrders) * 100).toFixed(1) : 'NA',
                cancelled_orders_perc: totalOrders > 0 ? ((weekData.cancelled_orders / totalOrders) * 100).toFixed(1) : 'NA',
            };
        });
    } else if (timeframe === 'monthly') {
        const updatedData: { [key: string]: MonthlyMetrics } = {};

        originalData?.forEach(item => {
            const monthYear = dayjs(item.date).format('YYYY-MM');

            if (!updatedData[monthYear]) {
                updatedData[monthYear] = {
                    date: monthYear + '-01T00:00:00.000Z', 
                    total_orders: 0,
                    hits: 0,
                    cod_orders: 0,
                    prepaid_orders: 0,
                    rto_cod_orders: 0,
                    pending_cod_orders: 0,
                    delivered_cod_orders: 0,
                    rto_overall_orders: 0,
                    awb_fill_rate: 0,
                    delivered_orders: 0,
                    cancelled_orders: 0,
                    pending_orders: 0,
                };
            }

            updatedData[monthYear].total_orders += item.total_orders;
            updatedData[monthYear].hits += item.rid_hits;
            updatedData[monthYear].delivered_orders += item.delivered_orders;
            updatedData[monthYear].cancelled_orders += item.cancelled_orders;
            updatedData[monthYear].awb_fill_rate += item.pending_orders + item.null_status_orders;
            updatedData[monthYear].pending_orders += item.pending_orders;

            if (item.payment_method === 'COD') {
                updatedData[monthYear].cod_orders += item.total_orders;
                updatedData[monthYear].rto_cod_orders += item.rto_orders;
                updatedData[monthYear].pending_cod_orders += item.pending_orders;
                updatedData[monthYear].delivered_cod_orders += item.delivered_orders;
            } else if (item.payment_method === 'PREPAID') {
                updatedData[monthYear].prepaid_orders += item.total_orders;
            }

            updatedData[monthYear].rto_overall_orders += item.rto_orders;
        });

        return Object.values(updatedData).map(monthData => {
            const totalOrders = monthData.total_orders;
            const rtoRateOrders = monthData.pending_orders + monthData.delivered_orders + monthData.rto_overall_orders;
            const codRtoRateOrders = monthData.rto_cod_orders + monthData.delivered_cod_orders + monthData.pending_cod_orders;
            return {
                ...monthData,
                cod_rto_perc: totalOrders > 0 ? ((monthData.rto_cod_orders / codRtoRateOrders) * 100).toFixed(1) : 'NA',
                rto_overall_perc: totalOrders > 0 ? ((monthData.rto_overall_orders / rtoRateOrders) * 100).toFixed(1) : 'NA',
                prepaid_share: totalOrders > 0 ? ((monthData.prepaid_orders / totalOrders) * 100).toFixed(1) : 'NA',
                awb_fill_rate_perc: totalOrders > 0 ? (100 - ((monthData.awb_fill_rate / totalOrders) * 100)).toFixed(1) : 'NA',
                delivered_orders_perc: totalOrders > 0 ? ((monthData.delivered_orders / totalOrders) * 100).toFixed(1) : 'NA',
                cancelled_orders_perc: totalOrders > 0 ? ((monthData.cancelled_orders / totalOrders) * 100).toFixed(1) : 'NA',
            };
        });
    } 
    else if (timeframe === 'quarterly') {
        const updatedData: { [key: string]: QuarterlyMetrics } = {};
    
    originalData?.forEach(item => {
        const month = dayjs(item.date).month();
        const year = dayjs(item.date).year();

        // Determine the quarter start month
        let quarterStartMonth;
        if (month >= 0 && month <= 2) { // Jan, Feb, Mar
            quarterStartMonth = 0; // January
        } else if (month >= 3 && month <= 5) { // Apr, May, Jun
            quarterStartMonth = 3; // April
        } else if (month >= 6 && month <= 8) { // Jul, Aug, Sep
            quarterStartMonth = 6; // July
        } else { // Oct, Nov, Dec
            quarterStartMonth = 9; // October
        }

        const quarterKey = `${year}-Q${Math.floor(month / 3) + 1}`;

        if (!updatedData[quarterKey]) {
            updatedData[quarterKey] = {
                date: convertUtcToIst(dayjs(`${year}-${quarterStartMonth + 1}-01`).toISOString()),
                total_orders: 0,
                hits: 0,
                cod_orders: 0,
                prepaid_orders: 0,
                rto_cod_orders: 0,
                delivered_cod_orders: 0,
                pending_cod_orders: 0,
                rto_overall_orders: 0,
                awb_fill_rate: 0,
                delivered_orders: 0,
                cancelled_orders: 0,
                pending_orders: 0
            };
        }

        updatedData[quarterKey].total_orders += item.total_orders;
        updatedData[quarterKey].hits += item.rid_hits;
        updatedData[quarterKey].delivered_orders += item.delivered_orders;
        updatedData[quarterKey].pending_orders += item.pending_orders;
        updatedData[quarterKey].cancelled_orders += item.cancelled_orders;
        updatedData[quarterKey].awb_fill_rate += item.pending_orders + item.null_status_orders;

        if (item.payment_method === 'COD') {
            updatedData[quarterKey].cod_orders += item.total_orders;
            updatedData[quarterKey].rto_cod_orders += item.rto_orders;
            updatedData[quarterKey].pending_cod_orders += item.pending_orders;
            updatedData[quarterKey].delivered_cod_orders += item.delivered_orders;
        } else if (item.payment_method === 'PREPAID') {
            updatedData[quarterKey].prepaid_orders += item.total_orders;
        }

        updatedData[quarterKey].rto_overall_orders += item.rto_orders;
    });

    return Object.values(updatedData)?.map(quarterData => {
        const totalOrders = quarterData.total_orders;
        const rtoRateOrders = quarterData.pending_orders + quarterData.delivered_orders + quarterData.rto_overall_orders;
        const codRtoRateOrders = quarterData.rto_cod_orders + quarterData.delivered_cod_orders + quarterData.pending_cod_orders;
        return {
            ...quarterData,
            cod_rto_perc: totalOrders > 0 ? ((quarterData.rto_cod_orders / codRtoRateOrders) * 100).toFixed(1) : 'NA',
            rto_overall_perc: totalOrders > 0 ? ((quarterData.rto_overall_orders / rtoRateOrders) * 100).toFixed(1) : 'NA',
            prepaid_share: totalOrders > 0 ? ((quarterData.prepaid_orders / totalOrders) * 100).toFixed(1) : 'NA',
            awb_fill_rate_perc: totalOrders > 0 ? (100 - ((quarterData.awb_fill_rate / totalOrders) * 100)).toFixed(1) : 'NA',
            delivered_orders_perc: totalOrders > 0 ? ((quarterData.delivered_orders / totalOrders) * 100).toFixed(1) : 'NA',
            cancelled_orders_perc: totalOrders > 0 ? ((quarterData.cancelled_orders / totalOrders) * 100).toFixed(1) : 'NA',
        };
    });
    } else {
        return originalData?.map(item => ({
            date: item.date,
            total_orders: item.total_orders,
            hits: item.rid_hits,
            cod_orders: item.payment_method === 'COD' ? item.total_orders : 0,
            prepaid_orders: item.payment_method === 'PREPAID' ? item.total_orders : 0,
            rto_cod_orders: item.payment_method === 'COD' ? item.rto_orders : 0,
            rto_overall_orders: item.rto_orders,
            awb_fill_rate: item.pending_orders + item.null_status_orders,
            delivered_orders: item.delivered_orders,
            cancelled_orders: item.cancelled_orders,

            cod_rto_perc: item.payment_method === 'COD' && item.total_orders > 0 ? ((item.rto_orders / item.total_orders) * 100).toFixed(1) : 'NA',
            rto_overall_perc: item.total_orders > 0 ? ((item.rto_orders / item.total_orders) * 100).toFixed(1) : 'NA',
            prepaid_share: item.payment_method === 'PREPAID' && item.total_orders > 0 ? ((item.total_orders / item.total_orders) * 100).toFixed(1) : 'NA',
            awb_fill_rate_perc: item.total_orders > 0 ? (100 - (((item.pending_orders + item.null_status_orders) / item.total_orders) * 100)).toFixed(1) : 'NA',
            delivered_orders_perc: item.total_orders > 0 ? ((item.delivered_orders / item.total_orders) * 100).toFixed(1) : 'NA',
            cancelled_orders_perc: item.total_orders > 0 ? ((item.cancelled_orders / item.total_orders) * 100).toFixed(1) : 'NA',
        })) as WeeklyMetrics[];
    }
};

export const updateDataBasedOnTimeframeRiskFlag = (
    originalData: OriginalDataItem[],
    timeframe: 'weekly' | 'monthly' | 'quarterly'
): (WeeklyMetrics | MonthlyMetrics | QuarterlyMetrics)[] => {

    const segregateByRiskFlag = (data: OriginalDataItem[]) => {
        const riskFlagData = {
            'HIGH RISK': [] as OriginalDataItem[],
            'MEDIUM RISK': [] as OriginalDataItem[],
            'LOW RISK': [] as OriginalDataItem[],
        };

        data?.forEach(item => {
            riskFlagData[item.risk_flag]?.push(item);
        });

        return riskFlagData;
    };

    const calculateMetrics = (
        data: OriginalDataItem[], 
        timeframe: 'weekly' | 'monthly' | 'quarterly'
    ): (WeeklyMetrics | MonthlyMetrics | QuarterlyMetrics)[] => {
        let updatedData: { [key: string]: any } = {};

        data?.forEach(item => {
            const key = timeframe === 'weekly' 
                ? dayjs(item.date).startOf('week').toISOString()
                : timeframe === 'monthly'
                ? dayjs(item.date).format('YYYY-MM')
                : `Q${Math.floor(dayjs(item.date).month() / 3) + 1}-${dayjs(item.date).year()}`;

            if (!updatedData[key]) {
                updatedData[key] = {
                    date: timeframe === 'weekly'
                        ? dayjs(item.date).startOf('week').toISOString()
                        : timeframe === 'monthly'
                        ? dayjs(item.date).format('YYYY-MM') + '-01T00:00:00.000Z'
                        : convertUtcToIst(dayjs(item.date).toISOString()),
                    total_orders: 0,
                    hits: 0,
                    cod_orders: 0,
                    prepaid_orders: 0,
                    rto_cod_orders: 0,
                    pending_cod_orders: 0,
                    delivered_cod_orders: 0,
                    rto_overall_orders: 0,
                    awb_fill_rate: 0,
                    delivered_orders: 0,
                    cancelled_orders: 0,
                    pending_orders: 0,
                    risk_flag: item.risk_flag
                };
            }

            updatedData[key].total_orders += item.total_orders;
            updatedData[key].hits += item.rid_hits;
            updatedData[key].delivered_orders += item.delivered_orders;
            updatedData[key].cancelled_orders += item.cancelled_orders;
            updatedData[key].pending_orders += item.pending_orders;
            updatedData[key].awb_fill_rate += item.pending_orders + item.null_status_orders;

            if (item.payment_method === 'COD') {
                updatedData[key].cod_orders += item.total_orders;
                updatedData[key].rto_cod_orders += item.rto_orders;
                updatedData[key].pending_cod_orders += item.pending_orders;
                updatedData[key].delivered_cod_orders += item.delivered_orders;
            } else if (item.payment_method === 'PREPAID') {
                updatedData[key].prepaid_orders += item.total_orders;
            }

            updatedData[key].rto_overall_orders += item.rto_orders;
        });

        return Object.values(updatedData)?.map(entry => {
            const totalOrders = entry.total_orders;
            const rtoRateOrders = entry.pending_orders + entry.delivered_orders + entry.rto_overall_orders;
            const codRtoRateOrders = entry.rto_cod_orders + entry.delivered_cod_orders + entry.pending_cod_orders;
            return {
                ...entry,
                cod_rto_perc: totalOrders > 0 ? ((entry.rto_cod_orders / codRtoRateOrders) * 100).toFixed(1) : 'NA',
                rto_overall_perc: totalOrders > 0 ? ((entry.rto_overall_orders / rtoRateOrders) * 100).toFixed(1) : 'NA',
                prepaid_share: totalOrders > 0 ? ((entry.prepaid_orders / totalOrders) * 100).toFixed(1) : 'NA',
                awb_fill_rate_perc: totalOrders > 0 ? (100 - ((entry.awb_fill_rate / totalOrders) * 100)).toFixed(1) : 'NA',
                delivered_orders_perc: totalOrders > 0 ? ((entry.delivered_orders / totalOrders) * 100).toFixed(1) : 'NA',
                cancelled_orders_perc: totalOrders > 0 ? ((entry.cancelled_orders / totalOrders) * 100).toFixed(1) : 'NA',
            };
        });
    };

    const segregatedData = segregateByRiskFlag(originalData);

    const highRiskMetrics = calculateMetrics(segregatedData['HIGH RISK'], timeframe);
    const mediumRiskMetrics = calculateMetrics(segregatedData['MEDIUM RISK'], timeframe);
    const lowRiskMetrics = calculateMetrics(segregatedData['LOW RISK'], timeframe);

    return [...highRiskMetrics, ...mediumRiskMetrics, ...lowRiskMetrics];
};
            