import { awbAwaitedText, defaultWorkflowImpactData, impactViewMapping } from '@library/utilities/constants/constants'
import { getAbTestImpactInfo, getActionNameByWorkflowResponse, getAwaitedText } from '@library/utilities/helpers/helper'
import { Workflow } from '@library/utilities/interface'
import { getWorkflowImpactMetrics } from '@store/actions/api'
import { getMerchantDetails } from '@store/user/selectors'
import { Alert, Card, InfoCircleOutlined, message, Tooltip } from 'gokwik-ui-kit'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

interface ImpactViewProps {
    workflow: Workflow
}

interface ImpactData {
    rule_ids: string[]
    merchant_id: number
    total_carts: number
    total_orders: number
    total_cod_orders: number
    total_rto_orders: number
    total_delivered_orders: number
    total_missing_orders: number
    awb_fill_rate: number
    awb_missing_perc: number
    total_cancelled_orders: number
    cod_rto_orders: number
    total_prepaid_orders: number
    cod_share: number
    conversion_rate: number
    rto_rate: number
    cod_rto_rate: number
    created_at: string
}

const WorkflowImpactView = ({ workflow }: ImpactViewProps) => {
    const merchantDetails = useSelector(getMerchantDetails)
    const merchant_id = merchantDetails.id
    const [worklfowImpactData, setWorklfowImpactData] = useState(defaultWorkflowImpactData)
    const [loading, setLoading] = useState(false)
    const [showAwaitedTooltip, setShowAwaitedTooltip] = useState(false)
    const ruleIDs = workflow?.rules?.map((item) => item.rule_id)
    const fetchWorkflowImpactMetrics = async (ruleIDs: string[], merchantId: number) => {
        try {
            setLoading(true)
            const response = await getWorkflowImpactMetrics(ruleIDs, merchantId)
            if (response?.data?.data?.length) {
                const { data } = response.data
                setWorklfowImpactData(data[0])
            }
        } catch (err) {
            message.error('Unable to fetch Workflow Impact Metrics')
            console.error(err)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (ruleIDs && merchant_id) {
            fetchWorkflowImpactMetrics(ruleIDs, merchant_id)
        }
        const workflowLastCreatedAt = workflow?.created_at ?? new Date()
        const showMetricsAfterDays = workflow?.show_awb_metrics_after_days ?? 0
        const resultDate = new Date(workflowLastCreatedAt)
        resultDate.setDate(resultDate.getDate() + showMetricsAfterDays)

        const currentDate = new Date()
        const showAwaitedTooltip = resultDate > currentDate
        setShowAwaitedTooltip(showAwaitedTooltip)
    }, [workflow])

    const getImpactTooltipText = (data) => {
        return `Delivery status only available for ${roundOffPercentageValue(data?.awb_fill_rate)}% of orders`
    }

    const roundOffPercentageValue = (value: number) => {
        const roundedValue = Math.round(value * 10) / 10 // Round to one decimal place
        return roundedValue % 1 === 0 ? Math.round(roundedValue) : +roundedValue.toFixed(1)
    }

    const renderCardContent = (data: ImpactData) =>
        data &&
        Object.keys(data).map(
            (key) =>
                impactViewMapping[key] && (
                    <p className='flex items-center justify-between leading-6' key={key}>
                        <span>{impactViewMapping[key]?.label}</span>
                        <span className='flex items-center'>
                            {impactViewMapping[key]?.showTooltip && showAwaitedTooltip ? (
                                <span className='text-[#000000E0] italic'>Awaited</span>
                            ) : (
                                <span className='text-[#000000E0] font-semibold'>
                                    {impactViewMapping[key]?.type === 'number'
                                        ? data[key]
                                        : `${roundOffPercentageValue(data[key])}%`}
                                </span>
                            )}
                            <>
                                <span className='min-w-6 min-h-4 flex items-center justify-center'>
                                    {impactViewMapping[key]?.showTooltip && (
                                        <Tooltip
                                            placement='bottom'
                                            title={
                                                showAwaitedTooltip
                                                    ? getAwaitedText(
                                                          workflow?.created_at,
                                                          workflow?.show_awb_metrics_after_days,
                                                      )
                                                    : getImpactTooltipText(data)
                                            }
                                        >
                                            <InfoCircleOutlined className='flex items-center justify-center ml-1' />
                                        </Tooltip>
                                    )}
                                </span>
                            </>
                        </span>
                    </p>
                ),
        )

    return (
        <>
            {workflow?.created_at && worklfowImpactData?.created_at ? (
                <Alert
                    message={getAbTestImpactInfo(worklfowImpactData?.created_at, workflow?.created_at)}
                    type='info'
                    className='mt-2'
                    showIcon
                    closable
                />
            ) : null}
            <div className='flex items-start gap-4 mt-4  border-t-2 border-gray-500 fade-in-delayed'>
                <div>
                    <p className='mb-2 text-[#000000E0] font-medium'>
                        Workflow Impact {`(${getActionNameByWorkflowResponse(workflow)})`}
                    </p>
                    <Card
                        size='small'
                        className='text-[#00000080] font-medium'
                        loading={loading}
                        style={{ minWidth: 420 }}
                    >
                        {worklfowImpactData && renderCardContent(worklfowImpactData)}
                    </Card>
                </div>
            </div>
        </>
    )
}

export default WorkflowImpactView
