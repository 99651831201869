import { ArrowRightOutlined, Drawer, InfoCircleOutlined, Tooltip } from 'gokwik-ui-kit';

const formatDate = (dateInput) => {
    if (!dateInput) return 'No value';

    let date;
    if (typeof dateInput === 'string' || typeof dateInput === 'number') {
        date = new Date(dateInput);
    } else if (dateInput instanceof Date) {
        date = dateInput;
    } else {
        return 'Invalid Date';
    }

    if (isNaN(date.getTime())) return 'Invalid Date';

    return new Intl.DateTimeFormat('en-GB', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
    }).format(date).replace(',', ' at');
};

function isValidDateString(value) {
    if (typeof value !== 'string') return false;
  
    const parsedDate = Date.parse(value);
    if (isNaN(parsedDate)) return false;
  
    // Ensure value is in a valid date string format (e.g., YYYY-MM-DD or ISO string)
    const validDate = new Date(parsedDate);
    return (
      /^\d{4}-\d{2}-\d{2}(T\d{2}:\d{2}:\d{2}(\.\d{1,3}Z)?)?$/.test(value) &&
      validDate.toISOString().startsWith(value)
    );
  }

const TimelineDrawer = ({ showHistory, setShowHistory, workflowTimelineData, createdAt }) => {
    return (
        <Drawer
            title={
                <div className="flex items-center">
                    <span>Logs</span>
                    <Tooltip title="Last 7-day changes">
                        <InfoCircleOutlined className="ml-2 text-gray-500" />
                    </Tooltip>
                </div>
            }
            placement="right"
            onClose={() => setShowHistory(false)}
            open={showHistory}
            width={600}
            mask={true}
            styles={{
                mask: {
                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                },
            }}
        >
            {workflowTimelineData.length === 0 && (
                <div className="p-4 text-gray-400 text-center text-sm">
                    No changes have been made in the last 7 days.
                </div>
            )}

            {workflowTimelineData.map((entry, index) => (
                <div key={index} className="p-4 bg-gray-50 rounded shadow mb-4 overflow-x-auto">
                    <div>{formatDate(entry.updated_at)}</div>
                    <div className="text-gray-400 my-1 text-xs">Configured by</div>
                    <div className="text-gray-500 font-semibold text-xs">{entry.user}</div>
                    <div>
                        {Array.isArray(entry.changes) && entry.changes.length > 0 &&
                            entry.changes.map((change, idx) => (
                                <div key={idx} className="mt-2">
                                    <div className="text-gray-500">{change.field}</div>
                                    <div className="flex items-center justify-center mt-1">
                                        <span className="flex-1 font-semibold text-sm">
                                            {
                                                typeof change.old_value === 'object'
                                                    ? JSON.stringify(change.old_value, null, 2)
                                                    : (isValidDateString(change.old_value))
                                                        ? formatDate(change.old_value)
                                                        : change.old_value?.toString() ?? 'No value'
                                            }
                                        </span>
                                        <span className="text-gray-500 mx-2"><ArrowRightOutlined /></span>
                                        <span className="flex-1 font-semibold text-sm text-center">
                                            {
                                                typeof change.new_value === 'object'
                                                    ? JSON.stringify(change.new_value, null, 2)
                                                    : (isValidDateString(change.new_value))
                                                        ? formatDate(change.new_value)
                                                        : change.new_value?.toString() ?? 'No value'
                                            }
                                        </span>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            ))}

            <div className="p-4 text-gray-400 text-xs text-center border-t border-gray-300">
                Created at: {formatDate(createdAt)}
            </div>
        </Drawer>
    );
};

export default TimelineDrawer;
