import { Fragment, useEffect, useMemo, useState, useRef, createRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ArrowLeftOutlined, Button, Form, InfoCircleFilled, InfoCircleOutlined, Input, Layout, Radio, Row, Space, Tooltip, message } from 'gokwik-ui-kit'
import ConditionBox from './condition-box-cmponents/conditionBox'
import { getMerchantDetails, getUserConfig, getUserDetails } from '@store/user/selectors'
import { setUserConfigData } from '@store/user'
import ConditionCategory from './conditionCategory'
import { ActionType, Condition, Rule, RuleRawData, Workflow } from '@library/utilities/interface'
import {
    actionTypes,
    bulkEntryData,
    workflowFlagConditionToolTip,
    codRateMessage,
    conditionCategories,
    duplicateOrderMessage,
    hideAbEditWorkflowTooltip,
    operators,
    rtoRiskKeys,
    rtoRiskWorkflow,
    rtoScore,
    specialNamesForBlankOrNull,
    workflowDefaultValue,
    WorkflowFlagTooltip,
    workflowKeys,
} from '@library/utilities/constants/constants'
import {
    fetchAllWorkflows,
    setAbTestSettings,
    setModalStates,
    setWorkflowAbTestTypeBAction,
    setWorkflowAction,
} from '@store/actions'
import { createWorkflowAsync, updateWorkflowAsync, callUpdateRtoActionsConfig } from '@store/actions/api'
import {
    convertIfNumber,
    getSubCategory,
    getAbControlActions,
    deepEqual,
    validateActionType,
} from '@library/utilities/helpers/helper'
import ModalComponents from './modalComponents'
import ABTest from '@library/components/abTest'
import {
    getAbTestSettings,
    getAbTestShopifyFlag,
    getAbTestToggle,
    getAbTreatmentPercentage,
    getModalStates,
    getWorkflowAbTestTypeBAction,
    getWorkflowAction,
} from '@store/actions/selectors'
import RenderTour from '../tour/demoTour'
import TakeAction from './takeAction'
import { logEvent } from '@library/utilities/userLogEvents/userLogEvents'
const { Content, Sider } = Layout

interface GroupType {
    conditions: Condition[]
}

interface EditValueProps {
    onBack: (workflows?: Workflow[], abTestToggle?: boolean) => void
    editData: Workflow | null
    allWorkflow: Workflow[]
    currentindex: number
}

interface UpdateWorkflowPayoadType {
    merchant_id: number
    updateWorkflows: Workflow[]
}

const EditWorkflow: React.FC<EditValueProps> = ({ onBack, editData = null, allWorkflow, currentindex }) => {
    const [workFlowName, setWorkflowName] = useState('')
    const [inputWorkflowFlag, setInputWorkflowFlag] = useState(undefined)
    const [inputWorkflowFlagBasedOnAction, setInputWorkflowFlagBasedOnAction] = useState(undefined)
    const [workFlowId, setWorkflowId] = useState('')
    const [createdAt, setCreatedAt] = useState(null)
    const [initialActionType, setInitialActionType] = useState(null)
    const [disabledButton, setDisabledButton] = useState(false)
    const [tourVisible, setTourVisible] = useState<boolean>(false)
    const tourRefs = useRef([...Array(5)].map(() => createRef<HTMLDivElement>()))
    const workflowData = {
        rto_rule_type: '',
        key: 'phone_number',
        operator: 'contains',
        workflow_type: 'CUSTOMER',
        dataType: 'individual',
    }
    const [conditionList, setConditionList] = useState<Condition[]>([
        {
            rto_rule_type: '',
            key: 'phone_number',
            operator: 'contains',
            workflow_type: 'CUSTOMER',
            dataType: 'individual',
            condition: 'and',
        },
    ])
    const merchant = useSelector(getMerchantDetails)
    const user_details = useSelector(getUserDetails)
    const merchantConfig = useSelector(getUserConfig)
    const rtoActionsConfigs = merchantConfig?.rto_actions_config
    const [resetForm, setResetForm] = useState(0)
    const abTestToggle = useSelector(getAbTestToggle)
    const abTreatmentPercentage = useSelector(getAbTreatmentPercentage) || 1
    const abTestShopifyFlag = useSelector(getAbTestShopifyFlag) || ''
    const showAbTestConfig = rtoActionsConfigs?.show_ab_test || false
    const modalStates = useSelector(getModalStates)
    const workflowAction = useSelector(getWorkflowAction)
    const workflowActionB = useSelector(getWorkflowAbTestTypeBAction)
    const { secondary: secondaryActionType, primary: actionType, showSecondaryAction } = workflowAction
    const {
        secondary: secondaryActionTypeB,
        primary: actionTypeB,
        showSecondaryAction: showSecondaryActionB,
        showActionA,
    } = workflowActionB
    const abTestSettings = useSelector(getAbTestSettings)
    const { abTestEnabledOn } = abTestSettings

    const [form] = Form.useForm()
    const dispatch = useDispatch()

    function mergeRtoScoreRules(extractedConditions: Condition[]) {
        const rules = [...extractedConditions]
        const mergedRules = []
        let pendingRtoScoreRule = null

        for (const rule of rules) {
            if (rule.key === rtoScore) {
                if (rule.operator === '>=') {
                    // If there's already a pending rule, add it to the merged rules and reset
                    if (pendingRtoScoreRule) {
                        mergedRules.push(pendingRtoScoreRule)
                        pendingRtoScoreRule = null
                    }
                    pendingRtoScoreRule = {
                        key: rtoScore,
                        operator: '==',
                        values: [rule.value],
                        workflow_type: rtoRiskWorkflow,
                        rule_id_lower: rule.rule_id,
                        condition: null, // Will be set when the corresponding "<=" rule is found
                        rule_id_upper: null, // Will be set when the corresponding "<=" rule is found
                        rule_id: rule?.rule_id,
                    }
                } else if (rule.operator === '<=' && pendingRtoScoreRule) {
                    pendingRtoScoreRule.values.push(rule.value)
                    pendingRtoScoreRule.condition = rule.condition
                    pendingRtoScoreRule.rule_id_upper = rule.rule_id
                    mergedRules.push(pendingRtoScoreRule)
                    pendingRtoScoreRule = null
                } else {
                    // If we encounter an unexpected state, just add the rule as is
                    mergedRules.push(rule)
                }
            } else {
                mergedRules.push(rule)
            }
        }

        // If there's a leftover pending rule, add it to the merged rules
        if (pendingRtoScoreRule) {
            mergedRules.push(pendingRtoScoreRule)
        }
        return mergedRules
    }

    const extractAbTestSettings = (editData: Workflow) => {
        if (editData.ab_test_enabled_on && editData.is_ab_test_enabled !== undefined) {
            return {
                abTestCreateFlag: true,
                abTestToggle: editData.is_ab_test_enabled,
                abTreatmentPercentage: 100 - editData.ab_control_perc,
                abTestShopifyFlag: editData.ab_test_flag?.flag_name || '',
                abTestEnabledOn: editData.ab_test_enabled_on,
                abTestControlIntervention: editData.ab_test_control_intervention || 'allow_cod',
                abTestFlagId: editData.ab_test_flag?.flag_id || null,
            }
        }
        return {}
    }

    const extractConditionsFromRules = (rules: Rule[] | RuleRawData[]) => {
        const extractedConditions: Condition[] = []
        rules.forEach((rule) => {
            const ruleData = rule.raw_data || rule
            ruleData.conditions.forEach((conditionItem: Condition, idx: number) => {
                const conditionItemClone = { ...conditionItem }
                if (conditionItem.is_not_variant) {
                    conditionItemClone.key = workflowKeys.productTitle
                }
                extractedConditions.push({
                    ...conditionItemClone,
                    condition: idx === ruleData.conditions.length - 1 ? 'or' : 'and',
                    rule_id: rule.rule_id,
                })
            })
        })
        return extractedConditions
    }

    const extractWorkflowActionBData = (editData: Workflow) => {
        const workflowActionBData = { ...workflowActionB }
        if (editData.ab_control_actions?.length) {
            const primaryAbAction = editData.ab_control_actions[0]
            const secondaryAbAction = editData.ab_control_actions[1]

            workflowActionBData.primary = {
                action: primaryAbAction?.action ?? actionTypes.allowCod,
                ...(primaryAbAction?.actionConfigs ?? {}),
            }
            workflowActionBData.secondary = {
                action: secondaryAbAction?.action ?? '',
                ...(secondaryAbAction?.actionConfigs ?? {}),
            }
            workflowActionBData.showSecondaryAction = !!secondaryAbAction
            workflowActionBData.showActionA = true
        }
        return workflowActionBData
    }

    const extractWorkflowActionData = (editData: Workflow) => {
        const rule = editData.rules[0] as RuleRawData
        const primaryAction = rule.raw_data ? rule.raw_data.actions[0] : rule.actions[0]
        const secondaryRule = rule.raw_data ? rule.raw_data.actions[1] : rule.actions[1]
        setInitialActionType(primaryAction.action);

        return {
            primary: primaryAction,
            secondary: secondaryRule?.action ? secondaryRule : { action: '' },
            showSecondaryAction: !!secondaryRule?.action,
        }
    }

    const resetWorkflowToDefaults = () => {
        dispatch(setAbTestSettings({}))
        const defaultAction = {
            primary: { action: '' },
            secondary: { action: '' },
            showSecondaryAction: false,
        }
        dispatch(setWorkflowAction(defaultAction))
        dispatch(
            setWorkflowAbTestTypeBAction({
                ...defaultAction,
                primary: { action: actionTypes.allowCod },
                showActionA: true,
            }),
        )
    }

    const initializeWorkflowFromEditData = (editData: Workflow) => {
        setWorkflowName(editData.rule_name)
        setInputWorkflowFlag(editData.workflow_flag)
        setWorkflowId(editData?.workflow_id)
        setCreatedAt(editData?.created_at)
        const workflowActionData = extractWorkflowActionData(editData)
        const workflowActionBData = extractWorkflowActionBData(editData)

        dispatch(setWorkflowAction(workflowActionData))
        dispatch(setWorkflowAbTestTypeBAction(workflowActionBData))

        const extractedConditions = extractConditionsFromRules(editData.rules)
        const mergedRules = mergeRtoScoreRules(extractedConditions)
        setConditionList(mergedRules)

        const abTestSettings = extractAbTestSettings(editData)
        dispatch(setAbTestSettings(abTestSettings))
    }

    useEffect(() => {
        if (editData) {
            initializeWorkflowFromEditData(editData)
        } else {
            resetWorkflowToDefaults()
        }
    }, [])

    const getWorkflow = () => {
        dispatch(
            //@ts-ignore
            fetchAllWorkflows({
                params: {
                    merchant_id: merchant.id,
                },
            }),
        )
    }

    const checkBulkEntryContains = (key: string, operator: string) => {
        if (bulkEntryData.includes(key) && operator !== operators.equals) {
            return true
        }
        return false
    }

    const updateItemValues = (item: Condition) => {
        item['dataType'] = 'individual'
        if (checkBulkEntryContains(item.key, item.operator)) {
            item['values'] = workflowDefaultValue?.[item.key]?.values ?? []
            item['selected_products'] = []
            delete item.value
        } else {
            item['value'] = workflowDefaultValue?.[item.key]?.value ?? ''
            delete item.values
        }
        item['key'] !== workflowKeys.productId && delete item?.is_not_variant
    }

    const initialKeyMapping: { [key: string]: string } = {
        CUSTOMER: 'phone_number',
        ADDRESS: 'shipping_state',
        CART: 'cart_value',
        RTO_RISK: 'risk_flag',
        UTM: 'utm_source',
        SKU: 'sku_ids',
    }

    const initialOperatorMapping: { [key: string]: string } = {
        CUSTOMER: 'contains',
        ADDRESS: 'contains',
        CART: '==',
        RTO_RISK: 'contains',
        UTM: 'contains',
        SKU: 'contains',
    }

    const mapKeyAndOperator = (item: Condition) => {
        item['key'] = initialKeyMapping[item.workflow_type]
        item['operator'] = initialOperatorMapping[item.workflow_type]
    }

    const handleRtoScore = (item: Condition) => {
        if (item.key === rtoScore && item.values) {
            delete item.value
        }
    }

    const actionHandlers = {
        key: (item: Condition) => {
            item['operator'] = workflowDefaultValue?.[item.key]?.operator ?? ''
            updateItemValues(item)
        },
        workflow_type: (item: Condition) => {
            mapKeyAndOperator(item)
            updateItemValues(item)
        },
        operator: (item: Condition) => {
            !rtoRiskKeys.includes(item.key) && updateItemValues(item)
            setResetForm(resetForm + 1)
        },
    }

    const onChangeWorklow = (event, key: string, index: number) => {
        const newItems = [...conditionList]
        const value = event?.target ? event.target.value : event?.value ? event.value : event

        newItems[index][key] = value

        if (actionHandlers[key]) {
            actionHandlers[key](newItems[index])
        }

        handleRtoScore(newItems[index])

        setConditionList(newItems)
    }

    const ruleFunction = (itm: Condition) => {
        let tmp = itm
        if (tmp.operator === 'contains') {
            tmp = { ...tmp, rto_rule_type: 'ContainsRule' }
        } else if (tmp.operator === 'not_contains') {
            tmp = { ...tmp, rto_rule_type: 'ContainsRule' }
        } else {
            tmp = { ...tmp, rto_rule_type: '' }
        }

        if (tmp.key === 'customer_type') {
            tmp = { ...tmp, rto_rule_type: 'CustomerType' }
        } else if (tmp.key === 'last_delivered') {
            tmp = { ...tmp, rto_rule_type: 'CustomerLastDelivered' }
        } else if (tmp.key === 'last_shipped') {
            tmp = { ...tmp, rto_rule_type: 'CustomerLastShipped' }
        }
        else if (tmp.key === 'cod_rate') {
            tmp = { ...tmp, rto_rule_type: 'CODRateLimit' }
        } else if (tmp.key === 'duplicate_order') {
            tmp = { ...tmp, rto_rule_type: 'DuplicateOrder' }
        }
        return tmp
    }

    const generateRtoScoreCondition = (rule) => {
        let tmp = rule
        const lowerLimit = tmp.values[0]
        const higherLimit = tmp.values[1]
        delete tmp.values
        tmp = [
            {
                ...tmp,
                value: String(lowerLimit),
                operator: '>=',
                ...(tmp?.rule_id_lower && { rule_id: tmp?.rule_id_lower }),
                rto_rule_type: '',
            },
            {
                ...tmp,
                value: String(higherLimit),
                operator: '<=',
                ...(tmp?.rule_id_upper && { rule_id: tmp?.rule_id_upper }),
                rto_rule_type: '',
            },
        ]
        return tmp
    }

    const separateItems = () => {
        const rulesArray = [...conditionList]
        const result = []
        let prevCondition = null
        let group: GroupType = { conditions: [] }
        for (let i = 0; i < rulesArray.length; i++) {
            let rule = { ...rulesArray[i] }
            if (prevCondition === 'and') {
                if (rule.key === rtoScore) {
                    group.conditions.push(...generateRtoScoreCondition(rule))
                } else {
                    group.conditions.push(ruleFunction(rule))
                }
            } else if (prevCondition === 'or') {
                if (Object.keys(group).length > 0) {
                    result.push(group)
                }
                delete rule.rule_id;
                group = {
                    conditions: rule.key === rtoScore ? [...generateRtoScoreCondition(rule)] : [ruleFunction(rule)],
                }
            } else {
                delete rule.rule_id;
                group = {
                    conditions: rule.key === rtoScore ? [...generateRtoScoreCondition(rule)] : [ruleFunction(rule)],
                }
            }
            prevCondition = rule.condition
        }
        result.push(group)
        result?.map((rule) => {
            return rule?.conditions.map((condtn: Condition) => {
                delete condtn?.condition
                return condtn
            })
        })
        return result
    }

    const submitWorkflow = async () => {
        onChangeModalStates('submitModalVisible', false)
        const items = separateItems()
        if (editData && !editData?.template) {
            updateWorkflow(items)
        } else {
            createWorkflow(items)
        }
    }

    const updateWorkflow = (allItems) => {
        let updateWorkflowPayload: UpdateWorkflowPayoadType
        if (editData) {
            let temp = [...allWorkflow]
            temp[currentindex] = {
                type: 'CUSTOM',
                rule_name: workFlowName,
                workflow_id: workFlowId,
                workflow_flag: inputWorkflowFlag || inputWorkflowFlagBasedOnAction,
                created_at: createdAt,
                is_enabled: true,
                updated_at: new Date().toISOString(),
                //@ts-ignore
                rules: [
                    ...(Array.isArray(allItems)
                        ? allItems?.map((item, idx: number) => {
                            let rule_id = item?.conditions[0]?.rule_id ? item?.conditions[0]?.rule_id : ''
                            let itm = item?.conditions?.map((condtn) => {
                                delete condtn?.rule_id
                                const values = condtn?.values?.map((val: String[] | number[]) => val.toString()) // Convert values to string
                                return {
                                    created_at: condtn?.created_at,
                                    workflow_type: condtn?.workflow_type,
                                    key: condtn?.key,
                                    operator: condtn?.operator,
                                    ...(condtn.rto_rule_type ? { rto_rule_type: condtn.rto_rule_type } : {}),
                                    ...(condtn.file_name ? { file_name: condtn.file_name } : {}),
                                    ...(checkBulkEntryContains(condtn.key, condtn.operator)
                                        ? { values }
                                        : { value: convertIfNumber(condtn?.value) }),
                                    ...(condtn.selected_products
                                        ? { selected_products: condtn.selected_products }
                                        : {}),
                                    ...(condtn.is_not_variant
                                        ? { key: workflowKeys.productId, is_not_variant: true }
                                        : {}),
                                    ...(condtn.key === 'cod_rate' ? { merchant_id: merchant?.id, total_hours: Number(condtn.value) } : {}),
                                    ...(condtn.key === 'duplicate_order' ? { merchant_id: merchant?.id, total_hours: Number(condtn.total_hours), total_orders: Number(condtn.value) } : {})
                                }
                            })
                            return {
                                rule_id,
                                raw_data: {
                                    conditions: [...itm],
                                    actions: [
                                        ...(actionType.action ? [actionType] : []),
                                        ...(secondaryActionType?.action ? [secondaryActionType] : []),
                                    ],
                                    priority: idx + 1,
                                },
                            }
                        })
                        : []),
                ],
                ...(abTestToggle && {
                    is_ab_test_enabled: abTestToggle,
                    ab_control_perc: 100 - abTreatmentPercentage,
                    ab_test_flag: {
                        flag_name: abTestShopifyFlag,
                        flag_id: null,
                    },
                    ab_test_enabled_on: new Date().toISOString(),
                    ab_control_actions: getAbControlActions(actionTypeB, secondaryActionTypeB),
                }),
            }

            let finalTemp = temp?.map((itm) => ({
                updated_at: itm?.updated_at ?? new Date().toISOString(),
                created_at: itm?.created_at,
                rule_name: itm?.rule_name,
                workflow_id: itm.workflow_id,
                workflow_flag: itm.workflow_flag || null,
                type: itm.type,
                is_enabled: itm.is_enabled,
                rules: (itm.rules as RuleRawData[]).filter((value) => Object.keys(value).length !== 0),
                ab_control_intervention: itm?.ab_test_control_intervention || 'allow_cod',
                ab_control_perc: itm?.ab_control_perc || null,
                ab_test_disabled_on: itm?.ab_test_disabled_on || null,
                ab_test_enabled_on: itm?.ab_test_enabled_on || null,
                ab_test_flag: itm?.ab_test_flag || null,
                ab_test_method: itm?.ab_test_method || null,
                is_ab_test_enabled: itm?.is_ab_test_enabled || false,
                show_ab_metrics_after_days: itm?.show_ab_metrics_after_days || 7,
                ab_control_actions: itm?.ab_control_actions || [{ action: actionTypes.allowCod, actionConfigs: null }],
            }))
            updateWorkflowPayload = {
                merchant_id: merchant?.id,
                updateWorkflows: [...finalTemp],
            }
        }
        callUpdateWorkflow(updateWorkflowPayload, abTestToggle)
    }

    async function callUpdateWorkflow(data: UpdateWorkflowPayoadType, abTestToggle: boolean = false) {
        const { updateWorkflows, merchant_id } = data
        try {
            let response = await updateWorkflowAsync(updateWorkflows, merchant_id)
            if (response.success && response.data) {
                message.success('Workflow Updated Successfully')
                onBack(data.updateWorkflows, abTestToggle)
            }
        } catch (err) {
            message.error('Workflow Updation Failed')
            console.log(err)
        }
    }

    const createWorkflow = async (allItems) => {
        if (allItems.length === 0) {
            message.warning('Please add rules')
            return
        }

        let temp = {
            created_at: new Date().toISOString(),
            merchant_id: merchant?.id,
            type: 'CUSTOM',
            name: workFlowName,
            workflow_flag: inputWorkflowFlag || inputWorkflowFlagBasedOnAction,
            ...(editData?.template ? { is_enabled: true } : null),
            rules: [
                ...allItems?.map((itm, idx: number) => {
                    return {
                        conditions: itm.conditions.map((i) => {
                            const condition: Condition = {
                                workflow_type: i.workflow_type,
                                key: i.key,
                                operator: i.operator,
                                ...(i.rto_rule_type ? { rto_rule_type: i.rto_rule_type } : {}),
                                ...(i.file_name ? { file_name: i.file_name } : {}),
                                ...(i.selected_products ? { selected_products: i.selected_products } : {}),
                                ...(i.is_not_variant ? { key: workflowKeys.productId, is_not_variant: true } : {}),
                                ...(i.key === 'cod_rate' ? { merchant_id: merchant?.id, total_hours: Number(i.value) } : {}),
                                ...(i.key === 'duplicate_order' ? { merchant_id: merchant?.id, total_hours: Number(i.total_hours), total_orders: Number(i.value) } : {}),
                            }

                            // Convert each value in i.values to string if it exists
                            if (i?.values && i?.values.length > 0) {
                                condition.values = i.values.map((value) => String(value))
                            } else if (i?.value) {
                                condition.value = convertIfNumber(i.value)
                            }

                            return condition
                        }),
                        actions: [
                            ...(actionType.action ? [actionType] : []),
                            ...(secondaryActionType?.action ? [secondaryActionType] : []),
                        ],
                        priority: idx + 1,
                    }
                }),
            ],
            ...(abTestToggle && {
                is_ab_test_enabled: abTestToggle,
                ab_control_perc: 100 - abTreatmentPercentage,
                ab_test_flag: {
                    flag_name: abTestShopifyFlag,
                    flag_id: null,
                },
                ab_test_enabled_on: new Date().toISOString(),
                ab_control_actions: getAbControlActions(actionTypeB, secondaryActionTypeB),
            }),
        }
        await callCreateWorkflow(temp)
    }

    async function callCreateWorkflow(data: Workflow) {
        try {
            let response = await createWorkflowAsync(data)
            if (response.success && response.data) {
                message.success('Workflow Created Successfully')
                getWorkflow()
                onBack()
            }
        } catch (err) {
            message.error('Workflow Creation Failed')
            console.log(err)
        }
    }
    const deleteCondition = (idx: number) => {
        if (conditionList.length <= 1) {
            message.warning('Cannot delete all conditions')
            return
        }
        let temp = [...conditionList]
        let deletedList = temp.filter((item, index) => index != idx)
        setConditionList(deletedList)
    }

    const disabledActionType = useMemo(() => {
        if (!actionType.action || !workFlowName) {
            return true
        }
        return validateActionType(actionType)
    }, [actionType, workFlowName])

    useEffect(() => {
        if (checkArrayForBlankOrNull(conditionList) && !disabledActionType) {
            setDisabledButton(false)
        } else {
            setDisabledButton(true)
        }
    }, [conditionList, actionType, workFlowName])

    function checkArrayForBlankOrNull(arr: Condition[]) {
        return arr.reduce((acc: boolean, obj: Condition) => {
            for (let key in obj) {
                if (!obj['values'] && obj['values']?.length === 0) {
                    return false
                }
                // Skip 'value' and 'values' keys for special handling
                if (key === 'value' || key === 'values') continue

                // Check for blank or null values
                if (key !== 'rto_rule_type') {
                    if (obj[key] === '' || obj[key] === null) {
                        return false
                    }
                }
            }
            if (specialNamesForBlankOrNull.includes(obj.key)) {
                const valuesAreEmpty = !obj.values || obj.values.length === 0
                const valueIsEmpty = !obj.value

                const operatorIsContains = obj.operator === operators.contains
                const operatorIsEqual = obj.operator === operators.equals

                const isInvalidContains = valuesAreEmpty && operatorIsContains
                const isInvalidEqual = valueIsEmpty && operatorIsEqual && obj.key !== rtoScore

                if (isInvalidContains || isInvalidEqual) {
                    return false
                }
            }
            if (!specialNamesForBlankOrNull.includes(obj['key'])) {
                if (!obj['value']) {
                    return false
                }
            }

            // If 'values' is non-empty and name is not special, then 'value' shouldn't be empty
            if (
                Array.isArray(obj['values']) &&
                obj['values'].length > 0 &&
                !specialNamesForBlankOrNull.includes(obj['key'])
            ) {
                if (obj['value'] === '') {
                    return false
                }
            }

            // Special handling for 'value' and 'values'
            if (obj.hasOwnProperty('value') && obj.hasOwnProperty('values')) {
                if (obj['value'] === '' && obj['values'].length === 0) {
                    return false
                }
            } else if (obj.hasOwnProperty('value')) {
                if (obj['value'] === '') {
                    return false
                }
            } else if (obj.hasOwnProperty('values')) {
                if (obj['values'].length === 0) {
                    return false
                }
            }
            return acc
        }, true)
    }

    const showDisabledErrorMessage = () => {
        if (disabledButton) {
            message.error('Please fill in all details to apply workflow!')
            return
        }
    }


    const isInvalidActionsCombination = () => {
        const hasInvalidActionType = [actionType, secondaryActionType, actionTypeB, secondaryActionTypeB].some(validateActionType);

        if (hasInvalidActionType) {
            message.error('Please fill in all details to apply workflow!')
            return true;
        }
        const isEqualAction = (obj1: ActionType, obj2: ActionType) =>
            (obj1.action === obj2.action);

        const bothSecondaryActionsEmpty = () =>
            !secondaryActionType?.action && !secondaryActionTypeB?.action;

        const arePrimaryAndSecondaryActionsEqual = () =>
            isEqualAction(actionType, actionTypeB) && isEqualAction(secondaryActionType, secondaryActionTypeB);

        const areCrossActionsEqual = () =>
            isEqualAction(actionType, secondaryActionTypeB) && isEqualAction(secondaryActionType, actionTypeB);

        const isDeepEqualForPrimaryAndSecondary = () =>
            deepEqual(actionType, actionTypeB) &&
            deepEqual(secondaryActionType, secondaryActionTypeB);

        const isDeepEqualForCrossActions = () =>
            deepEqual(actionType, secondaryActionTypeB) &&
            deepEqual(secondaryActionType, actionTypeB);


        let isInvalid = false;

        if (arePrimaryAndSecondaryActionsEqual()) {
            if (bothSecondaryActionsEmpty()) isInvalid = isDeepEqualForPrimaryAndSecondary();
            else if (isEqualAction(secondaryActionType, secondaryActionTypeB)) isInvalid = isDeepEqualForPrimaryAndSecondary();
        }

        if (!isInvalid && areCrossActionsEqual()) {
            isInvalid = isDeepEqualForCrossActions();
        }

        if (isInvalid) {
            message.error('Type A and Type B must have distinct actions or values. Please modify one of them.');
            return true;
        }

        return false;
    };


    const handleApplyWorkflow = () => {
        if (disabledButton) {
            message.error('Please fill in all details to apply workflow!')
        } else if (abTestToggle && isInvalidActionsCombination()) {
            return;
        } else {
            onChangeModalStates('submitModalVisible', true)
        }
    }

    useEffect(() => {
        if (actionType?.action?.length > 0 || resetForm > 0) {
            form?.resetFields()
        }
    }, [resetForm, actionType?.action])

    useEffect(() => {
        if (rtoActionsConfigs && rtoActionsConfigs?.show_tour) {
            setTourVisible(true)
        }
    }, [rtoActionsConfigs])

    const updateTourConfig = async () => {
        onChangeModalStates('tourModalVisible', false)
        const payload = {
            rto_actions_config: { ...rtoActionsConfigs, show_tour: false },
        };
        let response
        try {
            response = await callUpdateRtoActionsConfig(payload)
            if (response.data.status_code === 200) {
                dispatch(setUserConfigData({ ...merchantConfig, ...payload }))
            }
        } catch (error) {
            console.error('Error RTO Actions Configs Updation', error)
        }
        return response.data
    }

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            event.preventDefault()
        }
    }

    const handleButtonClick = () => {
        const actionType = editData ? 'editworkflow' : 'newworkflow'
        logEvent(
            'kwik_rto_rto_actions_' + actionType + '_applyworkflow_clicked',
            'click',
            'Kwik RTO Actions',
            user_details?.email,
            merchant?.m_id,
            merchant?.short_name,
            user_details?.name
        );
    };


    const onChangeModalStates = (key: string, value: Boolean) => {
        dispatch(setModalStates({ ...modalStates, [key]: value }))
    }

    useEffect(() => form.setFieldsValue({ ['workflow-input']: workFlowName }), [workFlowName])

    useEffect(() => {
        setInputWorkflowFlagBasedOnAction("Gokwik_" + actionType.action);
    }, [actionType.action]);

    return (
        <div className='h-[80vh] p-3'>
            <Form
                onFinish={handleApplyWorkflow}
                onFinishFailed={showDisabledErrorMessage}
                scrollToFirstError
                form={form}
                onKeyDown={handleKeyDown}
            >
                <Layout hasSider>
                    <Content>
                        <Row className='flex align-center'>
                            <ArrowLeftOutlined
                                onClick={() => onChangeModalStates('backModalVisible', true)}
                                width={40}
                                height={80}
                            />
                            <h6 className='m-0 ml-3 text-base font-semibold leading-6'>
                                {editData ? 'Edit Workflow' : 'Create New Workflow'}
                            </h6>
                        </Row>
                        <div className='overflow-auto h-[80vh] mt-2.5 flex flex-col items-start p-4 gap-4 self-stretch rounded-lg border-2 border-[#0000000f] bg-white'>
                            <p className='text-sm font-normal leading-6'>Workflow Name</p>
                            <div className='w-full items-center' ref={tourRefs.current[0]}>
                                <Form.Item
                                    className='my-2'
                                    name='workflow-input'
                                    initialValue={workFlowName}
                                    rules={[{ required: true, message: '' }]}
                                    shouldUpdate
                                >
                                    <Input
                                        placeholder='Enter Workflow name here e.g BLOCK COD for cart value between Rs 1600 and Rs 2500'
                                        value={workFlowName}
                                        onChange={(e) => setWorkflowName(e.target.value)}
                                        maxLength={50}
                                        count={{
                                            show: true,
                                            max: 50,
                                        }}
                                    />
                                </Form.Item>
                            </div>
                            <p>Workflow Type</p>

                            {conditionList.map((itm, idx) => (
                                <Fragment key={idx}>
                                    <Space size={40} className='w-full flex flex-wrap' ref={tourRefs.current[1]}>
                                        {conditionCategories.map(({ id, label, type }) => (
                                            <ConditionCategory
                                                key={id}
                                                type={type}
                                                label={label}
                                                active={itm.workflow_type === type}
                                                onClick={() => {
                                                    setResetForm(resetForm + 1)
                                                    onChangeWorklow(type, 'workflow_type', idx)
                                                }}
                                            />
                                        ))}
                                    </Space>

                                    <Form.Item
                                        name={`condition-${idx}`}
                                        rules={[
                                            {
                                                validator: (_, value) => {
                                                    return conditionList[idx]?.['key']?.length > 0
                                                        ? Promise.resolve()
                                                        : Promise.reject(new Error('Please Select a condition!'))
                                                },
                                            },
                                        ]}
                                        initialValue={conditionList[idx]['key']}
                                    >
                                        <div
                                            key={idx}
                                            className='rounded-lg border-2 border-[#0000000f] w-full p-4'
                                            ref={tourRefs.current[2]}
                                        >
                                            <Radio.Group
                                                onChange={(e) => {
                                                    setResetForm(resetForm + 1)
                                                    onChangeWorklow(e, 'key', idx)
                                                }}
                                                value={conditionList[idx]['key']}
                                            >
                                                {
                                                    <Space direction='horizontal' wrap size={20}>
                                                        {getSubCategory(itm.workflow_type)?.map((itm, idx) => {
                                                            return (
                                                                <>
                                                                    <Radio value={itm?.value} key={idx}>
                                                                        {itm.name}
                                                                        {['cod_rate', 'duplicate_order'].includes(itm.value) && (
                                                                            <Tooltip
                                                                                placement='bottom'
                                                                                title={itm.value === 'cod_rate' ? codRateMessage : duplicateOrderMessage}
                                                                            >
                                                                                <InfoCircleOutlined style={{ margin: '10px' }} />
                                                                            </Tooltip>
                                                                        )}
                                                                    </Radio>

                                                                </>

                                                            )
                                                        })}
                                                    </Space>
                                                }
                                            </Radio.Group>
                                        </div>
                                    </Form.Item>
                                    {itm.key ? (
                                        <div ref={tourRefs.current[3]} className='w-full'>
                                            <ConditionBox
                                                defaultDataType={itm.dataType}
                                                workflowType={itm.workflow_type}
                                                workflowData={itm}
                                                deleteItem={() => deleteCondition(idx)}
                                                index={idx}
                                                onChangeValues={(itm, key: string) => onChangeWorklow(itm, key, idx)}
                                                conditionList={conditionList}
                                            />
                                            {
                                                <>
                                                    {conditionList.length - 1 === idx ? (
                                                        conditionList.length !== 5 && (
                                                            <div className='flex w-full flex-col justify-center items-center'>
                                                                <div className='flex w-px h-6 items-start bg-[#004b8d]' />
                                                                <div
                                                                    className='flex px-4 flex-col justify-center items-center gap-2 rounded-md border-solid border-[#004b8d] text-[#004b8d] text-sm leading-5 cursor-pointer'
                                                                    onClick={() => {
                                                                        setConditionList((prev) => [
                                                                            ...prev,
                                                                            { ...workflowData, condition: 'and' },
                                                                        ])
                                                                    }}
                                                                >
                                                                    Add Condition
                                                                </div>
                                                            </div>
                                                        )
                                                    ) : (
                                                        <div className='flex w-full flex-col justify-center items-center'>
                                                            <div className='flex w-px h-6 items-start bg-[#004b8d]' />
                                                            <div className='flex px-2 py-1 justify-center items-center rounded-[36px] border-solid border-[#004b8d] bg-white gap-4 text-xs font-semibold leading-5 text-center'>
                                                                <div
                                                                    className={
                                                                        itm?.condition === 'and'
                                                                            ? 'flex py-1 px-2 flex-col items-start gap-3 rounded-2xl bg-[#004b8d] shadow-md text-white cursor-pointer'
                                                                            : 'text-[#004b8d] cursor-pointer'
                                                                    }
                                                                    onClick={() => {
                                                                        onChangeWorklow('and', 'condition', idx)
                                                                    }}
                                                                >
                                                                    And
                                                                </div>
                                                                <div
                                                                    className={
                                                                        itm?.condition === 'or'
                                                                            ? 'flex py-1 px-2 flex-col items-start gap-3 rounded-2xl bg-[#004b8d] shadow-md text-white cursor-pointer'
                                                                            : 'text-[#004b8d] cursor-pointer'
                                                                    }
                                                                    onClick={() =>
                                                                        onChangeWorklow('or', 'condition', idx)
                                                                    }
                                                                >
                                                                    Or
                                                                </div>
                                                            </div>
                                                            <div className='flex w-px h-6 items-start bg-[#004b8d]' />
                                                        </div>
                                                    )}
                                                </>
                                            }
                                        </div>
                                    ) : null}
                                </Fragment>
                            ))}
                        </div>
                    </Content>
                    <Sider
                        width={350}
                        className='h-fit flex p-4 flex-col item-start gap-8 flex-1 !bg-white ml-5 rounded-md max-h-[85vh] overflow-y-scroll pr-1'
                    >
                        {!abTestEnabledOn && !abTestToggle ? (
                            <div ref={tourRefs.current[4]}>
                                <div className='flex items-center'>
                                    <h6 className='m-0 ml-2.5 text-base font-semibold leading-6 mb-5'>
                                        Take Action(s)
                                    </h6>
                                </div>
                                <TakeAction />
                            </div>
                        ) : null}

                        {!abTestEnabledOn && !abTestToggle ? (
                            <>
                                <p className='mt-8' />
                                <Tooltip
                                    title={hideAbEditWorkflowTooltip}
                                    overlayStyle={{
                                        display: (!showAbTestConfig || !editData?.ab_test_enabled_on) && 'none',
                                    }}
                                >



                                    <div>

                                        <div className='flex items-center gap-2 text-sm'>
                                            <p>Set Shopify Flag</p>
                                            <Tooltip title={WorkflowFlagTooltip}>
                                                <InfoCircleOutlined className='opacity-40' />
                                            </Tooltip>
                                        </div>


                                        <Form.Item
                                            className='my-2'
                                            name='workflow-input-flag'
                                            initialValue={editData?.workflow_flag ? editData?.workflow_flag : (inputWorkflowFlag || "Gokwik_")}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: ''
                                                },
                                                {
                                                    pattern: /^[A-Za-z0-9_]*$/,
                                                    message: "Only letters, numbers, and underscores are allowed."
                                                }
                                            ]}
                                            shouldUpdate
                                        >
                                            <div className='flex items-center gap-2'>
                                                <Input
                                                    onChange={(e) => setInputWorkflowFlag(e.target.value)}
                                                    disabled={editData?.workflow_flag && actionType.action === initialActionType}
                                                    value={inputWorkflowFlag !== undefined ? inputWorkflowFlag : (editData?.workflow_flag || inputWorkflowFlagBasedOnAction)}
                                                    type='text'
                                                    title={workflowFlagConditionToolTip}
                                                    maxLength={40}
                                                    count={{
                                                        show: true,
                                                        max: 40,
                                                    }}
                                                />
                                            </div>
                                        </Form.Item>
                                    </div>

                                    <Button
                                        size={'large'}
                                        type='primary'
                                        htmlType='submit'
                                        className={`w-full border border-solid !border-gray-300`}
                                        disabled={showAbTestConfig && !!editData?.ab_test_enabled_on}
                                        onClick={handleButtonClick}
                                    >
                                        Apply Workflow
                                    </Button>
                                </Tooltip>
                            </>
                        ) : null}

                        <ABTest
                            disabledButton={disabledButton}
                            allWorkflow={allWorkflow}
                            currentIndex={currentindex}
                            onBack={onBack}
                        />
                    </Sider>
                </Layout>
            </Form>
            <RenderTour tourRefs={tourRefs} tourVisible={tourVisible} setTourVisible={setTourVisible} />
            {/* Add All Modals here...(Use redux variable for modal states) */}
            <ModalComponents
                onBack={onBack}
                submitWorkflow={submitWorkflow}
                updateTourConfig={updateTourConfig}
                actionType={(abTestToggle || abTestEnabledOn) && !showActionA ? actionTypeB : actionType}
                secondaryActionType={(abTestToggle || abTestEnabledOn) && !showActionA ? secondaryActionTypeB : secondaryActionType}
            />
        </div>
    )
}

export default EditWorkflow
