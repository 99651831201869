export const OrderSummaryIcon = () => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='none'>
            <path
                d='M7.052 11.532l-3.401 3.416c-.345.352-.097.944.39.944h1.498'
                stroke='#00192f'
                stroke-width='1.6'
                stroke-miterlimit='10'
                stroke-linecap='round'
            />
            <path
                d='M13.393 17.533a1.5 1.5 0 0 0 1.498-1.498 1.5 1.5 0 0 0-1.498-1.498 1.5 1.5 0 0 0-1.498 1.498 1.5 1.5 0 0 0 1.498 1.498zm-4.495 0a1.5 1.5 0 0 0 1.498-1.498 1.5 1.5 0 0 0-1.498-1.498A1.5 1.5 0 0 0 7.4 16.034a1.5 1.5 0 0 0 1.498 1.498z'
                fill='#00192f'
            />
            <path
                d='M3.6 1.8l2.717 8.788c.195.749.929 1.229 1.693 1.101l5.634-.914a1.49 1.49 0 0 0 1.176-.989l1.296-3.753a1.5 1.5 0 0 0-1.416-1.985H4.295L3.6 1.8zm0 0H1.8'
                stroke='#00192f'
                stroke-width='1.6'
                stroke-miterlimit='10'
                stroke-linecap='round'
                stroke-linejoin='round'
            />
        </svg>
    )
}
