export const PremiumTemplateIcon = () => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='28' height='28' fill='none'>
            <rect x='.5' y='.5' width='27' height='27' rx='3.5' fill='#fffaeb' />
            <rect x='.5' y='.5' width='27' height='27' rx='3.5' stroke='#fef0c7' />
            <g clip-path='url(#A)'>
                <path d='M14 9l3.333 5 4.167-3.333L19.833 19H8.167L6.5 10.667 10.667 14 14 9z' fill='#f09819' />
            </g>
            <defs>
                <clipPath id='A'>
                    <path fill='#fff' transform='translate(4 4)' d='M0 0h20v20H0z' />
                </clipPath>
            </defs>
        </svg>
    )
}
