import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { awbFlowsApi, fetchAwbFillRateData } from './api'
import { getPreviousMonthAwbFillRate } from '@library/components/settings/helpers'

export interface AwbFlowsSlice {
    boostAwbShown: boolean
    showBoostAwb: boolean
    awbFillRate: {
        status: 'idle' | 'loading' | 'failed'
        data: any
        previous_month_awb_fill_rate: string
    }
    getPendingMissingOrders: {
        status: 'idle' | 'loading' | 'failed'
        data: any
    }
}

const initialState: AwbFlowsSlice = {
    boostAwbShown: false,
    showBoostAwb: false,
    awbFillRate: {
        status: 'idle',
        data: [],
        previous_month_awb_fill_rate: null,
    },
    getPendingMissingOrders: {
        status: 'idle',
        data: [],
    },
}

const assignToState = ({
    state,
    key,
    nestedKeys,
    payload,
    status,
}: {
    state: AwbFlowsSlice
    status: 'idle' | 'loading' | 'failed'
    key: string
    nestedKeys: string[]
    payload: any
}) => {
    if (nestedKeys && nestedKeys.length > 0) {
        nestedKeys.forEach((nestedKey) => {
            state[key][nestedKey].status = status
            if (payload && payload.data) state[key][nestedKey].data = payload.data[nestedKey]
        })
    } else {
        state[key].status = status || 'idle'
        state[key].data = payload?.data || []
        state[key].previous_month_awb_fill_rate = getPreviousMonthAwbFillRate(payload?.data || []) || null
    }
    return state
}

export const fetchAwbFillRateAsync = createAsyncThunk(
    'actions/fetchAwbFillRate',
    async (
        { key, params }: { key: string; nestedKey?: string; params?: { [key: string]: any } },
        { rejectWithValue },
    ) => {
        const response = await fetchAwbFillRateData({ key, params })
        if (response.success) return response
        else return rejectWithValue(response)
    },
)

export const fetchAwbFillRate =
    ({ params }) =>
    async (dispatch: any) => {
        Object.keys(awbFlowsApi).forEach((key) => {
            if (key == 'awbFillRate') dispatch(fetchAwbFillRateAsync({ key, params }))
        })
    }

export const awbFlowsSlice = createSlice({
    name: 'awbFlows',
    initialState,
    reducers: {
        setBoostAwbShown: (state, action) => {
            state.boostAwbShown = action.payload
        },
        setShowBoostAwb: (state, action) => {
            state.showBoostAwb = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAwbFillRateAsync.pending, (state, action) => {
                const nestedKeys = action.meta.arg.nestedKey
                    ? [action.meta.arg.nestedKey]
                    : awbFlowsApi[action.meta.arg.key].nestedKeys || []
                assignToState({ state, key: action.meta.arg.key, nestedKeys, payload: {}, status: 'loading' })
            })
            .addCase(fetchAwbFillRateAsync.fulfilled, (state, action) => {
                const nestedKeys = action.meta.arg.nestedKey
                    ? [action.meta.arg.nestedKey]
                    : awbFlowsApi[action.meta.arg.key].nestedKeys || []
                assignToState({ state, key: action.meta.arg.key, nestedKeys, payload: action.payload, status: 'idle' })
            })
            .addCase(fetchAwbFillRateAsync.rejected, (state, action) => {
                const nestedKeys = action.meta.arg.nestedKey
                    ? [action.meta.arg.nestedKey]
                    : awbFlowsApi[action.meta.arg.key].nestedKeys || []
                assignToState({ state, key: action.meta.arg.key, nestedKeys, payload: {}, status: 'failed' })
            })
    },
})

export const { setBoostAwbShown, setShowBoostAwb } = awbFlowsSlice.actions

export default awbFlowsSlice.reducer
