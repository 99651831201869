import React from 'react'

interface AddressIconProps {
    active: boolean
    className?: string
}

const AddressIcon: React.FC<AddressIconProps> = ({ active, className = '' }) => {
    return (
        <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            xmlns='http://www.w3.org/2000/svg'
            className={className}
        >
            <path
                d="M20.8701 8.45C19.8201 3.83 15.7901 1.75 12.2501 1.75C12.2501 1.75 12.2501 1.75 12.2401 1.75C8.7101 1.75 4.6701 3.82 3.6201 8.44C2.4501 13.6 5.6101 17.97 8.4701 20.72C9.5301 21.74 10.8901 22.25 12.2501 22.25C13.6101 22.25 14.9701 21.74 16.0201 20.72C18.8801 17.97 22.0401 13.61 20.8701 8.45ZM12.2501 13.46C10.5101 13.46 9.1001 12.05 9.1001 10.31C9.1001 8.57 10.5101 7.16 12.2501 7.16C13.9901 7.16 15.4001 8.57 15.4001 10.31C15.4001 12.05 13.9901 13.46 12.2501 13.46Z"
                fill={active ? 'white' : '#5C5F62'}
            />
        </svg>
    )
}

export default AddressIcon