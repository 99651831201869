import { Button, Input, message, Select, Table, Switch, Alert } from 'gokwik-ui-kit'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getRTOMerchantConfig as getRTOMerchantConfigSelector } from '@store/rto-configs/selectors'
import { updateControlSegmentConfig } from '@store/rto-configs/api'
import { ControlSegmentConfig, RtoMerchantConfig } from '../interface'
import { validateControlSegmentConfig, validateControlSegmentSum } from '../helpers'

const RTOModelConfig = ({ fetchRtoConfigData }) => {
    const data = useSelector(getRTOMerchantConfigSelector) as RtoMerchantConfig
    const [oldData, setOldData] = useState('')
    const [updatedRtoModelConfig, setUpdatedRtoModelConfig] = useState<ControlSegmentConfig>({} as ControlSegmentConfig)
    const [configData, setConfigData] = useState<ControlSegmentConfig>({} as ControlSegmentConfig)
    const [RTOModelData, setRTOModelData] = useState({
        rto_model_risk_flag: undefined,
        rto_model_risk_threshold: undefined,
        rto_model_risk_type: undefined,
        enabled: false,
    })
    const [bucketTypeList, setBucketTypeList] = useState(['global'])
    const [saveDisabled, setSaveDisabled] = useState(true)

    useEffect(() => {
        setConfigData((prev) => ({
            ...prev,
            default_flag: 'Low Risk',
        }))
    }, [])

    useEffect(() => {
        if (data?.control_segment_configs) {
            setOldData(
                JSON.parse(
                    JSON.stringify({
                        ...data?.control_segment_configs,
                    }),
                ),
            )
            setConfigData(
                JSON.parse(
                    JSON.stringify({
                        ...data?.control_segment_configs,
                    }),
                ),
            )
        }
    }, [data])

    useEffect(() => {
        Object.keys(updatedRtoModelConfig).length ? setSaveDisabled(false) : setSaveDisabled(true)
    }, [updatedRtoModelConfig])

    const deleteItem = (k, key) => {
        let tempData = configData?.segments[k].values

        tempData[key] = null
        delete tempData[key]
        if (Object.values(configData?.segments[k]?.values).length === 0) {
            delete configData?.segments[k]
        }
        setUpdatedRtoModelConfig((configData) => ({
            ...configData,
            segments: {
                ...configData?.segments,
                [k]: {
                    values: {
                        ...tempData,
                    },
                },
            },
        }))
    }

    useEffect(() => {
        let options = new Set(['global'])
        if (!!data?.risk_flag_configs && Object.keys(data?.risk_flag_configs?.risk_flags).length != 0) {
            Object.keys(data?.risk_flag_configs?.risk_flags).forEach((flag) => {
                options.add(flag)
            })
        }
        if (
            data?.control_segment_configs?.segments != null &&
            Object.keys(data.control_segment_configs.segments).length != 0
        ) {
            Object.keys(data.control_segment_configs.segments).forEach((segment) => {
                options.add(segment)
            })
        }
        if (data?.blocked_buckets && data.blocked_buckets?.length != 0) {
            data.blocked_buckets.forEach((bucket) => {
                options.add(bucket)
            })
        }
        setBucketTypeList(Array.from(options))
    }, [data])

    const handleAddNew = () => {
        let submit = validateControlSegmentConfig(
            RTOModelData.rto_model_risk_type,
            RTOModelData.rto_model_risk_flag,
            RTOModelData.rto_model_risk_threshold,
        )

        let rtoRiskFlag = RTOModelData['rto_model_risk_flag']
        let rtoRiskThreshold = Number(RTOModelData['rto_model_risk_threshold'])
        let rtoRiskType = RTOModelData['rto_model_risk_type']
        let model_enabled = RTOModelData['enabled']

        if (submit.status) {
            let buckets = configData?.segments || {}
            let rtoRiskFlags

            buckets[rtoRiskType] = {
                values: { ...buckets[rtoRiskType]?.values, [rtoRiskFlag]: rtoRiskThreshold },
                enabled: model_enabled,
            }
            setUpdatedRtoModelConfig((prev) => ({
                ...(prev || {}),
                segments: {
                    ...(prev?.segments || {}),
                    [rtoRiskType]: { ...buckets[rtoRiskType] },
                },
                default_flag: configData?.default_flag || '',
            }))

            setConfigData({
                ...configData,
                segments: {
                    ...(configData.segments || {}),
                    [rtoRiskType]: { ...buckets[rtoRiskType] },
                },
                default_flag: configData?.default_flag || '',
            })

            setRTOModelData({
                rto_model_risk_flag: undefined,
                rto_model_risk_threshold: undefined,
                rto_model_risk_type: undefined,
                enabled: false,
            })
        } else {
            let err = submit.msg
            message.error(err)
        }
    }

    const configSubmit = async () => {
        if (configData?.segments?.global?.values != null) {
            if (Object.keys(configData?.segments).length === 0) {
                setConfigData({} as ControlSegmentConfig)
            } else if (!validateControlSegmentSum(configData)) {
                message.error("Risk Percentage's Sum Must Be 100")
                return
            }
        }

        let tempData = JSON.parse(JSON.stringify({ ...configData }))

        try {
            const response = await updateControlSegmentConfig({
                control_segment_configs: tempData,
            })
            if (response.status_code === 200) {
                message.success('Config Updated Successfully')
                fetchRtoConfigData()
                setUpdatedRtoModelConfig({} as ControlSegmentConfig)
            }
        } catch (err) {
            message.error(err)
        }
    }

    const columns = [
        {
            title: 'S.No.',
            dataIndex: 'serial',
            key: 'serial',
        },
        {
            title: 'Bucket Type',
            dataIndex: 'bucketType',
            key: 'bucketType',
        },
        {
            title: 'Risk Flag',
            dataIndex: 'riskFlag',
            key: 'riskFlag',
        },
        {
            title: 'Bucket Size',
            dataIndex: 'bucketSize',
            key: 'bucketSize',
        },
        {
            title: 'Enabled',
            dataIndex: 'enabled',
            key: 'enabled',
            render: (text: boolean) => <Switch checked={text} />,
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record: any) => (
                <Button type='link' style={{ textAlign: 'left', paddingLeft: 0 }} onClick={() => deleteItem(record.bucketType, record.riskFlag)}>
                    Delete
                </Button>
            ),
        },
    ]

    if (data == null) {
        return <p className='text-red-500 text-lg font-bold'>RTO Service Error</p>
    } else {
        return (
            <div className='p-4'>
                <h2 className='text-xl font-bold mb-4'>RTO Population Intervention Configuration</h2>
                <div className='mb-4'>
                    <div className='flex mb-4'>
                        <Alert
                            className='self-stretch text-sm my-2.5'
                            message="Default Flag for this intervention is set to 'Low Risk'."
                            type='info'
                            showIcon
                            closable
                        />
                    </div>
                    <div className='flex space-x-4 mb-2'>
                        <div className='w-1/4'>
                            <label className='block text-sm font-medium mb-1'>Bucket Type</label>
                            <Select
                                className='w-full'
                                placeholder='Bucket Type'
                                value={RTOModelData.rto_model_risk_type}
                                onChange={(value) => setRTOModelData({ ...RTOModelData, rto_model_risk_type: value })}
                                options={bucketTypeList.map((bucket) => ({ label: bucket, value: bucket }))}
                            />
                        </div>
                        <div className='w-1/4'>
                            <label className='block text-sm font-medium mb-1'>Risk Flag</label>
                            <Select
                                className='w-full'
                                placeholder='Risk Flag'
                                value={RTOModelData.rto_model_risk_flag}
                                onChange={(value) => setRTOModelData({ ...RTOModelData, rto_model_risk_flag: value })}
                                options={[
                                    { label: 'Low Risk', value: 'Low Risk' },
                                    { label: 'Medium Risk', value: 'Medium Risk' },
                                    { label: 'High Risk', value: 'High Risk' },
                                ]}
                            />
                        </div>
                        <div className='w-1/4'>
                            <label className='block text-sm font-medium mb-1'>Bucket Size</label>
                            <Input
                                className='w-full'
                                placeholder='Bucket Size'
                                type='number'
                                value={RTOModelData.rto_model_risk_threshold}
                                onChange={(e) =>
                                    setRTOModelData({ ...RTOModelData, rto_model_risk_threshold: e.target.value })
                                }
                            />
                        </div>
                        <div className='w-1/3'>
                            <label className='block text-sm font-medium mb-1'>Enable Population Intervention</label>
                            <Switch
                                className='mb-2'
                                checked={RTOModelData.enabled}
                                onChange={(checked) => setRTOModelData({ ...RTOModelData, enabled: checked })}
                                checkedChildren='Enabled'
                                unCheckedChildren='Disabled'
                            />
                        </div>
                        <div className='w-1/4 mt-4'>
                            <Button type='primary' onClick={handleAddNew}>
                                Add New
                            </Button>
                        </div>
                    </div>
                </div>

                <Table
                    dataSource={Object.keys(configData?.segments || {}).flatMap((key, i) =>
                        Object.keys(configData?.segments[key]['values']).map((itm) => ({
                            serial: i, 
                            bucketType: key,
                            riskFlag: itm,
                            bucketSize: Number(configData?.segments[key].values[itm]),
                            enabled: configData?.segments[key].enabled,
                        })),
                    )}
                    columns={columns}
                    pagination={false}
                />

                <div className='flex justify-center mt-4'>
                    <Button type='primary' onClick={configSubmit} disabled={saveDisabled} style={{ width: '200px' }}>
                        Save
                    </Button>
                </div>
            </div>
        )
    }
}

export default RTOModelConfig
