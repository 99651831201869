export const WalletIcon = () => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='18' height='14' fill='none'>
            <path
                fill-rule='evenodd'
                d='M12.3 4a3 3 0 0 0 0 6h4.8a3.6 3.6 0 0 1-3.6 3.6H4.9a4 4 0 0 1-4-4V4.4a4 4 0 0 1 4-4h8.6A3.6 3.6 0 0 1 17.1 4h-4.8zm1.8 3a1.8 1.8 0 0 1-3.6 0 1.8 1.8 0 0 1 3.6 0z'
                fill='#00192f'
            />
        </svg>
    )
}
