import { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import {
    Alert,
    ArrowUpOutlined,
    Button,
    Card,
    ClockCircleOutlined,
    DeleteFilled,
    ExclamationCircleFilled,
    HolderOutlined,
    InfoCircleFilled,
    InfoCircleOutlined,
    Layout,
    Modal,
    PlusOutlined,
    Tag,
    Tooltip,
    Progress,
    Segmented,
    Spin,
    Switch,
    Tabs,
    WarningFilled,
    message,
} from 'gokwik-ui-kit'
import EditWorkflow from '../edit-workflow'
import { getMerchantDetails, getUserConfig, getUserDetails } from '@store/user/selectors'
import { getAbTestShopifyFlag, getWorkflowData } from '@store/actions/selectors'
import { updateBreadcrumbs } from '@gokwik/utilities'
import { fetchAllWorkflows, fetchWorkflowsAsync, setWorkflows } from '@store/actions'
import { Workflow } from '@library/utilities/interface'
import EmptyState from './empty-state'
import { capitalize, shouldHideWorkflowForDuplicateOrders, getDifference } from '@library/utilities/helpers/helper'
import { updateWorkflowAsync, getTimelineData } from '@store/actions/api'
import {
    loadingWorkflowStatus,
    workflowLockedInfo,
    workflowShouldEnabled,
    impactViewLockedText,
    abTestShopifyFlagTooltip,
    WorkflowFlagTooltip,
    excludedFields,
    excludedPatterns,
} from '@library/utilities/constants/constants'
import AbImpactView from './ab-impact-view'
import TimelineDrawer from './timelineDrawer'
import { logEvent } from '@library/utilities/userLogEvents/userLogEvents'
import { createWorkflowAsync } from '@store/actions/api'
import { rtoActionsFilterMenu, rtoActionsOptionsMenu } from './constants'
import { ruleTemplates } from '@library/utilities/constants/constants'
import ReccomendedWorkflow from '../recommended-workflow'
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)

interface EditWorkflowType {
    item: Workflow
    index: number
}

const { Content } = Layout
export default function AllWorkflow() {
    const merchantDetails = useSelector(getMerchantDetails)
    const [draggedListItem, setDraggedListItem] = useState(null)
    const [allWorkflow, setAllWorkflow] = useState<Workflow[]>([])
    const [editFlow, setCreateFlow] = useState(false)
    const [showHistory, setShowHistory] = useState(false)
    const [timelineData, setTimelineData] = useState([])
    const [workflowTimelineData, setWorkflowTimelineData] = useState([])
    const [createdAt, setCreatedAt] = useState(null);
    const [isEditing, setIsEditing] = useState(null)
    const [currentindex, setCurrentIndex] = useState(0)
    const [deleteModal, setDeleteModal] = useState<EditWorkflowType>(null)
    const [editWorkflowData, setEditWorkflowData] = useState<EditWorkflowType>(null)
    const merchant = useSelector(getMerchantDetails)
    const user_details = useSelector(getUserDetails)
    const { data: workflowData, status } = useSelector(getWorkflowData)
    const [opacity, setOpacity] = useState('opacity-0')
    const [prevDraggedIndex, setPrevDraggedIndex] = useState(null)
    const [showAbExpandedResults, setShowAbExpandedResults] = useState([])
    const [current, setCurrent] = useState('All')
    const [showModal, setShowModal] = useState(false)
    const [isProgressModalVisible, setIsProgressModalVisible] = useState(false)
    const [progress, setProgress] = useState(0)
    const dispatch = useDispatch()
    const merchantConfig = useSelector(getUserConfig)
    const showAbTestConfig = merchantConfig?.rto_actions_config?.show_ab_test || false
    const storeAbShopifyFlag = useSelector(getAbTestShopifyFlag)
    const merchantList = process.env.TIMELINE_MERCHANTS?.split(',').map(Number)


    useEffect(() => {
        let breadcrumbs = [
            {
                key: 'rto-actions',
                href: '/rto/actions/all-workflows',
                text: 'Workflows',
            },
        ]
        updateBreadcrumbs((prev) => [prev[0], ...breadcrumbs])
    }, [])
    const onClick = (e) => {
        setCurrent(e)
    }
    useEffect(() => {
        if (workflowData && workflowData?.length > 0) {
            setAllWorkflow(workflowData)
        }
    }, [])

    const fetchWorkflowAsync = () => {
        dispatch(
            //@ts-ignore
            fetchAllWorkflows({
                params: {
                    merchant_id: merchantDetails.id,
                },
            }),
        )
    }

    const createWorkflow = async (item: Workflow) => {
        let payload = {
            merchant_id: merchantDetails?.id,
            name: item.rule_name,
            ...item,
            created_at: new Date().toISOString(),
        }

        try {
            let response = await createWorkflowAsync(payload)
            if (response.success && response.data) {
                message.success('Workflow Enabled Successfully')
                fetchWorkflowAsync()
            }
        } catch (err) {
            message.error('Workflow Creation Failed')
            console.log(err)
        }
    }

    useEffect(() => {
        if (merchant.id) {
            getWorkflow();
            getTimeline(merchant.id);
        }
    }, [merchant.id])

    useEffect(() => {
        setAllWorkflow(workflowData)
        setShowAbExpandedResults(new Array(workflowData.length).fill(false))
    }, [workflowData])

    useEffect(() => {
        let intervalId
        if (isProgressModalVisible) {
            intervalId = setInterval(async () => {
                try {
                    const response = await checkProgressAPI()
                    if (response.success) {
                        setProgress(100)
                        clearInterval(intervalId)
                    } else {
                        setProgress((prev) => Math.min(prev + 10, 99))
                    }
                    // }
                } catch (error) {
                    console.error('Error checking progress:', error)
                }
            }, 30000) // 30 seconds
            setProgress((prev) => Math.min(prev + 1, 99))
        }

        return () => clearInterval(intervalId)
    }, [isProgressModalVisible])

    const checkProgressAPI = async () => {
        // Replace with your actual API call
        return await fetch('/api/check-progress').then(res => res.json())
    }

    const getWorkflow = () => {
        dispatch(
            //@ts-ignore
            fetchAllWorkflows({
                params: {
                    merchant_id: merchant.id,
                },
            }),
        )
    }

    const getTimeline = async (merchantId) => {
        const timelineData = await getTimelineData(merchantId);
        setTimelineData(timelineData);
    }

    const getTimelineDataByWorkflow = (workflow_id, created_at) => {
        const history = timelineData
            .filter(entry => entry.new_value && entry.new_value.workflow_id === workflow_id && entry.old_value?.workflow_id === workflow_id)
            .map(entry => {
                const changes = getDifference(entry.old_value, entry.new_value)
                    .filter(change =>
                        !excludedFields.has(change.field) &&
                        !excludedPatterns.test(change.field) &&
                        Object.keys(change).length > 0
                    );

                return {
                    merchant_id: entry.merchant_id,
                    workflow_id: workflow_id,
                    changes: changes,
                    updated_at: entry.updated_at,
                    user: entry.modified_by,
                };
            })
            .filter(entry => entry.changes.length > 0);
        setWorkflowTimelineData(history)
        setCreatedAt(created_at);
        return history
    }


    const handleDragStart = (e, index: number) => {
        setPrevDraggedIndex(index)
        setTimeout(() => {
            setDraggedListItem(index)
        })
        e.dataTransfer.setData('index', index)
    }

    const handleDragOver = (e, index: number) => {
        setOpacity('opacity-1')
        e.preventDefault()
        if (draggedListItem !== index) {
            const newData = [...allWorkflow]
            const [removedItem] = newData.splice(draggedListItem, 1)
            newData.splice(index, 0, removedItem)
            setAllWorkflow(newData)
            setDraggedListItem(index)
        }
    }

    const handleDrop = (item: Workflow, index: number) => {
        setEditWorkflowData({ item, index })
    }

    const onDelete = async (index: number) => {
        setDeleteModal(null)
        const updatedWorkflows = allWorkflow.filter((_, idx) => index != idx)
        callUpdateWorkflow(merchant.id, updatedWorkflows, 'Workflow Deleted Successfully', 'Workflow Deletion Failed ')
    }

    const updateWorkflows = () => {
        const startIdx = Math.min(prevDraggedIndex, editWorkflowData?.index ?? 0)
        const endIdx = Math.max(prevDraggedIndex, editWorkflowData?.index ?? 0)
        const updatedWorkflows = [...allWorkflow]

        for (let i = startIdx; i <= endIdx; i++) {
            updatedWorkflows[i] = {
                ...updatedWorkflows[i],
                updated_at: new Date().toISOString(),
            }
        }
        callUpdateWorkflow(merchant.id, updatedWorkflows, 'Priority Changed Successfully', 'Priority Changed Failed')
        setEditWorkflowData(null)
    }

    const enableWorkflow = (idx: number, isEnabled: boolean) => {
        const updatedWorkflows = [...allWorkflow]
        const isAbEnabled = updatedWorkflows[idx]?.is_ab_test_enabled ?? false

        updatedWorkflows[idx] = {
            ...updatedWorkflows[idx],
            updated_at: new Date().toISOString(),
            is_enabled: !isEnabled,
            ...(isAbEnabled &&
                isEnabled && {
                is_ab_test_enabled: false,
                ab_test_disabled_on: new Date().toISOString(),
            }),
        }
        callUpdateWorkflow(
            merchant.id,
            updatedWorkflows,
            `Workflow ${!isEnabled ? 'Enabled' : 'Disabled'} Successfully`,
            'Updation Failed',
        )
    }

    const enableDisableAbTest = (idx: number, isAbEnabled: boolean) => {
        const updatedWorkflows = [...allWorkflow]
        const payload = !isAbEnabled
            ? {
                is_ab_test_enabled: true,
                ab_test_enabled_on: new Date().toISOString(),
                ab_test_flag: {
                    ...updatedWorkflows[idx]?.ab_test_flag,
                    flag_id: null, //when enabled then send flag id null so we get new flag id.
                },
                ab_test_disabled_on: null,
            }
            : {
                is_ab_test_enabled: false,
                ab_test_disabled_on: new Date().toISOString(),
            }

        updatedWorkflows[idx] = {
            ...updatedWorkflows[idx],
            updated_at: new Date().toISOString(),
            ...payload,
        }
        setAllWorkflow(updatedWorkflows)
        callUpdateWorkflow(
            merchant.id,
            updatedWorkflows,
            `A/B Test ${!isAbEnabled ? 'Enabled' : 'Disabled'} Successfully`,
            'A/B Test Updation Failed',
            !isAbEnabled,
        )
    }

    const callUpdateWorkflow = async (
        merchantId: number,
        updatedWorkflows: Workflow[],
        successMessage: string,
        errorMessage: string,
        isAbEnabled: boolean = false,
    ) => {
        try {
            let response = await updateWorkflowAsync(updatedWorkflows, merchantId)
            if (response.success && response.data) {
                message.success(successMessage)
                dispatch(setWorkflows(updatedWorkflows))
                //calling to update flag id from get workflows api.
                isAbEnabled && getWorkflow()
                getTimeline(merchant.id)
            }
        } catch (err) {
            message.error(errorMessage)
            console.log(err)
        }
    }

    const handleDragCancel = () => {
        if (draggedListItem !== prevDraggedIndex) {
            const newData = [...allWorkflow]
            const [removedItem] = newData.splice(draggedListItem, 1)
            newData.splice(prevDraggedIndex, 0, removedItem)
            setAllWorkflow(newData)
            setDraggedListItem(prevDraggedIndex)
        }
        setEditWorkflowData(null)
        setDraggedListItem(null)
    }

    const onEditBack = (workflows: Workflow[] = null, abTestToggle = false) => {
        setCreateFlow(false)
        getWorkflow()
        getTimeline(merchant.id)
    }

    const showAbTestSettings = (isAbTestEnabled: string) => {
        return showAbTestConfig && Boolean(isAbTestEnabled) ? true : false
    }

    const generateWorkflows = () => {
        setShowModal(true)
    }

    const handleGenerateOk = () => {
        setShowModal(false)
        setIsProgressModalVisible(true)
    }

    if (!allWorkflow) {
        return (
            <Content>
                <div className='overflow-scroll p-4 bg-white h-full'>
                    <EmptyState
                        icon={<WarningFilled className='text-[#ffc107] text-7xl' />}
                        title='We’re facing a technical glitch at the moment, Request you to come back later'
                    />
                </div>
            </Content>
        )
    }
    let visibleWorkflowCount = 0;

    const handleCreateButtonClick = () => {
        setCreateFlow(true)
        setIsEditing(null)
        logEvent('kwik_rto_rto_actions_newworkflow_clicked', 'click', 'Kwik RTO Actions', user_details?.email, merchant?.m_id, merchant?.short_name, user_details?.name)
    }

    return !editFlow && current.toLowerCase() === 'all' ? (
        <Layout hasSider>
            <Content>
                <div className='overflow-scroll p-4 bg-white h-full'>
                    <div className='flex justify-between recommended-segmented'>
                        <Segmented options={rtoActionsOptionsMenu} defaultValue={current} onChange={onClick}></Segmented>
                        <button
                            type='button'
                            className='bg-[#004b8d] hover:bg-[#186199] text-white font-medium py-3 px-4 rounded-lg border-none'
                            onClick={handleCreateButtonClick}
                        > <PlusOutlined style={{ marginRight: '8px' }} />
                            <span>New Workflow</span>
                        </button>
                    </div>
                    <div className='self-stretch text-[#00000073] text-sm mb-5 mt-2.5'>
                        All workflows will be executed in the order below. Drag and Drop any workflow to change its
                        priority.
                    </div>
                    <Alert
                        className='my-2.5'
                        message='Only active workflows will be executed. Turn the toggle ON to activate any workflow.'
                        type='info'
                        showIcon
                        closable
                    />
                    {status === loadingWorkflowStatus ? (
                        //@ts-ignore
                        <Spin fullscreen />
                    ) : allWorkflow.length === 0 ? (
                        <EmptyState
                            title='Workflow not found'
                            icon={<InfoCircleFilled className='text-7xl text-[#004b8d]' />}
                        />
                    ) : (
                        <ul className='p-0'>
                            {allWorkflow?.map((item, index) => (
                                <Fragment key={index}>
                                    <div
                                        key={index}
                                        className={`flex p-4 items-center gap-4 flex-1 rounded-lg border-solid border border-gray-300 mb-1.5 ${draggedListItem === index && opacity
                                            }`}
                                        draggable
                                        onDragStart={(e) => handleDragStart(e, index)}
                                        onDragOver={(e) => handleDragOver(e, index)}
                                        onDragEnd={(e) => console.log(e)}
                                        onDrop={(e) => handleDrop(item, index)}
                                    >
                                        <div className='flex py-3 pr-4 pl-0 flex-col justify-start align-start gap-3 self-stretch border-solid border-0 border-r border-r-gray-200 items-center cursor-pointer'>
                                            <p>{++visibleWorkflowCount}</p>
                                            <HolderOutlined
                                                draggable={false}
                                                className='margin-r15 pointer-events-none'
                                            />
                                        </div>
                                        <div className='flex flex-col items-start gap-2 flex-1'>
                                            <div className='flex items-center gap-2 self-stretch'>
                                                <Switch
                                                    checked={item.is_enabled}
                                                    onChange={() => enableWorkflow(index, item.is_enabled)}
                                                />
                                                <p className='flex-1 text-stone-900 text-base font-semibold leading-6'>
                                                    {item.rule_name}
                                                </p>
                                                {showAbTestSettings(item?.ab_test_enabled_on) ? (
                                                    <div className='flex items-center gap-2'>
                                                        <Tooltip
                                                            placement='bottom'
                                                            title={workflowShouldEnabled}
                                                            overlayStyle={{
                                                                display: item.is_enabled && 'none',
                                                            }}
                                                        >
                                                            <Switch
                                                                checked={item.is_ab_test_enabled}
                                                                onChange={() =>
                                                                    enableDisableAbTest(index, item.is_ab_test_enabled)
                                                                }
                                                                disabled={!item.is_enabled}
                                                            />
                                                        </Tooltip>
                                                        <Tag
                                                            color='green'
                                                            variant={item.is_ab_test_enabled ? 'success' : 'warning'}
                                                            key={index}
                                                        >
                                                            A/B {item.is_ab_test_enabled ? 'Active' : 'Inactive'}
                                                        </Tag>
                                                    </div>
                                                ) : null}
                                                {item?.workflow_id && merchantList.includes(merchant.id) && (
                                                    <ClockCircleOutlined
                                                        onClick={() => {
                                                            getTimelineDataByWorkflow(item?.workflow_id, item?.created_at)
                                                            setShowHistory(true)
                                                        }}
                                                        className='text-xl text-gray-500 cursor-pointer'
                                                    />
                                                )}

                                                <DeleteFilled
                                                    onClick={() => {
                                                        setDeleteModal({ item, index })
                                                    }}
                                                    className='text-xl text-gray-500'
                                                />
                                            </div>
                                            <p className='self-stretch color-[##00000073] text-xs leading-5'>
                                                Type: {capitalize(item.type.toLowerCase())} | Last changes on:{' '}
                                                {/* @ts-ignore */}
                                                {dayjs(item.updated_at).fromNow()}
                                            </p>
                                            <div className='flex items-start gap-4 self-stretch justify-between'>
                                                <div className='flex items-center gap-4'>
                                                    <Tooltip
                                                        title={workflowLockedInfo}
                                                        overlayStyle={{
                                                            display:
                                                                (!showAbTestConfig || !item?.ab_test_enabled_on) &&
                                                                'none',
                                                        }}
                                                    >
                                                        <Button
                                                            onClick={() => {
                                                                setCreateFlow(true)
                                                                setIsEditing(item)
                                                                setCurrentIndex(index)
                                                            }}
                                                            type='link'
                                                            className='p-0 m-0'
                                                        >
                                                            Edit workflow
                                                        </Button>
                                                    </Tooltip>
                                                </div>
                                            </div>

                                            {showAbTestSettings(item?.ab_test_enabled_on) ? (
                                                <div className='flex items-center gap-2 text-sm'>
                                                    {item?.ab_test_flag?.flag_name ? (
                                                        <>
                                                            <Tooltip title={abTestShopifyFlagTooltip}>
                                                                <p className='text-gray-500'>
                                                                    {`${item?.ab_test_flag?.flag_name}_Treatment`} |{' '}
                                                                </p>
                                                            </Tooltip>
                                                            <Tooltip title={abTestShopifyFlagTooltip}>
                                                                <p className='text-gray-500'>
                                                                    {`${item?.ab_test_flag?.flag_name}_Control`} |{' '}
                                                                </p>
                                                            </Tooltip>
                                                        </>
                                                    ) : null}

                                                    <Tooltip
                                                        title={impactViewLockedText}
                                                        overlayStyle={{
                                                            display:
                                                                !(
                                                                    storeAbShopifyFlag === item?.ab_test_flag?.flag_name
                                                                ) && 'none',
                                                        }}
                                                    >
                                                        <p
                                                            className='text-[#00325E] underline cursor-pointer font-semibold'
                                                            onClick={() => {
                                                                if (
                                                                    storeAbShopifyFlag !== item?.ab_test_flag?.flag_name
                                                                ) {
                                                                    const showAbExpandedResultsClone = [
                                                                        ...showAbExpandedResults,
                                                                    ]
                                                                    showAbExpandedResultsClone[index] =
                                                                        !showAbExpandedResultsClone[index]
                                                                    setShowAbExpandedResults(showAbExpandedResultsClone)
                                                                }
                                                            }}
                                                        >
                                                            {showAbExpandedResults[index]
                                                                ? 'Collapse A/B Test Details'
                                                                : 'View A/B Test Details'}
                                                        </p>
                                                    </Tooltip>
                                                </div>
                                            ) : item?.workflow_flag ? (
                                                <div className="flex items-center gap-2 text-sm">
                                                    <Tooltip title={WorkflowFlagTooltip}>
                                                        <p className="text-gray-500">
                                                            {item?.workflow_flag}
                                                        </p>
                                                    </Tooltip>
                                                </div>
                                            ) : null}

                                            {showAbExpandedResults[index] ? <AbImpactView workflow={item} /> : null}
                                        </div>
                                    </div>
                                </Fragment>
                            ))}
                        </ul>
                    )}
                </div>
            </Content>
            <Modal
                open={deleteModal !== null}
                closable
                title={
                    <span>
                        <ExclamationCircleFilled className='mr-2 text-[#ffc107]' />
                        Delete Workflow
                    </span>
                }
                okText='Yes, Delete'
                cancelText='No, I Want to Continue'
                onOk={() => {
                    onDelete(deleteModal?.index)
                }}
                onCancel={() => setDeleteModal(null)}
                centered
            >
                <p>Are you sure! you want to Delete Workflow, {deleteModal?.item?.rule_name}</p>
            </Modal>
            <Modal
                open={editWorkflowData !== null}
                closable
                title={
                    <span>
                        <ExclamationCircleFilled className='text-[#ffc107] mr-2' />
                        Changing Workflow Priority
                    </span>
                }
                okText='Ok'
                cancelText='Cancel'
                onOk={() => {
                    setDraggedListItem(null)
                    updateWorkflows()
                }}
                onCancel={handleDragCancel}
                centered
            >
                <p className='ml-6'>Are you sure you want to move priority of workflow</p>
            </Modal>
            <TimelineDrawer
                showHistory={showHistory}
                setShowHistory={setShowHistory}
                workflowTimelineData={workflowTimelineData}
                createdAt={createdAt}
            />
        </Layout>
    ) : current.toLowerCase() === 'recommended(beta)' ? (
        <ReccomendedWorkflow onClick={onClick} />
    ) : (
        <EditWorkflow onBack={onEditBack} editData={isEditing} allWorkflow={allWorkflow} currentindex={currentindex} />
    )


}
