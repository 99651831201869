import { logEvent } from '@library/utilities/userLogEvents/userLogEvents'
import { setBoostAwbShown } from '@store/awb-flows'
import { getBoostAwbShown } from '@store/awb-flows/selectors'
import { getMerchantDetails, getUserDetails } from '@store/user/selectors'
import { ArrowRightOutlined, Button, CloseOutlined, WarningOutlined } from 'gokwik-ui-kit'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const BoostAWB = ({ heading, subHeading, closeable = true }) => {
    const navigate = useNavigate()
    const boostAwbShown = useSelector(getBoostAwbShown)
    const merchant = useSelector(getMerchantDetails)
    const user_details = useSelector(getUserDetails)
    const dispatch = useDispatch()

    if (boostAwbShown) return null;

    const handleBoostAwbClick = () => {
        logEvent(
            'kwikflows_settings_boost_awb_clicked',
            'click',
            'Kwik Flows',
            user_details?.email,
            merchant?.m_id,
            merchant?.short_name,
            user_details?.name,
        )
        navigate('/rto/settings/awb-flows')
    }

    return (
        <>
            <div className='flex justify-between items-center border-2 border-[#FF891B] border-solid bg-[#FF891B0A] rounded-lg p-4 my-4 relative fade-in-delayed'>
                <div className='flex items-start'>
                    <WarningOutlined color='#FF891B' className='mr-2 text-[#FF891B] text-lg mt-1' size={21} />
                    <div className='flex flex-col'>
                        <p className='font-semibold text-lg mt-0'>{heading}</p>
                        <p className='font-normal text-sm text-[#00000073]'>{subHeading}</p>
                    </div>
                </div>
                <Button
                    className='bg-[#C96D17] shadow-sm text-white border-none awb-hover-css mr-4'
                    onClick={handleBoostAwbClick}
                >
                    <span>Boost AWB</span>
                    <ArrowRightOutlined />
                </Button>
                {closeable && <CloseOutlined onClick={() => dispatch(setBoostAwbShown(true))} className='absolute top-2 right-2 text-gray-500' />}
            </div>
        </>
    )
}

export default BoostAWB
