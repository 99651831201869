export const ExternalLink = () => {
    return (
        <svg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g clip-path='url(#clip0_3207_167)'>
                <path
                    d='M8.62679 4H4.62679C4.27317 4 3.93403 4.14048 3.68398 4.39052C3.43393 4.64057 3.29346 4.97971 3.29346 5.33333V12C3.29346 12.3536 3.43393 12.6928 3.68398 12.9428C3.93403 13.1929 4.27317 13.3333 4.62679 13.3333H11.2935C11.6471 13.3333 11.9862 13.1929 12.2363 12.9428C12.4863 12.6928 12.6268 12.3536 12.6268 12V8'
                    stroke='#9DA4AE'
                    stroke-width='1.2'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                />
                <path
                    d='M7.96045 8.66663L13.9604 2.66663'
                    stroke='#9DA4AE'
                    stroke-width='1.2'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                />
                <path
                    d='M10.627 2.66663H13.9603V5.99996'
                    stroke='#9DA4AE'
                    stroke-width='1.2'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                />
            </g>
            <defs>
                <clipPath id='clip0_3207_167'>
                    <rect width='16' height='16' fill='white' transform='translate(0.626953)' />
                </clipPath>
            </defs>
        </svg>
    )
}
