import { RightOutlined } from 'gokwik-ui-kit'
import React, { useEffect, useState } from 'react'

function RenderTableHeader({ currentRtoRiskTab, setCurrentRtoRiskTab, refs, rtoRiskOrders }) {
    // need to change count dynamically
    const [tabHeader, setTabHeader] = useState([
        {
            id: 1,
            title: 'Order Placed',
            count: 0,
            selector: 'placed',
        },
        {
            id: 2,
            title: 'Verification Required',
            count: 0,
            selector: 'verification_required',
        },
        {
            id: 3,
            title: 'Verification in Progress',
            count: 0,
            selector: 'verification_in_progress',
        },
        {
            id: 4,
            title: 'Verified Orders',
            count: 0,
            selector: 'verified_orders',
        },
        {
            id: 5,
            title: 'Unverified Orders',
            count: 0,
            selector: 'unverified_orders',
        },
    ])

    useEffect(() => {
        let tabHeaderClone = [...tabHeader]
        tabHeaderClone = tabHeaderClone.map((item) => {
            const rtoItem = rtoRiskOrders[item.selector]
            if (rtoItem) {
                return { ...item, count: rtoItem.total_items }
            } else {
                return { ...item, count: 0 }
            }
        })
        setTabHeader(tabHeaderClone)
    }, [rtoRiskOrders])

    const handlePillClick = (selector: string) => {
        setCurrentRtoRiskTab(selector)
    }

    return (
        <div className='flex items-center p-2' ref={refs.current[0]}>
            {tabHeader.map(({ id, title, count, selector }, index) => (
                <div key={id} className='flex items-center gap-1'>
                    <div
                        className={`p-1 cursor-pointer ${
                            selector === currentRtoRiskTab &&
                            'text-primary-500 border-solid border border-primary-100 rounded-md bg-primary-25'
                        }`}
                        onClick={() => handlePillClick(selector)}
                    >
                        <span className='flex gap-1 items-center' ref={id !== 1 ? refs.current[id-1] : undefined}>
                            {title}
                            {count ? (
                                <span className='bg-white py-0 px-1.5 border-solid border border-gray-200 rounded text-xs text-black'>
                                    {count}
                                </span>
                            ) : null}
                        </span>
                    </div>
                    {index !== tabHeader.length - 1 ? <RightOutlined /> : null}
                </div>
            ))}
        </div>
    )
}

export default RenderTableHeader
