import { createAsyncThunk, createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit'
import { actionsApi, fetchWorkflowsData } from './api'
import { actionTypes, defaultTieringConfig, secondaryActionTypeVar } from '@library/utilities/constants/constants'
import { getDefaultAbShopifyFlag } from '@library/utilities/helpers/helper'
import { ModalStates, WorkflowAction } from '@library/utilities/interface'

export interface WorkflowData {
    data: any[]
    status: 'idle' | 'loading' | 'failed'
    ab_control_enable?: boolean
    eida_reccomendations?: {
        raw_data: any[]
        last_run: string
    }
    kwikflows_tier_config?: {
        max_ab_workflows: number
        max_active_workflows: number
        enable_tiering: boolean
        start_tier_flow: boolean
        is_freelookup_requested: boolean
        freelookup_period: number
    }
}

export interface ABTest {
    abTestCreateFlag: boolean
    abTestToggle: boolean
    abTreatmentPercentage: number
    abTestShopifyFlag: string
    abTestEnabledOn: string | null
    abTestControlIntervention: string
    abTestFlagId: string | null
}

export interface WorkflowSlice {
    workflowData: WorkflowData
    storeActionType: any[]
    abTest: ABTest
    selectedProducts: any
    modalStates: ModalStates
    workflowAction: WorkflowAction
    workflowAbTestTypeBAction: WorkflowAction
    showKwikFlowTierDrawer: boolean
}
const assignToState = ({
    state,
    key,
    nestedKeys,
    payload,
    status,
}: {
    state: WorkflowSlice
    status: 'idle' | 'loading' | 'failed'
    key: string
    nestedKeys: string[]
    payload: any
}) => {
    if (nestedKeys && nestedKeys.length > 0) {
        nestedKeys.forEach((nestedKey) => {
            state[key][nestedKey].status = status
            if (payload && payload.data) state[key][nestedKey].data = payload.data[nestedKey]
        })
    } else {
        state[key].status = status || 'idle'
        state[key].data = payload.data || []
        state[key].eida_reccomendations = payload.eida_reccomendations || []
        state[key].ab_control_enable = payload.ab_control_enable || false
        state[key].kwikflows_tier_config = payload.kwikflows_tier_config || defaultTieringConfig
    }
    return state
}

const initialState: WorkflowSlice = {
    workflowData: {
        data: [],
        status: 'idle',
    },
    storeActionType: [],
    abTest: {
        abTestCreateFlag: false,
        abTestToggle: false,
        abTreatmentPercentage: 1,
        abTestShopifyFlag: getDefaultAbShopifyFlag(),
        abTestEnabledOn: null,
        abTestControlIntervention: 'allow_cod',
        abTestFlagId: null,
    },
    selectedProducts: null,
    modalStates: {
        isPreviewVisible: false,
        backModalVisible: false,
        submitModalVisible: false,
        tourModalVisible: false,
        showABTestModal: false,
        isSecondaryPreviewVisible: false,
        isTertiaryPreviewVisible: false,
    },
    workflowAction: {
        primary: { action: '' },
        secondary: { action: '' },
        tertiary: { action: '' },
        showSecondaryAction: false,
        showTertiaryAction: false,
    },
    workflowAbTestTypeBAction: {
        primary: { action: actionTypes.allowCod },
        secondary: { action: '' },
        tertiary: { action: '' },
        showSecondaryAction: false,
        showTertiaryAction: false,
        showActionA: true,
    },
    showKwikFlowTierDrawer: false,
}

export const fetchWorkflowsAsync = createAsyncThunk(
    'actions/fetchWorkflows',
    async (
        { key, params }: { key: string; nestedKey?: string; params?: { [key: string]: any } },
        { rejectWithValue },
    ) => {
        const response = await fetchWorkflowsData({ key, params })
        if (response.success) return response
        else return rejectWithValue(response)
    },
)

export const fetchAllWorkflows =
    ({ params }) =>
    async (dispatch: any) => {
        Object.keys(actionsApi).forEach((key) => {
            dispatch(fetchWorkflowsAsync({ key, params }))
        })
    }

export const actionsSlice = createSlice({
    name: 'actions',
    initialState,
    reducers: {
        setWorkflows: (state, action) => {
            state.workflowData.data = action.payload || []
            state.workflowData.status = 'idle'
        },

        setStoreActionType: (state, action) => {
            if (action.payload?.type === secondaryActionTypeVar) {
                if (state.storeActionType.length > 1) {
                    state.storeActionType.pop()
                }
                state.storeActionType.push(action.payload)
            } else {
                state.storeActionType = action.payload || []
            }
        },
        setAbTestCreateFlag: (state, action) => {
            state.abTest.abTestCreateFlag = action.payload
            if (action.payload) {
                state.abTest.abTestToggle = true
            }
        },

        setAbTestToggle: (state, action) => {
            state.abTest.abTestToggle = action.payload
        },

        resetAbActionValues: (state) => {
            state.abTest.abTestCreateFlag = false
            state.abTest.abTestToggle = false
            state.storeActionType = []
        },

        setAbTreatmentPercentage: (state, action) => {
            state.abTest.abTreatmentPercentage = action.payload || 1
        },

        setAbTestShopifyFlag: (state, action) => {
            state.abTest.abTestShopifyFlag = action.payload
        },

        setAbTestSettings: (state, action) => {
            state.abTest = {
                abTestCreateFlag: false,
                abTestToggle: false,
                abTreatmentPercentage: 1,
                abTestShopifyFlag: getDefaultAbShopifyFlag(),
                abTestEnabledOn: null,
                abTestControlIntervention: 'allow_cod',
                abTestFlagId: null,
                ...action.payload,
            }
        },

        setSelectedProducts: (state, action) => {
            state.selectedProducts = action.payload
        },

        setModalStates: (state, action) => {
            state.modalStates = action.payload
        },

        setWorkflowAction: (state, action) => {
            state.workflowAction = action.payload
        },

        setWorkflowAbTestTypeBAction: (state, action) => {
            state.workflowAbTestTypeBAction = action.payload
        },

        setShowKwikFlowTierDrawer: (state, action) => {
            state.showKwikFlowTierDrawer = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchWorkflowsAsync.pending, (state, action) => {
                const nestedKeys = action.meta.arg.nestedKey
                    ? [action.meta.arg.nestedKey]
                    : actionsApi[action.meta.arg.key].nestedKeys || []
                assignToState({ state, key: action.meta.arg.key, nestedKeys, payload: {}, status: 'loading' })
            })
            .addCase(fetchWorkflowsAsync.fulfilled, (state, action) => {
                const nestedKeys = action.meta.arg.nestedKey
                    ? [action.meta.arg.nestedKey]
                    : actionsApi[action.meta.arg.key].nestedKeys || []
                assignToState({ state, key: action.meta.arg.key, nestedKeys, payload: action.payload, status: 'idle' })
            })
            .addCase(fetchWorkflowsAsync.rejected, (state, action) => {
                const nestedKeys = action.meta.arg.nestedKey
                    ? [action.meta.arg.nestedKey]
                    : actionsApi[action.meta.arg.key].nestedKeys || []
                assignToState({ state, key: action.meta.arg.key, nestedKeys, payload: {}, status: 'failed' })
            })
    },
})

export const {
    setWorkflows,
    setStoreActionType,
    setAbTestCreateFlag,
    setAbTestToggle,
    resetAbActionValues,
    setAbTreatmentPercentage,
    setAbTestShopifyFlag,
    setAbTestSettings,
    setModalStates,
    setWorkflowAction,
    setWorkflowAbTestTypeBAction,
    setShowKwikFlowTierDrawer,
} = actionsSlice.actions

export default actionsSlice.reducer
