import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    rtoMerchantConfig: {},
}

const rtoConfigsSlice = createSlice({
    name: 'rtoConfigs',
    initialState,
    reducers: {
        setRtoMerchantConfig: (state, action) => {
            state.rtoMerchantConfig = action.payload
        },
    },
})

export const {
    setRtoMerchantConfig,
} = rtoConfigsSlice.actions
export default rtoConfigsSlice.reducer