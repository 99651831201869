import React, { useEffect, useMemo } from 'react'
import { ppcodActionType } from '@library/utilities/constants/constants'
import { ActionType } from '@library/utilities/interface'
import CodFeesInput from './codFeesInput'
import PpCodUpiInput from './ppcodInput'
import UpiDiscountInput from './upiDiscountInput'
import { getAbTestSettings, getAbTestToggle, getWorkflowAbTestTypeBAction } from '@store/actions/selectors'
import { useSelector } from 'react-redux'
import ApplyFreebieInput from './applyFreebieInput'

interface RenderActionInputProps {
    actionType: ActionType
    merchantDiscountConfig: any,
    setActionType: React.Dispatch<React.SetStateAction<ActionType>>
    primary?: boolean
}

const RenderActionInput: React.FC<RenderActionInputProps> = ({ actionType, setActionType, primary, merchantDiscountConfig }) => {
    const workflowAbTestTypeBAction = useSelector(getWorkflowAbTestTypeBAction)
    const { showActionA } = workflowAbTestTypeBAction
    const abTestToggle = useSelector(getAbTestToggle)
    const abTestSettings = useSelector(getAbTestSettings)
    const abTestEnabledOn = abTestSettings?.abTestEnabledOn
    const isActionDisabled = !!abTestEnabledOn
    const formText = abTestToggle ? (showActionA ? 'abtest-type-a' : 'abtest-type-b') : ''

    useEffect(() => {
        const isPPCODConfig = actionType?.ppcod_config && actionType?.ppcod_config?.length > 0
        if (!isPPCODConfig && actionType.action === 'ppcod_upi') {
            setActionType({
                ...actionType,
                ppcod_config: [
                    {
                        deductionType: ppcodActionType.fixed,
                    },
                ],
            })
        }
    }, [actionType])

    const renderInputByAction = useMemo(() => {
        switch (actionType.action) {
            case 'cod_fees':
                return (
                    <CodFeesInput
                        actionType={actionType}
                        setActionType={setActionType}
                        formText={formText}
                        primary={primary}
                        isActionDisabled={isActionDisabled}
                    />
                )
            case 'ppcod_upi':
                return (
                    <PpCodUpiInput
                        actionType={actionType}
                        setActionType={setActionType}
                        formText={formText}
                        primary={primary}
                        isActionDisabled={isActionDisabled}
                    />
                )
            case 'upi_discount':
                return (
                    <UpiDiscountInput
                        actionType={actionType}
                        setActionType={setActionType}
                        formText={formText}
                        primary={primary}
                        isActionDisabled={isActionDisabled}
                    />
                )
            case 'apply_freebie':
                return <ApplyFreebieInput actionType={actionType} merchantDiscountConfig={merchantDiscountConfig} setActionType={setActionType} isActionDisabled={isActionDisabled} formText={formText} primary={primary} />
            default:
                return null
        }
    }, [actionType, setActionType, primary])

    return <div>{renderInputByAction}</div>
}

export default RenderActionInput
