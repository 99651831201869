export const PointerIcon = () => {
    return (
        <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g clip-path='url(#clip0_2844_683)'>
                <path
                    d='M6.58659 14.636C6.6309 14.8351 6.73504 15.0159 6.88504 15.1541C7.03503 15.2923 7.22371 15.3814 7.42576 15.4093C7.62781 15.4372 7.83356 15.4027 8.01543 15.3104C8.1973 15.218 8.34658 15.0723 8.44325 14.8927L10.1849 12.3152L14.2741 16.4043C14.3567 16.4869 14.4547 16.5524 14.5626 16.5971C14.6704 16.6418 14.7861 16.6648 14.9028 16.6648C15.0196 16.6648 15.1352 16.6418 15.2431 16.5971C15.351 16.5524 15.449 16.4869 15.5316 16.4043L16.4041 15.5318C16.4867 15.4493 16.5522 15.3512 16.5968 15.2434C16.6415 15.1355 16.6645 15.0198 16.6645 14.9031C16.6645 14.7863 16.6415 14.6707 16.5968 14.5628C16.5522 14.4549 16.4867 14.3569 16.4041 14.2743L12.3149 10.1852L14.9091 8.4435C15.0887 8.34676 15.2344 8.19741 15.3267 8.01549C15.419 7.83356 15.4534 7.62776 15.4254 7.4257C15.3974 7.22363 15.3082 7.03497 15.1699 6.88503C15.0316 6.73509 14.8507 6.63103 14.6516 6.58683L3.33325 3.3335L6.58659 14.636Z'
                    stroke='#667085'
                    stroke-width='1.6'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                />
            </g>
            <defs>
                <clipPath id='clip0_2844_683'>
                    <rect width='20' height='20' fill='white' />
                </clipPath>
            </defs>
        </svg>
    )
}
