import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { ColumnProps, InfoCircleOutlined, Table, Tooltip } from 'gokwik-ui-kit'
import { numberFormatter } from '@library/utilities/helpers/helper'
import { updateDataBasedOnTimeframeRiskFlag } from '@library/utilities/helpers/data_filtering'

dayjs.extend(utc)

const metrics = {
    hits: { label: 'RTO API HITS', type: 'number' },
    total_orders: { label: 'ORDERS', type: 'number' },
    cod_orders: { label: 'COD ORDERS', type: 'number' },
    cod_rto_perc: { label: 'RTO COD %', type: 'percentage' },
    rto_overall_perc: { label: 'RTO % (OVERALL)', type: 'percentage' },
    prepaid_share: { label: 'PREPAID SHARE', type: 'percentage' },
    awb_fill_rate_perc: { label: 'AWB FILL RATE %', type: 'percentage' },
    delivered_orders_perc: { label: 'DELIVERED ORDERS %', type: 'percentage' },
    cancelled_orders_perc: { label: 'CANCELLED ORDERS %', type: 'percentage' },
}

const rtoApiHitsIconMsg =
    'The RTO API is triggered when a user proceeds from the Address Page to the Payment Page. This metric indicates the number of users who have reached the payment stage.'
const awbFillRateIconMsg =
    'The AWB Fill Rate measures the proportion of orders for which Gokwik has received delivery status updates. This information is crucial for maintaining a strong RTO Model and assisting you in RTO reduction Action Plan.'

const createColumnsWithMetrics = () => {
    const baseColumns: ColumnProps<any>[] = [
        {
            title: 'DATE',
            dataIndex: 'date',
            key: 'date',
            width: 150,
            render: (val, record) => ({
                children: val,
                props: {
                    rowSpan: record.rowSpan,
                    style: { textAlign: 'center', fontWeight:'bold' },
                },
            }),
        },
        {
            title: 'RISK FLAG',
            dataIndex: 'risk_flag',
            key: 'risk_flag',
            width: 150,
            render: (val, record) => ({
                children: val,
                props: {
                    style: { fontWeight: 'bold' },
                },
            }),
        },
    ]

    const metricColumns = Object.keys(metrics)?.map((metric_key) => {
        const metricInfo = metrics[metric_key]
        return {
            title: (
                <>
                    {metric_key === 'hits' || metric_key === 'awb_fill_rate_perc' ? (
                        <span className="flex items-center">
                        {metricInfo.label}{' '}
                        <Tooltip
                            placement="top"
                            title={metric_key === 'hits' ? rtoApiHitsIconMsg : awbFillRateIconMsg}
                        >
                            <InfoCircleOutlined className="ml-1" />
                        </Tooltip>
                    </span>
                    ) : (
                        metricInfo.label
                    )}
                </>
            ),
            dataIndex: metric_key,
            key: metric_key,
            render: (val, record) => {
                const exactValue = record[metric_key].exact
                const isPercentageAndNaN = metricInfo.type === 'percentage' && isNaN(exactValue);
                const formattedValue = isPercentageAndNaN ? 'Awaited' : record[metric_key].formatted
                const tooltipTitle = isPercentageAndNaN ? 'Metric awaited' : `${exactValue}`;
                return (
                    <Tooltip placement='top' title={`${tooltipTitle}`}>
                        {formattedValue}
                    </Tooltip>
                )
            },
            align: 'right' as const,
            width: 120,
        }
    })

    return [...baseColumns, ...metricColumns]
}

const mapDataToRows = (data, timeframe) => {
    const sortedData = data?.sort((a, b) => {
        if (a.date === b.date) {
            const riskOrder = ['high', 'medium', 'low']
            return riskOrder.indexOf(a.risk_flag) - riskOrder.indexOf(b.risk_flag)
        }
        return dayjs(a.date).unix() - dayjs(b.date).unix()
    })

    return sortedData?.map((item) => {
        const formattedDate = formatDate(item.date, timeframe)

        const row = {
            date: formattedDate,
            risk_flag: item.risk_flag,
        }

        Object.keys(metrics)?.forEach((metric_key) => {
            const metric = metrics[metric_key]
            const value = item[metric_key]
            const isPercentageAndNaN = metric.type === 'percentage' && isNaN(value);
            row[metric_key] = {
                formatted: isPercentageAndNaN ? 'Awaited' : numberFormatter(value, metric.type),
                exact: value,
            }
        })

        return row
    })
}

const formatDate = (date, timeframe) => {
    if (timeframe === 'weekly') {
        return dayjs(date).format('YYYY-MM-DD')
    } else if (timeframe === 'monthly') {
        return dayjs(date).format('MMMM YYYY')
    } else if (timeframe === 'quarterly') {
        const quarter = Math.floor((dayjs(date).month() + 3) / 3)
        return `Q${quarter} ${dayjs(date).year()}`
    }
}

const computeRowSpans = (data) => {
    const spans = []
    let currentRowSpan = 1

    for (let i = 0; i < data.length; i++) {
        if (i > 0 && data[i].date === data[i - 1].date) {
            currentRowSpan++
            spans[i] = 0
        } else {
            spans[i] = currentRowSpan
            currentRowSpan = 1
        }
    }
    spans[0] = 3
    return spans
}

const RiskFlagSummaryTable = ({ analyticsData, timeframe = 'monthly' }) => {
    const originalData = analyticsData?.rtoInsights?.data?.originalData
    const status = analyticsData?.rtoInsights?.status;
    const timeframeDataRiskWise = updateDataBasedOnTimeframeRiskFlag(
        originalData,
        timeframe as 'monthly' | 'weekly' | 'quarterly',
    )

    const tableData = mapDataToRows(timeframeDataRiskWise, timeframe)
    const rowSpans = computeRowSpans(tableData)

    const tableColumns = createColumnsWithMetrics()

    const finalTableData = tableData?.map((row, index) => ({
        ...row,
        rowSpan: rowSpans[index],
    }))

    return (
        <Table
            columns={tableColumns}
            dataSource={finalTableData}
            pagination={false}
            bordered={true}
            scroll={{ x: 'max-content' }}
            loading={status==='loading'}
        />
    )
}

export default RiskFlagSummaryTable
