import { abCreationMessage, ppcodActionType, workflowShouldEnabled } from '@library/utilities/constants/constants'
import { getDefaultAbShopifyFlag } from '@library/utilities/helpers/helper'
import { ActionType, Workflow } from '@library/utilities/interface'
import {
    fetchAllWorkflows,
    setAbTestShopifyFlag,
    setAbTestToggle,
    setAbTreatmentPercentage,
} from '@store/actions'
import { updateWorkflowAsync } from '@store/actions/api'
import {
    getAbTestCreateFlag,
    getAbTestSettings,
    getAbTestShopifyFlag,
    getAbTestToggle,
    getAbTreatmentPercentage,
    getWorkflowData,
} from '@store/actions/selectors'
import { getMerchantDetails, getUserConfig } from '@store/user/selectors'
import {
    Alert,
    Button,
    InfoCircleFilled,
    InfoCircleOutlined,
    Input,
    message,
    Modal,
    Slider,
    Switch,
    Tag,
    Tooltip,
} from 'gokwik-ui-kit'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AbTestAction from './abTestAction'

interface AbTestViewProps {
    allWorkflow: Workflow[]
    currentIndex: number
    onBack: (workflows?: Workflow[]) => void
}

function AbTestView({ allWorkflow, currentIndex, onBack }: AbTestViewProps) {
    const [showDisableABModal, setShowDisableABModal] = useState(false)
    const [inputShopifyFlag, setInputShopifyFlag] = useState(undefined)
    const abTestToggle = useSelector(getAbTestToggle)
    const abTreatmentPercentage = useSelector(getAbTreatmentPercentage)
    const abTestShopifyFlag = useSelector(getAbTestShopifyFlag)
    const abTestSettings = useSelector(getAbTestSettings)
    const abTestEnabledOn = abTestSettings?.abTestEnabledOn ?? false
    const isActionDisabled = !abTestToggle || !!abTestEnabledOn
    const merchant = useSelector(getMerchantDetails)
    const { data: workflowData } = useSelector(getWorkflowData)
    const dispatch = useDispatch()
    const abTestCreateFlag = useSelector(getAbTestCreateFlag)
    const [showEditABModal, setShowEditABModal] = useState(false)
    const merchantConfig = useSelector(getUserConfig)
    const rtoActionsConfigs = merchantConfig?.rto_actions_config
    const abEditWaitDays = rtoActionsConfigs?.ab_edit_wait_days || 15
    const abEditMaxLimit = rtoActionsConfigs?.ab_edit_max_limit || 2
    const currentWorkflow = allWorkflow[currentIndex];
    const currentAbEditCount = currentWorkflow?.ab_edit_count || 0;
    const hasTreatmentPercentageChanged = abTreatmentPercentage !== (100-currentWorkflow?.ab_control_perc);

    useEffect(() => {
        if (!abTestCreateFlag) {
            // Only run if abTestCreateFlag is false
            // checking so that no 2 workflows have same shopify flag.
            const isDuplicateShopifyFlagWorkflow = workflowData?.find(
                (item: Workflow) => item?.ab_test_flag?.flag_name === abTestShopifyFlag,
            )
            const duplicateShopifyFlag = isDuplicateShopifyFlagWorkflow?.ab_test_flag?.flag_name ?? ''
            let newShopifyFlag = getDefaultAbShopifyFlag()
            while (newShopifyFlag === duplicateShopifyFlag) {
                newShopifyFlag = getDefaultAbShopifyFlag()
            }
            dispatch(setAbTestShopifyFlag(newShopifyFlag))
        }
    }, [workflowData, abTestCreateFlag]) // Added abTestCreateFlag as a dependency

    const handleToggle = (checked: boolean) => {
        if (!checked) {
            setShowDisableABModal(true)
        } else {
            dispatch(setAbTestToggle(checked))
            abTestEnabledOn && callAbTestToggleAsync(checked)
            onBack()
        }
    }

    const callAbTestToggleAsync = (isAbEnabled: boolean) => {
        const updatedWorkflows = [...allWorkflow]
        updatedWorkflows[currentIndex] = {
            ...updatedWorkflows[currentIndex],
            updated_at: new Date().toISOString(),
            is_ab_test_enabled: isAbEnabled,
            ...(isAbEnabled
                ? {
                      ab_test_enabled_on: new Date().toISOString(),
                      ab_test_flag: {
                          flag_name: abTestShopifyFlag,
                          flag_id: null,
                      },
                      ab_test_disabled_on: null,
                  }
                : {
                      ab_test_disabled_on: new Date().toISOString(),
                  }),
        }
        callUpdateWorkflow(
            merchant.id,
            updatedWorkflows,
            `A/B Test ${isAbEnabled ? 'Applied' : 'Removed'} Successfully`,
            'A/B Test Updation Failed',
        )
    }

    const callUpdateWorkflow = async (
        merchantId: number,
        updatedWorkflows: Workflow[],
        successMessage: string,
        errorMessage: string,
    ) => {
        try {
            let response = await updateWorkflowAsync(updatedWorkflows, merchantId)
            if (response.success && response.data) {
                message.success(successMessage)
                fetchWorkflowAsync()
            }
        } catch (err) {
            message.error(errorMessage)
            console.log(err)
        }
    }

    const fetchWorkflowAsync = () => {
        dispatch(
            //@ts-ignore
            fetchAllWorkflows({
                params: {
                    merchant_id: merchant.id,
                },
            }),
        )
    }

    const sliderMarks = {
        1: '1',
        99: '99',
    }
    const percentageSplitChange = (value: number) => {
        dispatch(setAbTreatmentPercentage(value))
    }

    const getChosenActionType = (actionType: ActionType) => {
        if (!actionType?.action) return

        switch (actionType.action) {
            case 'cod_fees':
                return `COD Fee Rs ${actionType.cod_fees}`

            case 'cod_captcha':
                return 'COD Captcha'

            case 'cod_prompt':
                return 'COD Prompt'
            
            case 'apply_freebie':
                return 'Apply Freebie'

            case 'upi_discount':
                return `UPI Discount ${actionType.upi_discount}%${
                    actionType.discount_upto ? ` upto ${actionType.discount_upto}` : ''
                }`

            case 'ppcod_upi': {
                const ppcodConfig = actionType.ppcod_config?.[0]
                if (!ppcodConfig) return

                return ppcodConfig?.deductionType === ppcodActionType.fixed
                    ? `PPCOD Fixed Rs ${ppcodConfig?.fixedDeduction}`
                    : `PPCOD Percentage ${ppcodConfig?.percentageDeduction * 100}% between ${
                          ppcodConfig?.minimumDeduction
                      } and ${ppcodConfig?.maximumDeduction}`
            }

            case 'cod_blocking':
                return 'COD Blocking'

            case 'allow_cod':
                return 'Allow COD'
            default:
                return ''
        }
    }

    const handleInputSave = (e) => {
        e.preventDefault()
        const flagToUse = inputShopifyFlag !== undefined ? inputShopifyFlag : abTestShopifyFlag
        if (flagToUse.trim() === '') {
            message.error('Please provide a flag name, or a default will be automatically assigned.')
            return
        }
        dispatch(setAbTestShopifyFlag(flagToUse))
    }

    const isCurrentWorkflowDisabled = useMemo(() => {
        if (allWorkflow && allWorkflow?.length && currentIndex >= 0) {
            return !allWorkflow?.[currentIndex]?.is_enabled
        }
        return true
    }, [allWorkflow])

    const handleEditAb = () => {
        const currentDate = new Date();
        const abTestEnabledDate = abTestEnabledOn ? new Date(abTestEnabledOn) : null;
        const daysDifference = Math.floor((currentDate.getDate() - abTestEnabledDate.getDate()));

        if (currentAbEditCount >= abEditMaxLimit) {
            message.error("Max A/B Test edit limit has exceeded for this workflow. Please create a new workflow");
            return; // Exit the function if limit is exceeded
        }

        if(daysDifference < abEditWaitDays) {
            setShowEditABModal(true)
        } else {
            callAbTestEditAsync()
            onBack()
        }
    }

    const callAbTestEditAsync = () => {
        const updatedWorkflows = [...allWorkflow]
        updatedWorkflows[currentIndex] = {
            ...updatedWorkflows[currentIndex],
            updated_at: new Date().toISOString(),
            is_ab_test_enabled: true,
            ab_test_enabled_on: new Date().toISOString(),
            ab_test_flag: {
                flag_name: abTestShopifyFlag,
                flag_id: null,
            },
            ab_test_disabled_on: null,
            ab_control_perc: 100 - abTreatmentPercentage,
            ab_edit_count: currentAbEditCount+1,
        }
        callUpdateWorkflow(
            merchant.id,
            updatedWorkflows,
            `A/B Test Updated Successfully`,
            'A/B Test Updation Failed',
        )
    }

    return (
        <div className='flex flex-col gap-4'>
            <div className='flex items-center justify-between'>
                <p className='text-[#00325E] text-base font-semibold'>A/B Testing Active</p>
                <Tooltip
                    placement='bottom'
                    title={workflowShouldEnabled}
                    overlayStyle={{
                        display: !isCurrentWorkflowDisabled && 'none',
                    }}
                >
                    <Switch
                        defaultChecked
                        onChange={handleToggle}
                        checked={abTestToggle}
                        disabled={isCurrentWorkflowDisabled}
                    />
                </Tooltip>
            </div>
            <div className='flex items-center gap-2 text-gray-400 text-sm'>
                <p>Run A/B Testing</p>
                <Tooltip placement='bottom' title={abCreationMessage}>
                    <InfoCircleOutlined />
                </Tooltip>
            </div>
            <div>
                <p className='text-sm font-normal mb-6'>Choose your percentage split</p>
                <Slider
                    marks={sliderMarks}
                    min={1}
                    max={99}
                    value={abTreatmentPercentage}
                    onChange={percentageSplitChange}
                />
            </div>
            <div>
                <div className='flex items-center'>
                    <h6 className='m-0 ml-2.5 text-base font-semibold leading-6 mb-5'>Take Action(s)</h6>
                </div>
                <AbTestAction treatMentPercentage={abTreatmentPercentage} />
            </div>
            <div>
                <p className='mb-2 font-semibold'>Set Shopify Flag Name</p>
                <div className='flex items-center gap-2'>
                    <Input
                        placeholder='Enter Shopify Flag...'
                        value={inputShopifyFlag !== undefined ? inputShopifyFlag : abTestShopifyFlag}
                        onChange={(e) => setInputShopifyFlag(e.target.value)}
                        type='text'
                        disabled={isActionDisabled}
                    />
                    <Button type='primary' onClick={handleInputSave} disabled={isActionDisabled}>
                        Save
                    </Button>
                </div>
                <p className='mt-4 mb-2 font-semibold'>Shopify Flag for Type A</p>
                <Tag variant='default'>{abTestShopifyFlag}_Treatment</Tag>

                <p className='mt-4 mb-2 font-semibold'>Shopify Flag for Type B</p>
                <Tag variant='default'>{abTestShopifyFlag}_Control</Tag>
            </div>
            {abTestEnabledOn && (
                <Tooltip
                    placement='bottom'
                    title={workflowShouldEnabled}
                    overlayStyle={{
                        display: !isCurrentWorkflowDisabled && 'none',
                    }}
                >
                    <Button
                        key='submit'
                        type='primary'
                        onClick={() => handleEditAb()}
                        disabled={isCurrentWorkflowDisabled || !hasTreatmentPercentageChanged}
                    >
                        Apply A/B Test
                    </Button>
                </Tooltip>
            )}
            <Modal
                title={
                    <div className='flex items-center gap-4 flex-col'>
                        <InfoCircleFilled style={{ fontSize: '72px', color: '#004b8d' }} />
                        <p className='font-semibold text-xl'>Disable A/B Test</p>
                    </div>
                }
                open={showDisableABModal}
                onOk={() => setShowDisableABModal(false)}
                onCancel={() => setShowDisableABModal(false)}
                footer={[
                    <div className='flex items-center justify-center gap-1 mt-6'>
                        <Button
                            key='submit'
                            type='primary'
                            onClick={() => {
                                dispatch(setAbTestToggle(false))
                                setShowDisableABModal(false)
                                abTestEnabledOn && callAbTestToggleAsync(false)
                                onBack()
                            }}
                        >
                            Yes
                        </Button>
                        ,
                        <Button key='back' onClick={() => setShowDisableABModal(false)}>
                            No
                        </Button>
                    </div>,
                ]}
                width={400}
                centered
            >
                <div className='text-sm flex flex-col gap-4 text-[#000000E0] mt-4'>
                    <p className='text-center'>Are you sure that you want to disable A/B Test for this workflow?</p>
                    <Alert message={'If not please set a value between 1-99'} type='info' showIcon />
                </div>
            </Modal>
            <Modal
                title={
                    <div className='flex items-center gap-4 flex-col'>
                        <InfoCircleFilled style={{ fontSize: '36px', color: '#004b8d' }} />
                        <p className='font-semibold text-xl'>Changing AB test configs so soon?</p>
                    </div>
                }
                open={showEditABModal}
                onOk={() => setShowEditABModal(false)}
                onCancel={() => setShowEditABModal(false)}
                footer={[
                    <div className='flex items-center justify-center gap-2 mt-6'>
                        <Button
                            key='submit'
                            type='primary'
                            onClick={() => {
                                //dispatch(setAbTestToggle(false))
                                setShowEditABModal(false)
                                callAbTestEditAsync()
                                onBack()
                            }}
                        >
                            Yes, I confirm
                        </Button>
                        <Button key='back' onClick={() => setShowEditABModal(false)}>
                            No, Don’t change
                        </Button>
                    </div>,
                ]}
                width={400}
                centered
            >
                <div className='text-sm flex flex-col gap-4 text-[#000000E0] mt-4'>
                    <p className='text-center'>Gokwik recommends you to wait until impact on RTO rate is evaluated which usually takes upto 15 days for all AWB statuses to mature.</p>
                </div>
            </Modal>
        </div>
    )
}

export default AbTestView
