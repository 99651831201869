import { Layout, Tag } from 'gokwik-ui-kit'
import { useSelector } from 'react-redux'
import { getWorkflowData } from '@store/actions/selectors'

const { Sider } = Layout

const Section = ({ title, items, badgeCount, tagContent, showTiering }) => (
    <div>
        <h3 className='text-gray-500 text-xs font-semibold mb-3 flex items-center'>
            {title}
            {badgeCount && (
                <span className='ml-1 bg-gray-500 text-white rounded-full w-4 h-4 flex items-center justify-center text-xs'>
                    {badgeCount}
                </span>
            )}
            {tagContent && (
                <span className='ml-1 bg-[#EAECF5] rounded-sm p-1 text-[#4E5BA6] flex items-center justify-center text-xs'>
                    {tagContent}
                </span>
            )}
        </h3>
        <ul className='space-y-2 list-none p-0'>
            {items.map(({ label, isActive, id, showProTag }) => (
                <li
                    key={label}
                    className={`px-3 py-2 rounded cursor-pointer !pl-0 truncate ${
                        isActive ? 'bg-gray-100 text-blue-600' : 'hover:bg-gray-50'
                    }`}
                    onClick={() => {
                        if (id) {
                            const element = document.getElementById(id)
                            if (element) {
                                element.scrollIntoView({ behavior: 'smooth' })
                            }
                        }
                    }}
                >
                    {label}
                    {showProTag && showTiering && (
                        <Tag color='#004B8D' className='!text-white !py-[0rem] !px-2 !ml-2'>
                            Pro
                        </Tag>
                    )}
                </li>
            ))}
        </ul>
    </div>
)

const SidebarMenu = ({ filteredSections }) => {
    const workflowDetails = useSelector(getWorkflowData)
    const showTiering = workflowDetails?.show_tiering ? true : false
    return (
        <Sider
            theme='light'
            width={250}
            style={{
                background: '#fff',
                border: '1px solid #e0e0e0',
                padding: '16px',
                borderRadius: '10px',
                height: 'fit-content',
                margin: '1rem',
                position: 'fixed',
            }}
        >
            <div className='flex flex-col'>
                {filteredSections?.map(({ title, items, badgeCount, tagContent }) => (
                    <Section
                        key={title}
                        title={title}
                        items={items}
                        badgeCount={badgeCount}
                        tagContent={tagContent}
                        showTiering={showTiering}
                    />
                ))}
            </div>
        </Sider>
    )
}

export default SidebarMenu
