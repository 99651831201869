import { useState, useEffect, useMemo, useRef, createRef } from 'react'
import {
    Table,
    Button,
    Tooltip,
    message,
    EyeFilled,
    SearchOutlined,
    RedoOutlined,
    Col,
    DownloadOutlined,
    Alert,
    InfoCircleOutlined,
    WarningFilled,
} from 'gokwik-ui-kit'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { filterDateFormatter, downloadToCSV, updateBreadcrumbs } from '@gokwik/utilities'
import { internalUserColumns, merchantUserColumns } from './columnsData'
import { getMerchantPlatform, getMerchantDetails, isMerchantSelector, getUserConfig, getUserDetails } from '@store/user/selectors'
import RenderSearchFilters from '@library/components/search-filter'
import relativeTime from 'dayjs/plugin/relativeTime'
import { callUpdateKCConfig, fetchRtoEnabledTableData, triggerKwikChatAPI, updateVerificationStatus } from './api'
import { setUserConfigData } from '@store/user'
import { KCConfig, RTOTableData } from '@library/utilities/interface'
import High from '@library/images/high.svg'
import Low from '@library/images/low.svg'
import BadAddress from '@library/images/badAddress.svg'
import GoodAddress from '@library/images/goodAddress.svg'
import KwikChatDrawer from '@library/components/post_order_verification/drawer/kcDrawer'
import RenderTour from '@library/components/post_order_verification/tour/demoTour'
import RenderTableHeader from '@library/components/post_order_verification/table-header'
import WhatsAppPreview from '@library/components/post_order_verification/whatsapp-preview'
import EmptyPageData from '@library/components/empty-page-data'
import { logEvent } from '@library/utilities/userLogEvents/userLogEvents'
dayjs.extend(relativeTime)
const today = dayjs()
const formattedDate = filterDateFormatter(today)
const formattedYesterdayDate = filterDateFormatter(today.subtract(1, 'days'))
const filterTypeMappings = {
    placed: 0,
    verification_required: 1,
    verification_in_progress: 2,
    verified_orders: 3,
    unverified_orders: 4,
}

export default function RTOKwikChatOrders() {
    const isMerchantUser = useSelector(isMerchantSelector)
    const merchantPlatform = useSelector(getMerchantPlatform)
    const merchantDetails = useSelector(getMerchantDetails)
    const user_details = useSelector(getUserDetails)
    const merchant_id = merchantDetails.id
    const merchantConfig = useSelector(getUserConfig)
    const kcConfigs = merchantConfig?.kc_config
    const kcMessages = merchantConfig?.kc_messages
    const [currentRtoRiskTab, setCurrentRtoRiskTab] = useState('verification_required')
    const [showKwikChatDrawer, setShowKwikChatDrawer] = useState(false)
    const [actionButtonDisable, setActionButtonDisable] = useState(false)
    const [tourVisible, setTourVisible] = useState<boolean>(false)
    const CUSTOMER_HIGH_RISK_THRESHOLD = Number(process.env.CUSTOMER_HIGH_RISK_THRESHOLD) || 0.6
    const ADDRESS_QUALITY_THRESHOLD = Number(process.env.GOOD_ADDRESS_QUALITY_THRESHOLD) || 0.8
    const refs = useRef([...Array(7)].map(() => createRef<HTMLDivElement>()))
    const { selected_products } = merchantConfig;
    const isRtoRiskIncluded = kcConfigs?.include_rto_risk && selected_products?.rto || false;

    const [rtoRiskOrders, setRtoRiskOrders] = useState({
        placed: {
            data: [],
            loading: true,
        },
        verification_required: {
            data: [],
            loading: true,
        },
        verification_in_progress: {
            data: [],
            loading: true,
        },
        verified_orders: {
            data: [],
            loading: true,
        },
        unverified_orders: {
            data: [],
            loading: true,
        },
    })
    const [rtoParams, setRtoParams] = useState<any>({
        page: 0,
        page_size: 25,
        from_date: formattedYesterdayDate,
        to_date: formattedDate,
        merchant_id,
        filter_type: 0,
    })
    const [showWhatsAppPreview, setShowWhatsAppPreview] = useState(false)
    const [currentOrder, setCurrentOrder] = useState(null)
    const kcOnboardingStatus = kcConfigs?.kc_onboarding_status
    const tellephantID = kcConfigs?.tellephant_id
    const dispatch = useDispatch()

    if (merchantConfig && !isRtoRiskIncluded) {
        return (
            <EmptyPageData
                title="You currently don't have access to this page!"
                icon={<WarningFilled className='text-[#ffc107] text-7xl' />}
            />
        )
    }

    useEffect(() => {
        let breadcrumbs = [
            {
                key: 'rto-kwikcheck',
                href: '/rto/postOrderVerification',
                text: 'RTO Orders',
            },
        ]
        updateBreadcrumbs((prev) => [prev[0], ...breadcrumbs])
    }, [])

    const fetchRtoEnabledTableDataAsync = async (payload: RTOTableData, filterType: string) => {
        setRtoRiskOrders((prev) => ({ ...prev, [filterType]: { loading: true } }))
        const response = await fetchRtoEnabledTableData(payload)
        setRtoRiskOrders((prev) => ({ ...prev, [filterType]: { ...(response?.data || {}), loading: false } }))
        return response
    }

    const fetchAllRtoEnabledTableData = (
        defaultParams,
        filterTypes = [
            'verification_required',
            'verification_in_progress',
            'verified_orders',
            'unverified_orders',
            'placed',
        ],
    ) => {
        try {
            const promises = filterTypes?.map((param) =>
                fetchRtoEnabledTableDataAsync({ ...defaultParams, filter_type: filterTypeMappings[param] }, param),
            )
            Promise.allSettled(promises)
        } catch (err) {
            console.log('Error in fetching RTO Risk Order Data', err.message)
        }
    }

    useEffect(() => {
        logEvent('kwik_rto_verify_order_clicked', 'click', 'Kwik RTO Verify Order', user_details?.email, merchantDetails?.m_id, merchantDetails?.short_name, user_details?.name);
    }, [merchantDetails,user_details])

    useEffect(() => {
        fetchAllRtoEnabledTableData(rtoParams, undefined)
    }, [])

    useEffect(() => {
        if (kcConfigs && !kcConfigs?.rto_tour_shown) {
            setTourVisible(true)
        }
    }, [kcConfigs])

    const kcOnboardingStatusMappings = {
        verification_not_initiated: 0,
        verification_initiated: 1,
        verification_completed: 2,
    }

    const handleActionClick = async (moid: string, addressScore: number, rtoRate: number, rtoRiskFlag: string) => {
        if (
            kcOnboardingStatus === kcOnboardingStatusMappings['verification_not_initiated'] ||
            kcOnboardingStatus === kcOnboardingStatusMappings['verification_initiated']
        ) {
            setShowKwikChatDrawer(true)
        } else if (kcOnboardingStatus === kcOnboardingStatusMappings['verification_completed']) {
            const confirmationType = getConfirmationStatus(addressScore, rtoRate, rtoRiskFlag)
            const response = await triggerKwikChatAPI(moid, confirmationType, tellephantID)
            if (response?.data?.statusCode === '202') {
                message.success('Verification Initiated!')
                const rtoWebhookResponse = await updateVerificationStatus(moid, confirmationType)
                if (rtoWebhookResponse?.data?.status_code === 200) {
                    fetchAllRtoEnabledTableData(rtoParams, ['verification_required', 'verification_in_progress'])
                } else {
                    setActionButtonDisable(true)
                }
            } else {
                message.error('Error in Confirmation Request')
                console.log('Error in KwikChat API Call', response?.data?.status_code)
            }
        }
    }

    const getConfirmationStatus = (addressScore: number, rtoRate: number, rtoRiskFlag: string) => {
        if (addressScore > ADDRESS_QUALITY_THRESHOLD) {
            // Bad Quality Address & High RTO Customer || Low RTO Risk Customer
            return 'Address'
        } else if (addressScore <= ADDRESS_QUALITY_THRESHOLD && (rtoRate >= CUSTOMER_HIGH_RISK_THRESHOLD || rtoRiskFlag?.toLowerCase()?.includes('high'))) {
            // Good Quality Address & High RTO Customer
            return 'Order'
        }
    }

    const getVerificationStatus = (text: number, record: any) => {
        const verified = [1, 2, 3, 5, 7]
        const unverified = [6, 8]
        const verificationReqd = [0, 10]
        const verificationInProgress = [4, 9]
        if (verified.includes(text)) {
            return 'Verified'
        } else if (unverified.includes(text)) {
            return 'Unverified'
        } else if (verificationReqd.includes(text)) {
            if (
                record.address_score > ADDRESS_QUALITY_THRESHOLD ||
                record.rto_rate >= CUSTOMER_HIGH_RISK_THRESHOLD || 
                record?.rto_risk_flag?.toLowerCase()?.includes('high')
            ) {
                if (currentRtoRiskTab === 'verification_required') {
                    return 'Verification Required'
                } else if (currentRtoRiskTab === 'unverified_orders') {
                    return 'Unverified'
                }
            } else {
                //unverified for low rto risk and good address
                return 'Verification Not Required'
            }
        } else if (verificationInProgress.includes(text)) {
            if (currentRtoRiskTab === 'verification_in_progress') {
                //bad address and high rto
                return 'Verification InProgress'
            } else if (currentRtoRiskTab === 'unverified_orders') {
                return 'Unverified'
            }
        }
    }

    const onEyeIconClick = (order) => {
        setShowWhatsAppPreview(true)
        setCurrentOrder(order)
    }

    const handleColumnData = (temp: any[]) => {
        temp.splice(
            temp.findIndex((item) => item.dataIndex === 'rto_risk_flag'),
            1,
            {
                title: (
                    <span className='gap-2 flex'>
                        Risk Profile{' '}
                        <Tooltip
                            title={`The below risk flag, based on Gokwik's RTO Risk Intelligence, identifies high-customers and addresses orders with 70-75% accuracy.`}
                        >
                            <InfoCircleOutlined />
                        </Tooltip>
                    </span>
                ),
                dataIndex: 'rto_rate',
                render: (text: string, record: any) => (
                    <>
                        <div>
                            {(record?.rto_rate >= CUSTOMER_HIGH_RISK_THRESHOLD || record?.rto_risk_flag?.toLowerCase()?.includes('high')) ? (
                                <span>
                                    <span>
                                        <img src={High} alt='high' className='risk-images' /> &nbsp; High RTO Risk
                                    </span>
                                </span>
                            ) : (
                                <span>
                                    <span>
                                        <img src={Low} alt='low' className='risk-images' /> &nbsp; Low RTO Risk
                                    </span>
                                </span>
                            )}
                        </div>
                        <div>
                            {record?.address_score ? (
                                record.address_score <= ADDRESS_QUALITY_THRESHOLD ? (
                                    <span>
                                        <img src={GoodAddress} alt='goodaddress' className='risk-images' /> &nbsp; Good
                                        Address Quality
                                    </span>
                                ) : (
                                    <span>
                                        <img src={BadAddress} alt='badaddress' className='risk-images' /> &nbsp; Bad
                                        Address Quality
                                    </span>
                                )
                            ) : (
                                <span>-</span>
                            )}
                        </div>
                    </>
                ),
                width: 200,
                align: 'left',
            },
        )

        temp.splice(
            2,
            0,
            temp.splice(
                temp.findIndex((item) => item.dataIndex === 'rto_rate'),
                1,
            )[0],
        )

        temp.splice(3, 0, {
            title: <span>Verification Status</span>,
            dataIndex: 'verification_status',
            width: 150,
            render: (text, record) => <span>{getVerificationStatus(text, record)}</span>,
            hidden: currentRtoRiskTab === 'placed',
            align: 'center',
        })

        temp.splice(4, 0, {
            title: <span>Verification Remark</span>,
            dataIndex: 'verification_remark',
            width: 200,
            render: (text, record) => (
                <>
                    <p>{record?.verification_remark?.remark || ''}</p>
                    {(currentRtoRiskTab === 'unverified_orders' ||
                        currentRtoRiskTab === 'verification_in_progress') && (
                        record?.verification_remark?.timestamp ?     
                            <p className='text-xs text-gray-500'>
                                Message sent {dayjs(record?.verification_remark?.timestamp).fromNow()}
                            </p> : 
                        null
                    )}
                </>
            ),
            hidden: currentRtoRiskTab === 'placed' || currentRtoRiskTab === 'verification_required',
            align: 'center',
        })
 
        temp.splice(5, 0, {
            title: <span>Delivery Status</span>,
            dataIndex: 'status_info_new',
            width: 150,
            render: (text) => <span>{text || ''}</span>,
            hidden: currentRtoRiskTab !== 'verified_orders' && currentRtoRiskTab !== 'unverified_orders',
            align: 'center',
        });        

        temp.splice(6, 0, {
            title: <span>{merchantPlatform === 'shopify' ? 'Shopify Order Number' : 'Platform Order ID'}</span>,
            dataIndex: merchantPlatform === 'shopify' ? 'order_name' : 'moid',
            width: 180,
        })

        temp.splice(7, 0, {
            title: <span>{merchantPlatform === 'shopify' ? 'Shopify Order Status' : 'Platform Order Status'}</span>,
            dataIndex: 'm_order_status',
            width: 180,
            ellipsis: true,
            render: (text) => (
                <Tooltip placement='topLeft' title={text}>
                    <span>{`${text}`}</span>
                </Tooltip>
            ),
            align: 'center',
        })

        temp.push({
            title: <span>Take Action</span>,
            width: 150,
            render: (text, record, index) => (
                <div className='flex items-center'>
                    <Button
                        onClick={() => handleActionClick(record.moid, record.address_score, record.rto_rate, record?.rto_risk_flag)}
                        type='link'
                        disabled={actionButtonDisable}
                    >
                        <span ref={index === 0 ? refs.current[5] : undefined}>
                            Confirm {getConfirmationStatus(record.address_score, record.rto_rate, record?.rto_risk_flag)}
                        </span>
                    </Button>
                    <span
                        className='rounded-md bg-white shadow-md px-1 border border-solid border-gray-200'
                        ref={index === 0 ? refs.current[6] : undefined}
                    >
                        <EyeFilled onClick={() => onEyeIconClick(record)} />
                    </span>
                </div>
            ),
            fixed: 'right',
            hidden: currentRtoRiskTab !== 'verification_required',
        })
        return temp
    }

    const merchantColumns = useMemo(() => {
        const temp = [...merchantUserColumns]
        return handleColumnData(temp)
    }, [merchantPlatform, currentRtoRiskTab, rtoParams, rtoRiskOrders, actionButtonDisable])

    const internalUseColumns = useMemo(() => {
        const temp = [...internalUserColumns]
        return handleColumnData(temp)
    }, [merchantPlatform, currentRtoRiskTab, rtoParams, rtoRiskOrders, actionButtonDisable])

    const handlePaginationChange: any = async (current: number, pageSize?: number) => {
        setRtoParams((prev) => ({ ...prev, page: current - 1, page_size: pageSize }))
        const idx = filterTypeMappings[currentRtoRiskTab]
        fetchRtoEnabledTableDataAsync(
            {
                ...rtoParams,
                page: current - 1,
                page_size: pageSize,
                filter_type: Number(idx),
            },
            currentRtoRiskTab,
        )
        return
    }

    const handleSearchClick = (e) => {
        e?.preventDefault()
        if (rtoParams.from_date < formattedYesterdayDate) {
            message.error(`Please don't select dates earlier than yesterday!`)
            return
        }
        fetchAllRtoEnabledTableData({ ...rtoParams, page: 0 }, undefined)
        logEvent('kwik_rto_verify_order_search_clicked', 'click', 'Kwik RTO Verify Order', user_details?.email,merchantDetails?.m_id, merchantDetails?.short_name, user_details?.name)
    }

    const reset = async (e) => {
        e.preventDefault()
        const newRtoParams = {
            page: 0,
            page_size: 25,
            from_date: formattedYesterdayDate,
            to_date: formattedDate,
            merchant_id,
            filter_type: 0,
        }
        setRtoParams(newRtoParams)
        fetchAllRtoEnabledTableData(newRtoParams, undefined)
        logEvent('kwik_rto_verify_order_reset_clicked', 'click', 'Kwik RTO Verify Order', user_details?.email,merchantDetails?.m_id, merchantDetails?.short_name, user_details?.name)
    }

    const updateKCConfig = async (payload: KCConfig) => {
        let response
        try {
            response = await callUpdateKCConfig(payload)
            if (response.data.status_code === 200) {
                dispatch(setUserConfigData({ ...merchantConfig, ...payload }))
            }
        } catch (error) {
            console.error('Error KC Configs Updation', error)
        }
        return response.data
    }

    const getRiskProfile = (address_score: number, rto_rate: number, rto_risk_flag:string) => {
        if (address_score > ADDRESS_QUALITY_THRESHOLD && (rto_rate >= CUSTOMER_HIGH_RISK_THRESHOLD || rto_risk_flag?.toLowerCase()?.includes('high'))) {
            return 'High RTO Risk, Bad Address'
        } else if (address_score <= ADDRESS_QUALITY_THRESHOLD && (rto_rate >= CUSTOMER_HIGH_RISK_THRESHOLD || rto_risk_flag?.toLowerCase()?.includes('high'))) {
            return 'High RTO Risk, Good Address'
        } else if (address_score > ADDRESS_QUALITY_THRESHOLD && rto_rate < CUSTOMER_HIGH_RISK_THRESHOLD) {
            return 'Low RTO Risk, Bad Address'
        } else {
            return 'Low RTO Risk, Good Address'
        }
    }

    const filterAndDownloadCSVData = (data: any[]) => {
        const finalDataColumns =
            currentRtoRiskTab !== 'verification_required'
                ? [
                      'Order #',
                      'Order Status',
                      'Risk Profile',
                      'Verification Status',
                      'Verification Remark',
                      'Shopify Order Number',
                      'Shopify Order Status',
                  ]
                : [
                      'Order #',
                      'Order Status',
                      'Risk Profile',
                      'Verification Status',
                      'Shopify Order Number',
                      'Shopify Order Status',
                  ]
        const dataKeys =
            currentRtoRiskTab !== 'verification_required'
                ? [
                      'order_number',
                      'order_status',
                      'risk_profile',
                      'verification_status',
                      'verification_remark',
                      'order_name',
                      'm_order_status',
                  ]
                : [
                      'order_number',
                      'order_status',
                      'risk_profile',
                      'verification_status',
                      'order_name',
                      'm_order_status',
                  ]
        data.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()) //descending
        const filterData = data?.map((item) => {
            return {
                order_number: item.order_number,
                order_status: item.order_status,
                order_name: item.order_name,
                m_order_status: item.m_order_status,
                risk_profile: getRiskProfile(item.address_score, item.rto_rate, item?.rto_risk_flag),
                verification_status: getVerificationStatus(item.verification_status, {
                    address_score: item.address_score,
                    rto_rate: item.rto_rate,
                    rto_risk_flag: item.rto_risk_flag
                }),
                verification_remark: item.verification_remark?.remark,
            }
        })
        downloadToCSV(dataKeys, finalDataColumns, filterData, 'orders_data', { addSerialNumber: true })
        return
    }

    const handleDownloadCSV = async () => {
        const response = await fetchRtoEnabledTableData({
            ...rtoParams,
            page: 0,
            page_size: 10000,
            filter_type: filterTypeMappings[currentRtoRiskTab],
        })

        if (response?.data) {
            filterAndDownloadCSVData(response.data.data)
            message.success('CSV Downloaded!')
        } else {
            message.error('Error Downloading CSV!')
        }

        logEvent('kwik_rto_verify_order_export_csv_clicked', 'click', 'Kwik RTO Verify Order', user_details?.email,merchantDetails?.m_id, merchantDetails?.short_name, user_details?.name)
    }

    const RenderFilterButtons = () => {
        return (
            <Col className='flex items-center gap-x-3'>
                <Button onClick={handleSearchClick} variant='primary'>
                    <SearchOutlined />
                    Search
                </Button>
                <Button variant='default' onClick={reset}>
                    <RedoOutlined />
                    Reset
                </Button>
                {currentRtoRiskTab !== 'placed' && (
                    <Button onClick={handleDownloadCSV} variant='primary'>
                        <DownloadOutlined />
                        Export CSV
                    </Button>
                )}
            </Col>
        )
    }

    return (
        <>
            <div className='w-full'>
                <div className='orders-page overflow-auto font-normal text-sm'>
                    <div className='mt-5 bg-white rounded overflow-clip mx-5'>
                        <div className='bg-white rounded' style={{minHeight: "55px"}}>
                            <RenderSearchFilters
                                values={{ ...rtoParams, start_dt: rtoParams.from_date, end_dt: rtoParams.to_date }}
                                setValues={(data, reset) => {
                                    setRtoParams((prev) =>
                                        reset
                                            ? { ...data }
                                            : { ...prev, from_date: data.start_dt, to_date: data.end_dt, page: 0 },
                                    )
                                }}
                                page='orders'
                                onSearch={handleSearchClick}
                                onReset={reset}
                                // includeRtoRisk
                                children={<RenderFilterButtons />}
                            />
                        </div>

                        <RenderTableHeader
                            currentRtoRiskTab={currentRtoRiskTab}
                            setCurrentRtoRiskTab={setCurrentRtoRiskTab}
                            refs={refs}
                            rtoRiskOrders={rtoRiskOrders}
                        />
                        {currentRtoRiskTab === 'verified_orders' && (
                            <Alert
                                className='ml-2.5 w-fit italic py-1'
                                message='Please tap on reset to check the latest status of verified orders.'
                                type='info'
                                showIcon
                                closable
                            />
                        )}
                        {currentRtoRiskTab === 'verification_required' && (
                            <Alert
                                className='ml-2.5 w-fit italic py-1'
                                message='Only COD orders will be listed here for verification.'
                                type='info'
                                showIcon
                                closable
                            />
                        )}
                        <Table
                            columns={isMerchantUser ? merchantColumns : internalUseColumns}
                            dataSource={rtoRiskOrders[currentRtoRiskTab]?.data || []}
                            pagination={{
                                current: rtoRiskOrders[currentRtoRiskTab]?.page + 1, // Current page number
                                pageSize: rtoRiskOrders[currentRtoRiskTab]?.page_size, // Number of items to display per page
                                total: rtoRiskOrders[currentRtoRiskTab]?.total_items, // Total number of items in the data array
                                showSizeChanger: false,
                                onChange: handlePaginationChange,

                                position: ['topLeft', 'bottomLeft'],
                                showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} Records`,
                            }}
                            scroll={{ x: 3500 }}
                            loading={rtoRiskOrders[currentRtoRiskTab]?.loading}
                        />
                        <KwikChatDrawer
                            showKwikChatDrawer={showKwikChatDrawer}
                            setShowKwikChatDrawer={setShowKwikChatDrawer}
                            kcConfigs={kcConfigs}
                            updateKCConfig={updateKCConfig}
                        />
                        <RenderTour
                            refs={refs}
                            tourVisible={tourVisible}
                            setTourVisible={setTourVisible}
                            kcConfigs={kcConfigs}
                            updateKCConfig={updateKCConfig}
                        />
                        {currentOrder ? (
                            <WhatsAppPreview
                                kcMessages={kcMessages}
                                showWhatsAppPreview={showWhatsAppPreview}
                                setShowWhatsAppPreview={setShowWhatsAppPreview}
                                currentOrder={currentOrder}
                                getConfirmationStatus={getConfirmationStatus}
                            />
                        ) : null}
                    </div>
                </div>
            </div>
        </>
    )
}
