import TemplateIcon from '@library/images/templateIcon'
import { Divider, DownOutlined, Dropdown, PlusOutlined } from 'gokwik-ui-kit'

const CreateButton = ({ handleCreateButtonClick, setShowTemplates }) => {
    const items = [
        {
            label: (
                <div className='flex items-center' onClick={handleCreateButtonClick}>
                    <PlusOutlined className='text-[#101828] mr-2 text-lg' />
                    <div>
                        <p className='text-[#101828] text-sm'>New</p>
                        <p className='text-[#667085] text-sm'>Create from scratch</p>
                    </div>
                </div>
            ),
            key: '0',
        },
        {
            label: (
                <div className='flex items-center' onClick={() => setShowTemplates(true)}>
                    <span className='text-[#101828] mr-2 text-lg'>
                        <TemplateIcon />
                    </span>
                    <div>
                        <p className='text-[#101828] text-sm mb-1'>
                            Template
                            <span className='uppercase text-blue-500  ml-2 font-semibold px-1 py-[2px] rounded-[2px] bg-gradient-to-l from-blue-100 to-blue-200 gap-1 text-xs'>
                                New
                            </span>
                        </p>
                        <p className='text-[#667085] text-sm'>Choose workflow by your choice</p>
                    </div>
                </div>
            ),
            key: '1',
        },
    ]
    return (
        <Dropdown menu={{ items }} trigger={['click']}>
            <button
                type='button'
                className='bg-[#004b8d] hover:bg-[#186199] text-white font-medium rounded-lg border-none px-4 py-2'
            >
                {' '}
                <PlusOutlined style={{ marginRight: '8px' }} />
                <span>Create</span>
                <Divider type='vertical' className='bg-white' />
                <DownOutlined />
            </button>
        </Dropdown>
    )
}

export default CreateButton
