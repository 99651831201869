import { makeAPICall } from "@gokwik/utilities"
import APIEndPoints from "@library/utilities/constants/apiEndpoints"
import { handleError } from "@library/utilities/helpers/handle_error"

import { KCConfig, RTOTableData } from "@library/utilities/interface"
import { message } from "gokwik-ui-kit"

export const fetchRtoEnabledTableData = async (payload: RTOTableData) => {
    let response;
    try {
         response = await makeAPICall({
            method: 'post',
            url: process.env.RTO_DASHBOARD_SERVICE_URL + APIEndPoints.getRTOKwikChatOrders,
            payload: {
                ...payload,
            },
            headers: {
                Authorization: process.env.RTO_DASHBOARD_SERVICE_AUTHTOKEN,
            },
            skipLoader: true
        })
        if (response.data.status_code===200) {
            return response.data
        } else {
            return {
                data: [],
                status_code: response?.data?.status_code,
                error: response?.data?.error
            }
        }
    } catch (error) {
        handleError(error)
    }
    return response
}

export const triggerKwikChatAPI = async (moid: string, confirmationType: string, tellephantID: string) => {
    let response
    try {
        response = await makeAPICall({
            method: 'post',
            url: process.env.KWIKCHAT_BASE_URL + `/payload/${tellephantID}`,
            payload: {
                action: confirmationType.toUpperCase() + '_CONFIRMATION',
                moid: moid.toString(),
            },
            headers: {
                'X-Webhook-Topic': 'rto_events',
                'X-Api-Key': process.env.KWIKCHAT_API_KEY,
            },
            skipLoader: true,
            skipCookies: true,
        })

        if (response.data.status_code === 202) {
            console.log('KwikChat API Called Successfully')
        }
    } catch (error) {
        console.error('Error in KwikChat API Call', error)
    }
    return response
}

export const updateVerificationStatus = async (moid: string, confirmationType: string) => {
    let response
    try {
        response = await makeAPICall({
            method: 'post',
            url: process.env.RTO_API_BASE_URL + APIEndPoints.updateKCVerificationStatus,
            payload: {
                event: confirmationType.toLowerCase() + '_verification_initiated',
                order_id: moid.toString(),
                verification_flow: confirmationType.toLowerCase(),
                timestamp: new Date().toISOString(),
            },
            headers: {
                authtoken: process.env.RTO_API_AUTHTOKEN,
            },
            skipLoader: true,
            skipCookies: true,
        })

        if (response.data.status_code === 202) {
            console.log('Verification Status Updated Successfully')
        }
    } catch (error) {
        message.error('Error Updating Verification Status!')
        console.error('Error in Verification Status Updation', error)
    }
    return response
}

export const callUpdateKCConfig = async (payload: KCConfig) => {
    let response
    try {
        response = await makeAPICall({
            method: 'post',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.merchantConfigs,
            payload,
            skipLoader: true,
        })

    } catch (error) {
        console.error('Error KC Configs Updation', error)
    }
    return response
}