import React, { useMemo, useState } from 'react'
import { Condition, RiskOptions } from '@library/utilities/interface'
import { DeleteFilled } from 'gokwik-ui-kit'
import RiskSlider from './riskSlider'
import { initialRiskOptions } from '@library/utilities/constants/constants'
import RenderConditionDropDown from './renderConditionDropDown'

interface ConditionBoxProps {
    index: number
    deleteItem: (event: React.MouseEvent<HTMLElement>) => void
    workflowData: Condition
    onChangeValues: (value: any, field: string) => void
    conditionList: Condition[]
}

const RtoRiskCondition: React.FC<ConditionBoxProps> = ({
    index,
    deleteItem,
    workflowData,
    onChangeValues,
    conditionList,
}) => {
    const [riskOptions, setRiskOptions] = useState<RiskOptions>(initialRiskOptions)
    const workflowKey = workflowData?.key

    const RenderSlider = ({ workflowData, conditionName }) => (
        <div className='flex items-center flex-col gap-4'>
            <div className='flex w-full justify-between items-center'>
                <p className='text-gray-500 tex-sm leading-6'>
                    Condition {index + 1} - {conditionName}
                </p>
                <DeleteFilled className='text-neutral-400 text-lg' onClick={deleteItem} />
            </div>
            <p className='py-2 pl-4 pr-6 rounded-lg bg-gray-100 border border-none leading-6'>
                Slider ranges from 0% to 100% correspond to decimal values from 0.0(Low RTO Risk) to 1.0(High RTO Risk).
                Simply adjust the slider to your percentage preference. We'll handle the rest.
            </p>
            <RiskSlider onChangeValues={onChangeValues} workflowData={workflowData} />
        </div>
    )

    const riskConditionMap = useMemo(() => {
        return {
            risk_flag: (
                <RenderConditionDropDown
                    placeholder='Risk Flag'
                    conditionName='Add Risk Flag'
                    index={index}
                    deleteItem={deleteItem}
                    workflowData={workflowData}
                    onChangeValues={onChangeValues}
                    conditionList={conditionList}
                    workflowKey={workflowKey}
                    riskOptions={riskOptions}
                    setRiskOptions={setRiskOptions}
                />
            ),
            risk_reason: (
                <RenderConditionDropDown
                    placeholder='Risk Reason'
                    conditionName='Add Risk Reason'
                    index={index}
                    deleteItem={deleteItem}
                    workflowData={workflowData}
                    onChangeValues={onChangeValues}
                    conditionList={conditionList}
                    workflowKey={workflowKey}
                    riskOptions={riskOptions}
                    setRiskOptions={setRiskOptions}
                />
            ),
        }
    }, [workflowKey, workflowData?.values, riskOptions, conditionList?.length])

    const rtoScoreMap = useMemo(() => {
        return {
            rto_score: <RenderSlider workflowData={workflowData} conditionName='Add Risk Probability' />,
        }
    }, [workflowKey, riskOptions, workflowData?.value])

    const conditionMap = { ...riskConditionMap, ...rtoScoreMap }

    return (
        <div className='flex w-full py-4 px-3 flex-col justify-center items-start gap-3 rounded-md border-solid border-[#004b8d] bg-white shadow-md'>
            {conditionMap[workflowKey]}
        </div>
    )
}

export default RtoRiskCondition
