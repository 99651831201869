import React from 'react'

interface CustomerIconProps {
    active: boolean
    className?: string
}

const CustomerIcon: React.FC<CustomerIconProps> = ({ active, className = '' }) => {
    return (
        <svg
            width='22'
            height='20'
            viewBox='0 0 22 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            className={className}
        >
            <path
                d='M15.3445 5.15504C15.3531 5.71462 15.2503 6.27032 15.0421 6.7898C14.834 7.30929 14.5245 7.78217 14.1319 8.18093C13.7392 8.5797 13.2711 8.89637 12.7549 9.11253C12.2387 9.32868 11.6846 9.44 11.125 9.44C10.5653 9.44 10.0113 9.32868 9.49508 9.11253C8.97886 8.89637 8.51079 8.5797 8.11812 8.18093C7.72545 7.78217 7.41602 7.30929 7.20784 6.7898C6.99965 6.27032 6.89688 5.71462 6.90549 5.15504C6.92255 4.04721 7.3746 2.99052 8.16406 2.21313C8.95351 1.43574 10.017 1 11.125 1C12.233 1 13.2965 1.43574 14.0859 2.21313C14.8754 2.99052 15.3274 4.04721 15.3445 5.15504ZM3.65149 14.404C5.03649 13.314 7.79249 11.551 11.1255 11.551C14.4575 11.551 17.2145 13.315 18.5995 14.404C19.2175 14.89 19.4095 15.712 19.1665 16.46L18.8335 17.48C18.6953 17.9031 18.4269 18.2717 18.0666 18.5331C17.7063 18.7944 17.2726 18.9351 16.8275 18.935H5.42249C4.97757 18.9349 4.54408 18.7941 4.18398 18.5328C3.82389 18.2714 3.55561 17.903 3.41749 17.48L3.08449 16.46C2.83949 15.712 3.03249 14.89 3.65149 14.404Z'
                fill={active ? 'white' : '#5C5F62'}
            />
        </svg>
    )
}

export default CustomerIcon
