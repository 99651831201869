import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { ColumnProps, InfoCircleOutlined, Table, Tooltip } from 'gokwik-ui-kit'
import {
    formatDateTimeframe,
    handleDownloadOverallSummaryCSV,
    numberFormatter,
} from '@library/utilities/helpers/helper'
import { updateDataBasedOnTimeframe } from '@library/utilities/helpers/data_filtering'
import { awbFillRateIconMsg, rtoApiHitsIconMsg, summaryViewMetrics } from '@library/utilities/constants/constants'

dayjs.extend(utc)

const createDynamicColumns = (data, timeframe, status = 'loading') => {
    const baseColumns: ColumnProps<any>[] = [
        {
            title: 'Metric',
            dataIndex: 'metric_name',
            key: 'metric_name',
            render: (val) => {
                const metric = summaryViewMetrics[val]
                if (val === 'hits' || val === 'awb_fill_rate_perc') {
                    const tooltipMsg = val === 'hits' ? rtoApiHitsIconMsg : awbFillRateIconMsg
                    return (
                        <div>
                            <span className='font-bold'>{metric.label} </span>
                            <Tooltip placement='right' title={tooltipMsg}>
                                <InfoCircleOutlined />
                            </Tooltip>
                        </div>
                    )
                }
                return <span className='font-bold'>{metric.label} </span>
            },
            width: 150,
        },

        ...(data && data.length > 0
            ? data.map((item) => ({
                  title: formatDateTimeframe(item.date, timeframe),
                  dataIndex: formatDateTimeframe(item.date, timeframe),
                  key: formatDateTimeframe(item.date, timeframe),
                  render: (val, record) => {
                      if (summaryViewMetrics[record.metric_name].type === 'percentage' && isNaN(val)) {
                          return <Tooltip title='Awaited'>Awaited</Tooltip>
                      } else {
                          const formattedValue = numberFormatter(val, summaryViewMetrics[record.metric_name].type)
                          return <Tooltip title={`${val}`}>{formattedValue}</Tooltip>
                      }
                  },
                  align: 'right',
                  width: 120,
              }))
            : status === 'loading'
            ? [
                  {
                      title: 'Loading',
                      dataIndex: 'loading',
                      key: 'loading',
                      render: () => 'Loading...',
                      align: 'right',
                      width: 200,
                  },
              ]
            : [
                  {
                      title: 'No Data Found',
                      dataIndex: 'no_data',
                      key: 'no_data',
                      render: () => 'No Data Found',
                      align: 'right',
                      width: 150,
                  },
              ]),
    ]

    return baseColumns
}

const mapDataToRows = (data, timeframe) => {
    return Object.keys(summaryViewMetrics)?.map((metric_key) => {
        const row = { metric_name: metric_key }

        data?.forEach((item) => {
            const formattedDate = formatDateTimeframe(item.date, timeframe)
            row[formattedDate] = item[metric_key]
        })

        return row
    })
}

const SummaryTable = ({ analyticsData, timeframe = 'monthly' }) => {
    const originalData = analyticsData?.rtoInsights?.data?.originalData
    const status = analyticsData?.rtoInsights?.status
    const timeframeData = updateDataBasedOnTimeframe(originalData, timeframe)
    const tableColumns = createDynamicColumns(timeframeData, timeframe, status)
    const tableData = mapDataToRows(timeframeData, timeframe)
    const isButtonDisabled = tableData.some((row) => {
        if (row.metric_name === 'awb_fill_rate_perc') {
            return Object.keys(row).some((key) => key !== 'metric_name' && row[key] < 80)
        }
        return false
    })
    const handleDownload = () => {
        if (!isButtonDisabled) {
            handleDownloadOverallSummaryCSV(tableColumns, tableData, summaryViewMetrics)
        }
    }
    return (
        <>
            <div className='flex justify-end'>
                {isButtonDisabled ? (
                    <Tooltip title='Gokwik has delivery status available for less than 80% of orders, Please reach out to your CSM to increase your AWB Fill rate and unlock this view'>
                        <div>
                            <button
                                type='button'
                                className='bg-gray-400 hover:bg-gray-400 text-white font-medium py-2 px-3 rounded-lg border-none mb-5 cursor-not-allowed'
                                onClick={handleDownload}
                                disabled={isButtonDisabled}
                            >
                                Export CSV
                            </button>
                        </div>
                    </Tooltip>
                ) : (
                    <button
                        type='button'
                        className='bg-[#004b8d] hover:bg-[#186199] text-white font-medium py-2 px-3 rounded-lg border-none mb-5'
                        onClick={handleDownload}
                        disabled={isButtonDisabled}
                    >
                        Export CSV
                    </button>
                )}
            </div>
            <Table
                columns={tableColumns}
                dataSource={tableData}
                pagination={false}
                bordered={true}
                loading={status === 'loading'}
            />
        </>
    )
}

export default SummaryTable
