import React, { useEffect, useRef } from 'react';
import { BrowserRouter } from 'react-router-dom';

// Import Pages for Routing
import './main.css';
import { Provider, useDispatch } from 'react-redux';
import store from './store';
import { setFilters } from './store/filters';
import { dashboardStore, filterStore } from '@gokwik/utilities';
import { setUserData } from '@store/user';
import Routes from './routes';

const InitializeStore = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setFilters(filterStore.getState().filters?.rto || {}));
        dispatch(setUserData(dashboardStore.getState().userData))
        const unsubscribe = dashboardStore.subscribe((state) => {
            dispatch(setUserData(state.userData));
        });
        return () => unsubscribe?.();
    }, []);
    return <></>;
};
export default function Root(props: any) {
    const appContainerRef = useRef<HTMLDivElement>();
    useEffect(() => {
        setTimeout(() => {
            appContainerRef.current.style.maxWidth = `calc(100vw - ${
                document.getElementById('side-nav').getBoundingClientRect().width
            }px)`;
        }, 100);
    }, []);
    return (
        <Provider store={store}>
            <div
                className='app-container max-h-screen h-screen  overflow-y-auto pt-20 px-5 w-full bg-[#FFFFFF] rto-app-container'
                ref={appContainerRef}
                style={{
                    maxWidth: `calc(100vw - ${document.getElementById('side-nav')?.offsetWidth}px)`,
                }}
            >
                <InitializeStore />
                <BrowserRouter>
                    <Routes />
                </BrowserRouter>
            </div>
        </Provider>
    );
}
