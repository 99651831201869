import { makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import {
    calculateRTOSummaryMetrics,
} from '@library/utilities/helpers/calculate_metrics'

export const analyticsAPIs: {
    [key: string]: {
        url: string
        errMsg: string
        nestedKeys?: string[]
        methodType?: 'get' | 'post'
        defaultParams?: { [key: string]: any }
        defaultBody?: { [key: string]: any }
        headers?: object
        formatData?: (data: any) => any
    }
} = {
    rtoInsights: {
        url: APIEndPoints.getOverviewRTOInsightData,
        errMsg: 'Unable to fetch RTO Insight Data',
        defaultParams: {
            filter_type: 'all',
            filter_value: 'value',
        },
        methodType: 'get',
        headers: {
            Authorization: process.env.RTO_DASHBOARD_SERVICE_AUTHTOKEN,
        },
        formatData: calculateRTOSummaryMetrics,
    },
}

export const fetchAnalyticsData = async ({
    key,
    params,
    payload,
    nestedKey,
}: {
    key: string
    nestedKey?: string
    params?: { [key: string]: any }
    payload?: { [key: string]: any }
}) => {
    let { url, nestedKeys, defaultParams, methodType, defaultBody, headers, formatData } = analyticsAPIs[key]

    let response = await makeAPICall({
        method: methodType,
        url: process.env.RTO_DASHBOARD_SERVICE_URL + url,
        params: {
            ...(defaultParams || {}),
            ...(params || {}),
        },
        payload: {
            ...(defaultBody || {}),
            ...(payload || {}),
        },
        skipLoader: true,
        skipMode: true,
        headers: {
            ...headers,
        },
    })

    if (response.success) {
        let processedData = {
            fromDate: params.from_date,
            toDate: params.to_date,
            data: response.data.data,
        }

        if (formatData) {
            processedData = formatData(processedData)
        }

        return {
            success: true,
            data: processedData,
        }
    }
    return { error: true }
}

