import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import filters from './filters';
import user from './user';
import analytics from './analytics';
import rto_detials from './rto_details'
import actions from './actions';
import rto_summary from './rto_summary';

const rootReducer = combineReducers({
  analytics,
  user,
  filters,
  rto_detials,
  actions,
  rto_summary
});

const store = configureStore({
  reducer: rootReducer,
  devTools: true,
});

export default store;
export type RootStoreType = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
