export const BatteryIcon = () => {
    return (
        <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g id='battery_full_black_24dp' clip-path='url(#clip0_7017_79142)'>
                <path
                    id='Vector'
                    d='M9.4325 2.04167H8.45833V0.875H6.125V2.04167H5.15083C4.725 2.04167 4.375 2.39167 4.375 2.8175V11.76C4.375 12.1917 4.725 12.5417 5.15083 12.5417H9.42667C9.85833 12.5417 10.2083 12.1917 10.2083 11.7658V2.8175C10.2083 2.39167 9.85833 2.04167 9.4325 2.04167Z'
                    fill='white'
                />
            </g>
            <defs>
                <clipPath id='clip0_7017_79142'>
                    <rect width='14' height='14' fill='white' />
                </clipPath>
            </defs>
        </svg>
    )
}
