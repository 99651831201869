import React, { useEffect, useState } from 'react'
import { DeleteFilled, Form, message, Select, Space } from 'gokwik-ui-kit'
import { getSelectValue } from '@library/utilities/helpers/helper'
import { initialRiskOptions, workflowKeys } from '@library/utilities/constants/constants'
import { Condition, RiskOptions } from '@library/utilities/interface'
import { getRiskReasons } from '@store/actions/api'





interface RenderConditionDropDownProps {
    placeholder: string;
    conditionName: string;
    index: number;
    deleteItem: (event: React.MouseEvent<HTMLElement>) => void;
    workflowData: any; 
    onChangeValues: (value: any, field: string) => void;
    conditionList: Condition[];
    workflowKey: string;
    riskOptions: RiskOptions
    setRiskOptions: (options: RiskOptions) => void;
}

const RenderConditionDropDown: React.FC<RenderConditionDropDownProps> = ({
    placeholder,
    conditionName,
    index,
    deleteItem,
    workflowData,
    onChangeValues,
    conditionList,
    workflowKey,
    riskOptions,
    setRiskOptions,
}) => {
    const isRiskReasonSelected = conditionList.findIndex((item) => item.key === workflowKeys.riskReason)
    const [loading, setLoading] = useState(false)

    const fetchRiskReasons = async () => {
        setLoading(true)
        try {
            const response = await getRiskReasons()
            const { status_code, data } = response.data
            if (status_code === 200 && data?.risk_reasons?.length > 0) {
                return data.risk_reasons
            }
        } catch (error) {
            message.error('Failed to fetch risk reasons')
            console.log(error)
        } finally {
            setLoading(false)
        }   
    }

    useEffect(() => {
        if (isRiskReasonSelected !== -1) {
            fetchRiskReasons().then((riskReasons) => {
                const formattedRiskReasons = riskReasons.map((reason) => ({
                    label: reason,
                    value: reason,
                }))
                setRiskOptions({
                    ...riskOptions,
                    risk_reason: formattedRiskReasons,
                });
            })
        }
    }, [isRiskReasonSelected])

    useEffect(() => {
        if (isRiskReasonSelected !== index) {
            if (isRiskReasonSelected !== -1) {
                let riskOptionsClone = { ...riskOptions }
                riskOptionsClone = {
                    ...riskOptionsClone,
                    risk_flag: riskOptionsClone.risk_flag.filter(
                        (option) => option.value.replace(/\s+/g, '').toLowerCase() !== 'lowrisk',
                    ),
                }
                setRiskOptions(riskOptionsClone)
            } else {
                setRiskOptions(initialRiskOptions)
            }
        }
    }, [workflowKey])

    useEffect(() => {
        if(workflowData?.value?.length > 0) {
            onChangeValues([workflowData?.value], 'values')
            onChangeValues('contains', 'operator')
            onChangeValues('', 'value')
        }
    }, [workflowData?.value])

    return (
        <div className='flex flex-col w-full gap-2'>
            <div className='flex w-full justify-between items-center'>
                <p className='text-gray-500 tex-sm leading-6'>
                    Condition {index + 1} - {conditionName}
                </p>
                <DeleteFilled className='text-neutral-400 text-lg' onClick={deleteItem} />
            </div>
            <Space wrap size={30} direction='horizontal'>
                <Select className='w-36' value={'RTO Risk'} options={[{ value: 'RTO Risk', label: 'RTO Risk' }]} />
                <Form.Item
                    name={`risk-reason-${conditionName}-${index}`}
                    className="mb-0 w-72"
                    rules={[{ required: true, message: '' }]}
                    initialValue={workflowData?.values}
                >
                    <Select
                        loading={loading}
                        mode="multiple"
                        placeholder={placeholder}
                        options={riskOptions[workflowData.key]}
                        onChange={(e) => {
                            onChangeValues(e, 'values')
                            onChangeValues('contains', 'operator')
                        }}
                        value={workflowData?.values}
                        tokenSeparators={[',']}
                    />
                </Form.Item>
            </Space>
        </div>
    )
}

export default RenderConditionDropDown
