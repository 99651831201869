import React, { Fragment } from 'react'
import { Col, Divider, Row, Card } from 'gokwik-ui-kit'
import { Link, useNavigate } from 'react-router-dom'

const capitalizeFirstLetter = (str) => {
    if (!/^[a-zA-Z\s]*$/.test(str)) {
        return str
    }
    const words = str.split(' ')
    const capitalizedWords = words.map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1)
    })

    return capitalizedWords.join(' ')
}

const formatNumber = (val: number) =>
    Intl.NumberFormat('en-US', {
        //@ts-ignore
        notation: 'compact',
    })
        .format(val)
        .replace('T', 'K')

const RtoCards = ({ title, innerTitle, data, filter_type, fromDate, toDate , isLoading}) => {
    const navigate = useNavigate()
    const toRtoTable = () => {
        navigate(`/rto/analytics/rto-tables/${filter_type}`, { state: { filter_type: filter_type } })
    }

    return (
        <Card
            title={title}
            extra={
                <a
                    onClick={() => {
                        toRtoTable()
                    }}
                >
                    Click to Expand
                </a>
            }
            className='rto-card'
        >
            <Row gutter={16} className='justify-around'>
                <Col span={6} className='w-1/4'>
                    <h3 className='text-center text-color' >{innerTitle}</h3>
                </Col>
                <Col span={6} className='w-1/4' >
                    <h3 className='text-center text-color'>Total Orders</h3>
                </Col>
                <Col span={6} className='w-1/4'>
                    <h3 className='text-center text-color'>COD Orders</h3>
                </Col>
                <Col span={6} className='w-1/4'>
                    <h3 className='text-center text-color'>Overall RTO Rate</h3>
                </Col>
            </Row>
            <Card type='inner' className='h-80 overflow-y-auto' loading={isLoading}>
                 <Row gutter={16} className='justify-around mt-2 mb-2' style={{ marginLeft: '0px', marginRight: '0px' }}>
                    {data && data.length > 0 ? (
                        data?.map((values, index) => (
                            <Fragment key={values.name}>
                                <Col span={6} className='w-1/4'>
                                    <Link
                                        to={`/rto/analytics/overview/${filter_type}/${encodeURIComponent(values.name)}/${fromDate}/${toDate}`}
                                    >
                                        <p className='text-left card-text'>{capitalizeFirstLetter(values.name)}</p>
                                    </Link>
                                </Col>
                                <Col span={6} className='w-1/4'>
                                    <p className='text-right card-text'>{formatNumber(values.total_orders)}</p>
                                </Col>
                                <Col span={6} className='w-1/4'>
                                    <p className='text-right card-text'>{formatNumber(values.total_cod_orders)}</p>
                                </Col>
                                <Col span={6} className='w-1/4'>
                                    <p className='text-right card-text'>{formatNumber(values.rto_rate)}%</p>
                                </Col>
                                {index !== data.length - 1 && <Divider style={{ marginTop: '16px', marginBottom: '16px' }} />}
                            </Fragment>
                        ))
                    ) : (
                        <div>
                            <p className='text-center card-text'>No data found for selected date range.</p>
                        </div>
                    )}
                </Row>
            </Card>
        </Card>
    )
}

export default RtoCards
