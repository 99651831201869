import { Workflow } from '@library/utilities/interface'
import dayjs from 'dayjs'

const WorkflowTooltipContent = ({ item }: { item: Workflow }) => (
    <div className='flex flex-col gap-1 p-2'>
        <div>
            <p className='font-semibold'>Created On: {dayjs(item.created_at).format('Do MMMM YYYY')}</p>
            <p className='text-sm'>Time: {dayjs(item.created_at).format('h:mm A')}</p>
        </div>
        {!item.is_enabled && item.updated_at && (
            <div>
                <p className='font-semibold'>Disabled On: {dayjs(item.updated_at).format('Do MMMM YYYY')}</p>
                <p className='text-sm'>Time: {dayjs(item.updated_at).format('h:mm A')}</p>
            </div>
        )}
        {item.ab_test_enabled_on && (
            <div>
                <h4 className='font-bold mt-2 mb-0'>A/B Test</h4>
                <p>Created on: {dayjs(item.ab_test_enabled_on).format('Do MMMM YYYY')}</p>
                <p className='text-sm'>Time: {dayjs(item.ab_test_enabled_on).format('h:mm A')}</p>
                {item.ab_test_disabled_on && (
                    <>
                        <p className='font-semibold mt-1'>
                            Disabled On: {dayjs(item.ab_test_disabled_on).format('Do MMMM YYYY')}
                        </p>
                        <p className='text-sm'>Time: {dayjs(item.ab_test_disabled_on).format('h:mm A')}</p>
                    </>
                )}
            </div>
        )}
    </div>
)

export default WorkflowTooltipContent
