import { actionNames } from '@library/utilities/constants/constants'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getWorkflowAbTestTypeBAction, getWorkflowAction } from '@store/actions/selectors'
import { setWorkflowAbTestTypeBAction, setWorkflowAction } from '@store/actions'
import { ActionType } from '@library/utilities/interface'
import ActionFormItems from '../actions/edit-workflow/actionFormItems'
import { getAbPrimaryActionInputs } from '@library/utilities/helpers/helper'
import { getMerchantDiscountConfig } from '@store/actions/api'

function AbTestAction({ treatMentPercentage }) {
    const dispatch = useDispatch()
    const [merchantDiscountConfig, setMerchantDiscountConfig] = useState([]);

    // UseSelector hooks to get workflow actions
    const workflowAction = useSelector(getWorkflowAction)
    const workflowAbTestTypeBAction = useSelector(getWorkflowAbTestTypeBAction)

    // State management with initial values from workflowAction
    const [actionType, setActionType] = useState({ action: '' })
    const [secondaryActionType, setSecondaryActionType] = useState(workflowAction.secondary)
    const [showSecondaryAction, setShowSecondaryAction] = useState(workflowAction.showSecondaryAction)
    const [showATypeAction, setShowATypeAction] = useState(true) // State to toggle between Type A and B

    useEffect(() => {
        const currentAction = showATypeAction ? workflowAction : workflowAbTestTypeBAction
        setActionType(currentAction.primary)
        setSecondaryActionType(currentAction.secondary)
        setShowSecondaryAction(currentAction.showSecondaryAction)

        if (currentAction.primary.action === actionNames.allowCod) {
            setShowSecondaryAction(false)
        }
    }, [workflowAction, workflowAbTestTypeBAction])

    useEffect(() => {
        if (actionType.action === actionNames.allowCod) {
            setShowSecondaryAction(false)
        }
    }, [actionType.action])

    useEffect(() => {
        const fetchInitialMerchantDiscount = async () => {
            try {
                const response = await getMerchantDiscountConfig()
                if (response.status_code === 200 && response.data) {
                    const merchant_discount_config = response.data?.tiered_discount_config ?? []
                    const freebies_merchant_discount_config = merchant_discount_config
                        .filter(item => item.discount_type.toLowerCase() === "freebie" && item.is_driven_by_rto_actions == true)
                        .map(item => ({
                            value: `${item.discount_code} | ${item.method_type}`,
                            label: `${item.discount_code.toLowerCase()} | ${item.method_type.toLowerCase()}`
                        }));
                    setMerchantDiscountConfig(freebies_merchant_discount_config);
                }
            } catch (error) {
                console.error('Error fetching merchant discount config:', error)
            }
        }
        fetchInitialMerchantDiscount()
    }, [])

    const setAction = useCallback(
        (key: 'primary' | 'secondary' | 'showSecondaryAction', value: ActionType | boolean) => {
            const currentAction = showATypeAction ? workflowAction : workflowAbTestTypeBAction
            const updatedAction = { ...currentAction, [key]: value }
            const actionSetter = showATypeAction ? setWorkflowAction : setWorkflowAbTestTypeBAction
            dispatch(actionSetter(updatedAction))
        },
        [showATypeAction, workflowAction, workflowAbTestTypeBAction],
    )

    const setPrimaryAction = (value: ActionType) => setAction('primary', value)
    const setSecondaryAction = (value: ActionType) => setAction('secondary', value)
    const setShowSecondaryActionFn = (value: boolean) => setAction('showSecondaryAction', value)

    const handleTypeClick = (type: 'A' | 'B') => {
        const isTypeA = type === 'A'
        setShowATypeAction(isTypeA)
        const currentAction = isTypeA ? workflowAction : workflowAbTestTypeBAction
        setActionType(currentAction.primary)
        setSecondaryActionType(currentAction.secondary)
        setShowSecondaryAction(currentAction.showSecondaryAction)

        dispatch(setWorkflowAbTestTypeBAction({ ...workflowAbTestTypeBAction, showActionA: isTypeA }))
    }

    const setActionState = useCallback(
        (value) => {
            const actionSetter = showATypeAction ? setWorkflowAction : setWorkflowAbTestTypeBAction
            const baseAction = showATypeAction ? workflowAction : workflowAbTestTypeBAction

            if (value.showSecondaryAction === false && value.secondary.action === '') {
                const isPrimaryActionEmpty =
                    (showATypeAction && !workflowAbTestTypeBAction.secondary?.action) ||
                    (!showATypeAction && !workflowAction.secondary?.action)

                const updatedAction = isPrimaryActionEmpty
                    ? { ...baseAction, ...value, primary: { action: '' } }
                    : { ...baseAction, ...value }

                dispatch(actionSetter(updatedAction))
            } else {
                dispatch(actionSetter({ ...baseAction, ...value }))
            }
        },
        [workflowAction, workflowAbTestTypeBAction],
    )

    const actionInputOptions = getAbPrimaryActionInputs(showATypeAction, workflowAction, workflowAbTestTypeBAction, actionType)
    const selectedStyles = 'px-3 py-1 bg-white rounded-md border border-solid border-[#00000026]'
    return (
        <div>
            <div className='flex items-center gap-2 justify-around bg-[#0000000F] p-2 rounded-t-md'>
                <span
                    className={`cursor-pointer ${showATypeAction ? selectedStyles : ''}`}
                    onClick={() => handleTypeClick('A')}
                >
                    Type A ({treatMentPercentage}%)
                </span>
                <span
                    className={`cursor-pointer ${!showATypeAction ? selectedStyles : ''}`}
                    onClick={() => handleTypeClick('B')}
                >
                    Type B ({100 - treatMentPercentage}%)
                </span>
            </div>
            <ActionFormItems
                actionType={actionType}
                secondaryActionType={secondaryActionType}
                showSecondaryAction={showSecondaryAction}
                setShowSecondaryAction={setShowSecondaryActionFn}
                setSecondaryAction={setSecondaryAction}
                setPrimaryAction={setPrimaryAction}
                setActionState={setActionState}
                actionInputOptions={actionInputOptions}
                merchantDiscountConfig={merchantDiscountConfig}
            />
        </div>
    )
}

export default AbTestAction
