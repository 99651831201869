import { Table, Button, SearchOutlined, Input, Space, Menu, ReloadOutlined, Tooltip } from 'gokwik-ui-kit'
import React, { useState, Dispatch, useEffect } from 'react'

import { useRef } from 'react'
// import type { FilterDropdownProps } from 'antd/es/table/interface'
import Highlighter from 'react-highlight-words'
import * as XLSX from 'xlsx'

import FilterMenu from '@library/components/rto-details/filter-menu'
import { rtoDetailsTableMenu } from '@library/utilities/constants/constants'
import { useSelector } from 'react-redux'
import { getRtoDetailsData } from '@store/rto_details/selector'
import { updateBreadcrumbs } from '@gokwik/utilities'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import { getAnalyticsData } from '@store/analytics/selectors'

const today = dayjs()
const format = 'YYYY-MM-DD'
const t_15 = today.subtract(15, 'day').format(format)
const t_45 = today.subtract(45, 'day').format(format)

const RtoDetailsTable = ({
    formattedTableData,
    filterType,
}: {
    formattedTableData: any
    filterType: string
    selectedFilter: any
    setSelectedFilter: Dispatch<any>
}) => {
    const [searchText, setSearchText] = useState('')
    const [current, setCurrent] = useState('all')
    const [searchedColumn, setSearchedColumn] = useState('')
    const [tableData, setTableData] = useState(formattedTableData)
    const [originalData, setOriginalData] = useState(formattedTableData) // Store original data
    const [nonFilteredData] = useState(formattedTableData)
    const searchInput = useRef(null)
    const rtoDetailsData = useSelector(getRtoDetailsData)
    const status = rtoDetailsData.getTables.status
    const analyticsData = useSelector(getAnalyticsData)
    const { to_date = t_15, from_date = t_45 } = analyticsData?.rtoInsights?.data?.dateRange || {}

    useEffect(() => {
        updateBreadcrumbs((prev) => [
            prev[0],
            {
                key: 'rto-analytics',
                href: '/rto/analytics/overview',
                text: 'Overview',
            },
            {
                key: 'rto-analytics',
                href: '/rto/analytics/rto-tables',
                text: 'Deep-Dive',
            },
            {
                key: 'rto-analytics',
                href: `/rto/analytics/rto-tables/${filterType}`,
                text: `${filterType.toLowerCase()}`,
            },
        ])
    }, [])
    const onClick = (e) => {
        const filterClause = e?.key || 'all'
        filterTableData(filterClause)

        setCurrent(e.key)
    }
    const filterTableData1 = (filter, columnName, inputValues) => {
        const { maxValue, minValue, filterValue } = inputValues
        let filteredData = []

        switch (filter) {
            case 'between':
                filteredData = tableData.filter((item) => {
                    const value = item[columnName]
                    return value >= parseInt(minValue) && value <= parseInt(maxValue)
                })
                break
            case 'greaterThan':
                filteredData = tableData.filter((item) => {
                    const value = item[columnName]
                    return value > parseInt(filterValue)
                })
                break
            case 'lessThan':
                filteredData = tableData.filter((item) => {
                    const value = item[columnName]
                    return value < parseInt(filterValue)
                })
                break
            case 'equalTo':
                filteredData = tableData.filter((item) => {
                    const value = item[columnName]
                    return value === parseInt(filterValue)
                })
                break
            default:
                break
        }

        setTableData(filteredData)
        setOriginalData(filteredData)
    }
    const generateColumns = (filterType) => {
        const commonColumns = [
            {
                title: 'Total Orders',
                dataIndex: 'total_orders',
                key: 'total_orders',
                width: '15%',
            },
            {
                title: 'Cod Orders',
                dataIndex: 'cod_orders',
                key: 'cod_orders',
                width: '15%',
            },
            {
                title: 'RTO Orders',
                dataIndex: 'rto_orders',
                key: 'rto_orders',
                width: '15%',
            },

            {
                title: 'RTO Percentage (%)',
                dataIndex: 'rto_percentage',
                key: 'rto_percentage',
                width: '15%',
            },
        ]
        let filterColumn
        switch (filterType) {
            case 'state':
                filterColumn = {
                    title: 'State',
                    dataIndex: 'state',
                    key: 'state',
                    width: '20%',
                    ...getColumnSearchProps('state'),
                    render: (text: string) => (
                        <Link
                            to={
                                text
                                    ? `/rto/analytics/overview/${filterType}/${encodeURIComponent(text?.toLowerCase())}/${from_date}/${to_date}`
                                    : `/rto/analytics/overview/`
                            }
                        >
                            <a style={{ width: 'min-content' }}>{text}</a>
                        </Link>
                    ),
                }
                break
            case 'city':
                filterColumn = {
                    title: 'City',
                    dataIndex: 'city',
                    key: 'city',
                    width: '20%',
                    ...getColumnSearchProps('city'),
                    render: (text: string) => (
                        <Link
                            to={
                                text
                                    ? `/rto/analytics/overview/${filterType}/${encodeURIComponent(text?.toLowerCase())}/${from_date}/${to_date}`
                                    : `/rto/analytics/overview/`
                            }
                        >
                            <a style={{ width: 'min-content' }}>{text}</a>
                        </Link>
                    ),
                }
                break
            case 'product':
                filterColumn = {
                    title: 'Product',
                    dataIndex: 'product',
                    key: 'product',
                    width: '20%',
                    ...getColumnSearchProps('product'),
                    render: (text: string) => (
                        <Link
                            to={
                                text
                                    ? `/rto/analytics/overview/${filterType}/${encodeURIComponent(text?.toLowerCase())}/${from_date}/${to_date}`
                                    : `/rto/analytics/overview/`
                            }
                        >
                            <a style={{ width: 'min-content' }}>{text}</a>
                        </Link>
                    ),
                }
                break
            case 'pincode':
                filterColumn = {
                    title: 'Pincode',
                    dataIndex: 'pincode',
                    key: 'pincode',
                    width: '20%',
                    ...getColumnSearchProps('pincode'),
                    render: (text: string) => (
                        <Link
                            to={
                                text
                                    ? `/rto/analytics/overview/${filterType}/${encodeURIComponent(text?.toLowerCase())}/${from_date}/${to_date}`
                                    : `/rto/analytics/overview/`
                            }
                        >
                            <a style={{ width: 'min-content' }}>{text}</a>
                        </Link>
                    ),
                }
                break
            default:
                filterColumn = {
                    title: 'State',
                    dataIndex: 'state',
                    key: 'state',
                    width: '20%',
                    ...getColumnSearchProps('state'),
                }
        }
        return [filterColumn, ...commonColumns]
    }
    const clearAllFilters = () => {
        setTableData(nonFilteredData)
        setOriginalData(nonFilteredData)
        setCurrent('all')
    }
    const filterTableData = (filterClause) => {
        switch (filterClause) {
            case 'all': {
                setTableData(originalData)
                break
            }
            case 'rto_lt_20': {
                setTableData(originalData.filter((item) => item.rto_percentage < 20))
                break
            }
            case 'rto_bt_20_40': {
                setTableData(originalData.filter((item) => item.rto_percentage >= 20 && item.rto_percentage <= 40))
                break
            }
            case 'rto_gt_40': {
                setTableData(originalData.filter((item) => item.rto_percentage > 40))
                break
            }
        }
    }
    const capitalize = (s) => (s && s[0].toUpperCase() + s.slice(1)) || ''

    const exportToExcel = () => {
        const fileName = 'table_data.xlsx'
        const filteredData = tableData.map((item) => {
            const { key, ...rest } = item // Destructure the item object, excluding the 'key' field
            return {
                [capitalize(filterType)]: rest[filterType]?.props?.children || rest[filterType],
                'Total Orders': rest['total_orders'],
                'COD Orders': rest['cod_orders'],
                'RTO Orders': rest['rto_orders'],
                'RTO Percentage': rest['rto_percentage'],
            }
        })

        const ws = XLSX.utils.json_to_sheet(filteredData)
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
        XLSX.writeFile(wb, fileName)
    }

    const handleSearch = (selectedKeys: string[], confirm, dataIndex) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)
    }

    const handleReset = (clearFilters: () => void) => {
        clearFilters()
        setSearchText('')
    }

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type='primary'
                        onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                        icon={
                            <SearchOutlined
                            />
                        }
                        size='small'
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size='small'
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                    <Button
                        type='link'
                        size='small'
                        onClick={() => {
                            close()
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined
                style={{ color: filtered ? '#1677ff' : undefined }}
            />
        ),
        onFilter: (value, record) => {
            const dataIndexValue = record[dataIndex]
            const textContent = dataIndexValue.props ? dataIndexValue.props.children : dataIndexValue
            const searchText = value.toLowerCase()

            // If textContent is not a string, convert it to a string
            const content = typeof textContent === 'string' ? textContent : String(textContent)

            // Check if the content contains the search text
            return content.toLowerCase().includes(searchText)
        },
        render: (text) => {
            if (searchedColumn === dataIndex && typeof text === 'string') {
                // Render as highlighted text if it's being searched and it's a string
                return (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text}
                    />
                )
            } else if (typeof text === 'object' && text.props && text.props.children) {
                // Render as hyperlink if it's an object with children
                return <a href={text.props.href}>{text.props.children}</a>
            } else {
                // Render as plain text if none of the above conditions met
                return text
            }
        },
    })

    const newColumns = generateColumns(filterType)

    return (
        <div style={{ borderStyle: 'ridge' }}>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    paddingRight: '10px',
                    padding: '15px',
                }}
            >
                <Menu
                    onClick={onClick}
                    selectedKeys={[current]}
                    mode='horizontal'
                    items={rtoDetailsTableMenu}
                    defaultSelectedKeys={['all']}
                    style={{ flexGrow: 1 }}
                />
                <div style={{ margin: '10px', padding: '10px' }}>
                    <Tooltip placement='top' title='Clear all Filters' arrow={true}>
                        <Button style={{ marginRight: '5px' }} onClick={clearAllFilters} icon={<ReloadOutlined />}>
                            {''}
                        </Button>
                    </Tooltip>

                    <FilterMenu filterData={filterTableData1} />
                    <Button
                        disabled={!tableData?.length}
                        onClick={exportToExcel}
                        variant='primary'
                        style={{ marginLeft: '5px' }}
                    >
                        + Export data
                    </Button>
                </div>
            </div>
            <div style={{}}>
                <Table
                    columns={newColumns}
                    dataSource={tableData}
                    loading={status === 'loading'}
                />
            </div>
        </div>
    )
}

export default RtoDetailsTable
