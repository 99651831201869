import { makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'

export const rtoDetailApi: {
    [key: string]: {
        url: string
        errMsg: string
        nestedKeys?: string[]
        defaultParams?: { [key: string]: any }
        formatData?: (data: any) => any
    }
} = {
    getTables: {
        url: APIEndPoints.getTablesData,
        errMsg: 'Unable to fetch RTO Insight Data',
        defaultParams: {
            merchant_id: 8,
            filter_type: 'city',
            from_date: '2023-05-01',
            to_date: '2023-06-30',
            page_size: 10,
            page: 1,
        },
    },
}

export const fetchRtoDetailsData = async ({
    key,
    params,
    nestedKey,
}: {
    key: string
    nestedKey?: string
    params?: { [key: string]: any }
}) => {
    const { url, nestedKeys, defaultParams, formatData } = rtoDetailApi[key]
    const response = await makeAPICall({
        method: 'post',
        url: process.env.RTO_DASHBOARD_SERVICE_URL + url,
        payload: {
            merchant_id: params?.merchant_id,
            filter_type: params?.filter_type,
            from_date: params?.from_date,
            to_date: params?.to_date,
            page_size: 10000,
            page: 0,
        },
        skipLoader: true,
        skipMode: true,
        headers: {
            Authorization: process.env.RTO_DASHBOARD_SERVICE_AUTHTOKEN,
        },
    })
    if (response.success) {
        let processedData = response.data.data

        if (formatData) {
            processedData = formatData(processedData)
        }

        return {
            success: true,
            data: processedData,
        }
    }
    return { error: true }
}
