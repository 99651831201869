import { ArrowLeftOutlined, Layout } from 'gokwik-ui-kit'
import TemplateContainer from './templateContainer'
import SidebarMenu from './sidebarMenu'
import templateData from './templateData.json'
import { categoryMap, sections } from './constants'
import { Workflow } from '@library/utilities/interface'
import { getOrderedTemplates } from './helpers'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setTemplateWorkflowDetails } from '@store/actions'

interface TemplatesProps {
    setShowTemplates: (show: boolean) => void
    setIsEditing: (item: Workflow) => void
    setCreateFlow: (create: boolean) => void
}

const Templates = ({ setShowTemplates, setIsEditing, setCreateFlow }: TemplatesProps) => {
    const [filteredSections, setFilteredSection] = useState([])
    const dispatch = useDispatch()

    const enableEditing = (item: Workflow) => {
        setIsEditing(item)
        setCreateFlow(true)
        setShowTemplates(false)
        dispatch(setTemplateWorkflowDetails(item))
    }
    const renderTemplateContainers = () => {
        return Object.keys(getOrderedTemplates(templateData)).map((key: string) => {
            const heading = categoryMap[key] || key
            const templates = templateData[key]
            return (
                <TemplateContainer
                    key={key}
                    heading={heading}
                    templates={templates}
                    useTemplate={enableEditing}
                    id={key}
                />
            )
        })
    }

    useEffect(() => {
        const filteredSections = sections
            .map((section) => ({
                ...section,
                items: section.items.filter((item) => Object.keys(getOrderedTemplates(templateData)).includes(item.id)),
            }))
            .filter((section) => section.items.length > 0)
        setFilteredSection(filteredSections)
    }, [])

    return (
        <div>
            <div className='flex items-center gap-4 ml-4'>
                <span className='cursor-pointer' onClick={() => setShowTemplates(false)}>
                    <ArrowLeftOutlined className='text-lg' />
                </span>
                <p className='text-[#101828] text-xl font-semibold'>Templates</p>
            </div>

            <Layout className='bg-white'>
                <SidebarMenu filteredSections={filteredSections} />
                <Layout className='p-6 overflow-auto bg-white !ml-[17rem]'>{renderTemplateContainers()}</Layout>
            </Layout>
        </div>
    )
}

export default Templates
