import { Select, Input, Switch, InputNumber, message, Button, Form, Tooltip, InfoCircleOutlined, Modal } from 'gokwik-ui-kit'
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getRTOMerchantConfig as getRTOMerchantConfigSelector } from '@store/rto-configs/selectors'
import { updateKlothoConfig } from '@store/rto-configs/api'
import { KlothoMode, UpdateKlothoConfigObj, RtoMerchantConfig } from '../interface'
import { initialKlothoData, KlothoInputsData, KlothoModeEnum, minimumGibberishKlothoRulesThreshold, KlothoRulesDescription } from '../constants'
import { checkWhiteSpace } from '../helpers'

const RTOKlothoConfig = ({ fetchRtoConfigData }) => {
    const data = useSelector(getRTOMerchantConfigSelector) as RtoMerchantConfig
    const [oldData, setOldData] = useState({ ...initialKlothoData})
    const [updatedRtoKlothoConfig, setUpdatedRtoKlothoConfig] = useState<UpdateKlothoConfigObj>(
        {} as UpdateKlothoConfigObj,
    )
    const [configData, setConfigData] = useState({ ...initialKlothoData })
    const [saveDisabled, setSaveDisabled] = useState(true)
    const [klothoMode, setKlothoMode] = useState<KlothoMode>({ klotho_mode: undefined })

    useEffect(() => {
        if (klothoMode.klotho_mode === KlothoModeEnum.Live) {
            if (data?.klotho_config && data?.klotho_control_threshold !== null) {
                setOldData((prev) => ({
                    ...prev,
                    ...data.klotho_config,
                    klotho_control_threshold: data.klotho_control_threshold,
                }))
                setConfigData((prev) => ({
                    ...prev,
                    ...data.klotho_config,
                    klotho_control_threshold: data.klotho_control_threshold,
                }))
            }
        } else if (klothoMode.klotho_mode === KlothoModeEnum.Shadow) {
            if (data?.shadow_klotho_config) {
                setOldData((prev) => ({ ...prev, ...data.shadow_klotho_config }))
                setConfigData((prev) => ({ ...prev, ...data.shadow_klotho_config }))
            }
        }
        setUpdatedRtoKlothoConfig({} as UpdateKlothoConfigObj)
    }, [data, klothoMode])

    useEffect(() => {
        Object.keys(updatedRtoKlothoConfig).length ? setSaveDisabled(false) : setSaveDisabled(true)
    }, [updatedRtoKlothoConfig])

    const configSubmit = async () => {
        const finalData = Object.entries(configData).reduce((acc, [key, val]) => {
            if (key === 'klotho_control_threshold') {
                return acc
            }
            if (!val) {
                if (['rule_engine_enabled', 'is_score_calculate'].includes(key)) {
                    return { ...acc, [key]: false }
                } else if (key === 'f_blocked_strings') {
                    return { ...acc, [key]: '' }
                } else if (
                    key === 'is_rule_n_enabled' ||
                    key === 'is_rule_gn_enabled' ||
                    key === 'is_rule_ge_enabled'
                ) {
                    return { ...acc, [key]: val }
                } else {
                    return { ...acc, [key]: 0 }
                }
            } else if (key === 'f_blocked_strings' && !checkWhiteSpace(val).length) return { ...acc, [key]: '' }
            return { ...acc, [key]: val }
        }, {})
        let temp = JSON.parse(
            JSON.stringify({
                rto_klotho_config: { ...finalData },
                klotho_control_threshold: updatedRtoKlothoConfig.klotho_control_threshold,
                klotho_mode: klothoMode.klotho_mode,
            }),
        )
        if (
            temp?.rto_klotho_config?.rule_gn_threshold < minimumGibberishKlothoRulesThreshold ||
            temp?.rto_klotho_config?.rule_ge_threshold < minimumGibberishKlothoRulesThreshold ||
            temp?.rto_klotho_config?.rule_n_threshold < minimumGibberishKlothoRulesThreshold
        ) {
            message.error('Klotho Rule Thresholds for Rule N, GN & GE cant be less than minimum of 6.8')
            return
        }
        if (temp?.klotho_control_threshold === null) {
            message.error('Enter Klotho Control Threshold between 0-100')
            return
        }

        try {
            const response = await updateKlothoConfig({
                rto_klotho_config: temp?.rto_klotho_config,
                klotho_control_threshold: temp?.klotho_control_threshold,
                klotho_mode: temp?.klotho_mode,
            })
            if (response.status_code === 200) {
                message.success('Config Updated Successfully')
                fetchRtoConfigData()
                setUpdatedRtoKlothoConfig({} as UpdateKlothoConfigObj)
            }
        } catch (err) {
            message.error(err)
        }
    }

    const renderInput = (input: { type: string; name: string; max?: number; disabledKey?: string; key: string }) => {
        const inputDisabled = input.disabledKey && !configData[input.disabledKey]
        let inputElement;

        switch (input.type) {
            case 'number':
            case 'integer':
                inputElement = (
                    <InputNumber
                        className='w-48'
                        value={configData[input.key]}
                        min={0}
                        max={input.max}
                        onChange={(value) => {
                            setConfigData((prev) => ({ ...prev, [input.key]: value }))
                            setUpdatedRtoKlothoConfig((prev) => ({ ...prev, [input.key]: value }))
                        }}
                        disabled={inputDisabled}
                    />
                );
                break;
            case 'boolean':
                inputElement = (
                    <Switch
                        className="mt-1 justify-center"
                        checked={configData[input.key]}
                        onChange={(checked) => {
                            setConfigData((prev) => ({ ...prev, [input.key]: checked }))
                            setUpdatedRtoKlothoConfig((prev) => ({ ...prev, [input.key]: checked }))
                        }}
                    />
                );
                break;
            default:
                inputElement = (
                    <Input
                        className='w-48'
                        placeholder={input.name}
                        value={configData[input.key]}
                        onChange={(e) => {
                            setConfigData((prev) => ({ ...prev, [input.key]: e.target.value }))
                            setUpdatedRtoKlothoConfig((prev) => ({ ...prev, [input.key]: e.target.value }))
                        }}
                        maxLength={255}
                    />
                );
                break;
        }

        return (
            <div className='w-64'>
                <label className='block text-sm font-medium mb-1'>{input.name}</label>
                {inputElement}
            </div>
        );
    }

    if (data == null) {
        return <p className='text-red-500 text-lg font-bold'>RTO Service Error</p>
    } else {
        return (
            <div className='p-4'>
                <h2 className='text-xl font-bold mb-4'>RTO Klotho Configurations</h2>
                <div className='mb-12 p-5'>
                    <div className='row mb-8'>
                        <div className='w-1/4'>
                            <label className='block text-sm font-medium mb-1'>Klotho Mode Selection</label>
                            <Select
                                className='w-64'
                                placeholder='Klotho Mode Selection'
                                value={klothoMode.klotho_mode}
                                onChange={(value) => setKlothoMode({ klotho_mode: value })}
                                options={[
                                    { label: 'No selection', value: 'No selection' },
                                    { label: 'Live', value: 'Live' },
                                    { label: 'Shadow', value: 'Shadow' },
                                ]}
                            />
                        </div>
                        {klothoMode.klotho_mode !== 'No selection' && (
                            <div className='w-1/4'>
                                <label className='block text-sm font-medium mb-2 ml-4 mr-4'>Rule Engine Enabled</label>
                                <Switch
                                    className='ml-8'
                                    checked={configData.rule_engine_enabled}
                                    onChange={(checked) => {
                                        setConfigData({ ...configData, rule_engine_enabled: checked })
                                        setUpdatedRtoKlothoConfig((prev) => ({ ...prev, rule_engine_enabled: checked }))
                                    }}
                                    checkedChildren='Enabled'
                                    unCheckedChildren='Disabled'
                                />
                            </div>
                        )}
                        {klothoMode.klotho_mode === KlothoModeEnum.Live && (
                            <div className='w-1/4'>
                                <label className='block text-sm font-medium mb-1'>Klotho Control Threshold</label>
                                <InputNumber
                                    className='w-64'
                                    min={0}
                                    max={100}
                                    value={configData.klotho_control_threshold}
                                    onChange={(value) => {
                                        setConfigData({ ...configData, klotho_control_threshold: Number(value) })
                                        setUpdatedRtoKlothoConfig((prev) => ({
                                            ...prev,
                                            klotho_control_threshold: Number(value),
                                        }))
                                    }}
                                    placeholder='Klotho Control Threshold'
                                />
                            </div>
                        )}
                    </div>

                    {klothoMode.klotho_mode && klothoMode.klotho_mode !== 'No selection' && (
                        <>
                            {Object.entries(KlothoInputsData).map(([key, value]) => (
                                <div className={`row ${key !== 'head' ? 'mb-4' : ''}`} key={key}>
                                    {key !== 'head' && (
                                        <div className="w-full flex items-center mb-4">
                                            <p className='col-12 text-left font-bold text-xl mr-4'>
                                                Rule {key.toUpperCase()}:
                                            </p>
                                            {KlothoRulesDescription[key] && (
                                                <Tooltip title={KlothoRulesDescription[key]}>
                                                    <InfoCircleOutlined /> 
                                                </Tooltip>
                                            )}
                                        </div>
                                    )}
                                    <div className='grid grid-cols-3 gap-x-16 gap-y-0'>
                                        {value.map((input) => (
                                            <Form.Item key={input.key}>
                                                {renderInput(input)}
                                            </Form.Item>
                                        ))}
                                    </div>
                                </div>
                            ))}
                            <div className='flex justify-center mt-4'>
                                <Button
                                    type='primary'
                                    disabled={saveDisabled}
                                    onClick={configSubmit}
                                    style={{ width: '200px' }}
                                >
                                    Save
                                </Button>
                            </div>
                        </>
                    )}
                </div>
            </div>
        )
    }
}

export default RTOKlothoConfig
