import React from 'react'
import { Select, Form } from 'gokwik-ui-kit'
import { getSelectValue } from '@library/utilities/helpers/helper'
import { OS_OPTIONS, BROWSER_OPTIONS, operators, TIER_OPTIONS } from '@library/utilities/constants/constants'

interface KeySpecificRendererProps {
    keyName: string
    workflowData: {
        operator: string
        value?: string | number
        values?: string[]
    }
    onChangeValues: (value: string | string[], field: string) => void
    index: number
}

const KeySpecificRenderer: React.FC<KeySpecificRendererProps> = ({ keyName, workflowData, onChangeValues, index }) => {
    const handleSelectChange = (selected: string | string[]) => {
        if (Array.isArray(selected)) {
            onChangeValues(selected, 'values')
        } else {
            onChangeValues(selected, 'value')
        }
    }

    const getOptions = () => {
        switch (keyName) {
            case 'pincode_tier':
                return TIER_OPTIONS
            case 'os':
                return OS_OPTIONS
            case 'browser':
                return BROWSER_OPTIONS
            default:
                return []
        }
    }

    const isMultiSelect =
        workflowData?.operator === operators.contains || workflowData?.operator === operators.notContains

    const formItemWidth = keyName === 'os' || keyName === 'browser' ? 'w-64' : 'w-48'

    return (
        <Form.Item
            className={`${formItemWidth} mb-0`}
            name={`${keyName}-${index}`}
            rules={[{ required: true, message: 'Please select a value' }]}
            initialValue={isMultiSelect ? workflowData.values : getSelectValue(workflowData.value)}
        >
            <Select
                className={formItemWidth}
                mode={isMultiSelect ? 'multiple' : undefined}
                placeholder={`Select ${keyName.replace('_', ' ')}`}
                onChange={handleSelectChange}
                value={isMultiSelect ? workflowData.values : getSelectValue(workflowData.value)}
                options={getOptions()}
            />
        </Form.Item>
    )
}

export default KeySpecificRenderer
