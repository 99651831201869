import { LeftOutlined, DownOutlined, CheckOutlined, Tag, RightOutlined } from 'gokwik-ui-kit'
import { NotificationBar } from '@library/images/templates/notificationBar'
import { BuildingStoreIcon } from '@library/images/templates/buildingStore'
import { OrderSummaryIcon } from '@library/images/templates/orderSummaryIcon'
import { CouponTickIcon } from '@library/images/templates/couponTickIcon'
import { TrustIcon1 } from '@library/images/templates/trustIcon1'
import { TrustIcon2 } from '@library/images/templates/trustIcon2'
import { TrustIcon3 } from '@library/images/templates/trustIcon3'
import { ExternalLink } from '@library/images/templates/externalLink'
import { GokwikIcon } from '@library/images/gokwikIcon'
import { getPaymentMethods } from './helpers'
import CodPromptPreview from './codPromptPreview'
import { actionNames, opacityActions } from './constants'
import CodCaptchaPreview from './codCaptchaPreview'

interface PaymentMethod {
    label: string
    subtitle?: string
    icon: any
    badge?: {
        color?: string
        text: string
    }
    highlight?: boolean
    actionName?: string
    amount?: number
    isFading?: boolean
}

interface PaymentPreviewProps {
    hoveredAction: string | null
    variantHovered: string
}

const PaymentPreview = ({ hoveredAction, variantHovered }: PaymentPreviewProps) => {
    const isHighlightMethod = (method: PaymentMethod) => {
        return method.highlight && method.actionName === hoveredAction
    }
    return (
        <div className='w-80 relative'>
            <div className='mb-4'>
                <h2 className='text-lg font-semibold text-gray-800'>
                    Preview {variantHovered ? `- Variant ${variantHovered}` : ''}
                </h2>
                <p className='text-sm text-gray-500 mt-1'>
                    This is the screen the user will see after this intervention.
                </p>
            </div>
            <div className='border-4 border-solid border-[#667085] rounded-[24px] overflow-hidden '>
                <div className='py-1.5 bg-white flex flex-col items-center gap-4'>
                    <NotificationBar />
                    <div className='flex items-center justify-between w-full px-4'>
                        <div className='flex items-center'>
                            <LeftOutlined className='text-gray-400 h-5 w-5' />
                            <BuildingStoreIcon />
                        </div>
                        <div className='flex items-center gap-2 text-xs'>
                            <div className='flex items-center gap-3 text-sm'>
                                <div className='flex items-center gap-1'>
                                    <span className='rounded-full bg-[#EFFEFA] h-4 w-4'>
                                        <CheckOutlined
                                            className='text-[#03B696] flex items-center justify-center h-full'
                                            style={{ fontSize: '6px' }}
                                        />
                                    </span>
                                    <span className='text-[#03B696]'>Mobile</span>
                                </div>
                                <span>•</span>
                                <div className='flex items-center gap-1'>
                                    <span className='rounded-full bg-[#EFFEFA] h-4 w-4'>
                                        <CheckOutlined
                                            className='text-[#03B696] flex items-center justify-center h-full'
                                            style={{ fontSize: '6px' }}
                                        />
                                    </span>
                                    <span className='text-[#03B696]'>Address</span>
                                </div>
                                <span>•</span>
                                <span className='font-medium text-[#405065]'>Pay</span>
                            </div>
                        </div>
                    </div>
                </div>

                {actionNames.codCaptcha === hoveredAction ? (
                    <CodCaptchaPreview />
                ) : (
                    <div className='bg-gray-100 p-4 relative'>
                        {opacityActions.includes(hoveredAction) && (
                            <div className='absolute inset-0 bg-black opacity-40 z-10' />
                        )}
                        <div className='bg-white rounded-3xl border border-gray-200 px-3 py-2 mb-3 mt-1 flex justify-between items-center relative'>
                            <div className='flex items-center gap-2'>
                                <OrderSummaryIcon />
                                <span className='text-sm text-gray-700'>Order Summary</span>
                                <DownOutlined className='h-4 w-4' />
                            </div>
                            <div className='flex items-center gap-1'>
                                <span className='text-sm font-medium'>₹100.00</span>
                            </div>
                            <Tag
                                color='#15B79E'
                                className='absolute top-[-11px] left-[30%] !text-white !py-[0rem] !px-2 text-[10px] leading-[14px]'
                            >
                                Fee Gift Added
                            </Tag>
                        </div>

                        <div className='bg-white rounded-3xl border border-gray-200 px-3 py-2 mb-3 flex items-center gap-2'>
                            <CouponTickIcon />
                            <span className='text-sm text-gray-700'>Enter Coupon</span>
                        </div>

                        <div className='text-xs font-medium text-[#405065] mb-3'>
                            Select payment method to complete the order
                        </div>

                        <div className='space-y-2 relative min-h-72'>
                            {getPaymentMethods(hoveredAction).map((method: PaymentMethod) => (
                                <div
                                    key={method.label}
                                    className={`relative ${isHighlightMethod(method) && 'z-30'} ${
                                        method.isFading ? 'animate-fadeOut opacity-0' : 'animate-fade-in opacity-100'
                                    }`}
                                >
                                    <button
                                        className={`w-full flex items-center justify-between p-3 border border-[#00192F] rounded-3xl transition-colors max-h-12 ${
                                            method.isFading ? 'pointer-events-none' : ''
                                        }`}
                                    >
                                        {' '}
                                        <div className='flex items-center gap-3'>
                                            <span className='flex'>
                                                <method.icon />
                                            </span>
                                            <div>
                                                <div className='text-sm font-medium text-left'>{method.label}</div>
                                                {method.subtitle && (
                                                    <div className='text-[10px] text-[#00192F]'>{method.subtitle}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div>
                                            <span className='text-sm font-medium'>₹{method.amount ?? 100}</span>
                                            <RightOutlined style={{ fontSize: '10px' }} />
                                        </div>
                                    </button>
                                    {method.badge && (
                                        <Tag
                                            color={method.badge.color || '#15B79E'}
                                            className='absolute top-1 left-[50%] -translate-y-1/2 -translate-x-1/2 !text-white !py-[0rem] !px-2 text-[10px] !m-0 leading-[14px]'
                                        >
                                            {method.badge.text}
                                        </Tag>
                                    )}
                                </div>
                            ))}
                            {hoveredAction === actionNames.codPrompt && <CodPromptPreview />}
                        </div>
                    </div>
                )}

                {/* Footer */}
                <div className='flex justify-between items-center border-t border-gray-200 bg-white px-2 py-1'>
                    <div className='flex gap-2'>
                        <TrustIcon1 />
                        <TrustIcon2 />
                        <TrustIcon3 />
                    </div>
                    <div className='flex items-center gap-2 text-xs text-gray-500'>
                        <ExternalLink />
                        <span className='border-b border-gray-300 text-[10px]'>Terms & Policy</span>
                        <GokwikIcon />
                        <div className='text-gray-400 flex flex-col justify-start'>
                            <span className='text-[5px] h-2.5'>Powered By</span>
                            <span className='font-bold'>Gokwik</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PaymentPreview
