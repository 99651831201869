import TemplateCard from './templateCard'
import { Carousel } from 'gokwik-ui-kit'
import { getWorkflowData } from '@store/actions/selectors'
import { useSelector } from 'react-redux'
import TemplatePreviewModal from './templatePreviewModal'
import { useState } from 'react'
import { Workflow } from '@library/utilities/interface'

interface TemplateContainerProps {
    heading: string
    templates: Workflow[]
    useTemplate: (template: Workflow) => void
    id: string
}

const TemplateContainer = ({ heading, templates, useTemplate, id }: TemplateContainerProps) => {
    const workflowDetails = useSelector(getWorkflowData)
    const showTiering = workflowDetails?.show_tiering ? true : false
    const [selectedTemplate, setSelectedTemplate] = useState({})
    const [showPreview, setShowPreview] = useState(true)

    return (
        <div className='flex flex-col w-full mb-6 gap-4' id={id}>
            <p className='text-[#101828] font-semibold text-xl'>{heading}</p>
            <Carousel
                arrows
                slidesToShow={3}
                slidesToScroll={1}
                infinite={false}
                style={{ width: '100%' }}
                dots={false}
            >
                {templates.map((template, index) => (
                    <div key={index} style={{ display: 'flex', justifyContent: 'center' }}>
                        <TemplateCard
                            templateData={template}
                            showTiering={showTiering}
                            setSelectedTemplate={setSelectedTemplate}
                            setShowPreview={setShowPreview}
                        />
                    </div>
                ))}
            </Carousel>
            {Object.keys(selectedTemplate).length > 0 && (
                <TemplatePreviewModal
                    selectedTemplate={selectedTemplate}
                    showPreview={showPreview}
                    setShowPreview={setShowPreview}
                    useTemplate={useTemplate}
                />
            )}
        </div>
    )
}

export default TemplateContainer
