import StarIcon from '@library/images/starIcon'
import { TickIcon } from '@library/images/tickIcon'
import { Button, Drawer, message } from 'gokwik-ui-kit'

const KwikChatDrawer = ({ showKwikChatDrawer, setShowKwikChatDrawer, kcConfigs, updateKCConfig }) => {
    const features = [
        {
            id: 1,
            title: 'Customer RTO Risk',
            content: 'Enhanced RTO prediction, trained on 1.8 billion data points',
        },
        {
            id: 2,
            title: 'Address Quality Flag',
            content: 'Reduce shipping costs for bad quality addresses with an AI, trained on 3 billion data points',
        },
        {
            id: 3,
            title: 'Reduce RTO upto 10%',
            content: 'Ship order only after verification',
        },
    ]

    const steps = [
        {
            id: 1,
            content: 'Click on Activate RTO action on bottom right corner',
        },
        {
            id: 2,
            content: 'Team will reach out to setup you KwikChat account',
        },
        {
            id: 3,
            content: 'Confirm your high risk orders with you buyer',
        },
        {
            id: 4,
            content: 'Ship your orders with no worries',
        },
    ]

    const handleButtonClick = async () => {
        const response = await updateKCConfig({
            kc_config: { ...kcConfigs, kc_onboarding_status: 1 },
            merchant_requested_kc_onboarding: true, // when setting 0 -> 1 not initiated -> initiated
        })
        if (response.status_code === 200) {
            message.success('Activation Initiated!')
            setShowKwikChatDrawer(false)
        } else {
            message.error('Activation Failed!')
        }
    }
    const kcOnboardingStatus = kcConfigs?.kc_onboarding_status
    const RenderFooter = () => {
        return (
            <div className='bg-gray-50 shadow-md p-4 flex justify-end gap-2'>
                <button
                    onClick={() => setShowKwikChatDrawer(false)}
                    className='text-sm text-gray-500 bg-white outline-none rounded-md border border-solid border-gray-200 shadow-sm py-1 px-2'
                >
                    Discard
                </button>
                <Button
                    type='primary'
                    className='flex items-center gap-2'
                    disabled={kcOnboardingStatus === 1}
                    onClick={handleButtonClick}
                >
                    {kcOnboardingStatus === 1 ? 'Activation Initiated' : 'Activate RTO Risk'}
                    <StarIcon fillColor='#fff' />
                </Button>
            </div>
        )
    }

    return (
        <Drawer
            onClose={() => setShowKwikChatDrawer(false)}
            open={showKwikChatDrawer}
            placement='right'
            closable={false}
            width={750}
            footer={<RenderFooter />}
            styles={{
                footer: {
                    padding: 0,
                },
            }}
            zIndex={2147483001} //to hide help chat icon
        >
            <div className='gap-4 flex flex-col'>
                <div className='flex items-center gap-2'>
                    <span
                        className='uppercase text-blue-500 font-semibold
                flex items-center justify-center px-2 py-1 rounded-md bg-gradient-to-l from-blue-100 to-blue-200 gap-1'
                    >
                        <StarIcon />
                        New
                    </span>
                    <span className='font-semibold text-xl'>Introducing RTO Risk</span>
                </div>

                <div className='flex justify-between gap-4'>
                    {features.map(({ id, title, content }) => (
                        <div
                            key={id}
                            className='flex flex-col flex-1 rounded-md gap-2 p-4 my-2 shadow-lg shadow-blue-200/20 bg-gradient-to-br from-opacity-5 to-opacity-10 from-blue-100/20 to-blue-200/40'
                        >
                            <p className='font-semibold text-base'>{title}</p>

                            <span className='text-sm font-normal'>{content}</span>
                        </div>
                    ))}
                </div>

                <div className='bg-gray-50 p-4 rounded-md'>
                    <p className='font-semibold text-base'>How does it work?</p>
                    <ul className='font-medium text-sm list-none p-0'>
                        {steps.map(({ id, content }) => (
                            <li key={id} className='kwikChatDrawerList relative'>
                                <span className='absolute w-2 h-2 rounded-full bg-black top-2 left-2' />
                                <p className='text-gray-500 m-0 flex items-center gap-1'>
                                    Step {id}
                                    {kcOnboardingStatus === 1 && id === 1 ? <TickIcon /> : null}
                                </p>
                                <p>{content}</p>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </Drawer>
    )
}

export default KwikChatDrawer
