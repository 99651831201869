import { makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import {
    calculateRTOMetrics,
    formatConfigData,
    formatOrderMetrics,
    formatTableData,
} from '@library/utilities/helpers/calculate_metrics'

export const analyticsAPIs: {
    [key: string]: {
        url: string
        errMsg: string
        nestedKeys?: string[]
        methodType?: 'get' | 'post'
        defaultParams?: { [key: string]: any }
        defaultBody?: { [key: string]: any }
        headers?: object
        formatData?: (data: any) => any
    }
} = {
    rtoInsights: {
        url: APIEndPoints.getOverviewRTOInsightData,
        errMsg: 'Unable to fetch RTO Insight Data',
        defaultParams: {
            filter_type: 'all',
            filter_value: 'value',
        },
        methodType: 'get',
        headers: {
            Authorization: process.env.RTO_DASHBOARD_SERVICE_AUTHTOKEN,
        },
        formatData: calculateRTOMetrics,
    },
    rtoInsightsPrevComparison: {
        url: APIEndPoints.getOverviewRTOInsightData,
        errMsg: 'Unable to fetch RTO Insight Data',
        defaultParams: {
            filter_type: 'all',
            filter_value: 'value',
        },
        methodType: 'get',
        headers: {
            Authorization: process.env.RTO_DASHBOARD_SERVICE_AUTHTOKEN,
        },
        formatData: calculateRTOMetrics,
    },
    orderInsights: {
        url: APIEndPoints.getOverviewOrdersInsightData,
        errMsg: 'Unable to Fetch Order Insight Data',
        defaultParams: {
            filter_type: 'all',
        },
        methodType: 'get',
        headers: {
            Authorization: process.env.RTO_DASHBOARD_SERVICE_AUTHTOKEN,
        },
        formatData: formatOrderMetrics,
    },
    orderInsightsPrevComparison: {
        url: APIEndPoints.getOverviewOrdersInsightData,
        errMsg: 'Unable to Fetch Order Insight Data',
        defaultParams: {
            filter_type: 'all',
        },
        methodType: 'get',
        headers: {
            Authorization: process.env.RTO_DASHBOARD_SERVICE_AUTHTOKEN,
        },
        formatData: formatOrderMetrics,
    },
    configData: {
        url: APIEndPoints.getConfigData,
        errMsg: 'Unable to fetch Config Data',
        methodType: 'get',
        headers: {
            Authorization: process.env.RTO_DASHBOARD_SERVICE_AUTHTOKEN,
        },
        formatData: formatConfigData,
    },
    topStatesByRtoRate: {
        url: APIEndPoints.getOverviewTableData,
        errMsg: 'Unable to Fetch Top States',
        defaultBody: {
            filter_type: 'state',
            page_size: 5,
            page: 0,
        },
        methodType: 'post',
        headers: {
            Authorization: process.env.RTO_DASHBOARD_SERVICE_AUTHTOKEN,
        },
        formatData: formatTableData,
    },
    topCitiesByRtoRate: {
        url: APIEndPoints.getOverviewTableData,
        errMsg: 'Unable to Fetch Top Cities',
        defaultBody: {
            filter_type: 'city',
            page_size: 5,
            page: 0,
        },
        methodType: 'post',
        headers: {
            Authorization: process.env.RTO_DASHBOARD_SERVICE_AUTHTOKEN,
        },
        formatData: formatTableData,
    },
    topPincodesByRtoRate: {
        url: APIEndPoints.getOverviewTableData,
        errMsg: 'Unable to Fetch Top Pincodes',
        defaultBody: {
            filter_type: 'pincode',
            page_size: 5,
            page: 0,
        },
        methodType: 'post',
        headers: {
            Authorization: process.env.RTO_DASHBOARD_SERVICE_AUTHTOKEN,
        },
        formatData: formatTableData,
    },
    topProductsByRtoRate: {
        url: APIEndPoints.getOverviewTableData,
        errMsg: 'Unable to Fetch Top Products',
        defaultBody: {
            filter_type: 'product',
            page_size: 5,
            page: 0,
        },
        methodType: 'post',
        headers: {
            Authorization: process.env.RTO_DASHBOARD_SERVICE_AUTHTOKEN,
        },
        formatData: formatTableData,
    },
}

export const fetchAnalyticsData = async ({
    key,
    params,
    payload,
    nestedKey,
}: {
    key: string
    nestedKey?: string
    params?: { [key: string]: any }
    payload?: { [key: string]: any }
}) => {
    let { url, nestedKeys, defaultParams, methodType, defaultBody, headers, formatData } = analyticsAPIs[key]
    if (key === 'rtoInsightsPrevComparison' || key === 'orderInsightsPrevComparison') {
        params = {
            ...params,
            from_date: params.prev_comparison_from_date,
            to_date: params.prev_comparison_to_date,
        }
    }
    if (key === 'configData') {
        params = {
            ...params,
            merchant_id: params.m_id,
        }
    }
    const response = await makeAPICall({
        method: methodType,
        url: process.env.RTO_DASHBOARD_SERVICE_URL + url,
        params: {
            ...(defaultParams || {}),
            ...(params || {}),
        },
        payload: {
            ...(defaultBody || {}),
            ...(payload || {}),
        },
        skipLoader: true,
        skipMode: true,
        headers: {
            ...headers,
        },
    })

    if (response.success) {
        let processedData = {
            fromDate: params.from_date,
            toDate: params.to_date,
            data: response.data.data,
        }

        if (formatData) {
            processedData = formatData(processedData)
        }

        return {
            success: true,
            data: processedData,
        }
    }
    return { error: true }
}

export const updateShopifyDashboardAnalytics = async (filterType, filterValue) => {
    const response = await makeAPICall({
        method: 'post',
        url: process.env.RTO_DASHBOARD_SERVICE_URL + APIEndPoints.updateShopifyAnalytics,
        payload: {
            search_type: filterType,
            search_field: filterValue,
        },
        skipLoader: true,
        skipMode: true,
        headers: {
            Authorization: process.env.RTO_DASHBOARD_SERVICE_AUTHTOKEN,
        },
    })
    return response
}
