import { Button, Dropdown, Input, Menu, FilterOutlined } from 'gokwik-ui-kit'
import React, { useEffect, useState } from 'react'
import { useRef } from 'react'

const FilterMenu = ({ filterData }) => {
    const [filterValue, setFilterValue] = useState('')
    const [selectedFilter, setSelectedFilter] = useState(null)
    const [selectedColumn, setSelectedColumn] = useState(null)
    const [minValue, setMinValue] = useState('')
    const [maxValue, setMaxValue] = useState('')
    const [visible, setVisible] = useState(false)
    const dropdownRef = useRef(null)
    const mouseClickEvents = ['mousedown', 'click', 'mouseup']
    const simulatedDiv = document.querySelector('div[class="main-div"]')

    useEffect(() => {
        const handleClickOutside = (event) => {
            const clickedElement = event.target as HTMLElement
            const classListArray = Array.from(clickedElement.classList) as string[]
            const isAntClass = classListArray.some((className) => className.includes('ant'))
            if (dropdownRef.current && !dropdownRef.current.contains(event.target) && !isAntClass) {
                setVisible(false)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    const simulateMouseClick = (element) => {
        mouseClickEvents.forEach((mouseEventType) =>
            element.dispatchEvent(
                new MouseEvent(mouseEventType, {
                    view: window,
                    bubbles: true,
                    cancelable: true,
                    buttons: 1,
                }),
            ),
        )
    }

    const handleFilterChange = (e) => {
        setFilterValue(e.target.value)
        setVisible(true)
    }

    const handleMinValueChange = (e) => {
        setMinValue(e.target.value)
        setVisible(true)
    }

    const handleMaxValueChange = (e) => {
        setMaxValue(e.target.value)
        setVisible(true)
    }

    const handleMenuClick = ({ key }) => {
        setSelectedFilter(key)
        setVisible(true)
    }

    const handleColumnClick = ({ key }) => {
        setSelectedColumn(key)
        setVisible(true)
    }

    const handleFilterClick = () => {
        filterData(selectedFilter, selectedColumn, {
            maxValue,
            minValue,
            filterValue,
        })
        simulateMouseClick(simulatedDiv)
        setVisible(false)
        resetValues()
    }

    const renderInputFields = () => {
        if (selectedFilter === 'between') {
            return (
                <div style={{ width: '300px' }}>
                    <Input
                        placeholder='Min'
                        value={minValue}
                        onChange={handleMinValueChange}
                        style={{ width: '290px', margin: '5px' }}
                        type='number'
                    />
                    <Input
                        placeholder='Max'
                        value={maxValue}
                        onChange={handleMaxValueChange}
                        style={{ width: '290px', margin: '5px' }}
                        type='number'
                    />
                </div>
            )
        }
        return (
            <Input
                placeholder='Enter filter value'
                value={filterValue}
                onChange={handleFilterChange}
                style={{ width: '290px', margin: '5px' }}
                type='number'
                disabled={!Boolean(selectedFilter)}
            />
        )
    }

    const renderSubMenu = () => {
        return (
            <>
                <Menu onClick={handleMenuClick} style={{ width: '300px' }} theme='light'>
                    <Menu.Item key='greaterThan'>Greater Than</Menu.Item>
                    <Menu.Item key='lessThan'>Less Than</Menu.Item>
                    <Menu.Item key='equalTo'>Equal To</Menu.Item>
                    <Menu.Item key='between'>Between</Menu.Item>
                </Menu>

                {renderInputFields()}

                <div style={{ display: 'flex', justifyContent: 'right' }}>
                    <Button
                        onClick={handleFilterClick}
                        type='primary'
                        style={{ margin: '5px' }}
                        disabled={!Boolean((minValue && maxValue) || filterValue)}
                    >
                        Filter
                    </Button>
                </div>
            </>
        )
    }
    const menuStyle = {
        width: '300px',
    }

    const resetValues = () => {
        setMinValue('')
        setMaxValue('')
        setFilterValue('')
    }

    const menu = (
        <div style={menuStyle}>
            <Menu
                onClick={handleColumnClick}
                onOpenChange={resetValues}
                theme='light'
                triggerSubMenuAction='click'
                subMenuCloseDelay={0.5}
                forceSubMenuRender={true}
            >
                <Menu.SubMenu title='Total Orders Count' key='total_orders' onTitleClick={handleColumnClick}>
                    {renderSubMenu()}
                </Menu.SubMenu>
                <Menu.SubMenu title='COD Orders Count' key='cod_orders' onTitleClick={handleColumnClick}>
                    {renderSubMenu()}
                </Menu.SubMenu>
                <Menu.SubMenu title='RTO Orders Count' key='rto_orders' onTitleClick={handleColumnClick}>
                    {renderSubMenu()}
                </Menu.SubMenu>
            </Menu>
        </div>
    )

    return (
        <>
            <Dropdown overlay={menu} trigger={['click']} open={visible} onOpenChange={setVisible}>
                <Button
                    onClick={() => setVisible(true)}
                    icon={
                        <FilterOutlined/>
                    }
                >
                    {''}
                </Button>
            </Dropdown>
        </>
    )
}

export default FilterMenu
