import { BatteryIcon } from '@library/images/batteryIcon'
import { CallIcon } from '@library/images/callIcon'
import { CellularIcon } from '@library/images/cellularIcon'
import { GokwikIcon } from '@library/images/gokwikIcon'
import { TickIcon } from '@library/images/tickIcon'
import { getMerchantDetails } from '@store/user/selectors'
import { ArrowLeftOutlined, Modal, MoreOutlined } from 'gokwik-ui-kit'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

const WhatsAppPreview = ({
    showWhatsAppPreview = false,
    setShowWhatsAppPreview,
    kcMessages,
    currentOrder,
    getConfirmationStatus,
}) => {
    const merchantDetails = useSelector(getMerchantDetails)
    const [convertedMessage, setConvertedMessage] = useState('')

    const getAddress = () => {
        let address = currentOrder?.billing_address1 ? currentOrder?.billing_address1 : ''
        address += currentOrder?.billing_address2 ? ', ' + currentOrder?.billing_address2 : ''
        return address
    }

    const kcMessageMapping = useMemo(() => {
        return {
            username: currentOrder?.billing_first_name ?? 'Customer Name',
            merchant_name: merchantDetails?.short_name ?? 'Merchant Name',
            order_number: currentOrder?.order_number ?? '',
            amount: currentOrder?.grand_total ?? '',
            address: getAddress() ?? '',
        }
    }, [currentOrder, merchantDetails])
    const confirmationStatus = getConfirmationStatus(currentOrder?.address_score, currentOrder?.rto_rate, currentOrder?.rto_risk_flag) ?? ''

    useEffect(() => {
        const currentKCMessage =
            confirmationStatus.toLowerCase() === 'order'
                ? kcMessages?.order_confirmation
                : kcMessages?.address_confirmation
        const renderedMessage = currentKCMessage.replace(/{(.*?)}/g, (match, key) => kcMessageMapping[key] || '')
        setConvertedMessage(renderedMessage)
    }, [kcMessages, confirmationStatus])

    return (
        <Modal
            open={showWhatsAppPreview}
            closable
            title={<span>WhatsApp Preview</span>}
            footer={null}
            onCancel={() => setShowWhatsAppPreview(false)}
            centered
            width={380}
        >
            <div className=''>
                <p className='border-b border-gray-200 text-sm text-gray-500'>
                    This is the {confirmationStatus} confirmation template, we will trigger this template after your
                    action.
                </p>
                <div className='p-3.5 flex justify-center'>
                    <div className='bg-gray-200 rounded-xl p-2 relative w-80'>
                        <div>
                            <div className='bg-[#054D47] rounded-t-xl flex justify-between px-4 items-center py-1'>
                                <span className='text-xs font-normal text-white  opacity-25'>1:42</span>
                                <span className=' opacity-25 flex items-center gap-2'>
                                    <BatteryIcon />
                                    <CellularIcon />
                                </span>
                            </div>
                            <div className='bg-[#085B53] text-white px-4 py-2'>
                                <div className='flex items-center justify-between'>
                                    <div className='flex items-center gap-2'>
                                        <ArrowLeftOutlined />
                                        <div className='bg-white rounded-full p-1 flex items-center'>
                                            <GokwikIcon />
                                        </div>
                                        <span>GoKwik</span>
                                        <TickIcon />
                                    </div>
                                    <div className='flex items-center gap-2'>
                                        <CallIcon />
                                        <MoreOutlined className='text-lg font-bold' />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='bg-[#F3EFE5] rounded-b-lg shadow-lg overflow-hidden  text-xs'>
                            <div className='m-4 mb-0 bg-white rounded-lg p-2'>
                                {convertedMessage.split('\n').map((line, index) => (
                                    <Fragment key={index}>
                                        {line}
                                        <br />
                                    </Fragment>
                                ))}

                                <p className='text-gray-400 text-[10px] ml-auto w-fit'>11.14 AM</p>
                            </div>
                            <div className='px-4 pb-4 pt-1 flex flex-col space-y-1'>
                                {confirmationStatus?.toLowerCase() === 'order' ? (
                                    <>
                                        <div className='bg-white text-[#0096DE] rounded-md px-4 py-2 text-center font-bold'>
                                            Confirm Order
                                        </div>
                                        <div className='bg-white text-[#0096DE] rounded-md px-4 py-2 text-center font-bold'>
                                            Cancel Order
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className='bg-white text-[#0096DE] rounded-md px-4 py-2 text-center font-bold'>
                                            Yes, My Address is correct
                                        </div>
                                        <div className='bg-white text-[#0096DE] rounded-md px-4 py-2 text-center font-bold'>
                                            No, My Address is incorrect
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default WhatsAppPreview
