import { message } from 'gokwik-ui-kit'
import { downloadCsv, getIngestionStatus, notifyUpload } from '@store/awb-flows/api'
import { awbFillRateSuccessfullIngestion, invalidUploadFile, requiredHeadings } from './constants'
import {
    AwbFillRate,
    AwbFillRateDetails,
    FileValidationResult,
    PollingConfig,
    RawDataSource,
    TransformedDataSource,
    UnknownAwbStatus,
} from './interface'
import dayjs from 'dayjs'
import { downloadToCSV } from '@gokwik/utilities'

export enum FileType {
    CSV = 'csv',
    XLS = 'xls',
    XLSX = 'xlsx',
}

const pollIngestionStatus = async (uploadId: string, config: PollingConfig = {}): Promise<string> => {
    const { maxAttempts = 5, interval = 2000 } = config

    return new Promise((resolve, reject) => {
        let attempts = 0

        const checkStatus = async () => {
            try {
                const response = await getIngestionStatus(uploadId)
                const status = response.data.status

                const statusHandlers = {
                    INGESTING: () => {
                        message.success(awbFillRateSuccessfullIngestion)
                        resolve(status)
                    },
                    SUCCESSFUL: () => {
                        message.success(awbFillRateSuccessfullIngestion)
                        resolve(status)
                    },
                    ERROR: () => {
                        message.error(status)
                        reject(status)
                    },
                    VALIDATION_ERROR: () => {
                        message.error(status)
                        reject(status)
                    },
                    PROCESSING: () => {
                        if (attempts < maxAttempts) {
                            attempts++
                            setTimeout(checkStatus, interval)
                        } else {
                            message.success(awbFillRateSuccessfullIngestion)
                            resolve(status)
                        }
                    },
                }

                ;(statusHandlers[status] || (() => reject('Unknown status')))()
            } catch (err) {
                reject(err)
            }
        }

        checkStatus()
    })
}

const notifyAndPollUpload = async (uploadId: string, merchantId: number) => {
    try {
        const res = await notifyUpload(uploadId, merchantId)
        if (res.status === 201 && res.data) {
            const validRows = res.data.valid_rows
            const totalRows = res.data.total_rows
            message.info(`${validRows} out of ${totalRows} entries successfully processed`)
            const result = await pollIngestionStatus(uploadId)
            return result
        }
    } catch (err) {
        console.error('Error in notify and poll', err)
        throw err
    }
}

const validateFile = (
    file: File,
    maxSize: number = 20 * 1024 * 1024, // 20 MB default
): boolean => {
    if (file.size > maxSize) {
        message.error(`File size exceeds the ${maxSize / (1024 * 1024)} MB limit.`)
        return false
    }
    return true
}

export const createUploadHeaders = (contentType: string = 'text/csv'): Headers => {
    const headers = new Headers()
    headers.append('Content-Type', contentType)
    return headers
}

const getPercentageCategory = (percentValue: number) => {
    if (percentValue === 100) {
        return { color: 'success', text: 'Perfect' }
    } else if (percentValue > 95) {
        return { color: 'default', text: 'Great' }
    } else if (percentValue >= 80 && percentValue <= 95) {
        return { color: 'warning', text: 'Good' }
    } else {
        return { color: 'error', text: 'Low' }
    }
}

const getTagColor = (percentage: string) => {
    const percentValue = parseFloat(percentage)
    return getPercentageCategory(percentValue).color as 'success' | 'default' | 'warning' | 'error'
}

const getTagTextByPercentage = (percentage: string) => {
    const percentValue = parseFloat(percentage)
    return getPercentageCategory(percentValue).text
}

const getFileType = (fileName: string): FileType => {
    const fileExtension = fileName.slice(((fileName.lastIndexOf('.') - 1) >>> 0) + 2).toLowerCase() as FileType

    return Object.values(FileType).includes(fileExtension) ? fileExtension : FileType.CSV
}

const validateFileHeaders = (headers: string[], requiredHeaders: string[]): FileValidationResult => {
    const normalizedHeaders = headers.map((header) => header.toLowerCase())
    const normalizedRequiredHeaders = requiredHeaders.map((header) => header.toLowerCase())

    const headersMatch = normalizedRequiredHeaders.every((requiredHeader) => normalizedHeaders.includes(requiredHeader))

    if (!headersMatch) {
        return {
            isValid: false,
            error: invalidUploadFile,
            headers: normalizedHeaders,
        }
    }

    return {
        isValid: true,
        headers: normalizedHeaders,
    }
}

const parseFileContents = (file: File, fileType: FileType, XLSX: any): Promise<FileValidationResult> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()

        reader.onload = (e) => {
            try {
                const data = e.target.result
                const workbook = XLSX.read(data, { type: fileType === FileType.CSV ? 'string' : 'binary' })

                const wsname = workbook.SheetNames[0]
                const ws = workbook.Sheets[wsname]

                const parsedData = XLSX.utils.sheet_to_json(ws)

                if (parsedData.length === 0) {
                    reject(new Error('No data found in the file'))
                    return
                }

                const fileHeaders = Object.keys(parsedData[0])
                const validationResult = validateFileHeaders(fileHeaders, requiredHeadings)

                resolve(validationResult)
            } catch (error) {
                reject(error)
            }
        }

        reader.onerror = (error) => {
            reject(error)
        }

        fileType === FileType.CSV ? reader.readAsText(file) : reader.readAsBinaryString(file)
    })
}

const getPreviousMonthAwbFillRate = (data: AwbFillRate[]): number | null => {
    if (!data) return null
    const sortedData = [...data].sort((a, b) => new Date(b.month).getTime() - new Date(a.month).getTime())
    if (sortedData.length < 2) {
        return null
    }

    const previousMonthData = sortedData[1]
    if (previousMonthData.total_merchant_orders > 0) {
        const awbFillPercentage = (previousMonthData.endstate_count / previousMonthData.total_merchant_orders) * 100
        return Number(awbFillPercentage.toFixed(1))
    }
    return null
}

const transformDataSource = (awbData: AwbFillRateDetails): TransformedDataSource[] => {
    const data = awbData.awbFillRate
    const sortedData = [...data].sort((a, b) => dayjs(a.month).diff(dayjs(b.month)))
    const currentMonth = dayjs().format('YYYY-MM-01')

    return sortedData.map((item, index) => {
        const awbFillRate = getAwbFillRate(item).toFixed(1) + '%'

        const ingestionRequired = determineIngestionRequired(item, currentMonth)

        const ingestionStatus = determineIngestionStatus(item, currentMonth)

        const currentDate = new Date(item.month)
        const firstDayOfMonth = dayjs(currentDate).format('YYYY-MM-01')
        const lastDayOfMonth = dayjs(currentDate).endOf('month').format('YYYY-MM-DD')

        const unknownAwbStatus: UnknownAwbStatus = {
            merchant_id: awbData.merchant_id,
            platform: awbData.platform,
            start_date: new Date(firstDayOfMonth),
            end_date: new Date(lastDayOfMonth),
            pending_missing_count: item.pending_missing_count,
        }

        return {
            key: (index + 1).toString(),
            month: dayjs(item.month).format('MMMM YY'),
            awbFillRate,
            ingestionRequired,
            ingestionStatus,
            unknownAwbStatus,
        }
    })
}

const filterAndDownloadCSVData = async (unknownAwbStatus: UnknownAwbStatus) => {
    const finalDataColumns = ['Merchant OrderID', 'AWB Number (Optional)', 'Shipping Provider (Optional)', 'AWB Status']
    const dataKeys = ['merchant_order_id']
    let responseReceived = false
    setTimeout(() => {
        if (!responseReceived) {
            message.info(
                `CSV generation is in-progress. It may take up to 2 minutes and will be available in your downloads section.`,
            )
        }
    }, 2000)
    const responseData = await downloadCsv({
        merchant_id: unknownAwbStatus.merchant_id,
        platform: unknownAwbStatus.platform,
        start_date: unknownAwbStatus.start_date,
        end_date: unknownAwbStatus.end_date,
    })

    responseReceived = true
    if (!responseData)
        message.error(
            'The CSV is currently unavailable. Please reach out to merchant care support for further assistance.',
        )

    downloadToCSV(dataKeys, finalDataColumns, responseData, 'Pending/Missing Orders', { addSerialNumber: false })
    message.success('CSV Downloaded!')
}

const getAwbFillRate = (item: RawDataSource) => {
    return (item.endstate_count / item.total_merchant_orders) * 100
}

const determineIngestionRequired = (item: RawDataSource, currentMonth: string): string => {
    if (dayjs(item.month).isSame(currentMonth, 'month')) {
        return 'NA'
    }

    const fillRate = getAwbFillRate(item)

    if (fillRate < 80) {
        return 'Yes'
    }

    return 'No'
}

const determineIngestionStatus = (item: RawDataSource, currentMonth: string): string | null => {
    const fillRate = (item.endstate_count / item.total_merchant_orders) * 100

    if (fillRate >= 95) {
        return 'NA'
    }

    return 'Pending'
}

const aggregateMonthlyAWBData = (
    data: Array<{ date: string; total_orders: string; total_null_status_orders: string; total_pending_orders: string }>,
) => {
    const transformedData = data.reduce((acc, curr) => {
        const month = new Date(curr.date).toISOString().split('T')[0].slice(0, 8) + '01T00:00:00.000Z' // Format: YYYY-MM-01T00:00:00.000Z

        if (!acc[month]) {
            acc[month] = {
                month: month,
                total_merchant_orders: 0,
                endstate_count: 0,
                pending_missing_count: 0,
            }
        }

        acc[month].total_merchant_orders += parseInt(curr.total_orders, 10)

        const total_null_pending = parseInt(curr.total_null_status_orders, 10) + parseInt(curr.total_pending_orders, 10)
        acc[month].endstate_count += parseInt(curr.total_orders, 10) - total_null_pending
        acc[month].pending_missing_count += total_null_pending

        return acc
    }, {})

    return Object.values(transformedData)
}

const validateRTOModelConfig = (rtoRiskType, rtoRiskFlag, rtoRiskThreshold) => {
    if (!rtoRiskType)
        return {
            msg: 'Select Risk Type',
            status: false,
        }
    if (!rtoRiskFlag)
        return {
            msg: 'Enter Risk Flag',
            status: false,
        }
    if (!rtoRiskThreshold)
        return {
            msg: 'Enter Risk Threshold',
            status: false,
        }
    if (rtoRiskThreshold < 0 || rtoRiskThreshold > 1)
        return {
            msg: 'Enter Risk Threshold between 0 and 1',
            status: false,
        }

    return {
        msg: 'Success',
        status: true,
    }
}

const validateControlSegmentConfig = (rtoRiskType, rtoRiskFlag, rtoRiskThreshold) => {
    if (!rtoRiskType)
        return {
            msg: 'Select Risk Type',
            status: false,
        }
    if (!rtoRiskFlag)
        return {
            msg: 'Enter Risk Flag',
            status: false,
        }
    if (!rtoRiskThreshold)
        return {
            msg: 'Enter Bucket Size',
            status: false,
        }
    if (rtoRiskThreshold < 0 || rtoRiskThreshold > 100)
        return {
            msg: 'Enter Bucket Size between 0 and 100',
            status: false,
        }

    return {
        msg: 'Success',
        status: true,
    }
}

const validateControlSegmentSum = (configData) => {
    let sum
    for (let k in configData?.segments) {
        sum = Object.values(configData?.segments[k]?.values).reduce(
            (partialSum, a) => (partialSum as number) + (a as number),
            0,
        )
        if (sum !== 100) return false
    }
    return true
}

const checkWhiteSpace = (str) => str.replace(/\s+/g, '')

export {
    getTagColor,
    getTagTextByPercentage,
    pollIngestionStatus,
    notifyAndPollUpload,
    validateFile,
    getFileType,
    parseFileContents,
    validateFileHeaders,
    getPreviousMonthAwbFillRate,
    transformDataSource,
    aggregateMonthlyAWBData,
    validateRTOModelConfig,
    validateControlSegmentConfig,
    validateControlSegmentSum,
    checkWhiteSpace,
    filterAndDownloadCSVData,
}
