import { ReactNode, useEffect, useMemo, useRef } from 'react'
import { Layout, Menu, WarningFilled } from 'gokwik-ui-kit'
import { useNavigate, Routes, Route, useLocation } from 'react-router-dom'
import AllWorkflow from '@library/components/actions/all-workflow'
import { useSelector } from 'react-redux'
import { getUserConfig, getMerchantDetails, getUserDetails } from '@store/user/selectors'
import EmptyPageData from '@library/components/empty-page-data'
import { logEvent } from '@library/utilities/userLogEvents/userLogEvents'

const { Sider } = Layout

interface MenuItem {
    key: string
    label: ReactNode
    icon?: ReactNode
    children?: MenuItem[]
    type?: 'group'
}

function createMenuItem(
    label: ReactNode,
    key: string,
    icon?: ReactNode,
    children?: MenuItem[],
    type?: 'group',
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
        type,
    }
}
export default function () {
    const route = useLocation()
    const navigate = useNavigate()
    const merchantConfig = useSelector(getUserConfig)
    const merchantData = useSelector(getMerchantDetails)
    const user_details = useSelector(getUserDetails)
    const { selected_products, show_kwik_ai, show_kwik_ai_risk_navigation } = merchantConfig;
    const showRTOActions = show_kwik_ai && show_kwik_ai_risk_navigation && selected_products?.rto;

    useEffect(() => {
        if (route.pathname.includes('/rto/actions')) {
            navigate('/rto/kwikflows')
        }
    }, [])

    if (merchantConfig && !showRTOActions) {
        return (
            <EmptyPageData
                title="You currently don't have access to this page!"
                icon={<WarningFilled className='text-[#ffc107] text-7xl' />}
            />
        )
    }

    const previousOpenedItems = useRef({
        openedKey: '',
        selectedKey: '',
    })

    const settingsList = [
        {
            title: 'All Workflows',
            path: '/all-workflows',
            component: AllWorkflow,
        },
        {
            title: 'All Workflows',
            path: '/',
            component: AllWorkflow,
        },
    ]

    const menuItems = () => {
        return [
            createMenuItem('All Workflows', '/rto/kwikflows/all-workflows'),
        ]
    }

    useEffect(() => {
        logEvent('kwik_rto_rto_actions_clicked', 'click', 'Kwik RTO Actions', user_details?.email, merchantData?.m_id, merchantData?.short_name, user_details?.name);
    }, [merchantData, user_details])

    const openItems = useMemo(() => {
        const menu = menuItems()

        const openedKey = menu.find((item) => {
            if (route.pathname.includes(item.key)) {
                return true
            }
            return false
        })

        if (openedKey) {
            previousOpenedItems.current = {
                openedKey: openedKey.key,
                selectedKey: openedKey.key,
            }
            return {
                openedKey: openedKey.key,
                selectedKey: openedKey.key,
            }
        } else {
            previousOpenedItems.current = {
                openedKey: '/rto/kwikflows/all-workflows',
                selectedKey: '/rto/kwikflows/all-workflows',
            }
            return {
                openedKey: '/rto/kwikflows/all-workflows',
                selectedKey: '/rto/kwikflows/all-workflows',
            }
        }
    }, [route.pathname])

    const handleMenuSelection = ({ key }: any) => {
        navigate(key)
    }

    useEffect(() => {
        if (route.pathname === '/rto/actions') navigate('/rto/kwikflows' + settingsList[0].path)
        if (route.pathname === '/rto/kwikflows') navigate('/rto/kwikflows' + settingsList[0].path)
    }, [route.pathname])

    return (
        <div className='w-full bg-white'>
            <Layout className='bg-white h-full '>
                <Sider className='bg-white h-full border-0 border-gray-100 border-r-2 fixed overflow-hidden'>
                    <div className='setting-menu-custom-css'>
                        <Menu
                            onClick={handleMenuSelection}
                            defaultOpenKeys={[openItems.openedKey]}
                            defaultSelectedKeys={[openItems.selectedKey]}
                            key={route.pathname}
                            className='h-screen'
                            items={menuItems()}
                            inlineCollapsed={true}
                            mode='inline'
                        />
                    </div>
                </Sider>
                <Layout className='px-0 overflow-auto bg-white'>
                    <Routes>
                        {settingsList.map((item, index) => (
                            <Route key={index} path={item.path} element={<item.component />} />
                        ))}
                    </Routes>
                </Layout>
            </Layout>
        </div>
    )
}
